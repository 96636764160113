import React, { useState, useEffect, useRef } from 'react'
import useCheckCodePushVersion from '../../utils/customHooks/useConvertCodePushVersion'
import WhiteTextTimer from '../WhiteTextTimer'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import DealAutomationStore from '../../store/DealAutomationStore'
import TimeStore from '../../store/TimeStore'
import { useNavigate } from 'react-router-dom'
import timeChecker from 'utils/timeChecker'
import AlwayzUserStore from 'store/AlwayzUserStore'
import userButtonClickLogger from 'utils/userButtonClickLogger'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import { observer } from 'mobx-react-lite'
import ABStore from 'store/ABStore'
import moment from 'moment'
import AlfarmAdStore from 'store/AlfarmAdStore'

const sprite = [
  //브랜드배너딜 진입점
  {
    type: 'alfarmBrandBannerDeal',
    img: () => `icon/alfarmBrandBannerDeal/BrandBannerDealBottomIcon.png`,
    display: ({ farmData }) => farmData?.giveWaterCount >= 300,
    onClick: () => {
      ModalStore.setIsModalOpen('AlfarmBrandBannerDealModal')
    },
    codepush: '4.8.51',
    text: ({ farmData }) =>
      !farmData?.browsingBannerAdAt ||
      (farmData?.browsingBannerAdAt &&
        moment(farmData?.browsingBannerAdAt).format('YYYY-MM-DD') !==
          moment().format('YYYY-MM-DD'))
        ? `물 20g`
        : 'NEW',
  },
  {
    type: 'teamAlfarm',
    img: () => `/teamAlfarm/entryIcon.png`,
    display: ({ giveWaterCount, farmData }) =>
      (ABStore?.teamAlfarmTest && giveWaterCount > 300) ||
      (farmData?.teamAlfarmUser && farmData?.teamAlfarmUser?.teamId),

    onClick: () => {},
  },
  // 팀추첨임
  {
    type: 'lottery',
    img: () => `/icon/lotteryItemv3.png`,
    display: ({ giveWaterCount }) =>
      giveWaterCount > 12 && new Date('2023/08/21 19:00:00') > new Date(),
    codepush: '2.7.65',
    onClick: () => {
      const prop = {
        itemId: '64ddc2705a2b0fbd7ff31400',
        enteringComponent: 'Lottery',
        teamDealId: AlwayzUserStore.teamDealId || '',
      }
      window.location.href = `#quizPurchaseReward.${JSON.stringify(prop)}`
    },
    text: () => `기간한정`,
  },

  // 구 - 포춘쿠키
  {
    type: 'eggBreakers',
    img: () => '/eggBreakers/fortuneCookieEntry.png',
    display: ({ giveWaterCount, userId, farmData }) => {
      let cond1 =
        !ABStore.isCPMAdViewer &&
        giveWaterCount > 200 &&
        !farmData?.resurrectBonus &&
        farmData?.gganbuList?.length > 0
      return cond1
    },
    codepush: '2.3.87',
    onClick: () => {
      ModalStore.setIsModalOpen('eggBreakersV2')
    },
    text: ({ farmData }) => {
      const filteredEggHistory = farmData?.breakEggHistory?.filter((x) => {
        return !timeChecker(x?.brokenAt)
      })
      let chance = filteredEggHistory?.length || 0
      if (
        chance >= AlfarmAdStore.eggAdDailyMaxExposureCount &&
        new Date(
          farmData?.breakEggHistory[
            farmData?.breakEggHistory?.length - 1
          ]?.brokenAt,
        ).getTime() +
          1000 * 60 * 60 * AlfarmAdStore.eggAdTimeIntervalBetweenExposure <
          new Date().getTime()
      ) {
        return '내일 가능'
      } else if (
        farmData?.breakEggHistory &&
        new Date(
          farmData?.breakEggHistory[
            farmData?.breakEggHistory?.length - 1
          ]?.brokenAt,
        ).getTime() +
          1000 * 60 * 60 * AlfarmAdStore.eggAdTimeIntervalBetweenExposure >=
          new Date().getTime()
      ) {
        return `구매보상`
      } else {
        return ` ${AlfarmAdStore.eggAdDailyMaxExposureCount - chance}번 남음`
      }
    },
  },
  {
    type: 'firstBrandDeal',
    img: () => DealAutomationStore.firstBrandDealData.entryIcon,
    display: ({ giveWaterCount, farmData }) =>
      giveWaterCount > 100 &&
      TimeStore.currentDayJS >
        dayjs(DealAutomationStore.firstBrandDealData.timeRangeStart) &&
      TimeStore.currentDayJS <
        dayjs(DealAutomationStore.firstBrandDealData.timeRangeEnd),

    codepush: '2.3.54',
    onClick: () => {
      ModalStore.setIsModalOpen('brandDeal')
    },
  },
  {
    type: 'secondBrandDeal',
    img: () => DealAutomationStore.secondBrandDealData.entryIcon,
    display: ({ giveWaterCount, farmData }) =>
      giveWaterCount > 100 &&
      TimeStore.currentDayJS >
        dayjs(DealAutomationStore.secondBrandDealData.timeRangeStart) &&
      TimeStore.currentDayJS <
        dayjs(DealAutomationStore.secondBrandDealData.timeRangeEnd),

    codepush: '2.3.54',
    onClick: () => {
      ModalStore.setIsModalOpen('brandDeal')
    },
  },
  // 오늘의특가 - 평소에 노출 안 되어있다가, 소비자가 광고 보고 보상 받으면 그 때 x시간 동안 노출
  // 아래 first ~ second는 진입 아이콘의 숫자를 바꾸기 위함이라 건드릴 필요 없음
  {
    type: 'fertMachine',
    img: () => `/icon/produceFertMachinev3.png`,
    display: ({ producingFertMachine }) => !!producingFertMachine,
    codepush: '1.6.26',
    onClick: () => {
      ModalStore.setIsModalOpen('produceFertMachine')
    },
    largeText: ({ produceFertMachineHistory }) =>
      Math.min(Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10), 8),
  },

  {
    type: 'phoneBookGganbu',
    img: () => `/phoneBookGganbu/entryIconv3.png`,
    display: ({ giveWaterCount, farmData }) =>
      giveWaterCount > 300 &&
      farmData?.gganbuList?.filter((each) => !each?.deletedAt)?.length < 20 &&
      !farmData?.resurrectBonus,
    codepush: '2.1.12',
    onClick: () => {
      // ModalStore.setIsModalOpen('contactsList')
      window.location.href = `#alfarmCheckContactsPermission`
    },
    text: () => `보상가득`,
  },
  {
    type: 'requestGganbu',
    img: () => '/requestGganbu/entryIcon.png',
    display: ({ farmData }) => {
      let temp = farmData?.gganbuRequestList?.filter((item) => !item?.deletedAt)
      let flag = temp?.length > 0
      // if (flag) {
      //   setTimeout(() => {
      //     ModalStore.setIsModalOpen("requestGganbu");
      //   }, 1500);
      // }

      return flag
    },
    codepush: '2.3.77',
    onClick: () => {
      ModalStore.setIsModalOpen('requestGganbu')
    },
    shake: true,
    // text: () => ``,
  },
]

const IconButton = observer(({ data, props }) => {
  const throttleRef = useRef({})

  const navigate = useNavigate()

  const codePushVersionAvailable = useCheckCodePushVersion()
  const {
    img,
    display,
    codepush,
    onClick,
    timer = null,
    text = null,
    largeText = null,
    type,
    shake,
    textForceHide = () => false,
    style = () => ({}),
  } = data

  const available =
    codePushVersionAvailable(props?.userCodePushVersion, codepush) &&
    display(props)

  const src = img(props)
  const buttonStyle = style({ props })

  let content = false
  if (timer && timer(props) > TimeStore.currentNewDate) {
    content = (
      <WhiteTextTimer
        style={{ fontSize: '3vw' }}
        timeStamp={timer(props)}
        timerMinutes={0}
      />
    )
  }

  if (text && !textForceHide())
    content = <div style={{ fontSize: '3vw' }}>{text(props)}</div>
  if (largeText)
    content = <div style={{ fontSize: '3.5vw' }}>{largeText(props)}</div>

  if (!available) {
    return null
  }
  if (available) {
    return (
      <button
        className={shake ? 'shake-bottom' : ''}
        style={{
          paddingTop: '0.5vw',
          margin: 0,
          marginTop: '0.5vw',
        }}
        onClick={() => {
          if (type === 'teamAlfarm') {
            navigate(
              `../teamAlfarm?token=${props?.token}&itemType=${props?.itemType}`,
            )
          }
          if (type === 'grapeEvent') {
            navigate(
              `../grapeEvent?token=${props?.token}&itemType=${props?.itemType}`,
            )
          }
          if (type === 'friendGarden') {
            navigate(
              `../friendGarden?token=${props?.token}&itemType=${props?.itemType}`,
            )
          }
          AlfarmEventLogger({
            throttleRef,
            locationType: 'bottomEventIcons',
            locationName: type,
            eventType: 'click',
            collection: 'UserAlfarmClickLog',
          })

          userButtonClickLogger({ buttonType: type })

          onClick(props)
        }}
      >
        {content && (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                fontFamily: 'maplestory',
                position: 'absolute',
                textAlign: 'left',
                background: '#EA3323',
                color: 'white',
                borderRadius: '5vw',
                paddingTop: '1vw',
                paddingBottom: '1vw',
                paddingRight: '2vw',
                paddingLeft: '2vw',
                right: '0vw',
                top: '-1vw',
                zIndex: 1,
              }}
            >
              {content}
            </div>
          </div>
        )}
        <img
          style={{
            width: '18vw',
            height: '17vw',
            ...buttonStyle,
          }}
          alt=''
          src={src}
        />
      </button>
    )
  }
})

const CPMSprite = observer(({ props }) => {
  const cpmSprites = [
    {
      type: 'CPMLeavesAdButton',
      img: () => props?.cpmLeavesAdProps?.bottomEventIconImageUrl,
      style: ({ props }) => ({
        filter: props?.cpmLeavesAdProps?.isAdAvailable
          ? 'none'
          : 'grayscale(100%)',
        opacity: props?.cpmLeavesAdProps?.isAdAvailable ? 1 : 0.9,
      }),
      display: () =>
        ABStore.isCPMAdViewer && // 테스터만 노출
        props?.cpmLeavesAdProps?.isAdDisplayable &&
        (props?.cpmLeavesAdProps?.hasRemainingViewCounts ?? true) && // 수량 있을 때에만 보임
        props?.farmData?.giveWaterCount >= 200, // 원래 있던 조건
      onClick: () => {
        props?.cpmLeavesAdProps?.isAdAvailable &&
          ModalStore.setIsModalOpen(props?.cpmLeavesAdProps?.modalPath)
      },
      text: () => props?.cpmLeavesAdProps?.buttonText,
      shake: props?.cpmLeavesAdProps?.isAdAvailable,
    },
    {
      type: 'CPMFortuneCookieAdButton',
      img: () => props?.cpmFortuneCookieAdProps?.bottomEventIconImageUrl,
      style: ({ props }) => ({
        filter: props?.cpmFortuneCookieAdProps?.isAdAvailable
          ? 'none'
          : 'grayscale(100%)',
        opacity: props?.cpmFortuneCookieAdProps?.isAdAvailable ? 1 : 0.9,
      }),
      display: () =>
        ABStore.isCPMAdViewer && // 테스터만 노출
        props?.cpmFortuneCookieAdProps?.isAdDisplayable &&
        (props?.cpmFortuneCookieAdProps?.hasRemainingViewCounts ?? true) && // 수량 있을 때에만 보임
        props?.farmData?.giveWaterCount >= 200, // 원래 있던 조건

      onClick: () => {
        props?.cpmFortuneCookieAdProps?.isAdAvailable &&
          ModalStore.setIsModalOpen(props?.cpmFortuneCookieAdProps?.modalPath)
      },
      text: () => props?.cpmFortuneCookieAdProps?.buttonText,
      shake: props?.cpmFortuneCookieAdProps?.isAdAvailable,
    },
    // 다른 비동기 아이콘들도 여기에 추가
  ]

  return (
    <>
      {cpmSprites.map((each) => (
        <IconButton key={each.type} data={each} props={props} />
      ))}
    </>
  )
})

const BottomEventIcons = observer((props) => {
  return (
    <div className='flex-shrink-0'>
      <CPMSprite props={props} />
      {sprite?.map((each) => (
        <IconButton key={each.type} data={each} props={props} />
      ))}
    </div>
  )
})

export default BottomEventIcons
