import CryptoJS from 'crypto-js'

const tckMembers = [
  '6372d7f0a46f404188904124',
  '648010ee7963166cb100d727',
  '65f7c4d7b3af9957fba5c41e',
  '643784c8b38345fb0f44b994',
  '65e537eebf2ab1a9a79e0168',
  '66b05a03df87cd06d46aec59',
  '65adf386f43f89899779b7fd',
  '63e1d1d5802c060d5cbcce3a',
  '63ec391662e5fc0668c503e8',
  '647dc53478ab377b911eef63',
  '6424e3f8c2366109ac3c497a',
  '66668698d077efd545a56aff',
  '66cc0b9d7fb243335b44bbc7',
  '66cbc48575b6ae01aa2f926b',
  '66cb32afca784be8bcf844b7',
  '67159b082fc2e4b0f4d30b8e',
  '66d7c96bf48275e14f042776',
  '61d4393cbdc3672fc80ac3a7',
  '6703653693b25c6594261bb5',
  '65adf54c35e157468895ac73',
  '63d50410fe4d4d0f70b942c2',
  '65f7a2e49ca1010a3f00c1e9',
  '63c738881abcaf131a4c8b8a',
  '633b74fa1c50280453f012d6',
  '64d096f30cb1b20a097b492f',
  '65b0c955edc1c426ed4e8758',
  '66fcebbc28c9799b9f37b0cf',
  '670331c7a632f2e85befce6d',
  '67036528449fb3daf16d8b69',
  '6715a1bc35690098ee1ee3c1',
  '6715a842e0ce638552dae395',
  '63a1ea7595ca936935cc204d',
  '6715d8ec76969d80f7b45601',
  '67318eeda2505ac58544c343',
  '67318d684662400897925353',
  '64a8383889314e34086d6bb2',
  '66a8c7910aa4f85a32c1704c',
  '66345c1cd530fb6b2dbc8779',
  '648bf3bed4a10707c3a78df5',
  '66227fbaf177482338245ea9',
  '65a0e184d84c1296c15d78f1',
  '63f5775827aa911c0c6bc8bd',
].reduce((acc, id) => {
  return {
    ...acc,
    [`${id}_badaham1107`]: 'b',
  }
}, {})

const members = {
  '6278ad49821f0816a0efb28a_kakaopay1': 'b',
  '624ec6d947e1e75262a67069_kakaopay1': 'b',
  '60edc82a02e0338c61886477_kakaopay1': 'b',
  '6280857fa7b6c50ef93b1915_algorithm': 'd',
  '63170d9c356d44d1c9404184_algorithm': 'c',
  '626ec2b1a66592f5c1603f5f_algorithm': 'c',
  '6229d4bffab6bc5deedfd8b3_algorithm': 'c',
  '60edc82a02e0338c61886477_recommendModelRandomSelect': 'a',
  '60edc82a02e0338c61886477_aiPricing': 'a',
  '62289ecd88d236b5f8d40bc4_aiPricing': 'a',
  '62494250ec5c9b755afbba10_aiPricing': 'a',
  '6280857fa7b6c50ef93b1915_openteamdeal1108': 'b',
  '6368c909eb756b0381c89be2_openteamdeal1108': 'a',
  '60edc82a02e0338c61886477_timedeal0906': 'a',
  '62494250ec5c9b755afbba10_rankdeal0906': 'a',
  '62baa11046770bc2278c7f19_rankdeal0906': 'a',
  '62289ecd88d236b5f8d40bc4_rankdeal0906': 'a',
  '62271be8b3ef552fb2dd424d_rankdeal0906': 'a',
  '60fa478ad28d5e1c65152c6f_rankdeal0906': 'b',
  '6171614346fa9f36878921c1_rankdeal0906': 'a',
  '6280857fa7b6c50ef93b1915_mabsys0126': 'b',
  '6171614346fa9f36878921c1_lqd0130': 'a',
  '60fa478ad28d5e1c65152c6f_lqd0130': 'b',
  '6171614346fa9f36878921c1_ltd0207': 'a',
  '60fa478ad28d5e1c65152c6f_ltd0207': 'a',
  '626ec2b1a66592f5c1603f5f_canary0216': 'b',
  '6280857fa7b6c50ef93b1915_canary0216': 'b',
  '614b747d698b43323956cf2c_catefi0308': 'a',
  '614b747d698b43323956cf2c_canary0216': 'b',
  '6280857fa7b6c50ef93b1915_serverChange0309': 'b',
  '60f7444cd28d5e1c65152c21_catere0316': 'a', // 현식
  '60edc82a02e0338c61886477_arrivedOrderCaching': 'a', // 현직
  '6280857fa7b6c50ef93b1915_searchPersonalize0421': 'b',
  '614b747d698b43323956cf2c_styleMainToggle0407': 'a',
  '6301c88204228e2fd03fb3b6_EnhanceImpThruSearch': 'a', //세진
  '6280857fa7b6c50ef93b1915_fastFeedback0515': 'b',
  '6280857fa7b6c50ef93b1915_searchES0615': 'b',
  '6280857fa7b6c50ef93b1915_teamDealFix0713': 'b',
  '614b747d698b43323956cf2c_searchES0615': 'b',
  '626aa6a6c4d18f4110ecd6cb_dailyCheckInAd0605': 'e',
  // deal
  '62494250ec5c9b755afbba10_purechunk0618': 'b', // 진희
  '63d5ee1d3996f9130f8a7440_purechunk0618': 'b', // 영훈
  '62494250ec5c9b755afbba10_insert0622': 'a',
  '63d5ee1d3996f9130f8a7440_insert0622': 'a',
  '62494250ec5c9b755afbba10_related0629': 'a',
  '63d5ee1d3996f9130f8a7440_related0629': 'a',
  '6280857fa7b6c50ef93b1915_searchRelatedKeyword0704': 'b',
  '614b747d698b43323956cf2c_searchRelatedKeyword0704': 'b',
  '62494250ec5c9b755afbba10_visited0712': 'a', // 진희
  '63d5ee1d3996f9130f8a7440_visited0712': 'a', // 영훈
  '62494250ec5c9b755afbba10_random0718': 'a', // 진희
  '63d5ee1d3996f9130f8a7440_random0718': 'a', // 영훈
  '62494250ec5c9b755afbba10_price0720': 'a', // 진희
  '63d5ee1d3996f9130f8a7440_price0720': 'a', // 영훈
  '6280857fa7b6c50ef93b1915_itemWinnerAB0731': 'a', // 영환
  '614b747d698b43323956cf2c_itemWinnerAB0731': 'b', // 시창
  '61adb8da70ddf077458f5688_itemWinnerAB0731': 'b', // MD 나라
  '60edc82a02e0338c61886477_visited0817': 'b',
  '60edc82a02e0338c61886477_price0720': 'b',
  '649edb415d75ad38c6a1c445_tenMinDeal1025': 'b',
  '60edc82a02e0338c61886477_tenMinDeal1025': 'b', // 현직
  '6486ad07e7745aa2fd26566e_tenMinDeal1025': 'b', // 우진 dev
  '620cce8196d049583db642e3_tenMinDeal1025': 'd', // 우진
  '64983246bc423e260005dc6e_tenMinDeal1025': 'c', // 정준
  '651105228137d2b41b5d2e93_tenMinDeal1025': 'd', // 민웅
  '62494250ec5c9b755afbba10_tenMinDeal1025': 'a', // 진희
  '643df625a7a3e326096aece6_tenMinDeal1025': 'b', // 유성
  '60f7444cd28d5e1c65152c21_tenMinDeal1025': 'd', // 현식
  '614b747d698b43323956cf2c_winnerDeal1016': 'b', // 시창
  '6280857fa7b6c50ef93b1915_winnerDeal1016': 'c', // 영환
  '62494250ec5c9b755afbba10_winnerDeal1016': 'd', // 진희
  '60edc82a02e0338c61886477_winnerDeal1016': 'e', // 현직
  '64983246bc423e260005dc6e_winnerDeal1016': 'f', // 정준
  '643df625a7a3e326096aece6_luckTest1022': 'b', // 유성
  '6243835a9d22e940cecde8a5_luckTest1022': 'b', // 유성
  '64983246bc423e260005dc6e_winnerDeal1020': 'f', // 정준
  '64983246bc423e260005dc6e_winnerDeal1023': 'e', // 정준
  '614b747d698b43323956cf2c_winnerDeal1023': 'e', // 시창
  '64983246bc423e260005dc6e_winnerDealSorting1025': 'e', //정준
  '64983246bc423e260005dc6e_winnerDealMethod1025': 'c', //정준
  '6280857fa7b6c50ef93b1915_shippingInfoPush1125': 'b', // 영환
  '63aaa42da6e60a0e1fbdd7a3_shippingInfoPush1125': 'b',
  '6273409489df7134fe1d3674_yutnori0114': 'b',
  '627d10bfd430af8ffc940c7a_yutnori0114': 'b',
  '62271be8b3ef552fb2dd424d_yutnori0114': 'b',
  '60f1393ffc798a6746ccb33e_yutnori0114': 'b',
  '6243835a9d22e940cecde8a5_yutnori0114': 'b',
  '60edc82a02e0338c61886477_yutnori0114': 'b',
  '64d8a693675a64d4c3e20a31_yutnori0114': 'b', // dev
  '6273409489df7134fe1d3674_tWaterUp0116': 'b',
  '6273409489df7134fe1d3674_tteokgguk0122': 'b',
  '627d10bfd430af8ffc940c7a_tteokgguk0122': 'b',
  '620cce8196d049583db642e3_newYear24DealGetWaterBtn0123': 'a', // 우진
  '627d10bfd430af8ffc940c7a_newYear24DealGetWaterBtn0123': 'a', // 지성
  '6273409489df7134fe1d3674_newYear24DealGetWaterBtn0123': 'a', // 범래
  '64983246bc423e260005dc6e_newYear24DealGetWaterBtn0123': 'a', // 정준
  '627d10bfd430af8ffc940c7a_rewardTest0213': 'a', // wtwt
  '627d10bfd430af8ffc940c7a_cheerupGganbuTest': 'a',
  '643df625a7a3e326096aece6_reward0815': 'b',
  '627d10bfd430af8ffc940c7a_reward0815': 'b',
  '626ec2b1a66592f5c1603f5f_alranch0911': 'b',
  '6565aa51ed116a90f986d306_alranch0911': 'b',

  '652e877c5b42725b35fccbfe_showDramaMission1010': 'a', // 범순
  '6676526427b3189f5d5ffe13_showDramaMission1010': 'b', // 현경
  '6655a627a6f8326bda1d0abe_showDramaMission1010': 'b', // 승호
  '64d98ee742e2c30efc87d6e5_offerwallWaterRange1017': 'b', // 민국
  '634cd960677f1e3b8b9ff306_offerwallWater1022': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_offerwallWater1022': 'b', // 민국
  '63bfb30b14a835810aacffb3_offerwallWater1022': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_offerwallWater1022': 'b', // 욱

  '620cce8196d049583db642e3_offerwallWater1022': 'b', // 우진
  '660f5187d674252fe0453e66_offerwallWater1022': 'b', // 버즈빌 사람들
  '6582f286cda05b8dcb556227_offerwallWater1022': 'b', // 버즈빌 사람들
  '66a206b38b54b6add496e38c_offerwallWater1022': 'b', // 버즈빌 사람들
  '63ea25fc7acef407511ff71c_offerwallWater1022': 'b', // 버즈빌 사람들
  '64e88e7fba9e505050566a94_offerwallWater1022': 'b', // 버즈빌 사람들

  '626ec2b1a66592f5c1603f5f_farmEfficacy241016': 'b', // 지헌
  '627d10bfd430af8ffc940c7a_farmEfficacy241016': 'b', // 지성
  '65bafbcf5db92a7b27b554a4_farmEfficacy241016': 'b', // 종현
  '670faed62a34f0f1cd58a8ec_farmEfficacy241016': 'b', // 종현2
  '641dbcc308507ead72c47fa5_farmEfficacy241016': 'b', // 솔비
  '64d98ee742e2c30efc87d6e5_bannerBuzzvil1025': 'b', // 민국
  '652dc352cc8af8976dc9cde1_bannerBuzzvil1025': 'b', // 기수
  '620cce8196d049583db642e3_bannerBuzzvil1025': 'b', // 우진

  '634cd960677f1e3b8b9ff306_dailyMissionBanner1106': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_dailyMissionBanner1106': 'b', // 민국
  '63bfb30b14a835810aacffb3_dailyMissionBanner1106': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_dailyMissionBanner1106': 'b', // 욱

  '620cce8196d049583db642e3_badaham1107': 'b', // 우진
  '634cd960677f1e3b8b9ff306_badaham1107': 'b', // 종근
  '66d2e95858c039e66de7789e_badaham1107': 'b', // 승민
  '64d98ee742e2c30efc87d6e5_badaham1107': 'b', // 민국
  '63bfb30b14a835810aacffb3_badaham1107': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_badaham1107': 'b', // 욱
  '616d3536e3cda663ff558501_badaham1107': 'b', // 영재
  '668cede0f40db3d37b0fdb11_badaham1107': 'b', // 구글 재인 매니저
  '66cbd7d8acab18533caab325_badaham1107': 'b', // 혁진
  '6565aa51ed116a90f986d306_badaham1107': 'b', // 주언
  '652e877c5b42725b35fccbfe_badaham1107': 'b', // 범순

  '620cce8196d049583db642e3_specialWaterBomb1217': 'b', // 우진
  '634cd960677f1e3b8b9ff306_specialWaterBomb1217': 'b', // 종근
  '66d2e95858c039e66de7789e_specialWaterBomb1217': 'b', // 승민
  '64d98ee742e2c30efc87d6e5_specialWaterBomb1217': 'b', // 민국
  '63bfb30b14a835810aacffb3_specialWaterBomb1217': 'b', // 은지
  '627d10bfd430af8ffc940c7a_specialWaterBomb1217': 'b', // 지성
  '620cce8196d049583db642e3_specialLotteryDraw1219': 'b', // 우진
  '634cd960677f1e3b8b9ff306_specialLotteryDraw1219': 'b', // 종근
  '66d2e95858c039e66de7789e_specialLotteryDraw1219': 'b', // 승민
  '64d98ee742e2c30efc87d6e5_specialLotteryDraw1219': 'b', // 민국
  '63bfb30b14a835810aacffb3_specialLotteryDraw1219': 'b', // 은지
  '627d10bfd430af8ffc940c7a_specialLotteryDraw1219': 'b', // 지성
  '6246b1c63433802aafeb591f_specialLotteryDraw1219': 'b', // 조소현님
  '64e7cad226232f188ba057d4_specialLotteryDraw1219': 'b', // 박정은님

  '634cd960677f1e3b8b9ff306_basicWaterDouble1113': 'c', // 종근
  '64d98ee742e2c30efc87d6e5_basicWaterDouble1113': 'c', // 민국
  '63bfb30b14a835810aacffb3_basicWaterDouble1113': 'c', // 은지
  '626aa6a6c4d18f4110ecd6cb_basicWaterDouble1113': 'c', // 욱
  '634cd960677f1e3b8b9ff306_cpcvABTest1127': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_cpcvABTest1127': 'b', // 민국
  '63bfb30b14a835810aacffb3_cpcvABTest1127': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_cpcvABTest1127': 'b', // 욱
  '620cce8196d049583db642e3_cpcvABTest1127': 'b', // 욱
  '65bafbcf5db92a7b27b554a4_cpcvABTest1127': 'b', // 종현
  '6246b1c63433802aafeb591f_cpcvABTest1127': 'b', // 조소현님
  '64e7cad226232f188ba057d4_cpcvABTest1127': 'b', // 박정은님
  '641dbcc308507ead72c47fa5_cpcvABTest1127': 'b', // 솔비
  '627d10bfd430af8ffc940c7a_cpcvABTest1127': 'b', // 지성
  '6690b6ed54282e4b06bf3908_cpcvABTest1127': 'b', // 나스미디어 안건 매니저
  '6565aa51ed116a90f986d306_cpcvABTest1127': 'b', // 주언
  '634cd960677f1e3b8b9ff306_badahamAdTreasureHunt1128': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_badahamAdTreasureHunt1128': 'b', // 민국
  '63bfb30b14a835810aacffb3_badahamAdTreasureHunt1128': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_badahamAdTreasureHunt1128': 'b', // 욱
  '620cce8196d049583db642e3_badahamAdTreasureHunt1128': 'b', // 우진
  '65bafbcf5db92a7b27b554a4_badahamAdTreasureHunt1128': 'b', // 종현
  '6246b1c63433802aafeb591f_badahamAdTreasureHunt1128': 'b', // 조소현님
  '64e7cad226232f188ba057d4_badahamAdTreasureHunt1128': 'b', // 박정은님
  '641dbcc308507ead72c47fa5_badahamAdTreasureHunt1128': 'b', // 솔비
  '627d10bfd430af8ffc940c7a_badahamAdTreasureHunt1128': 'b', // 지성
  '6690b6ed54282e4b06bf3908_badahamAdTreasureHunt1128': 'b', // 나스미디어 안건 매니저
  '6565aa51ed116a90f986d306_badahamAdTreasureHunt1128': 'b', // 주언
  '66cbd7d8acab18533caab325_badahamAdTreasureHunt1128': 'b', // 혁진

  '634cd960677f1e3b8b9ff306_badahamOfferwallBrowsing1128': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_badahamOfferwallBrowsing1128': 'b', // 민국
  '63bfb30b14a835810aacffb3_badahamOfferwallBrowsing1128': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_badahamOfferwallBrowsing1128': 'b', // 욱
  '620cce8196d049583db642e3_badahamOfferwallBrowsing1128': 'b', // 우진
  '65bafbcf5db92a7b27b554a4_badahamOfferwallBrowsing1128': 'b', // 종현
  '6246b1c63433802aafeb591f_badahamOfferwallBrowsing1128': 'b', // 조소현님
  '64e7cad226232f188ba057d4_badahamOfferwallBrowsing1128': 'b', // 박정은님
  '641dbcc308507ead72c47fa5_badahamOfferwallBrowsing1128': 'b', // 솔비
  '627d10bfd430af8ffc940c7a_badahamOfferwallBrowsing1128': 'b', // 지성
  '6690b6ed54282e4b06bf3908_badahamOfferwallBrowsing1128': 'b', // 나스미디어 안건 매니저
  '6565aa51ed116a90f986d306_badahamOfferwallBrowsing1128': 'b', // 주언
  '66cbd7d8acab18533caab325_badahamOfferwallBrowsing1128': 'b', // 혁진

  '66cbd7d8acab18533caab325_scratchLotto1222': 'b', // 혁진
  '634cd960677f1e3b8b9ff306_scratchLotto1222': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_scratchLotto1222': 'b', // 민국
  '63bfb30b14a835810aacffb3_scratchLotto1222': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_scratchLotto1222': 'b', // 욱
  '620cce8196d049583db642e3_scratchLotto1222': 'b', // 우진
  '65bafbcf5db92a7b27b554a4_scratchLotto1222': 'b', // 종현
  '6246b1c63433802aafeb591f_scratchLotto1222': 'b', // 조소현님
  '64e7cad226232f188ba057d4_scratchLotto1222': 'b', // 박정은님
  '627d10bfd430af8ffc940c7a_scratchLotto1222': 'b', // 지성
  '6565aa51ed116a90f986d306_scratchLotto1222': 'b', // 주언

  '634cd960677f1e3b8b9ff306_isFertilizerSetAdWatchTester1219': 'b', // 종근
  '64d98ee742e2c30efc87d6e5_isFertilizerSetAdWatchTester1219': 'b', // 민국
  '63bfb30b14a835810aacffb3_isFertilizerSetAdWatchTester1219': 'b', // 은지
  '626aa6a6c4d18f4110ecd6cb_isFertilizerSetAdWatchTester1219': 'b', // 욱
  '620cce8196d049583db642e3_isFertilizerSetAdWatchTester1219': 'b', // 우진
  '62e37050fc5f215e10c9ee72__isFertilizerSetAdWatchTester1219': 'a', // 심예슬
  '66d51da1fc4176bf8079c9ac__isFertilizerSetAdWatchTester1219': 'a', // 이덕규
  '624a566271d05d3e4c35a7ef__isFertilizerSetAdWatchTester1219': 'a', // 윤승진
  '642562174d679c4da14aa974__isFertilizerSetAdWatchTester1219': 'a', // 손석주
  '661cde7cae157c5ac22eaf90__isFertilizerSetAdWatchTester1219': 'a', // 이동규
  '636c4664d2ae331bee0b1a35__isFertilizerSetAdWatchTester1219': 'a', // 임진혁
  '663c3386ed965925ca281553__isFertilizerSetAdWatchTester1219': 'a', // 김민경
  '61d51df1c12367667b781e68__isFertilizerSetAdWatchTester1219': 'a', // 김민경
  '66f8ea4c7b9855542527377d__isFertilizerSetAdWatchTester1219': 'a', // 김성연
  '61e8d7551ed88866d728b272__isFertilizerSetAdWatchTester1219': 'a', // 김용진
  '663d69a6c63b5decdf64736e__isFertilizerSetAdWatchTester1219': 'a', // 유지아
  '61b981a1bf836b26d4cf66e2__isFertilizerSetAdWatchTester1219': 'a', // 이지영

  '6565aa51ed116a90f986d306_fertilizerOfferwall1227': 'b', // 주언
  '652e877c5b42725b35fccbfe_fertilizerOfferwall1227': 'b', // 범순
  '620cce8196d049583db642e3_fertilizerOfferwall1227': 'b', // 우진
  '660f5187d674252fe0453e66_fertilizerOfferwall1227': 'a', // 버즈빌 사람들
  '6582f286cda05b8dcb556227_fertilizerOfferwall1227': 'a', // 버즈빌 사람들
  '66a206b38b54b6add496e38c_fertilizerOfferwall1227': 'a', // 버즈빌 사람들
  '63ea25fc7acef407511ff71c_fertilizerOfferwall1227': 'a', // 버즈빌 사람들
  '64e88e7fba9e505050566a94_fertilizerOfferwall1227': 'a', // 버즈빌 사람들

  '6565aa51ed116a90f986d306_radomAdDisplay1216': 'b', // 주언
  '620cce8196d049583db642e3_radomAdDisplay1216': 'b', // 우진

  '66d2e95858c039e66de7789e_withCPCCPSAdInCPM0120': 'b',
  '66d2e95858c039e66de7789e_screenScrollCPM0114': 'd',

  tckMembers,
}

const map = 'abcdefghij'
const AB = (id = '', key = 'test0000', g = [50]) => {
  if (!id) return 'a'
  if (`${id}_${key}` in members) {
    return members[`${id}_${key}`]
  }

  var hashed = CryptoJS.HmacSHA256(key, id).toString(CryptoJS.enc.Hex)
  const code = (parseInt(hashed?.slice(-8), 36) % 10000) / 100

  let r = ''
  g.forEach((each, i) => {
    if (code < each && !r) r = map[i]
  })

  if (!r) r = map[g.length]
  return r
}

export default AB
