const ClickFinger = () => {
  return (
    <div>
      <div
        style={{
          width: '8vw',
          height: '8vw',
          backgroundImage: 'url(../minigame/waterBomb/finger.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: 9001,
          position: 'absolute',
          top: '35vw',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '12vw',
          height: '8vw',
          zIndex: 9001,
          top: '30vw',
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'white',
          fontFamily: 'maplestory',
          textAlign: 'center',
        }}
      >
        클릭!
      </div>
    </div>
  )
}

export default ClickFinger
