import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ModalCloseButton from 'comps/atoms/buttons/ModalCloseButton'
import clsx from 'clsx'

const NewYearSpecialDealEntryModal = observer(() => {
  const event = 'newYear2025'
  const eventTitle = '복주머니'

  const [isButtonPressed, setIsButtonPressed] = useState(false)

  const throttleRef = useRef({})

  const handleClose = () => {
    ModalStore.setIsModalOpen('basic')
  }

  const endDate = new Date('2025-01-24T00:00:00.000Z') // UTC 기준 24일 9시
  const now = new Date()
  const diffTime = endDate - now
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const displayText = diffDays === 1 ? '오늘 마지막!' : `단 ${diffDays}일`

  const goToNewYearScreen = () => {
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'WebviewScreen',
      params: {
        title: '설 특가 상품',
        enteringComponent: `Alfarm_${event}`,
        enteringLocation: 'purchaseQuest',
        isFrom: `Alfarm_${event}`,
        dealTypes: ['mainBanner_newYear2025'],
        isBrowsingQuestAvailable: false,
        missionText: `초 구경하면 ${eventTitle} 2개을 받아요`,
        isDealRecommend: true,
        timer: 30,
        enabledScrollTimer: false,
        buyingReward: `고급비료 5개, ${eventTitle} 35개`,
        webviewPrefixType: 'shop',
        url: '/deals/main-banner-new-year-2025',
      },
    })}`
  }

  return (
    <>
      <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
        <div
          className='w-[90vw] h-[134vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute items-center flex-col px-[16px] pt-[40vw] pb-[16px]'
          style={{
            backgroundImage: 'url(../dealEvent/newYear2025/entryModal.png)',
          }}
        >
          <ModalCloseButton onClick={handleClose} style={{ top: '-3vw' }} />

          <div className='relative w-full mt-[6vw] flex justify-center flex-col gap-1 items-center'>
            <div className='flex text-[#6A3615] font-bold text-[6vw]'>
              파격적인 설날 특가 상점!
            </div>
            <div className='flex text-[#B28A72] text-[4.2vw]'>
              고급비료 5개, 복주머니 35개를 드려요
            </div>

            <img
              src='/dealEvent/newYear2025/modalReward.png'
              alt='reward'
              className='w-[60vw]'
              style={{ marginLeft: '6vw' }}
            />

            <div className='relative mt-[4vw] flex flex-col items-center w-full'>
              <img
                className='absolute top-[-6vw] w-[50vw]'
                src='/dealEvent/newYear2025/toastBackground.png'
              />
              <div className='absolute top-[-4.8vw] flex flex-row'>
                <div className='flex text-[#6A3615] font-bold text-[1.125rem]'>
                  상점 종료까지
                </div>
                &nbsp;
                <div className='flex text-[#F00] font-bold text-[1.125rem]'>
                  {displayText}
                </div>
              </div>
              <button
                type='button'
                className={clsx(
                  'w-[60vw] h-[14vw] disabled:bg-[#B2B2B2] bg-[#F7C82A] rounded-[99px] text-black font-maplestory text-[4.5vw] text-center flex justify-center items-center gap-2',
                  isButtonPressed &&
                    'shadow-[inset_3px_3px_4px_rgba(79,19,0,0.15)]',
                )}
                onClick={() => {
                  AlfarmEventLogger({
                    throttleRef,
                    locationType: 'page',
                    locationName: 'dealEventNudge',
                    eventType: 'click',
                    eventName: 'modal',
                    data: {},
                    collection: 'UserAlfarmClickLog',
                  })

                  goToNewYearScreen()

                  ModalStore.setIsModalOpen('basic')
                }}
                onPointerDown={() => {
                  setIsButtonPressed(true)
                }}
                onPointerCancel={() => {
                  setIsButtonPressed(false)
                }}
                onPointerUp={() => {
                  setIsButtonPressed(false)
                }}
              >
                보러가기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default NewYearSpecialDealEntryModal
