import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
} from './cpmAdTypes'

const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.NONE,
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 20,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 6,
    intervalHours: 4,
    textMaxRewardAmount: 30,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 6,
    intervalHours: 4,
    textMaxRewardAmount: 30,
    browsingSeconds: 30,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '올웨이즈 상품 30초 구경하기',
    navigationModal: CPM_GOODS_MODAL_TYPES.WATER_BROWSING_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '',
    navigationModal: '',
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    buttonMainIconImageUrl: '/icon/searchIcon.png',
    buttonSubtitleIconType: 'water', // 버튼 내 리워드 아이콘 - water, fertilizer, null
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    textMaxRewardAmount: purchaseConfig.textMaxRewardAmount,
    buttonTitleText: viewerConfig.buttonTitleText,
    navigationModal: viewerConfig.navigationModal,
    buttonSubtitleText: `${purchaseConfig.textMaxRewardAmount}g, 하루 ${purchaseConfig.maxViewCount}번 가능`,
  }

  return BUTTON_PROPS
}

// 상품 모달 PROPS를 동적으로 반환
const getGoodsModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '상품 추천 중',
    FINAL_BUTTON_TEXT: '상품 구경하기',
    MODAL_IMAGE_URL: '/modal/cpmGoodsAdModal.png',
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' },
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.WATER_BROWSING_AD,
    MODAL_TITLE: '올웨이즈 상품 구경하기',
    MODAL_TEXT_1: '올웨이즈 상품을 구경하면',
    MODAL_TEXT_2: `물 ${purchaseConfig.textMaxRewardAmount}g`,
    MODAL_TEXT_3: '을 받아요',
  }

  const CPM_PURCHASE_SCREEN_PROPS = {
    title: '올웨이즈 상품 구경하기',
    enteringComponent: CPM_ENTERING_COMPONENTS.WATER_BROWSING_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    scrollQuestReward: `물 ${purchaseConfig.textMaxRewardAmount}g`,
    buyingReward: '물 700g',
    // NOTE - deprecated
    image: 'https://assets.ilevit.com/1b6ec647-f56b-4c25-8cda-daec68e79d97.png',
    ratio: 99 / 360,
    questRewardText: `초간 구경하면 물을 ${purchaseConfig.textMaxRewardAmount}g 받아요!`,
    isTimerOn: true,
    // NOTE - deprecated
    enabledScrollTimer: true,
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS,
    CPM_PURCHASE_SCREEN_PROPS,
  }

  return GOODS_MODAL_PROPS
}

export { getGoodsModalProps, getButtonProps }
