import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'
import AB from 'utils/ab'
import BottomSheetStore from 'store/BottomSheetStore'

const ReviewModal = observer(({ token }) => {
  const [reviewData, setReviewData] = useState([])
  const [category, setCategory] = useState('myFruit')
  const alfarmReview = async () => {
    if (category === 'myFruit') {
      const result = await backendApis.getAlfarmReview(token)
      setReviewData(result)
    } else if (category === 'all') {
      const result = await backendApis.getAlfarmReviewAll(token)
      setReviewData(result)
    }
  }

  useEffect(() => {
    alfarmReview()
  }, [category])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '180vw',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: 'url(../icon/alfarmReviewBackGround.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={
              category === 'myFruit'
                ? {
                    left: '10vw',
                    textAlign: 'center',
                    top: '60vw',
                    position: 'absolute',
                    width: '35%',
                    borderRadius: 8,
                    padding: 8,
                    fontSize: '4vw',
                    color: '#965F42',
                    backgroundColor: 'white',
                    borderTop: '2px solid #593306',
                    borderLeft: '2px solid #593306',
                  }
                : {
                    left: '10vw',
                    textAlign: 'center',
                    top: '60vw',
                    position: 'absolute',
                    width: '35%',
                    borderRadius: 8,
                    padding: 8,
                    fontSize: '4vw',
                    color: '#965F42',
                    borderTop: '1px solid #593306',
                    borderLeft: '1px solid #593306',
                    borderBottom: '2px solid #593306',
                    borderRight: '2px solid #593306',
                  }
            }
            onClick={async () => {
              // await getSidepickGganbuRanks();
              setCategory('myFruit')
            }}
          >
            내 작물
          </div>
          <div
            style={
              category === 'all'
                ? {
                    right: '10vw',
                    textAlign: 'center',
                    top: '60vw',
                    position: 'absolute',
                    width: '35%',
                    borderRadius: 8,
                    padding: 8,
                    fontSize: '4vw',
                    color: '#965F42',
                    backgroundColor: 'white',
                    borderTop: '2px solid #593306',
                    borderLeft: '2px solid #593306',
                  }
                : {
                    right: '10vw',
                    textAlign: 'center',
                    top: '60vw',
                    position: 'absolute',
                    width: '35%',
                    borderRadius: 8,
                    padding: 8,
                    fontSize: '4vw',
                    color: '#965F42',
                    borderTop: '1px solid #593306',
                    borderLeft: '1px solid #593306',
                    borderBottom: '2px solid #593306',
                    borderRight: '2px solid #593306',
                  }
            }
            onClick={async () => {
              // await getSidepickGganbuRanks();
              setCategory('all')
            }}
          >
            전체 작물
          </div>
          <div
            style={{
              position: 'relative',
              overflowX: 'hidden',
              overflowY: 'scroll',
              width: '93%',
              marginTop: '60vw',
              zIndex: 1999,
              background: 'white',
              borderRadius: '5vw',
              boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
              height: '95vw',
            }}
          >
            {reviewData?.length > 1 && (
              <>
                {reviewData?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginRight: '4vw',
                        marginLeft: '4vw',
                        marginTop: '4vw',
                        // width: "93%",
                      }}
                    >
                      <div
                        style={{
                          // position: "relative",
                          zIndex: 1999,
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                            marginTop: 4,
                          }}
                        >
                          <img
                            style={{
                              zIndex: 1999,
                              width: 30,
                              height: 30,
                              borderRadius: 50,
                            }}
                            src={item?.userImageUri}
                            alt='abc'
                          />

                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 14,
                              marginLeft: 8,
                            }}
                          >
                            {item?.userName}
                          </div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignContent: 'flex-end',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div
                            style={{
                              color: '#855C36',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 12,
                            }}
                          >
                            {dayjs(item?.createdAt).format('YYYY-MM-DD')}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            color: '#525964',
                            zIndex: 1999,
                            fontFamily: 'maplestory',
                            marginBottom: 12,
                            marginTop: 12,
                            fontSize: 14,
                            flexDirection: 'column',
                            display: 'flex',
                          }}
                        >
                          {item?.text?.length > 150 ? (
                            <>{item?.text.slice(0, 150)}...</>
                          ) : (
                            <>{item?.text.slice(0, 150)}</>
                          )}
                        </div>
                      </div>
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        {item?.reviewImageUris?.map((image, index) => (
                          <div key={index}>
                            <img
                              style={{
                                zIndex: 1999,
                                width: '22vw',
                                height: '22vw',
                                borderRadius: 5,
                                marginBottom: 16,
                                marginRight: 8,
                              }}
                              src={image}
                              alt='abc'
                            />
                          </div>
                        ))}
                      </div>

                      <div>
                        <div
                          style={{
                            height: 1,
                            backgroundColor: '#D3D7DD',
                            flex: 1,
                            flexDirection: 'column',
                            width: '100%',
                            alignContent: 'center',
                            justifyContent: 'center',
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>

          <button
            style={{
              width: '10%',
              top: '17vw',
              right: '5%',
              position: 'absolute',
              zIndex: 1113,
              background: 'black',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>

        {/* 튜토리얼일때만 닫기 버튼 보여지게 하기 위함 */}
        {UserStore?.giveWaterCount < 10 && (
          <button
            style={{
              position: 'absolute',
              zIndex: 1113,
              left: '5vw',
              top: '91vh',
              textAlign: 'center',
              width: '90%',
              paddingTop: 12,
              paddingBottom: 12,
              fontSize: '4.6vw',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#F19938',
              borderRadius: 16,
              fontFamily: 'maplestory',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            닫기
          </button>
        )}
      </div>
    </>
  )
})
export default ReviewModal
