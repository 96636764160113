import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import WhiteTextTimer from '../WhiteTextTimer'
import useDebounce from '../useDebounce'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../../store/TimeStore'
import UserStore from 'store/UserStore'
import AlwayzUserStore from 'store/AlwayzUserStore'
import ABStore from 'store/ABStore'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import moment from 'moment'

const DrinkWaterModal = observer(({ token, farmData, setFarmData }) => {
  const debounce = useDebounce()
  const [drinkCount, setDrinkCount] = useState(0)
  const [canClickButton, setCanClickButton] = useState(false)
  const [isRewarding, setIsRewarding] = useState(false)
  const [getReward, setGetReward] = useState(false)
  const [amount, setAmount] = useState(0)
  const codePushVersionAvailable = useCheckCodePushVersion()
  const is60CherryPickingUser = UserStore.is60CherryPickingUser

  const buttonStyles = {
    base: {
      width: '55vw',
      height: '14vw',
      borderRadius: 99,
      color: 'black',
      fontFamily: 'Maplestory',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1vw',
      left: '40%',
      bottom: '0%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      fontSize: '5vw',
    },
    active: {
      background:
        'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
    },
    disabled: {
      background: '#B5BBC1',
    },
  }

  const tilePositions = [
    { left: '14.5vw', top: '57%' },
    { left: '35vw', top: '57%' },
    { left: '55.5vw', top: '57%' },
    { left: '76vw', top: '57%' },
    { left: '14.5vw', top: '73%' },
    { left: '35vw', top: '73%' },
    { left: '55.5vw', top: '73%' },
    { left: '76vw', top: '73%' },
  ]

  const tileNames = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
  ]

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmLetsDrinkWater',
      title: '[올팜] 물 마시고 건강 챙기세요',
      description: '잊지말고 물도 받아가세요. 다 키우면 진짜 작물 보내드려요.',
      bannerImage:
        'https://assets.ilevit.com/3e02f2b7-e917-4eab-a59b-8c8f0da81469.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  const drinkWaterButton = async () => {
    const result = await backendApis.drinkWaterButton()

    if (result?.data?.success) {
      const temp = { ...farmData }
      let tempAmount = result?.data?.amount

      if (UserStore.waterExceeded) {
        tempAmount = Math.round(tempAmount * 0.5)
      }
      temp.water += tempAmount
      temp.initTimeCheckerResult.drinkWaterDone = true

      if (!temp.drinkWaterQuest) {
        temp.drinkWaterQuest = [new Date()]
      } else {
        temp.drinkWaterQuest.push(new Date())
      }

      setFarmData(temp)
      setDrinkCount((prev) => prev + 1)
      setAmount(tempAmount)
      setGetReward(true)

      setTimeout(() => {
        setGetReward(false)
        setAmount(0)
      }, 2500)
    }
  }

  const checkButtonAvailability = () => {
    if (!farmData?.initTimeCheckerResult?.drinkWaterDone) {
      setDrinkCount(0)
      setCanClickButton(true)
      return
    }

    const lastDrinkTime = new Date(
      farmData?.drinkWaterQuest[farmData?.drinkWaterQuest?.length - 1],
    )
    const timeDiff = TimeStore.currentNewDate - lastDrinkTime
    const canDrinkAgain =
      timeDiff >= 1000 * 60 * 60 && farmData?.drinkWaterQuest?.length < 8

    setCanClickButton(canDrinkAgain)
    setDrinkCount(farmData?.drinkWaterQuest?.length)
  }

  useEffect(() => {
    checkButtonAvailability()
  }, [])

  useEffect(() => {
    const lastNudgedAt = window.localStorage.getItem(
      'floating_button_nudge_drinkwater',
    )
    const handleOverlayPermission = ({ data }) => {
      let parsedData

      try {
        parsedData = JSON.parse(data)
      } catch (e) {
        console.error('Failed to parse data:', e)
        return // 파싱 실패 시 함수 종료
      }

      if (
        parsedData?.type === 'checkButtonState' &&
        (parsedData?.result === false ||
          window.localStorage.getItem('softOnboardingFinished') !== 'true') &&
        (!lastNudgedAt || moment().diff(moment(lastNudgedAt), 'days') > 2) &&
        AlwayzUserStore.alwayzUserData.OS === 'android' &&
        ABStore.floatingButtonTester &&
        codePushVersionAvailable(
          UserStore.codePushVersionFromAlwayzApp,
          '6.7.4',
        )
      ) {
        // logger
        backendApis.logABclick(token, 'PUT', {
          location: 'bottomSheet',
          action: 'openedFloatingButtonNudge',
          data: {
            description: 'floatingButtonNudge on',
          },
        })
        window.localStorage.setItem(
          'floating_button_nudge_drinkwater',
          new Date(),
        )

        ModalStore.setIsModalOpen('floatingButtonNudge')
      }
    }

    document.addEventListener('message', handleOverlayPermission)
    return () => {
      document.removeEventListener('message', handleOverlayPermission)
    }
  }, [])

  const renderTiles = () => {
    return tilePositions?.map((pos, idx) => (
      <div key={idx}>
        <img
          style={{
            width: '19.5vw',
            position: 'absolute',
            ...pos,
            transform: 'translate(-50%, -50%)',
          }}
          alt=''
          src={`/drinkwater/${tileNames[idx]}.png`}
        />
        {drinkCount > idx && (
          <img
            style={{
              width: '19.5vw',
              position: 'absolute',
              ...pos,
              transform: 'translate(-50%, -50%)',
              zIndex: 22,
            }}
            alt=''
            src='/drinkwater/clearTile.png'
          />
        )}
      </div>
    ))
  }

  const renderButton = () => {
    if (isRewarding) {
      return (
        <div style={{ ...buttonStyles.base, ...buttonStyles.active }}>
          완료!
        </div>
      )
    }

    if (farmData?.drinkWaterQuest?.length === undefined) {
      return (
        <div
          style={{ ...buttonStyles.base, ...buttonStyles.active }}
          onClick={() => {
            debounce(drinkWaterButton, 500)
            setIsRewarding(true)

            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'checkButtonState',
              }),
            )
            setTimeout(() => {
              setIsRewarding(false)
              setCanClickButton(false)
            }, 2500)
          }}
        >
          물 마시고 물 받기
        </div>
      )
    }

    if (farmData?.drinkWaterQuest?.length >= 8) {
      return (
        <div style={{ ...buttonStyles.base, ...buttonStyles.disabled }}>
          내일 다시 가능해요
        </div>
      )
    }

    if (canClickButton) {
      return (
        <div
          style={{ ...buttonStyles.base, ...buttonStyles.active }}
          onClick={() => {
            debounce(drinkWaterButton, 500)
            setIsRewarding(true)

            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'checkButtonState',
              }),
            )
            setTimeout(() => {
              setIsRewarding(false)
              setCanClickButton(false)
            }, 2500)
          }}
        >
          물 마시고 물 받기
        </div>
      )
    }

    return (
      <div style={{ ...buttonStyles.base, ...buttonStyles.disabled }}>
        <WhiteTextTimer
          onTimeEnd={checkButtonAvailability}
          timeStamp={
            farmData?.drinkWaterQuest
              ? new Date(
                  farmData?.drinkWaterQuest[
                    farmData?.drinkWaterQuest?.length - 1
                  ],
                )
              : new Date()
          }
          timerMinutes={60}
          interval={1000}
          style={{
            fontFamily: 'Maplestory',
            color: '#442b22',
            fontWeight: 'bold',
          }}
          startFromMinutes
          noDays
        />
        &nbsp;후에 다시 가능해요
      </div>
    )
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
          }}
        >
          <img
            style={{ width: '90vw' }}
            alt=''
            src='/drinkwater/drinkWaterModal.png'
          />
          <div
            style={{
              width: '100%',
              left: '50%',
              top: '42%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              textAlign: 'center',
              fontSize: '5vw',
              lineHeight: '130%',
            }}
          >
            매일 8잔 물을 마셔요 <br />
            건강도 챙기고 물도 받을 수 있어요
          </div>

          {renderTiles()}
          {renderButton()}

          <img
            style={{
              width: '16vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1vw',
              right: '-1vw',
              bottom: '0%',
              transform: 'translate(-50%, -35%)',
              position: 'absolute',
              fontSize: '5vw',
            }}
            src='/drinkwater/share.png'
            alt=''
            onClick={shareKakao}
          />
        </div>

        <button
          style={{
            width: '10%',
            top: '18%',
            right: '10%',
            transform: 'translate(0%, -50%)',
            position: 'absolute',
          }}
          onClick={() => ModalStore.setIsModalOpen('basic')}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>

      {getReward && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 22222,
          }}
          onClick={() => setGetReward(false)}
        >
          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
                zIndex: 100,
              },
              width: '35vw',
              height: '35vw',
              borderRadius: '15px',
              opacity: '0.8',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <img
            src='/icon/getWaterButtonNew.png'
            alt=''
            style={{
              width: '20vw',
              top: 0,
              height: 'auto',
              transform: 'translate(-50%, -70%)',
            }}
            className='get-water-popup-move'
          />
          <div
            style={{
              position: 'relative',
              transform: 'translate(-50%, -220%)',
              fontWeight: 'bold',
              textAlign: 'center',
              fontFamily: 'maplestory',
            }}
          >
            {`${amount}g 획득!`}
          </div>
        </div>
      )}
    </>
  )
})

export default DrinkWaterModal
