import ModalStore from '../store/ModalStore'

// 유효한 광고 세트 배열 가져오기
const sendToCPMPurchaseScreen = async ({
  cpmItems,
  props,
  withCPCCPSAdInCPM = false,
  abTesterGroup,
}) => {
  if (!props?.enteringComponent) {
    setTimeout(() => {
      ModalStore.setIsModalOpen('basic')
    }, 300)

    return
  }

  window.location.href = `#generalNavigator.${JSON.stringify({
    screen: 'AlfarmCPMPurchaseScreen',
    params: {
      itemInfoList: cpmItems,
      scrollTimerStopDelay: 5000,
      withCPCCPSAdInCPM,
      abTesterGroup,
      ...props,
    },
  })}`

  setTimeout(() => {
    ModalStore.setIsModalOpen('basic')
  }, 300)
}

export default sendToCPMPurchaseScreen
