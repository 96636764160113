import React, { useState, useEffect } from 'react'
import ModalStore from 'store/ModalStore'

const imgSrcMap = {
  water: '/icon/getWaterButtonNew.png',
  fertilizer: '/icon/fertilizer.png',
  fertilizerSet: '/icon/fertilizerSet.png',
  waterAndFertilizer: '/icon/waterAndFertilizer.png',
  waterAndFertilizerSet: '/icon/waterAndFertilizerSet.png',
  doubleFertilizerAndWater: '/icon/waterAndFertilizer.png',
  doubleFertilizerSetAndWater: '/icon/waterAndFertilizerSet.png',
  nutriment: '/icon/nutriment.png',
  specialPurchase: '/icon/specialPurchase.png',
  jangbogiCoin: '/dealEvent/jangbogi/coin.png',
}

// if (!getResourceModal) {
//   setTimeout(() => {
//     setGetResourceModal(false);
//     setRewardAmountForRewardToast(0);
//     setRewardTypeForRewardToast("water");
//   }, 1500);
// }

const parseRewardContent = (rewardType, amount) => {
  let prefix = amount
  let unit = ''
  let suffix = ' 획득!'
  if (rewardType === 'water') {
    unit = 'g'
  } else if (rewardType === 'nutriment') {
    unit = ''
  } else if (rewardType === 'fertilizer' || rewardType === 'fertilizerSet') {
    unit = '개'
  } else if (rewardType === 'specialPurchase') {
    unit = '개'
  } else if (rewardType === 'jangbogiCoin') {
    unit = '개'
  } else if (rewardType === 'waterAndFertilizer') {
    prefix = `${amount}g + 일반비료 1`
    unit = '개'
    suffix = ''
  } else if (rewardType === 'waterAndFertilizerSet') {
    prefix = `${amount}g + 고급비료 1`
    unit = '개'
    suffix = ''
  } else if (rewardType === 'doubleFertilizerAndWater') {
    prefix = `${amount}g + 일반비료 2`
    unit = '개'
    suffix = ''
  } else if (rewardType === 'doubleFertilizerSetAndWater') {
    prefix = `${amount}g + 고급비료 2`
    unit = '개'
    suffix = ''
  }

  return `${prefix}${unit}${suffix}`
}

const GetResourceModal = ({
  amount,
  rewardType = 'water',
  setGetResourceModal = () => {},
  getResourceModal,
  setRewardAmountForRewardToast,
  setRewardTypeForRewardToast,
}) => {
  const [timerId, setTimerId] = useState(null)
  const rewardContent = parseRewardContent(rewardType, amount)

  useEffect(() => {
    if (getResourceModal) {
      const newTimerId = setTimeout(() => {
        setGetResourceModal(false)
        setRewardAmountForRewardToast(0)
        setRewardTypeForRewardToast('water')
        ModalStore.set('rewardPopUpOn', false)
      }, 1500)

      // 타이머 ID를 상태에 저장합니다.
      setTimerId(newTimerId)
    }

    // 컴포넌트가 언마운트되면 타이머를 정리합니다.
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [getResourceModal])

  const handleCloseModal = () => {
    setGetResourceModal(false)

    // 클릭 이벤트 핸들러에서 타이머를 취소합니다.
    if (timerId) {
      clearTimeout(timerId)
    }
  }

  const modalWidth =
    rewardType === 'waterAndFertilizer' ||
    rewardType === 'waterAndFertilizerSet' ||
    rewardType === 'doubleFertilizerAndWater' ||
    rewardType === 'doubleFertilizerSetAndWater'
      ? 'w-[45vw]'
      : 'w-[35vw]'

  return (
    <div
      className='absolute flex-1 left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-full h-full bg-transparent z-[2000000]'
      onClick={handleCloseModal}
    >
      <div
        className={`z-[2000000] bg-black absolute ${modalWidth} h-[35vw] left-[50%] top-[50%] rounded-[15px] opacity-80 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center flex-col`}
      ></div>
      <div
        className={`z-[2000000] absolute ${modalWidth} h-[35vw] left-[50%] top-[50%] rounded-[15px] -translate-x-1/2 -translate-y-1/2 flex justify-center items-center flex-col`}
      >
        <img
          className='w-[20vw] z-[2000000] h-auto left-[50%] top-[50%] '
          src={imgSrcMap[rewardType]}
          alt=''
        />

        <div className='left-[50%] top-[50%] z-[2000000] font-bold text-center'>
          {`${rewardContent}`}
        </div>
      </div>
    </div>
  )
}
export default GetResourceModal
