import React, { useState, useEffect } from 'react'
import LeftScrollButton from '../molecules/leftScrollButton'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import TimeStore from 'store/TimeStore'
import ModalStore from 'store/ModalStore'
import BottomSheetStore from 'store/BottomSheetStore'
import UserStore from 'store/UserStore'
import backendApis from 'utils/backendApis'
import moment from 'moment'
import AlfarmAdStore from 'store/AlfarmAdStore'
import AB from 'utils/ab_v3'
import ABStore from 'store/ABStore'

const LeftScrollSection = observer(
  ({
    isNewUser,
    giveWaterCount,
    newUserEvent,
    farmData,
    canGetSevendaysEventNotification,
    questLogForGetWaterDailyAttendance,
    canGetDailyAttendanceWaterNotification,
    codePushVersionAvailable,
    userCodePushVersion,
    level,
    isHarvestInviteAvailable,
    token,
    itemType,
    navigate,
  }) => {
    const isShowMysteryFlower = UserStore.farmData.water >= 200

    const [isShowMysteryFlowerPing, setIsShowMysteryFlowerPing] = useState(
      new Date(localStorage.getItem('mysteryFlowerOpenedAt')) <
        new Date(new Date().setHours(7, 0, 0, 0)),
    )
    useEffect(() => {
      const checkEngagementNutrimentType = async () => {
        let result = await backendApis.checkEngagementNutrimentType()
        if (result?.data) {
          UserStore.set('farmData.engagementNutrimentInfo', result.data)
        }
      }
      checkEngagementNutrimentType()
    }, [])

    if (giveWaterCount <= 30) return null

    return (
      <div
        className='masked-overflow scrollbar-hide'
        style={{
          height: '48vh',
          paddingLeft: '0.5vw',
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: 'flex',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          zIndex: !farmData?.questLogForAttendanceWater ? 100 : 100,
          color: '#bbb',
        }}
      >
        {isNewUser &&
          giveWaterCount >= 40 &&
          newUserEvent &&
          canGetSevendaysEventNotification && (
            <LeftScrollButton
              imageType={'canGetSevendaysEvent'}
              onClick={() => {
                ModalStore.setIsModalOpen('sevenDays')
              }}
            />
          )}

        {giveWaterCount > 13 &&
          questLogForGetWaterDailyAttendance &&
          canGetDailyAttendanceWaterNotification && (
            <LeftScrollButton
              imageType='attendanceWater'
              onClick={() => {
                ModalStore.setIsModalOpen('attendanceWater')
              }}
            />
          )}

        {UserStore?.comebackUserCanCheckIn &&
          TimeStore?.currentMoment?.isSameOrBefore(
            moment(farmData?.resurrectInfo?.createdAt ?? '2023-01-01').add(
              10,
              'd',
            ),
          ) && (
            <LeftScrollButton
              imageType={'comebackUser'}
              onClick={() => {
                ModalStore.setIsModalOpen('comebackUser')
              }}
              pingType={UserStore?.comebackUserCanCheckIn ? '!' : null}
            />
          )}

        {giveWaterCount >= 300 && (
          <LeftScrollButton
            imageType='dailyMission'
            onClick={() => {
              ModalStore.setIsModalOpen('dailyMissionv2')
            }}
            pingType={UserStore?.pingManager?.dailyMission ? '!' : null}
          />
        )}

        {AlfarmAdStore?.isSanSinEventTarget && (
          <LeftScrollButton
            imageType='sansin'
            onClick={() => {
              ABStore.isCPMAdViewer
                ? ModalStore.setIsModalOpen('sansinEventModal')
                : ModalStore.setIsModalOpen('SellerAdPromotingModal')
            }}
            pingType={AlfarmAdStore?.showPingForSanSinEvent ? '!' : null}
          />
        )}

        {isShowMysteryFlower && (
          <LeftScrollButton
            imageType='mysteryFlower'
            onClick={() => {
              setIsShowMysteryFlowerPing(false)
              localStorage.setItem('mysteryFlowerOpenedAt', new Date())
              ModalStore.setIsModalOpen('mysteryFlower')
            }}
            pingType={isShowMysteryFlowerPing ? '!' : null}
          />
        )}

        {giveWaterCount >= 9 &&
          codePushVersionAvailable(userCodePushVersion, '1.5.57') && (
            <LeftScrollButton
              imageType='inviteQuestV2'
              onClick={() => {
                ModalStore.setIsModalOpen('dailyInvite')
              }}
              wobble={
                dayjs(new Date(farmData?.clearedInviteQuest)).format(
                  'YYYY-MM-DD',
                ) !== dayjs(new Date()).format('YYYY-MM-DD') ||
                farmData?.clearedInviteQuest === undefined
              }
            />
          )}

        {(farmData?.resurrectBonus ||
          (giveWaterCount >= 10 &&
            farmData?.isNewUser &&
            dayjs(farmData?.createdAt)
              .add(3, 'd')
              .diff(TimeStore.currentDayJS, 'day') >= 0)) && (
          <LeftScrollButton
            imageType='engagementNutriment'
            onClick={() => {
              ModalStore.setIsModalOpen('engagementNutriment')
            }}
            wobble={!UserStore?.farmData?.engagementNutrimentInfo?.didReceive}
            wobbleImage='nutrimentBubble'
          />
        )}
        {!farmData?.resurrectBonus && giveWaterCount > 50 && (
          <LeftScrollButton
            imageType='vipCard'
            onClick={() => {
              ModalStore.setIsModalOpen('vipCard')
            }}
          />
        )}

        {giveWaterCount >= 500 && (
          <LeftScrollButton
            imageType='qrIcon'
            onClick={() => {
              ModalStore?.setIsModalOpen('qrBonus')
            }}
            wobble={false}
          />
        )}
        {!farmData?.resurrectBonus &&
          giveWaterCount >= 200 &&
          codePushVersionAvailable(userCodePushVersion, '1.5.57') && (
            <LeftScrollButton
              imageType='friendGarden'
              onClick={() => {
                navigate(`../friendGarden?token=${token}&itemType=${itemType}`)
              }}
              wobble={false}
            />
          )}
        {farmData?.rouletteEventCoupon >= 1 &&
          !farmData?.resurrectBonus &&
          codePushVersionAvailable(userCodePushVersion, '1.6.26') &&
          giveWaterCount >= 500 && (
            <LeftScrollButton
              imageType='roulette'
              onClick={() => {
                ModalStore.setIsModalOpen('roulette')
              }}
            />
          )}

        {giveWaterCount >= 62 &&
          farmData?.gganbuList?.length < 6 &&
          codePushVersionAvailable(userCodePushVersion, '1.5.57') && (
            <LeftScrollButton
              imageType={!farmData?.gganbuList ? 'makeGganbu' : 'makeGganbu'}
              onClick={() => {
                ModalStore.setIsModalOpen('gganbuFarmIntro')
              }}
              wobble={
                !farmData?.gganbuList && !farmData?.bonusRewardBoxFromAlwayzFarm
              }
            />
          )}

        {level === 6 &&
          isHarvestInviteAvailable &&
          codePushVersionAvailable(userCodePushVersion, '2.3.43') && (
            <LeftScrollButton
              imageType='harvestInvite'
              onClick={() => {
                ModalStore.setIsModalOpen('harvestInvite')
              }}
            />
          )}

        {isNewUser &&
          giveWaterCount >= 40 &&
          newUserEvent &&
          !canGetSevendaysEventNotification && (
            <LeftScrollButton
              imageType={'sevendaysEvent'}
              onClick={() => {
                ModalStore.setIsModalOpen('sevenDays')
              }}
            />
          )}

        {giveWaterCount > 13 &&
          questLogForGetWaterDailyAttendance &&
          !canGetDailyAttendanceWaterNotification && (
            <LeftScrollButton
              imageType={'canGetAttendanceWaterIcon'}
              onClick={() => {
                ModalStore.setIsModalOpen('attendanceWater')
              }}
            />
          )}

        {!farmData?.resurrectBonus && farmData?.giveWaterCount > 50 && (
          <LeftScrollButton
            imageType={'pet'}
            onClick={() => {
              BottomSheetStore.setIsbottomSheetOpen('pet')
            }}
          />
        )}

        {!UserStore?.comebackUserCanCheckIn &&
          TimeStore?.currentMoment?.isSameOrBefore(
            moment(farmData?.resurrectInfo?.createdAt ?? '2023-01-01').add(
              10,
              'd',
            ),
          ) && (
            <LeftScrollButton
              imageType={'comebackUser'}
              onClick={() => {
                ModalStore.setIsModalOpen('comebackUser')
              }}
              pingType={UserStore?.comebackUserCanCheckIn ? '!' : null}
            />
          )}
      </div>
    )
  },
)

export default LeftScrollSection
