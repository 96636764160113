import LottieGif from '../LottieGif'
import glitter from '../../../json/gifJson/lotteryDraw/glitter.json'
import ClickFinger from './ClickFinger'
const SpecialLotteryCapsulesNotOpen = ({ capsule, onClickCapsule }) => {
  const handleClickCapsule = () => {
    onClickCapsule(capsule.id)
  }

  return (
    <div
      key={capsule.id}
      onClick={handleClickCapsule}
      style={{
        width: '99px',
        height: '99px',
        position: 'absolute',
        top: '30vw',
        left: capsule.left,
        transform: 'translate(-50%, 0)',
        backgroundImage: `url(../minigame/lotteryDraw/specialCapsule${capsule.id}.png)`,
        zIndex: 900,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: capsule.isVisible ? 1 : 0,
        transition: 'all 0.3s ease-in-out',
        animation: 'float 2s ease-in-out infinite',
      }}
    >
      <style>
        {`
      @keyframes float {
        0% {
          transform: translate(-50%, 0);
        }
        50% {
          transform: translate(-50%, -10px);
        }
        100% {
          transform: translate(-50%, 0);
        }
      }
    `}
      </style>
      <LottieGif
        animationData={glitter}
        style={{
          zIndex: 9000,
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
        loop
        speed={1.5}
      />
      <div style={{ position: 'absolute', top: '-18vw', left: '100%' }}>
        <ClickFinger />
      </div>
    </div>
  )
}

export default SpecialLotteryCapsulesNotOpen
