import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ModalStore from 'store/ModalStore'
import ToastStore from 'store/ToastStore'

const GganbuInteractionButton = ({
  token,
  type,
  gganbuData,
  gganbuFarmResetInfo,
  refreshGganbuFarmResetInfo,
  disabled,
  indicator = '',
  style,
  waterGganbuFarm,
  getGoodsFromGganbu,
  setPokeModalInfo,
}) => {
  const sameImageAsDisabledMissionType = [
    'giveWater',
    'giveWaterFive',
    'visitFarm',
  ]
  let randomString = Math.random().toString(36).slice(2, 8)
  const navigate = useNavigate()

  return (
    <button
      className='relative'
      onClick={async () => {
        if (ModalStore?.isModalOpen !== 'basic') {
          return
        }

        if (disabled) {
          if (type === 'giveWater' || type === 'giveWaterFive') {
            if (
              !gganbuData.exp ||
              gganbuData?.farmLevel?.level6 <= gganbuData?.exp
            ) {
              ToastStore?.toastOn({
                type: 'emoji',
                emoji: '🚧',
                message: '수확이 완료된 농장이에요!',
                duration: 1500,
              })
            } else {
              ToastStore?.toastOn({
                type: 'error',
                message: '물이 부족해요',
                duration: 1500,
              })
            }
          } else if (type === 'getWater') {
            // ToastStore?.toastOn({
            //   type: 'error',
            //   message: '4시간마다 받을 수 있어요',
            //   duration: 1500,
            // })
            ToastStore?.toastOn({
              type: 'emoji',
              emoji: '🕰️',
              message: `${
                moment(gganbuFarmResetInfo?.lastGetWaterAt)
                  .add(4, 'hours')
                  .diff(moment(), 'minutes') > 60
                  ? `${moment(gganbuFarmResetInfo?.lastGetWaterAt)
                      .add(4, 'hours')
                      .format('H시 m분')}`
                  : `  ${moment(gganbuFarmResetInfo?.lastGetWaterAt)
                      .add(4, 'hours')
                      .diff(moment(), 'minutes')}분 후`
              }에 다시 받을 수 있어요`,
              duration: 3000,
            })
          } else if (type === 'gganbuBox') {
            ToastStore?.toastOn({
              type: 'error',
              message: '오늘의 맞팜상자를 이미 열었어요!',
              duration: 1500,
            })
          } else if (type === 'poke') {
            ToastStore?.toastOn({
              type: 'success',
              message: '오늘 콕! 찌르기를 완료했어요',
              duration: 1500,
            })
          } else if (type === 'visitFarm') {
            ToastStore?.toastOn({
              type: 'emoji',
              emoji: '🚧',
              message: '수확이 완료된 농장이에요!',
              duration: 1500,
            })
          }
        } else {
          if (type === 'giveWater') {
            const result = await waterGganbuFarm({
              gganbuId: gganbuData.userId,
              count: 1,
            })

            if (result === false) {
              return
            }
            refreshGganbuFarmResetInfo()
          } else if (type === 'giveWaterFive') {
            const result = await waterGganbuFarm({
              gganbuId: gganbuData.userId,
              count: 5,
            })
            if (result === false) {
              return
            }
            refreshGganbuFarmResetInfo()
          } else if (type === 'getWater') {
            const result = await getGoodsFromGganbu({
              gganbuId: gganbuData.userId,
              gganbuData: gganbuData,
              goodsType: 'water',
              gganbuFarmResetInfo,
            })
            if (result === false) {
              return
            }

            refreshGganbuFarmResetInfo()
          } else if (type === 'gganbuBox') {
            const result = await getGoodsFromGganbu({
              gganbuId: gganbuData.userId,
              gganbuData: gganbuData,
              goodsType: 'gganbuBox',
              gganbuFarmResetInfo,
            })
            if (result === false) {
              return
            }

            refreshGganbuFarmResetInfo()
          } else if (type === 'poke') {
            setPokeModalInfo((prevState) => ({
              ...prevState,
              gganbuId: gganbuData.userId, // 해당 gganbuId의 pokeModalInfo 업데이트
              show: true, // 해당 gganbuId의 show 상태 업데이트
            }))
            ModalStore.setIsModalOpen('pokeModal')
          } else if (type === 'visitFarm') {
            navigate(
              `/gganbuFarm/${gganbuData.userId}?token=${token}&itemType=${gganbuData?.itemType}&gganbuName=${gganbuData?.userName}&randomNumber=${randomString}`,
            )
          }
        }
      }}
    >
      <img
        className='w-[14vw] h-[14vw]'
        style={style}
        src={
          disabled && !sameImageAsDisabledMissionType.includes(type)
            ? `/icon/gganbuFarm/icn_gganbuFarmList_${type}_disabled.png`
            : `/icon/gganbuFarm/icn_gganbuFarmList_${type}.png`
        }
        alt=''
      />
      {indicator && (
        <img
          className='w-[3vw] h-[3vw] absolute top-[1vw] right-[1vw]'
          src={`/icon/gganbuFarm/icn_gganbuFarmList_indicator_${type}.png`}
          alt=''
        />
      )}
    </button>
  )
}

export default GganbuInteractionButton
