import React, { useState, useEffect } from 'react'
import LottieGif from './LottieGif'
import IncreaseWaterNumber from './IncreaseWaterNumber'
import upgradedGetWaterJson from '../../json/gifJson/water/upgradedGetWater.json'
import getWaterJson from '../../json/gifJson/water/getWater.json'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import UserStore from 'store/UserStore'
import { observer } from 'mobx-react-lite'

const ActionButton = observer(
  ({
    action,
    onClick,
    basicWater,
    farmData,
    increasePerSecond,
    setBasicWater,
  }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const codePushVersionAvailable = useCheckCodePushVersion()
    const userCodePushVersion = UserStore.codePushVersionFromAlwayzApp

    useEffect(() => {
      if (action === 'getWater' || action === 'upgradedGetWater') {
        if (codePushVersionAvailable(userCodePushVersion, '7.0.27')) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'prepareRewardedAd',
              data: {
                adType: 'rewarded',
                placement: 'ALFARM_DOUBLE_BASIC_WATER_MISSION_REWARD',
              },
            }),
          )
        }
      }
    }, [])

    const WaterImage = () => {
      if (action === 'upgradedGetWater') {
        if (basicWater === 90) {
          return (
            <>
              <img
                style={{
                  width: '20vw',
                  zIndex: 2,
                }}
                src='/icon/upgradedGetWaterFull.png'
                alt=''
              />
              <img
                style={{
                  width: '7vw',
                  height: '7vw',
                  position: 'absolute',
                  top: '6%',
                  left: '16vw',
                }}
                className='wobble-ver-left'
                src='/icon/waterFullBubble.png'
                alt=''
              />
            </>
          )
        } else {
          return (
            <LottieGif
              animationData={upgradedGetWaterJson}
              style={{
                width: '20vw',
                zIndex: 999,
              }}
              loop
            />
          )
        }
      }
      if (action === 'getWater') {
        if (basicWater === 30) {
          return (
            <>
              <img
                style={{
                  width: '20vw',
                  zIndex: 2,
                }}
                src='/icon/getWaterNewFull.png'
                alt=''
              />
              <img
                style={{
                  width: '7vw',
                  height: '7vw',
                  position: 'absolute',
                  top: '6%',
                  left: '16vw',
                }}
                className='wobble-ver-left'
                src='/icon/waterFullBubble.png'
                alt=''
              />
            </>
          )
        } else {
          return (
            <LottieGif
              animationData={getWaterJson}
              style={{
                width: '20vw',
                zIndex: 999,
              }}
              loop
            />
          )
        }
      }
    }

    if (farmData?.giveWaterCount <= 4) return null

    return (
      <div
        style={{
          position: 'relative',
          height: '24vw',
        }}
      >
        <button
          onClick={onClick}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          style={{
            opacity: isButtonPressed ? 0.6 : 1.0,
          }}
        >
          <WaterImage />
          <div
            style={{
              position: 'absolute',
              bottom: '0.25vw',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <IncreaseWaterNumber
              initialNumber={basicWater}
              maxNumber={action === 'getWater' ? 30 : 90}
              increasePerSecond={increasePerSecond}
              setBasicWater={(num) => setBasicWater(num)}
              farmData={farmData}
            />
          </div>
        </button>
      </div>
    )
  },
)

export default ActionButton
