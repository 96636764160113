import { observer } from 'mobx-react-lite'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { VIDEO_AD_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import { useEffect } from 'react'
import UserStore from 'store/UserStore'

const FertilizerSetAdWatchModal = observer(({}) => {
  const adType = VIDEO_AD_TYPE.ALFARM_FERTILIZER_SET_AD_WATCH

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: adType.placement,
        },
      }),
    )
  }, [adType])

  return (
    <VideoAdModal
      modalImageUrl={
        'https://assets.ilevit.com/66b7f975-736d-4942-897d-80d5b96b1369.png'
      }
    >
      <VideoAdModal.Title
        title='광고보고 비료받기'
        description1={'영상광고를 보면'}
        description2={
          <div className='text-[#442B22]'>
            <strong className='text-[#FF6565]'>고급비료 1개</strong>를 받을 수
            있어요!
          </div>
        }
      />
      <VideoAdModal.ActionButton adType={adType} className='bottom-[9vw]'>
        광고보고 비료 받기
      </VideoAdModal.ActionButton>
    </VideoAdModal>
  )
})

export default FertilizerSetAdWatchModal
