import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam } from 'react-use'
import { observer } from 'mobx-react-lite'
import Farm from '../comps/molecules/farm'
import Cookies from 'js-cookie'
import Layout from '../comps/atoms/layout'
import ActionButton from '../comps/atoms/actionButton'
import BasicButton from '../comps/atoms/basicButton'
import GaugeBar from '../comps/atoms/gaugeBar'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import Modal from 'react-modal'
import dayjs from 'dayjs'
import WaterEffect from '../comps/atoms/waterEffect'
import Popup from '../comps/atoms/Popup'
import backendApis from '../utils/backendApis'
import Log from '../utils/log'
import timeChecker from '../utils/timeChecker'
import GaugeBarPercentage from '../comps/atoms/gaugeBarPercentage'
import commaNumber from 'comma-number'
import TapCountGameModal from '../comps/atoms/Modals/TapCountGameModal'
import GetResourceModal from '../comps/atoms/getResourceModal'
import ResourceButton from '../comps/atoms/resourceButton'
import LoadingIndicator from '../comps/loadingIndicator'
import NoonDailyWaterGetModal from '../comps/atoms/noonDailyWaterGetModal'
import InviteSuccessModal from '../comps/atoms/inviteSuccessModal'
import GetResourceButton from '../comps/atoms/getResourceButton'
import WaterBottomsheet from '../comps/atoms/WaterBottomsheetNew'
import FertilizerBottomsheet from '../comps/atoms/FertilizerBottomsheetNew'
import ReviewModal from '../comps/atoms/reviewModal'
import LevelUpRewardModal from '../comps/atoms/levelUpRewardModal'
import LackWaterEngaingModal from '../comps/atoms/lackWaterEngaingModal'
import TomorrowEngagingWaterModal from '../comps/atoms/tomorrowEngagingWaterModal'
import MinimumBaiscWater from '../comps/atoms/MinimumBasicWater'
import AlertModal from '../comps/atoms/AlertModal'
import GganbuWithEventIcons from '../comps/atoms/gganbuWithEventIcons'
import FeedModal from '../comps/atoms/FeedModal'
import Minigame from '../comps/atoms/Minigame'
import Luckydraw from '../comps/atoms/luckydraw'
import LuckydrawPurchaseReward from '../comps/atoms/luckydrawPurchaseReward'
import WaterHistoryModal from '../comps/atoms/waterHistoryModal'
import MinigameHistoryModal from '../comps/atoms/MinigameHistoryModal'
import FertilizerHistoryModal from '../comps/atoms/fertilizerHistoryModal'
import LuckydrawHistoryModal from '../comps/atoms/luckyHistoryModal'
import TutorialFinisedModal from '../comps/atoms/tutorialFinishedModal'
import PuppyModal from '../comps/atoms/puppyModal'
import PuppyGiftModal from '../comps/atoms/puppyGiftModal'
import SevenDaysModal from '../comps/atoms/sevenDaysModal'
import HarvestModal from '../comps/atoms/harvestModal'
import SecretScratchModal from '../comps/atoms/SecretScratchModal'
import ChangePlantModal from '../comps/atoms/ChangePlantModal'
import ChangePlantForMandarin from '../comps/atoms/ChangePlantForMandarin'
import ChangePlantForPersimmon from '../comps/atoms/ChangePlantForPersimmon'
import InviteHistoryModal from '../comps/atoms/inviteHistoryModal'
import LastMinuteInviteModal from '../comps/atoms/LastMinuteInviteModal'
import HarvestInviteModal from '../comps/atoms/HarvestInviteModal'
import QRBonusModal from '../comps/atoms/QRBonusModal'
import CouponUsageNudgeModal from '../comps/atoms/CouponUsageNudgeModal'
import SellerCounponPurchaseModal from '../comps/atoms/SellerCounponPurchaseModal'
import MissionHunterModal from '../comps/atoms/MissionHunterModal'
import RelayRewardModal from '../comps/atoms/RelayRewardModal'
import QRBonusRewardModal from '../comps/atoms/QRBonusRewardModal'
import EngagementNutrimentModal from '../comps/atoms/EngagementNutrimentModal'
import AnnouncementModal from '../comps/atoms/AnnouncementModal'
import CommonRemovedDependencyModal from '../comps/atoms/CommonRemovedDependencyModal'
import DailyLotteryModal from '../comps/atoms/DailyLotteryModal'
import ComebackUserModal from '../comps/atoms/combackUserModal'
import PurchaseScreenTimerButton from '../comps/molecules/PurchaseScreenTimerButton'
import useCheckCodePushVersion from '../utils/customHooks/useConvertCodePushVersion'
import WaterToFertilizerModal from '../comps/atoms/waterToFertilizerModal'
import WaterOneOneBattleModal from '../comps/atoms/waterOneOneBattleModal'
import NewRewardModal from '../comps/atoms/newRewardModal'
import WaterBombGameModal from '../comps/atoms/WaterBombGameModal'
import LotteryDrawModal from '../comps/atoms/LotteryDrawModal'
import SingleDealModal from '../comps/atoms/Modals/SingleDealModal'
import AttendanceWaterModalNew from '../comps/atoms/attendanceWaterModalNew'
import ProduceFertMachineModal from '../comps/atoms/produceFertMachineModal'
import LevelUpChanceModalNew from '../comps/atoms/levelUpChanceModalNew'
import WeeklyBingoPurchaseModal from '../comps/atoms/WeeklyBingoPurchaseModal'
import AuthStore from '../store/AuthStore'
import ToastStore from '../store/ToastStore'
import NewbieTreasureBoxModal from '../comps/atoms/NewbieTreasureBoxModal'
import NameMyItemModal from '../comps/atoms/NameMyItemModal'
import LackNutrimentEngagingModal from '../comps/atoms/lackNutrimentEngagingModal'
import MakeGganbuModal from '../comps/atoms/makeGganbuModal'
import BrandDealModal from '../comps/atoms/Modals/BrandDealModal'
import PushSettingModal from '../comps/atoms/PushSettingModal'
import ReferralCodeModal from '../comps/atoms/ReferralCodeModal'
import PushSettingFinishedPopup from '../comps/atoms/PushSettingFinishedPopup'
import VipCardModal from '../comps/atoms/vipCardModal'
import ChangePlantBeforeChurnModal from '../comps/atoms/ChangePlantBeforeChurnModal'
import TimeCapsuleBonus from '../comps/atoms/TimeCapsuleBonus'
import ChangePlantGetFasterModal from '../comps/atoms/ChangePlantGetFasterModal'
import PlantBasicInfo from '../comps/molecules/PlantBasicInfo'
import DailyInviteModal from '../comps/atoms/dailyInviteModal'
import moment from 'moment'
import FashionModal from '../comps/atoms/Modals/FashionModal'
import DrinkWaterModal from '../comps/atoms/DrinkWaterModal'
import SeasonOffModal from '../comps/atoms/Modals/SeasonOffModal'
import PhoneBookModal from '../comps/atoms/phoneBookModal'
import ContactsList from '../comps/atoms/ContactsList'
import JuwelryModal from '../comps/atoms/Modals/JuwelryModal'
import { isIOS, isIOS13 } from 'react-device-detect'
import QuotesModal from '../comps/atoms/Modals/QuotesModal'
import LifeModal from '../comps/atoms/Modals/LifeModal'
import NewItemDealModal from '../comps/atoms/Modals/NewItemDealModal'
import indexStore from '../store/indexStore'
import PhotoReviewModal from '../comps/atoms/PhotoReviewModal'
import CategoryFirstConv from '../comps/atoms/Modals/CategoryFirstConv'
import RouletteModal from '../comps/atoms/Modals/RouletteModal'
import FertilizerRouletteModal from '../comps/atoms/Modals/FertilizerRouletteModal'
import FertilizerTowerGameModal from '../comps/atoms/Modals/FertilizerTowerGameModal'
import GuessMeModal from '../comps/atoms/Modals/GuessMeModal'
import RequestGganbuModal from '../comps/atoms/Modals/RequestGganbuModal'
import GganbuRecommendationModal from '../comps/atoms/Modals/GganbuRecommendationModal'
import CheckInPurchaseBefore from '../comps/atoms/Modals/CheckInPurchaseBefore'
import LastHarvestPurchaseModal from '../comps/atoms/Modals/LastHarvestPurchaseModal'
import TimeStore from '../store/TimeStore'
import TrendQuizModal from '../comps/atoms/Modals/TrendQuizModal'
import SurpriseMissionModal from '../comps/atoms/Modals/SurpriseMissionModal'
import AdItemBrowsingModal from '../comps/atoms/Modals/AdItemBrowsingModal'
import useCheckAppVersion from '../utils/customHooks/useConvertAppVersion'
import CherryPickerRouletteModal from '../comps/atoms/Modals/CherryPickerRouletteModal'
import ActivationEngagingModal from '../comps/atoms/Modals/ActivationEngagingModal'
import PurchaseRouletteModal from '../comps/atoms/Modals/purchaseRouletteModal'
import { useLocation, useNavigate } from 'react-router-dom'
import PersonalizedShopModal from '../comps/atoms/Modals/PersonalizedShopModal'
import PersonalizedShopRewardModal from '../comps/atoms/Modals/PersonalizedShopRewardModal'
import DailyGiveWaterGetButton from '../comps/molecules/dailyGiveWaterGetButton'
import YesterdayGiveWaterModal from '../comps/atoms/Modals/yesterdayGiveWaterModal'
import TomorrowGetWaterButton from '../comps/molecules/tomorrowGetWaterButton'
import SuccessTomorrowGetWaterModal from '../comps/atoms/Modals/SuccessTomorrowGetWaterModal'
import SelectOnePurchaseModal from '../comps/atoms/Modals/SelectOnePurchaseModal'
import AllBrandDealModal from '../comps/atoms/Modals/AllBrandDealModal'
import EggBreakersV2 from 'comps/atoms/Modals/EggBreakersV2'
import BadgeModal from 'comps/atoms/badgeModal'
import {
  purchaseHistoryWithinPeriod,
  purchaseCountWithinPeriod4Hour,
  getUserOrderCountIn30Days,
} from 'utils/utils'
import LotteryTeamDealModal from 'comps/atoms/Modals/LotteryTeamDealModal'
import LeftScrollButtonSeosonalEventInviteModal from 'comps/seasonalEvent/LeftScrollButtonSeosonalEventInviteModal'
import { CheckSeasonalEventStatus } from 'comps/seasonalEvent/CheckSeasonalEvent'
import DailyGetWaterUpgradeModal from 'comps/atoms/Modals/dailyWaterUpgradeModal'
import TenMinDealModal from 'comps/atoms/Modals/TenMinDealModal'
import WinnerDealModal from '../comps/atoms/Modals/WinnerDealModal'
import WinnerDealModalV2 from 'comps/atoms/Modals/WinnerDealModalV2'
import WinnerDealModalV3 from 'comps/atoms/Modals/WinnerDealModalV3'
import DealAutomationStore from 'store/DealAutomationStore'
import GoldFilledModal from 'comps/atoms/Modals/GoldFilledModal'
import TimerWaterUpgradeModal from 'comps/atoms/Modals/TimerWaterUpgradeModal'
import JewelryRevenueDealModal from 'comps/atoms/Modals/JewelryRevenueDealModal'
import WatermelonModal from 'comps/atoms/Modals/WatermelonModal'
import KidsRevenueDealModal2 from 'comps/atoms/Modals/KidsRevenueDealModal2'
import WinterRevenueDealModal from 'comps/atoms/Modals/SummerRevenueDealModal'
import ChristmasTreeModal from 'comps/atoms/Modals/ChristmasTreeModal'
import InitModal from 'comps/atoms/Modals/InitModal'
import InitModalStore from 'store/InitModalStore'
import PesticideModal from 'comps/atoms/Modals/PesticideModal'
import LotteryDrawSpecialPurchaseModal from 'comps/atoms/Modals/LotteryDrawPurchaseModal'
import SnackRevenueDealModal from 'comps/atoms/Modals/SnackRevenueDealModal'
import { API_URI } from 'utils/constant'
import FeverModeModal from 'comps/atoms/Modals/FeverModeModal'
import FeverGameStart from 'comps/atoms/Modals/FeverModeStart'
import FeverModePurchaseModal from 'comps/atoms/Modals/FeverModePurchaseModal'
import ABv2 from 'utils/abv2'
import YutnoriGameModalV2 from 'comps/atoms/YutnoriGameModalV2'
import DailyMissionModalV2 from 'comps/atoms/Modals/DailyMissionV2'
import ABv3 from 'utils/ab_v3'
import AlphabetEventModal from 'comps/atoms/Modals/AlphabetEventModal'
import AlphabetEventHistoryModal from 'comps/atoms/Modals/AlphabetEventHistoryModal'
import SecondModalStore from 'store/SecondModalStore'
import DaisoRevenueDealModal from 'comps/atoms/Modals/DaisoRevenueDealModal'
import DailyInviteSpecial from 'comps/atoms/Modals/DailyInviteSpecial'
import ItemEndBeforeModal from 'comps/atoms/Modals/ItemEndBeforeModal'
import LeftScrollSection from 'comps/organisms/LeftScrollSection'
import ABStore from 'store/ABStore'
import HealthRevenueDealModal from 'comps/atoms/Modals/HealthRevenueDealModal'
import PetBottomSheet from 'comps/atoms/PetBottomSheet'
import PetDetailModal from 'comps/atoms/PetDetailModal'
import ItemEndedModal from 'comps/atoms/Modals/ItemEndedModal'
import PetTrialModal from 'comps/atoms/PetTrialModal'
import NewUserEventWaterModal from 'comps/atoms/NewUserEventWaterModal'
import EventPetModal from 'comps/atoms/EventPetModal'
import ResurrectBonusAttendanceEventModal from 'comps/atoms/ResurrectBonusAttendanceEventModal'
import ChurnedGganbuBountyModal from 'comps/atoms/ChurnedGganbuBountyModal'
import NewUserInstantHarvestModal from 'comps/atoms/NewUserInstantHarvestModal'
import NewUserValueIndicateModal from 'comps/atoms/NewUserValueIndicateModal'
import ResurrectD1NudgeModal from 'comps/atoms/ResurrectD1NudgeModal'
import DoubleExpEventModal from 'comps/atoms/DoubleExpEventModal'
import GroceryDealModal from 'comps/atoms/Modals/GroceryDealModal'
import WaterMaximumModal from 'comps/atoms/WaterMaximumModal'
import RewardAdModal from 'comps/atoms/RewardAdModal'
import SummerRevenueDealModal from 'comps/atoms/Modals/SummerRevenueDealModal'
import CarnationModal from 'comps/atoms/Modals/CarnationModal'
import EventModal from 'comps/atoms/Modals/EventModal'
import AlfarmBrandBannerDealModal from 'comps/atoms/Modals/alfarmBrandBannerDealModal'
import WinnerDealModalForAd from 'comps/atoms/Modals/WinnerDealModalForAd'
import WinnerDealModalForAdV2 from 'comps/atoms/Modals/WinnerDealModalForAdV2'
import BlackFridayForceModal from 'comps/atoms/Modals/BlackFridayForceModal'
import BrainTeaserModal from 'comps/atoms/Modals/BrainTeaserModal'
import { sleep, checkIsTodayWithISODate } from 'utils/utils'
import QuizModalV2 from 'comps/atoms/Modals/QuizModalV2'
import QuizPurchaseModalV2 from 'comps/atoms/Modals/QuizPurchaseModalV2'
import RewardAdModalForWinnerDeal from 'comps/atoms/RewardAdModalForWinnerDeal'
import PuppyButton from 'comps/atoms/puppyButton'
import NextLevelRewardModal from 'comps/atoms/nextLevelRewardModal'
import AlfarmAdStore from 'store/AlfarmAdStore'
import SellerAdPromotingModal from 'comps/atoms/Modals/SellerAdPromotingModal'
import { grapeUserIds } from '../grape'
import HarvestGrapeModal from 'comps/atoms/Modals/HarvestGrapeModal'
import AlranchMissions from 'comps/atoms/AlranchMissions'
import client from 'utils/alranchClient'
import FloatingButtonNudgeModal from 'comps/atoms/FloatingButtonNudgeModal'
import OfferwallMissionCompleteModal from 'comps/atoms/OfferwallMissionCompleteModal'
import FarmEfficacyModal from 'comps/atoms/FarmEfficacyModal'
import AB from 'utils/ab_v3'

import { useOnlyOneGifticonPerDay } from 'hooks/useOnlyOneGifticonPerDay'
import OnlyOneGifticonPerDayModal from 'comps/modal/composite/OnlyOneGifticonPerDayModal'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import { MysteryFlowerGameModal } from 'comps/atoms/Modals/MysteryFlowerGameModal'

import { basicWaterDoubleAB } from 'utils/abTests'
import DoubleWaterModal from 'comps/atoms/Modals/DoubleWaterModal'
import QuizAdReadyModal from 'comps/atoms/Modals/QuizAdReadyModal'
import {
  CPM_GOODS_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
  CPM_PURCHASE_FREQUENCY_TYPES,
} from 'utils/alfarmCPMConstant/cpmAdTypes'
import { VIDEO_AD_MODAL_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import CPMVideoAdModal from 'comps/organisms/VideoAdModal/CPMVideoAdModal'
import SansinEventModal from 'comps/organisms/SansinEventModal'
import QuizVideoAdModal from 'comps/organisms/VideoAdModal/QuizVideoAdModal'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import getCPMBottomEventIconButtonProps from 'utils/getCPMBottomEventIconButtonProps'
import LeavesVideoAdModal from 'comps/organisms/VideoAdModal/LeavesVideoAdModal'
import FortuneCookieAdReadyModal from 'comps/atoms/Modals/FortuneCookieAdReadyModal'
import CPMModalWrapper from '../comps/atoms/Modals/CPMModalWrapper'
import YutnoriVideoAdModal from 'comps/organisms/VideoAdModal/YutnoriVideoAdModal'
import FortuneCookieVideoAdModal from 'comps/organisms/VideoAdModal/FortuneCookieVideoAdModal'
import YutnoriAdReadyModal from 'comps/atoms/Modals/YutnoriAdReadyModal'
import UpdateAppRequestModal from 'comps/atoms/Modals/UpdateAppRequestModal'
import { USER_STORE_DEFAULT_CODE_PUSH_VERSION } from 'store/UserStore'
import FertilizerSetAdWatchModal from 'comps/atoms/Modals/FertilizerSetAdWatchModal'
import LotteryTicketScratchModal from 'comps/atoms/LotteryTicketScratchModal'
import ScratchLottoModal from 'comps/atoms/Modals/ScratchLottoModal/ScratchLottoModal'
import { showRewardAd } from 'utils/rewardAdHelper'
import FertilizerSetGetAdWatchModal from 'comps/atoms/Modals/FertilizerSetGetAdWatchModal'
import LottieGif from 'comps/atoms/LottieGif'
import bluebird from '../json/lottie/bluebird.json'
import LotteryTicketScratchBluebirdModal from 'comps/atoms/LotteryTicketScratchBluebirdModal'
import ScratchLottoBluebirdModal from 'comps/atoms/Modals/ScratchLottoModal/ScratchLottoBluebirdModal'
import NewYearSpecialDealEntryModal from 'comps/atoms/Modals/NewYearSpecialDealEntryModal'
let hidden, visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

// 백에서 userData 가져오는 것, 수정 할 필요가 없습니당!!
const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource = method === 'GET' ? url + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

const FarmPage = observer(({}) => {
  const throttleRef = useRef({})
  const token = useSearchParam('token')
  Cookies.set('x-access-token', token)
  const [itemType, setItemType] = useState(
    useSearchParam('itemType') || 'onion',
  )
  const { state } = useLocation()

  const phoneNumber = useSearchParam('phoneNumber')
  const indexNumber = useSearchParam('indexNumber')
  const [isLoading, setIsLoading] = useState(true)
  const randomString = useSearchParam('randomString')
  const [farmData, setFarmData] = useState({})
  const enteringType = useSearchParam('enteringType')
  const shareType = useSearchParam('shareType')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const myItemName = useSearchParam('myItemName')
  const [animState, setAnimState] = useState('')
  // const [disabled, setDisabled] = useState(true);
  const [waterModalVisible, setWaterModalVisible] = useState(false)
  const [photoReviewItemsInfo, setPhotoReviewItemsInfo] = useState([])
  const [harvestModalVisible, setHarvestModalVisible] = useState(false)
  const [finishedModalVisible, setFinishedModalVisible] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [inviteSuccessModal, setInviteSuccessModal] = useState(false)
  const [lastLackNutrimentPopUpAt, setLastLackNutrimentPopUpAt] = useState(
    JSON.parse(window.localStorage.getItem('lastLackNutrimentPopUpAt')) ?? null,
  )

  const [dailyGiveWaterPopUp, setDailyGiveWaterPopUp] = useState(0)
  const [canGetDailyGiveWater, setCanGetDailyGiveWater] = useState(false)
  const [luckydrawPurchaseRewardOn, setLuckydrawPurchaseRewardOn] =
    useState(false)

  const [luckydrawPurchaseRewardEndAt, setLuckydrawPurchaseRewardEndAt] =
    useState(new Date())
  const [luckydrawPurchaseRewardModal, setLuckydrawPurchaseRewardModal] =
    useState(false)
  const [dailyGetWaterUpgradeButton, setDailyGetWaterUpgradeButton] =
    useState(false)
  const [timeLoadingEndLuckydraw, setTimeLoadingEndLuckydraw] = useState(true)
  const [userCodePushVersion, setUserCodePushVersion] = useState(
    window.localStorage.getItem('userCodePushVersionNew'),
  )
  const [getResourceModal, setGetResourceModal] = useState(false)
  const [addWater, setAddWater] = useState(0)
  const [addFertilizer, setAddFertilizer] = useState(0)
  const [addFertilizerSet, setAddFertilizerSet] = useState(0)
  const [showlevelUpRewardModal, setShowLevelUpRewardModal] = useState(false)
  const [level, setLevel] = useState(1)
  const [remainExp, setRemainExp] = useState(0)
  const [expToLevelUp, setExpToLevelUp] = useState(0)
  const [basicWater, setBasicWater] = useState(0.001)
  const [resourceType, setResourceType] = useState('water')
  const [canClickGetWater, setCanClickGetWater] = useState(false)
  const [openJuwelryModal, setOpenJuwelryModal] = useState(false)

  const [dailyGiveWaterLevelOne, setDailyGiveWaterLevelOne] = useState(0)
  const [dailyGiveWaterLevelTwo, setDailyGiveWaterLevelTwo] = useState(0)
  const [dailyGiveWaterLevelThree, setDailyGiveWaterLevelThree] = useState(0)
  const [dailyGiveWaterLevelFour, setDailyGiveWaterLevelFour] = useState(0)
  const [checkInPurchaseIconOn, setCheckInPurchaseIconOn] = useState(false)

  const [canClickGiveWater, setCanClickGiveWater] = useState(false)
  const [canClickSellerFarm, setCanClickSellerFarm] = useState(false)

  const [indexCount, setIndexCount] = useState(0)

  const [showMinimumWaterModal, setShowMinimumWaterModal] = useState(false)
  const [showMinimumNutrimentModal, setShowMinimumNutrimentModal] =
    useState(false)
  const [showMinimumBasicWaterModal, setShowMinimumBasicWaterModal] =
    useState(false)
  const [userId, setUserId] = useState()
  const [increasePerSecond, setIncreasePerSecond] = useState(0)
  const [
    canGetDailyAttendanceWaterNotification,
    setCanGetDailyAttendanceWaterNotification,
  ] = useState(false)

  const [
    canGetSevendaysEventNotification,
    setCanGetSevendaysEventNotification,
  ] = useState(false)

  const [isFirstConversion, setIsFirstConversion] = useState(false)
  const [canGetFertButtonNotification, setCanGetFertButtonNotification] =
    useState(true)
  const [canGetWaterButtonNotification, setCanGetWaterButtonNotification] =
    useState(true)
  const [enteringRoute, setEnteringRoute] = useState('normal')
  const [giveWaterCount, setGiveWaterCount] = useState(0)
  const [getWaterCount, setGetWaterCount] = useState(0)
  const [lastHarvestPurchaseButtonOn, setLastHarvestPurchaseButtonOn] =
    useState(false)
  const [quizPurchaseButtonOn, setQuizPurchaseButtonOn] = useState(false)
  const [adItemBrowsingButtonOn, setAdItemBrowsingButtonOn] = useState(false)

  const [inviteFriendLength, setInviteFriendLength] = useState(0)
  const [
    questLogForGetWaterDailyAttendance,
    setQuestLogForGetWaterDailyAttendance,
  ] = useState(false)

  const [questLogForAttendanceFert, setQuestLogForAttendanceFert] =
    useState(false)
  const [alertModal, setAlertModal] = useState(false)

  const [tutorialFinishedModal, setTutorialFinishedModal] = useState(false)

  const [puppyGiftModal, setPuppyGiftModal] = useState(false)
  const [puppyRewardType, setPuppyRewardType] = useState('')
  const [showPuppy, setShowPuppy] = useState(false)
  const [puppyEndTime, setPuppyEndTime] = useState(new Date())
  const [showSevenDaysModal, setShowSevenDaysModal] = useState(false)

  const [isNewUser, setIsNewUser] = useState(false)
  const [successModalRewardType, setSuccessModalRewardType] = useState()
  const [newUserEvent, setNewUserEvent] = useState(false)
  const [openChangeMandarinModal, setOpenChangeMandarinModal] = useState(false)
  const [openChangePersimmonModal, setOpenChangePersimmonModal] =
    useState(false)
  const [inviteResultStatus, setInviteResultStatus] = useState('normal')
  const [maxDailyInviteCount, setMaxDailyInviteCount] = useState(3)
  const [didDailyInviteQuestCleared, setDidDailyInviteQuestCleared] =
    useState(false)

  const [newbiePurchaseWaterRemainTime, setNewbiePurchaseWaterRemainTime] =
    useState(-1)
  const [isSecretIconOn, setIsSecretIconOn] = useState(false)
  const [lastScratch, setLastScratch] = useState()
  const [clearedAlwayzFarm, setClearedAlwayzFarm] = useState(false)
  const [openSecretScratchModal, setOpenSecretScratchModal] = useState(false)
  const [secretScratchHistory, setSecretScratchHistory] = useState()
  const [isLastMinuteInviteOn, setIsLastMinuteInviteOn] = useState(false)
  const [isLevelUpChanceInviteOn, setIsLevelUpChanceInviteOn] = useState(false)

  const [produceFertMachineHistory, setProduceFertMachineHistory] = useState()

  const [
    commonRemovedDependencyInviteInfo,
    setCommonRemovedDependencyInviteInfo,
  ] = useState(false)

  const [isDailyLotteryOn, setIsDailyLotteryOn] = useState(false)
  const [dailyLotteryData, setDailyLotteryData] = useState(false)
  const [personalDailyLotteryInfo, setPersonalDailyLotteryInfo] =
    useState(false)

  const [ongoingWaterBattleBanner, setOngoingWaterBattleBanner] =
    useState(false)

  const [amount, setAmount] = useState(0)
  const [battleWaterAmount, setBattleWaterAmount] = useState(0)
  const [opponentBattleWaterCount, setOpponentBattleWaterCount] = useState(0)
  const codePushVersionAvailable = useCheckCodePushVersion()
  const AppVersionAvailable = useCheckAppVersion()
  const [battleData, setBattleData] = useState(false)
  const [battleClassName, setBattleClassName] = useState('none')

  const [novHarvestUserData, setNovHarvestUserData] = useState('')
  const [dailyWaterPurchaseRewardToggle, setDailyWaterPurchaseRewardToggle] =
    useState()

  const [updateMinigameInfo, setUpdateMinigameInfo] = useState(false)
  const [rewardTypeForRewardToast, setRewardTypeForRewardToast] =
    useState('water')
  const [rewardAmountForRewardToast, setRewardAmountForRewardToast] =
    useState(0)
  const [producingFertMachine, setProducingFertMachine] = useState(false)
  const [lotteryDrawSpecialPurchase, setLotteryDrawSpecialPurchase] =
    useState(false)
  const [lastRewardAnnouncementDoc, setLastRewardAnnouncementDoc] =
    useState(false)
  const [hasUnreadAnnouncement, setHasUnreadAnnouncement] = useState(false)
  const [announcementList, setAnnouncementList] = useState([])

  const [singleDealInfo, setSingleDealInfo] = useState()

  const [singleDealParticipated, setSingleDealParticipated] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [todayCheckInDone, setTodayCheckInDone] = useState(false)
  const [couponInfo, setCouponInfo] = useState()

  const [showCouponUsageNudgeIconOn, setShowCouponUsageNudgeIconOn] =
    useState(false)

  const [showSellerCouponPurchaseIconOn, setShowSellerCouponPurchaseIconOn] =
    useState(false)
  const [openIndicator, setOpenIndicator] = useState(false)
  const [checkShowPuppy, setCheckShowPuppy] = useState(false)

  const [relayRewardIconOn, setRelayRewardIconOn] = useState(false)
  const [relayRewardInfo, setRelayRewardInfo] = useState(false)
  const [lastAnnouncementNumber, setLastAnnouncementNumber] = useState()
  const [gganbuData, setGganbuData] = useState([])
  const [sellerFarmList, setSellerFarmList] = useState([])

  const [giveWaterCountAtThisVisit, setGiveWaterCountAtThisVisit] = useState(0)
  const [sellerCoupons, setSellerCoupons] = useState()
  const [selectedSellerCoupon, setSelectedSellerCoupon] = useState()
  const [openLifeModal, setOpenLifeModal] = useState(false)
  const [openSeasonOffModal, setOpenSeasonOffModal] = useState(false)

  const [showChangePlantAmountModal, setShowChangePlantAmountModal] =
    useState(false)
  const [
    showChangePlantAmountRightButton,
    setShowChangePlantAmountRightButton,
  ] = useState(false)

  const [showMissionHunterIcon, setShowMissionHunterIcon] = useState(false)
  const [missionHunterPurchaseInfo, setMissionHunterPurchaseInfo] = useState()
  const [showPushSettingModal, setShowPushSettingModal] = useState(false)

  const [showPushSettingFinishedPopup, setShowPushSettingFinishedPopup] =
    useState(false)

  const [canCheckInForWelcomeNewbie, setCanCheckInForWelcomeNewbie] =
    useState(false)

  const [quotesIndex, setQuotesIndex] = useState(false)

  const [changePlantBeforeChurnIconOn, setChangePlantBeforeChurnIconOn] =
    useState(false)
  const [isButtonPressedGiveWater, setIsButtonPressedGiveWater] =
    useState(false)

  const [surpriseMissionEntryIconOn, setSurpriseMissionEntryIconOn] =
    useState(false)
  const [isTreasureBoxMissionTargetUser, setIsTreasureBoxMissionTargetUser] =
    useState(false)
  const [kidsRevenueDealIconOn, setKidsRevenueDealIconOn] = useState(false)
  const [kidsRevenueDealEndTime, setKidsRevenueDealEndTime] = useState(
    new Date(),
  )
  const [winterRevenueDealIconOn, setWinterRevenueDealIconOn] = useState(false)
  const [winterRevenueDealEndTime, setWinterRevenueDealEndTime] = useState(
    new Date(),
  )
  const [snackRevenueDealIconOn, setSnackRevenueDealIconOn] = useState(false)
  const [snackRevenueDealEndTime, setSnackRevenueDealEndTime] = useState(
    new Date(),
  )
  const [daisoRevenueDealIconOn, setDaisoRevenueDealIconOn] = useState(false)
  const [daisoRevenueDealEndTime, setDaisoRevenueDealEndTime] = useState(
    new Date(),
  )

  const [goldAccesorryRevenueDealIconOn, setGoldAccesorryRevenueDealIconOn] =
    useState(false)
  const [goldAccesorryRevenueDealEndTime, setGoldAccesorryRevenueDealEndTime] =
    useState(new Date())

  const [summerRevenueDealIconOn, setSummerRevenueDealIconOn] = useState(false)
  const [summerRevenueDealEndTime, setSummerRevenueDealEndTime] = useState(
    new Date(),
  )

  const [healthRevenueDealIconOn, setHealthRevenueDealIconOn] = useState(false)
  const [healthRevenueDealEndTime, setHealthRevenueDealEndTime] = useState(
    new Date(),
  )
  const [yutnoriPurchaseEntryButtonOn, setYutnoriPurchaseEntryButtonOn] =
    useState(false)
  const [timeDealEntryIcon, setTimeDealEntryIcon] = useState(false)
  const [timeCapsuleReceived, setTimeCapsuleReceived] = useState(false)
  const [fertilizerRouletteOn, setFertilizerRouletteOn] = useState(false)
  const [fertilizerTowerGameOn, setFertilizerTowerGameOn] = useState(false)
  const [feverModeGameOn, setFeverModeGameOn] = useState(false)
  const [purchaseRouletteButtonOn, setPurchaseRouletteButtonOn] =
    useState(false)
  const [selectOnePurchaseButtonOn, setSelectOnePurchaseButtonOn] =
    useState(false)
  const [allBrandDealPurchaseButtonOn, setAllBrandDealPurchaseButtonOn] =
    useState(false)

  const [personalizedShopButtonOn, setPersonalizedShopButtonOn] =
    useState(false)
  const [purchaseRouletteData, setPurchaseRouletteData] = useState(false)

  const [personalizedShopData, setPersonalizedShopData] = useState(false)
  const [selectOnePurchaseData, setSelectOnePurchaseData] = useState(false)
  const [allBrandDealPurchaseData, setAllBrandDealPurchaseData] =
    useState(false)

  const [giveWaterBlock, setGiveWaterBlock] = useState(false)
  const [lackWaterEngageType, setLackWaterEngageType] = useState('')
  const [engageModalCount, setEngageModalCount] = useState(0)
  const [isHarvestInviteAvailable, setIsHarvestInviteAvailable] =
    useState(false)
  const [waterBrowsingAdQuestOn, setWaterBrowsingAdQuestOn] = useState(false)

  const isLoadingRef = useRef()
  const [dailyGetWaterActionName, setDailyGetWaterActionName] =
    useState('getWater')

  const [yesterdayGiveWater, setYesterdayGiveWater] = useState(0)
  const [removedWater, setRemovedWater] = useState(0)
  const [trendQuizOn, setTrendQuizOn] = useState(false)
  const navigate = useNavigate()

  const [isEntryVisible, setIsEntryVisible] = useState(false)

  // 좌상단 아이콘 진입점 개인화
  const [lastFriendGardenUse, setLastFriendGardenUse] = useState(null)
  const [lastRouletteUse, setLastRouletteUse] = useState(null)
  const [alertModalOnClick, setAlertModalOnClick] = useState(() => {})

  const [pendingWaterAmount, setPendingWaterAmount] = useState(0)
  const [purchaseFrequency, setPurchaseFrequency] = useState(null)

  const [showByebyeBluebird, setShowByebyeBluebird] = useState(false)

  // 우물강화 트래킹용 AB
  const timerWaterUpTracking = ABv3(farmData?.userId, 'tWaterUp0116', [5])

  const { ModalStore, AlwayzUserStore, BottomSheetStore, UserStore } =
    indexStore()

  // 물 주기 버튼 클릭 카운트
  const [giveWaterButtonClickedCount, setGiveWaterButtonClickedCount] =
    useState(0)

  const giveWaterTest = ABv2(farmData?.userId, 'giveWater0108', [95])

  // 오늘의특가, 퀴즈, 윷놀이 플레이 가능 체크 리마인더(개수, 시간)
  const [winnerDealViewAvailable, setWinnerDealViewAvailable] = useState(false)
  const [quizViewAvailable, setQuizViewAvailable] = useState(false)
  const [yutnoriPlayAvailable, setYutnoriPlayAvailable] = useState(false)

  // 올목장 올랜치
  const [alranchMissionsData, setAlranchMissionsData] = useState(null)
  const [alranchCowData, setAlranchCowData] = useState(null)

  const [cpmLeavesAdProps, setCPMLeavesAdProps] = useState(null)
  const [cpmFortuneCookieAdProps, setCPMFortuneCookieAdProps] = useState(null)

  // 2025 설 기획전 진입점 on/off -> 우주최저가, 10분핫딜 자리에도 관여
  const newYear2025EventEntryOn =
    codePushVersionAvailable(userCodePushVersion, '6.8.29') &&
    new Date() <= new Date('2025-02-07T00:00:00.000Z')

  // 24시간 한 번만 수확 가능 모달안내!
  const { onlyOneGifticonPerDayModal, setonlyOneGifticonPerDayModal } =
    useOnlyOneGifticonPerDay()

  // 10분 핫딜 물주기 modal
  useEffect(() => {
    const tenMinDealInflowModalAB = ABv2(
      farmData?.userId,
      'tenMinInflowModal1127',
      [90],
    )
    if (
      giveWaterCount > 200 &&
      giveWaterButtonClickedCount > 0 && // 최초 렌더 이후
      DealAutomationStore.isTenMinDealOpen() &&
      DealAutomationStore.isTenMinDealModalOpen() &&
      ABv2(farmData?.userId, 'tenMin1110', [95]) === 'a' &&
      tenMinDealInflowModalAB === 'a' &&
      codePushVersionAvailable(userCodePushVersion, '4.3.14')
    ) {
      const openModal = async () => {
        const now = dayjs()
        const lastShown = localStorage.getItem('tenMinDealGiveWaterModalShow')
        const lastShownDate = lastShown ? dayjs(lastShown) : null

        if (!lastShownDate || !now.isSame(lastShownDate, 'day')) {
          showAndSaveModal(now)
        } else {
          // 같은 날이면 오전/오후 체크
          const isMorningNow = now.hour() < 12
          const wasMorningThen = lastShownDate.hour() < 12

          if (isMorningNow !== wasMorningThen) {
            showAndSaveModal(now)
          }
        }
      }

      openModal()
    }
  }, [giveWaterButtonClickedCount])

  const showAndSaveModal = async (now) => {
    await TimeStore.timeSleep(1500)
    ModalStore.setIsModalOpen('tenMinDealModal')
    localStorage.setItem('tenMinDealGiveWaterModalShow', now)
  }

  useEffect(() => {
    // 우물강화 광고 시작/끝나는 날짜
    const startTime = new Date(
      DealAutomationStore?.firstWaterFountainAdData?.timeRangeStart,
    )
    const endTime = new Date(
      DealAutomationStore?.firstWaterFountainAdData?.timeRangeEnd,
    )

    const checkVisibility = () => {
      const currentTime = new Date()

      if (currentTime >= startTime && currentTime <= endTime) {
        setIsEntryVisible(true)
        setDailyGetWaterUpgradeButton(true)
      } else {
        setIsEntryVisible(false)
        setDailyGetWaterUpgradeButton(false)
      }
    }

    checkVisibility()

    // Set interval to check every minute
    const intervalId = setInterval(checkVisibility, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  // 체리피커 정의
  useEffect(() => {
    if (!farmData?.userId) return
    if (farmData?.isNewUser) return
    if (
      farmData?.resurrectedAt &&
      new Date() - new Date(farmData?.resurrectedAt) <= 1000 * 60 * 60 * 24 * 15
    ) {
      return
    }
    if (codePushVersionAvailable(userCodePushVersion, '5.0.61') === false)
      return

    const purchaseHistoryCheck = purchaseHistoryWithinPeriod(farmData, 30, 1)
    UserStore.setCherryPickerForAd(purchaseHistoryCheck)
  }, [UserStore?.orderCount, farmData?.userId])

  // 헤비유저 정의 - 한 달 내 구매기록 2회 이상
  useEffect(() => {
    if (!farmData?.userId) return
    if (farmData?.isNewUser) return

    const purchaseHistoryCheck = purchaseHistoryWithinPeriod(farmData, 30, 2) // 30일 내 2회 미만 구매했는지 확인하는 함수임
    AlfarmAdStore.setIsAlfarmHeavyUserForAd(!purchaseHistoryCheck)
  }, [UserStore?.orderCount, farmData?.userId])

  // 아래는 CPM 광고 관련 로직
  // 유저 PURCHASE FREQUENCY 정의 - LOW / MEDIUM / HEAVY
  useEffect(() => {
    const RESURRECTED_USER_TIME_LIMIT = 1000 * 60 * 60 * 24 * 15
    const CODE_PUSH_VERSION_LIMIT = '5.0.61' // 영상 광고를 볼 수 있는 최소 지점
    const MEDIUM_PURCHASE_FREQUENCY = 1

    // farmData가 완전히 로드되었는지 확인
    if (!farmData?.purchaseHistorys) {
      return
    }

    if (
      !farmData?.userId || // 유저 아이디 없으면 무조건 리턴
      farmData?.isNewUser || // 뉴유저는 무조건 리턴
      codePushVersionAvailable(
        UserStore.codePushVersionFromAlwayzApp,
        CODE_PUSH_VERSION_LIMIT,
      ) === false || // 코드푸시 버전 체크 안되면 리턴
      (farmData?.resurrectedAt &&
        new Date() - new Date(farmData?.resurrectedAt) <=
          RESURRECTED_USER_TIME_LIMIT) // 부활한 유저는 리턴
    )
      return

    const orderCountIn30Days = getUserOrderCountIn30Days(farmData)

    // 이전 상태와 비교하여 불필요한 업데이트 방지
    const currentFrequency = UserStore.purchaseFrequency
    let newFrequency

    if (orderCountIn30Days > MEDIUM_PURCHASE_FREQUENCY) {
      newFrequency = CPM_PURCHASE_FREQUENCY_TYPES.HEAVY
    } else if (orderCountIn30Days === MEDIUM_PURCHASE_FREQUENCY) {
      newFrequency = CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM
    } else {
      newFrequency = CPM_PURCHASE_FREQUENCY_TYPES.LOW
    }

    if (currentFrequency !== newFrequency) {
      setPurchaseFrequency(newFrequency)
      UserStore.setPurchaseFrequency(newFrequency)
    }
  }, [
    farmData?.userId,
    farmData?.isNewUser,
    farmData?.resurrectedAt,
    farmData?.purchaseHistorys,
    UserStore.codePushVersionFromAlwayzApp,
  ])

  useEffect(() => {
    const is60CherryPickUser = async () => {
      const MS_PER_DAY = 1000 * 60 * 60 * 24
      const SIXTY_DAYS = MS_PER_DAY * 60

      const lastPurchaseDate =
        UserStore?.purchaseHistorys?.[UserStore?.purchaseHistorys?.length - 1]
          ?.purchasedAt
      const timeSinceLastPurchase = lastPurchaseDate
        ? new Date() - new Date(lastPurchaseDate)
        : null
      const timeSinceCreation = new Date() - new Date(farmData?.createdAt)

      const isEligible = !farmData?.cherryPickPurchased
      const hasNoPurchases = !UserStore?.purchaseHistorys?.length

      // 60일 체리피커 체크
      if (
        isEligible &&
        ((timeSinceLastPurchase && timeSinceLastPurchase > SIXTY_DAYS) ||
          (hasNoPurchases && timeSinceCreation > SIXTY_DAYS))
      ) {
        UserStore.setIs60CherryPickingUser(true)
      }
    }
    is60CherryPickUser()
  }, [
    UserStore,
    farmData?.cherryPickPurchased,
    farmData?.createdAt,
    farmData?.userId,
  ])

  useEffect(() => {
    const getRewardAdWatchCount = async () => {
      const result = await backendApis.getRewardAdWatchCount()
      if (result?.status === 200) {
        UserStore.setRewardAdWatchCount(result.data)
      }
    }
    getRewardAdWatchCount()
  }, [])

  // CPM - 6.8.37 미만 버전 유저는 앱스토어 업데이트
  useEffect(() => {
    const UPDATE_APP_REQUEST_CODE_PUSH_VERSION = '6.9.99'

    if (!farmData?.userId) return
    if (
      UserStore.codePushVersionFromAlwayzApp ===
      USER_STORE_DEFAULT_CODE_PUSH_VERSION
    )
      return // return when default version

    if (
      !codePushVersionAvailable(
        UserStore.codePushVersionFromAlwayzApp,
        UPDATE_APP_REQUEST_CODE_PUSH_VERSION,
      )
    ) {
      ModalStore.setIsModalOpen('updateAppRequest')
      ABStore.isCPMAdViewer = false
    } else {
      ABStore.isCPMAdViewer = true
    }
  }, [farmData?.userId, UserStore.codePushVersionFromAlwayzApp])

  // 낙엽 & 포춘쿠키 -> gganbuWithEventIcons -> bottomEventIcons로 전달됨, 낙엽은 ->Farm->Plant로도 감
  const setCPMBottomEventIconsAdProps = async () => {
    const leavesAdProp = await getCPMBottomEventIconButtonProps({
      enteringComponent: CPM_ENTERING_COMPONENTS.LEAVES_AD,
    })
    const fortuneCookieAdProp = await getCPMBottomEventIconButtonProps({
      enteringComponent: CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
    })

    setCPMLeavesAdProps(leavesAdProp)
    setCPMFortuneCookieAdProps(fortuneCookieAdProp)
  }

  // 광고 시청 후 setCPMBottomEventIconsAdProps 실행
  useEffect(() => {
    // 초기 실행 & 비디오 광고 시청 후 실행
    setCPMBottomEventIconsAdProps()

    // 상품 광고 시청 후 실행
    const handleStoredCPMEvent = async (event) => {
      if (event.detail?.success) {
        await setCPMBottomEventIconsAdProps()
      }
    }

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        await sleep(1000)
        await setCPMBottomEventIconsAdProps()
      }
    }

    window.addEventListener('storedCPMExposedItems', handleStoredCPMEvent)
    document.addEventListener(visibilityChange, handleVisibilityChange)

    return () => {
      window.removeEventListener('storedCPMExposedItems', handleStoredCPMEvent)
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [
    UserStore.codePushVersionFromAlwayzApp,
    UserStore.purchaseFrequency,
    UserStore.isWatchingAd,
  ])

  // 레디 모달에서 상품 모달 넘어간 후 실행 (산신령)
  useEffect(() => {
    const targetModalList = [
      CPM_GOODS_MODAL_TYPES.FORTUNE_COOKIE_AD,
      CPM_VIDEO_MODAL_TYPES.FORTUNE_COOKIE_AD,
    ]
    if (targetModalList.includes(ModalStore.isModalOpen)) {
      setCPMBottomEventIconsAdProps()
    }
  }, [ModalStore.isModalOpen])

  // CPM - localStorageCPMAdHistory와 serverCPMAdHistory 비교하여 RN request && localStorage 업데이트
  // localStorage의 'CPMAdHistory' 키에 저장되는 데이터 형태 => { "Alfarm_recommendedProductAd": "2024-03-14T12:00:00Z", "Alfarm_todayDealAd": "2024-03-14T15:30:00Z", ... }
  useEffect(() => {
    if (!farmData) return
    const AD_TYPES = [...Object.values(CPM_ENTERING_COMPONENTS)]

    const getCPMAdHistory = async () => {
      const result = await backendApis.getMultipleCPMAdStatusesByTypes({
        types: AD_TYPES,
      })
      if (result.status === 200) {
        const resultData = result.data.reduce(
          (acc, x) => ({
            ...acc,
            [x.type]: x.clearedAt,
          }),
          {},
        )
        return resultData
      }
      return {}
    }

    // localStorageHistory와 CPMAdHistory 비교하여 trigger
    const cpmExposedItemsRequestTrigger = async () => {
      try {
        const localStorageCPMAdHistory =
          JSON.parse(localStorage.getItem('CPMAdHistory')) || {}

        const serverCPMAdHistory = await getCPMAdHistory()

        const isCPMAdHistoryChanged = Object.entries(serverCPMAdHistory).some(
          ([key, serverTimestamp]) =>
            serverTimestamp &&
            (!localStorageCPMAdHistory?.[key] ||
              localStorageCPMAdHistory?.[key] !== serverTimestamp),
        )

        // trigger 시 RN에 데이터 요청
        if (isCPMAdHistoryChanged) {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'requestCPMExposedItems',
            }),
          )
        }

        window._serverCPMAdHistory = serverCPMAdHistory

        return isCPMAdHistoryChanged
      } catch (error) {
        console.log(error)
        return false
      }
    }

    // 저장 완료 후 localStorageHistory 업데이트
    const updateLocalStorageHistoryWhenStored = (event) => {
      if (!event.detail?.success) return

      try {
        const serverHistory = window._serverCPMAdHistory || {}

        const newHistory = Object.fromEntries(
          Object.entries(serverHistory).filter(
            ([_, timestamp]) => timestamp != null && timestamp != undefined,
          ),
        )

        localStorage.setItem('CPMAdHistory', JSON.stringify(newHistory))
      } catch (error) {
        console.error('Error updating localStorage history:', error)
      } finally {
        // 이벤트 리스너 정리
        window.removeEventListener(
          'storedCPMExposedItems',
          updateLocalStorageHistoryWhenStored,
        )
      }
    }

    // trigger 작동 & localStorage 업데이트 listener 설정
    const initCPMAdHistory = async () => {
      const isTriggered = await cpmExposedItemsRequestTrigger()
      if (isTriggered) {
        window.addEventListener(
          'storedCPMExposedItems',
          updateLocalStorageHistoryWhenStored,
        )
      }
    }

    initCPMAdHistory()

    // cleanup
    return () => {
      window.removeEventListener(
        'storedCPMExposedItems',
        updateLocalStorageHistoryWhenStored,
      )
      delete window._serverCPMAdHistory
    }
  }, [farmData])

  // CPM - RN에서 exposedItems 받아서 localStorage 저장 (공용)
  // localStorage 중 CPMExposedItemIds = { recentUpdatedAt: , exposedItemIds: [] }
  useEffect(() => {
    // local에 저장할 CPMItemIds 최대 저장 개수
    const MAX_CPM_ITEMS_STORE_LENGTH = 20

    // 1. RN 데이터 파싱
    const getCPMExposedItemIdsFromRN = (event) => {
      if (event?.data) {
        return false
      }

      try {
        const { type, CPMExposedItemIdList } = JSON.parse(event.data)
        if (type === 'responseCPMExposedItems') {
          return CPMExposedItemIdList
        }
        return false
      } catch (error) {
        console.error('Error parsing CPM data:', error)
        return false
      }
    }

    // 2. localStorage 저장
    const storeCPMExposedItemIdsToLocalStorage = (inputIds) => {
      if (!inputIds || inputIds.length === 0) return

      try {
        const storedExposedItemIds =
          JSON.parse(localStorage.getItem('CPMExposedItems'))?.exposedItemIds ||
          []

        const newItems = [
          ...new Set([...storedExposedItemIds, ...inputIds]),
        ].slice(-MAX_CPM_ITEMS_STORE_LENGTH)

        localStorage.setItem(
          'CPMExposedItems',
          JSON.stringify({
            recentUpdatedAt: new Date().toISOString(),
            exposedItemIds: newItems,
          }),
        )

        // 저장 성공 이벤트 발생
        return true
      } catch (error) {
        console.error('Error storing CPM items:', error)
        return false
      }
    }

    // 3. 저장 성공 이벤트 발생
    const dispatchSuccessEventWhenStored = () => {
      window.dispatchEvent(
        new CustomEvent('storedCPMExposedItems', {
          detail: { success: true },
        }),
      )
    }

    // 4. 메인 핸들러
    const handle_CPMExposedItems = (event) => {
      const exposedItemIds = getCPMExposedItemIdsFromRN(event)
      if (
        exposedItemIds &&
        storeCPMExposedItemIdsToLocalStorage(exposedItemIds)
      ) {
        dispatchSuccessEventWhenStored()
      }
    }

    // 기기 별로 이벤트 리스너 설정
    const target = isIOS || isIOS13 ? window : document
    target.addEventListener('message', handle_CPMExposedItems)

    // 클린업
    return () => target.removeEventListener('message', handle_CPMExposedItems)
  }, [])

  // CPM - 일 단위로 CPMLocalStorage 비워주기
  useEffect(() => {
    const removeCPMLocalStorageIfNotToday = () => {
      const result = localStorage.getItem('CPMExposedItems')
      if (!result) return
      const isCPMExposedItemsUpdatedToday = checkIsTodayWithISODate(
        JSON.parse(result).recentUpdatedAt,
      )
      if (!isCPMExposedItemsUpdatedToday) {
        localStorage.removeItem('CPMExposedItems')
      }
    }
    // removeCPMLocalStorageIfNotToday() - 꺼뒀음
  }, [])

  //leftScroll 미션
  useEffect(() => {
    // 필요 시 이벤트 기간 통제
    // 기본 필터링 조건 - 뉴유저 & 물주기 300회 이하, 체리피커, 코드푸시

    if (!farmData?.userId) {
      return
    }
    if (farmData?.isNewUser && farmData?.giveWaterCount < 300) {
      return
    }
    if (
      codePushVersionAvailable(
        UserStore.codePushVersionFromAlwayzApp,
        '5.0.61',
      ) === false
    ) {
      return
    }
    if (!ABStore.isCPMAdViewer && UserStore.cherryPickerForAd) {
      return
    }
    if (
      ABStore.isCPMAdViewer &&
      UserStore.purchaseFrequency === CPM_PURCHASE_FREQUENCY_TYPES.LOW
    ) {
      AlfarmAdStore.setIsSanSinEventTarget(false)
      return
    }

    const checkSellerAdPromotingTarget = async () => {
      if (AlfarmAdStore.isSanSinLoading) return
      AlfarmAdStore.setIsSanSinLoading(true)
      // 마지막 광고 이벤트 날짜 & 날짜 차이 정의
      const result = await backendApis.getWaterBottomSheetInfo(token)
      let lastSellerAdPromotingStartedDate =
        result?.waterBottomSheetInfoTimeChecker
          ?.lastSellerAdPromotingStartedDate
      let dayDifference = lastSellerAdPromotingStartedDate
        ? Math.floor(
            (new Date() - new Date(lastSellerAdPromotingStartedDate)) /
              (1000 * 60 * 60 * 24),
          )
        : 0

      // 이벤트 로그를 새로 설정해야 하는 고객 -> 이벤트 기록이 없거나, 마지막 이벤트와 날짜 차이 1 이상
      const shouldSetNewEventLog =
        !lastSellerAdPromotingStartedDate || dayDifference >= 1

      // 프로모션 타겟 설정 & 조건에 따라 gep 이벤트 로그 생성
      const setSellerAdPromotingTarget = async () => {
        AlfarmAdStore.setIsSanSinEventTarget(true)
        if (shouldSetNewEventLog) {
          await backendApis.setNewSellerAdPromotingEventLog(token)
          // 로그 남긴 후 조건 맞추기 위해 temp 데이터 셀프 업데이트
          const calculateMissionDate = () => {
            const now = new Date()
            const offset = now.getHours() < 4 ? -2 : -1
            const missionStartDate = new Date(now)
            missionStartDate.setDate(now.getDate() + offset)
            missionStartDate.setUTCHours(19, 0, 0, 0) // 전날 19시 UTC로 고정
            return missionStartDate
          }
          lastSellerAdPromotingStartedDate = calculateMissionDate()
          dayDifference = 0
        }
      }
      await setSellerAdPromotingTarget()

      // 오늘 세 번째 리워드 아직 안 받았으면(다 못 깼으면) 핑 띄움
      const rewardLog = await backendApis.getRewardLogForSellerAdPromoting(
        token,
      )
      const isTodayMissionCleared =
        (rewardLog?.data?.rewardLogForSellerAdPromoting ?? []).filter(
          (item) => !timeChecker(item?.getThirdRewardAt),
        )?.length || 0
      if (isTodayMissionCleared === 0) {
        AlfarmAdStore.setShowPingForSanSinEvent('!')
      }
      AlfarmAdStore.setIsSanSinLoading(false)
    }
    checkSellerAdPromotingTarget()
  }, [
    farmData?.userId,
    token,
    UserStore.cherryPickerForAd,
    AlfarmAdStore.isAlfarmHeavyUserForAd,
    AlfarmAdStore.getRendering,
    purchaseFrequency,
    UserStore.codePushVersionFromAlwayzApp,
  ])

  //데이터 가져오기
  useEffect(() => {
    if (!token) {
      return
    }

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        setIsLoading(true)

        await sleep(UserStore.isWatchingAd ? 1000 : 500)
        await getAlfarmData({ token })
      }
    }

    codePushVersion()
    getAlfarmData({ token, farmData })
    getWelcomeQuestCheckInData()

    document.addEventListener(visibilityChange, handleVisibilityChange)

    window?.ReactNativeWebView?.postMessage('alfarmMessageChannelReadySignal')

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [token, UserStore.isWatchingAd])

  useEffect(() => {
    if (
      farmData?.isNewUser &&
      farmData?.userId &&
      farmData?.treasureBoxMissionStartedAt &&
      farmData?.giveWaterCount >= 30 &&
      new Date(farmData?.treasureBoxMissionStartedAt) &&
      !farmData?.treasureBoxMissionFinishedAt
      // <
      //   new Date(new Date().getTime() + 1000 * 60 * 60 * 24))
    ) {
      setIsTreasureBoxMissionTargetUser(true)
    } else setIsTreasureBoxMissionTargetUser(false)
  }, [
    farmData?.giveWaterCount >= 30,
    farmData?.exp > farmData?.farmLevel?.level2,
    farmData?.treasureBoxMissionStartedAt,
    farmData?.treasureBoxMissionFinishedAt,
  ])

  /// 포도 유저들 수확시켜주기
  useEffect(() => {
    if (!farmData?.userId) return

    if (grapeUserIds?.includes(farmData?.userId)) {
      if (farmData?.exp >= 200000) {
        ModalStore.setIsModalOpen('harvestGrape')
      }
    }
  }, [farmData?.userId])

  useEffect(() => {
    const handleContactGganbu = ({ data }) => {
      if (data === 'rejected') {
        ModalStore.setIsModalOpen('phoneBookPermission')
      } else if (data === 'accepted') {
        ModalStore.setIsModalOpen('basic')
        ModalStore.setIsModalOpen('contactsList')
      }
    }
    if (isIOS || isIOS13) {
      window.addEventListener('message', handleContactGganbu)
      return () => {
        window.removeEventListener('message', handleContactGganbu)
      }
    } else {
      document.addEventListener('message', handleContactGganbu)
      return () => {
        document.removeEventListener('message', handleContactGganbu)
      }
    }
  }, [])

  useEffect(() => {
    const handleTeamDealId = ({ data }) => {
      if (!data || data === 'undefined') {
        return
      }

      try {
        const parsedData = JSON.parse(data)
        if (parsedData?.teamDealId) {
          AlwayzUserStore.setTeamDealId(parsedData?.teamDealId)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (isIOS || isIOS13) {
      window.addEventListener('message', handleTeamDealId)
      return () => {
        window.removeEventListener('message', handleTeamDealId)
      }
    } else {
      document.addEventListener('message', handleTeamDealId)
      return () => {
        document.removeEventListener('message', handleTeamDealId)
      }
    }
  }, [])

  useEffect(() => {
    if (enteringType === 'normal' || enteringType === 'gganbuNormal') {
      return
    } else if (enteringType === 'RepurchaseCouponModal') {
      ModalStore.setIsModalOpen('sellerCounponPurchase')
    } else if (enteringType) {
      setEnteringRoute(enteringType)
      setAlertModal(true)
    } else {
      setAlertModal(false)
    }
    // lastPlayedAt();
  }, [])

  useEffect(() => {
    if (farmData) {
      UserStore.setFarmData(farmData)
      checkinPurchaseInfo()
      if (
        farmData?.purchaseRouletteInfo?.purchase &&
        !farmData?.purchaseRouletteInfo?.run
      ) {
        ModalStore.setIsModalOpen('purchaseRoulette')
      }
      if (
        farmData?.dailyWaterUpgradeEndAt &&
        dayjs(farmData?.dailyWaterUpgradeEndAt).isAfter(TimeStore?.currentDayJS)
      ) {
        setDailyGetWaterActionName('upgradedGetWater')
      }
    }
  }, [farmData, TimeStore?.currentDayJS])

  useEffect(() => {
    if (farmData?._id) {
      getTrendQuiz()
    }
  }, [farmData?._id])

  const getTrendQuiz = async () => {
    if (
      TimeStore.currentDayJS <=
        dayjs(DealAutomationStore.firstTrendQuizData.timeRangeStart) ||
      TimeStore.currentDayJS >=
        dayjs(DealAutomationStore.firstTrendQuizData.timeRangeEnd)
    ) {
      return
    }

    const result = await backendApis.trendQuiz()

    if (!result?.data?.res || result?.data?.msg !== 'success') {
      setTrendQuizOn(false)
      return
    }

    setTrendQuizOn(true)
  }

  useEffect(() => {
    if ((lastAnnouncementNumber || 0) < announcementList?.[0]?.number) {
      setHasUnreadAnnouncement(true)
    }
    for (let i = 0; i < announcementList?.length; i++) {
      let doc = announcementList[i]

      if (doc?.type === 'reward') {
        if (doc?.number > (farmData?.lastRewardAnnouncementNumber || 0)) {
          ModalStore.setIsModalOpen('announce')
          setLastRewardAnnouncementDoc(doc)
        }

        break
      }
    }
  }, [announcementList])

  // 특정 모달을 강제로 띄우고 싶으신가영~?~?
  useEffect(() => {
    if (!farmData?.userId) return
    // ModalStore.setIsModalOpen('qrBonus')
  }, [farmData?.userId])

  useEffect(() => {
    if (singleDealInfo?.itemId) {
      setIsLoaded(false)
      singleDealFetchData()
      setIsLoaded(true)
    }
  }, [singleDealInfo?.itemId])

  useEffect(() => {
    getLevel(farmData?.exp, token)
    levelUpChanceInviteInit(farmData)
    lastHarvestPurchaseInit()
  }, [token, farmData?.exp])

  useEffect(() => {
    if (farmData?.questLogForGetWaterDailyAttendance) {
      setQuestLogForGetWaterDailyAttendance(true)
    }
  }, [farmData?.questLogForGetWaterDailyAttendance])

  useEffect(() => {
    if (farmData?.questLogForAttendanceFert) {
      setQuestLogForAttendanceFert(true)
    }
  }, [farmData?.questLogForAttendanceFert])

  useEffect(() => {
    if (
      (level === 2 && !farmData?.levelUpRewardTwoLog) ||
      (level === 3 && !farmData?.levelUpRewardThreeLog) ||
      (level === 4 && !farmData?.levelUpRewardFourLog) ||
      (level === 5 && !farmData?.levelUpRewardFiveLog) ||
      (level === 6 && !farmData?.levelUpRewardSixLog)
    ) {
      setTimeout(() => {
        setShowLevelUpRewardModal(true)
        ModalStore.setIsAutoAppearModal(true)
      }, 1200)
    }
  }, [level])

  useEffect(() => {
    getWaterButtonNotification(token)
  }, [token, farmData?.questLogForGetWaterDailyAttendance])
  useEffect(() => {
    if (giveWaterCount === 9 && !farmData?.tutorialClearedAt) {
      backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'tutorialClearedAt',
      })
      setFarmData((prev) => {
        return { ...prev, tutorialClearedAt: new Date() }
      })
    }

    if (giveWaterCount === 40 && farmData?.isNewUser && newUserEvent) {
      setTimeout(() => ModalStore.setIsModalOpen('sevenDays'), 1200)
    }

    if (giveWaterCount === 30) {
      setIsTreasureBoxMissionTargetUser(true)
      setTimeout(() => {
        ModalStore.setIsModalOpen('treasureBox')
      }, 1200)
    }

    // if (giveWaterCount === 10) {
    //   setDailyGetWaterUpgradeButton(true);
    // }
  }, [token, farmData?.exp])

  useEffect(() => {
    getFertButtonNotification(token)
  }, [token, farmData?.questLogForAttendanceFert])
  useEffect(() => {
    if (
      TimeStore.currentNewDate - new Date(farmData?.createdAt) <=
        1000 * 60 * 60 * 24 * 10 &&
      dayjs(farmData?.createdAt).format('YYYY-MM-DD') >= '2022-09-28'
    ) {
      setNewUserEvent(true)
    }
  }, [farmData?.createdAt])

  useEffect(() => {
    quizPurchaseButtonInit()
  }, [farmData?.solvedQuizHistory])

  useEffect(() => {
    yutnoriPurchaseButtonInit()
  }, [farmData?.lastYutnoriPlayedAt])

  useEffect(() => {
    adItemBrowsingButtonInit()
  }, [farmData?.adItemBrowsingFertilizer])

  //reward toast handler for store

  useEffect(() => {
    setGetResourceModal(ModalStore.rewardPopUpOn)
    setRewardTypeForRewardToast(ModalStore?.rewardPopUpType)
    setRewardAmountForRewardToast(ModalStore?.rewardPopUpAmount)
  }, [
    ModalStore.rewardPopUpOn,
    ModalStore.rewardPopUpType,
    ModalStore.rewardPopUpAmount,
  ])

  useEffect(() => {
    async function getInviteHistory() {
      let result = await backendApis.getInviteHistory(token)
      if (result?.status === 200) {
        let logsInfo = result?.data?.logsInfo?.reverse()
        for (let i = 0; i < logsInfo.length; i++) {
          let item = logsInfo[i]
          if (item.successModalOpened === false) {
            if (['puppy3days', 'changePlantInvite'].includes(item.type)) {
              if (item.type === 'changePlantInvite') {
                let isChangePlantAvailableResult =
                  await backendApis.getChangePlantData(token)

                if (!isChangePlantAvailableResult.isChangePlantAvailable) return
              }

              setInviteSuccessModal(true)
              setSuccessModalRewardType(item.type)
              let fixedLogsInfo = logsInfo.reverse()
              fixedLogsInfo.forEach((item) => {
                item.successModalOpened = true
              })
              await backendApis.setInviteHistorySuccessModalOpened(
                token,
                'PUT',
                {
                  docId: result?.data?.docId.toString(),
                  logsInfo: fixedLogsInfo,
                },
              )
              return
            }
          }
        }
      }
    }
    getInviteHistory()

    document.addEventListener('message', (message) => {
      if (message.data === 'addFert') {
        setFarmData((prev) => {
          return { ...prev, fertilizer: prev?.fertilizer + 1 }
        })
      }
    })
  }, [token])

  useEffect(() => {
    if (!farmData?.userId) return
    if (farmData?.jangbogiCoin >= 0) return
    if (farmData?.jangbogiCoin < 0) {
      const setCoinZero = async () => {
        await backendApis.updateSpecificField({
          fieldName: 'jangbogiCoin',
          value: Number(0),
        })
      }

      setCoinZero()
    }
  }, [farmData?.userId])

  useEffect(() => {
    const fetchAndUpdate = async () => {
      const friendGardenData = await backendApis.friendGardenData(token)
      const lastFriendGardenData = getPersonalizationLastDate(
        friendGardenData?.data,
        'createdAt',
      )
      setLastFriendGardenUse(lastFriendGardenData?.createdAt)

      const rouletteData = await backendApis.getRouletteEventHistory(token)
      const lastRouletteData = getPersonalizationLastDate(
        rouletteData?.data,
        'rouletteAt',
      )
      setLastRouletteUse(lastRouletteData?.rouletteAt)
    }
    fetchAndUpdate()
  }, [farmData])

  // finished pop up
  useEffect(() => {
    if (!farmData) return
    if (
      typeof farmData?.exp !== 'number' ||
      !farmData?.farmLevel ||
      typeof farmData?.farmLevel?.level6 !== 'number'
    ) {
      // console.error('서버 통신 오류')
      return
    }
    if (farmData?.exp >= farmData?.farmLevel?.level6) {
      if (!farmData?.finishedcompletedAt) {
        setHarvestModalVisible(true)
      } else {
        setFinishedModalVisible(true)
      }
    }
  }, [farmData?.exp, farmData?.finishedcompletedAt])

  useEffect(() => {
    if (
      !farmData?.userId ||
      !codePushVersionAvailable(userCodePushVersion, '6.8.29')
    ) {
      return
    }

    // 1/10 오전 9시 ~ 1/24 오전 9시로 제한
    if (!newYear2025EventEntryOn) {
      return
    }

    // 신규 유저일 경우 리턴
    if (farmData?.isNewUser && giveWaterCount < 100) {
      return
    }

    const now = new Date()
    const targetDate = new Date('2025-01-22T15:00:00.000Z')

    const storageKey = `newYearSpecialDealModal_${farmData.userId}`

    // 2025/1/23 00시 이전
    if (now < targetDate) {
      const hasSeenBefore = localStorage.getItem(storageKey)

      if (!hasSeenBefore) {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'dealEventNudge',
          eventType: 'pageview',
          eventName: 'modal',
          data: {},
          collection: 'UserAlfarmClickLog',
        })

        ModalStore.setIsModalOpen('newYearSpecialDealEntryModal')
        localStorage.setItem(storageKey, 'true')
      }
      return
    }
  }, [farmData?.userId])

  // // admob 보상형광고 pre-loading
  // useEffect(() => {
  //   const placements = [
  //     'ALFARM_DOUBLE_BASIC_WATER_MISSION_REWARD',
  //     'ALFARM_SPECIAL_WATER_BOMB_REWARD',
  //     'ALFARM_WATER_DAILY_MISSION_REWARD',
  //     'ALFARM_BADAHAM_V2_MISSION_REWARD',
  //     'ALFARM_YUTNORI_AD',
  //     'ALFARM_LEAVES_AD',
  //     'ALFARM_QUIZ_AD',
  //     'ALFARM_FORTUNE_COOKIE_AD',
  //     'ALFARM_TODAY_DEAL_AD',
  //     'ALFARM_RECOMMENDED_PRODUCT_AD',
  //     'ALFARM_SPECIAL_LOTTERY_DRAW_REWARD',
  //     'ALFARM_FERTILIZER_SET_AD_WATCH',
  //     // 'ALFARM_SCRATCH_LOTTO_BLUEBIRD',
  //     // 'ALFARM_MYSTERY_FLOWER_REWARD',
  //     // 'ALFARM_SCRATCH_LOTTO',
  //     // 'ALFARM_WINNER_DEAL_MISSION_REWARD',
  //     // 'ALFARM_FERTILIZER_SET_GET_AD_WATCH',
  //   ]

  //   placements.forEach((placement, index) => {
  //     if (UserStore?.adMobStatement?.get(placement)) return
  //     window?.ReactNativeWebView?.postMessage(
  //       JSON.stringify({
  //         type: 'prepareRewardedAd',
  //         data: {
  //           adType: 'rewarded',
  //           placement,
  //         },
  //       }),
  //     )
  //   })
  // }, [])

  useEffect(() => {
    UserStore.set('showPuppy', showPuppy)
    puppyEndAt()
  }, [
    token,
    showPuppy,
    ModalStore?.isModalOpen === 'luckydraw',
    showSevenDaysModal,
    ModalStore?.isModalOpen === 'comebackUser',
    checkShowPuppy,
  ])

  useEffect(() => {
    if (token) {
      updatelWater()
    }
  }, [token, farmData?.lastGetWaterAt])

  useEffect(() => {
    const checkInData = async () => {
      const result = await backendApis.comebackUserCheckInData(token)
      UserStore?.set('comebackUserCanCheckIn', result?.data?.success)
    }

    checkInData()
  }, [farmData])

  useEffect(() => {
    if (farmData?.itemType === 'mandarin') {
      setTimeout(() => {
        setOpenChangeMandarinModal(true)
      }, 1000 * 1)
    }
    if (farmData?.itemType === 'persimmon') {
      setTimeout(() => {
        setOpenChangePersimmonModal(true)
      }, 1000 * 1)
    }
  }, [farmData?.itemType])

  //처음 키자마자 시간 체크하고 -> 과거 광고 or 새로운 광고 가져옴
  useEffect(() => {
    if (
      codePushVersionAvailable(userCodePushVersion, '2.2.74') &&
      giveWaterCount >= 200
    ) {
      if (
        farmData?.waterFromSellerFarm &&
        TimeStore.currentDayJS -
          dayjs(
            farmData?.waterFromSellerFarm[
              farmData?.waterFromSellerFarm?.length - 1
            ]?.rewardedAt,
          ) <
          14400000
      ) {
        getStoreByAdIdList()
      } else {
        getSellerFarmListForTripleExposure()
      }
    }
  }, [farmData?.userId, userCodePushVersion])

  useEffect(() => {
    if (BottomSheetStore?.isBottomSheetOpen === 'basic') {
      setGiveWaterBlock(true)
      setTimeout(() => {
        setGiveWaterBlock(false)
      }, 1500)
    }
  }, [BottomSheetStore?.isBottomSheetOpen])

  useEffect(() => {
    if (
      inviteResultStatus === 'normal' ||
      inviteResultStatus === 'gganbuNormal'
    ) {
      return
    } else if (inviteResultStatus && !alertModal) {
      setEnteringRoute(inviteResultStatus)
      setAlertModal(true)
    } else {
      setAlertModal(false)
    }
  }, [inviteResultStatus])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef(null)

    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    useEffect(() => {
      const executeCallback = () => {
        savedCallback.current()
      }
      const timerId = setInterval(executeCallback, delay)
      return () => clearInterval(timerId)
    }, [])
  }

  useEffect(() => {
    setAddWater(UserStore?.addWater)
    setAddFertilizer(UserStore?.addFertilizer)
    setAddFertilizerSet(UserStore?.addFertilizerSet)
    setPuppyGiftModal(UserStore?.puppyGiftModal)
    setPuppyRewardType(UserStore?.puppyRewardType)
  }, [
    UserStore?.addWater,
    UserStore?.addFertilizer,
    UserStore?.addFertilizerSet,
    UserStore?.puppyGiftModal,
    UserStore?.puppyRewardType,
  ])

  // 리마인더용
  useEffect(() => {
    const isYutnoriPlayAvailable = async () => {
      const maxCount = AlfarmAdStore.yutnoriDailyMaxExposureCount
      const todayPlayedCount =
        farmData?.clearedYutnoriHistory?.filter(
          (x) => !timeChecker(x.clearedAt),
        )?.length || 0
      const timeInterval = AlfarmAdStore.yutnoriTimeIntervalBetweenExposure
      const lastPlayedAt = farmData?.lastYutnoriPlayedAt
      if (
        maxCount > todayPlayedCount &&
        new Date(new Date() - timeInterval * 1000 * 60 * 60) >
          new Date(lastPlayedAt)
      ) {
        setYutnoriPlayAvailable(true)
      } else {
        setYutnoriPlayAvailable(false)
      }
    }
    const isWinnerDealViewAvailable = async () => {
      const maxCount = AlfarmAdStore.winnerDealDailyMaxExposureCount
      const todayPlayedCount =
        farmData?.clearedWinnerDealHistory?.filter(
          (x) => !timeChecker(x.clearedAt),
        )?.length || 0
      const timeInterval = AlfarmAdStore.winnerDealTimeIntervalBetweenExposure
      const lastPlayedAt =
        farmData?.clearedWinnerDealHistory?.[
          farmData?.clearedWinnerDealHistory?.length - 1
        ]?.clearedAt
      if (
        maxCount > todayPlayedCount &&
        new Date(new Date() - timeInterval * 1000 * 60 * 60) >
          new Date(lastPlayedAt)
      ) {
        setWinnerDealViewAvailable(true)
      } else {
        setWinnerDealViewAvailable(false)
      }
    }
    const isQuizViewAvailable = async () => {
      const maxCount = AlfarmAdStore.quizDailyMaxExposureCount
      const todayPlayedCount =
        farmData?.solvedQuizHistory?.filter((x) => !timeChecker(x.solvedAt))
          ?.length || 0
      const timeInterval = AlfarmAdStore.quizTimeIntervalBetweenExposure
      const lastPlayedAt =
        farmData?.solvedQuizHistory?.[farmData?.solvedQuizHistory?.length - 1]
          ?.solvedAt
      if (
        maxCount > todayPlayedCount &&
        new Date(new Date() - timeInterval * 1000 * 60 * 60) >
          new Date(lastPlayedAt)
      ) {
        setQuizViewAvailable(true)
      } else {
        setQuizViewAvailable(false)
      }
    }
    isYutnoriPlayAvailable()
    isWinnerDealViewAvailable()
    isQuizViewAvailable()
  }, [
    AlfarmAdStore.isAlfarmHeavyUserForAd,
    farmData?.lastYutnoriPlayedAt,
    farmData?.clearedWinnerDealHistory,
    farmData?.solvedQuizHistory,
  ])

  useEffect(() => {
    if (UserStore?.triggerLoadUserCoupon) {
      loadUserCoupon()
      UserStore.set('triggerLoadUserCoupon', false)
    }
  }, [UserStore?.triggerLoadUserCoupon])

  // 매일도전 핑 찍기용

  useEffect(() => {
    if (!isLoading && !UserStore?.pingManager?.dailyMission) {
      let newDateTime = moment().add(-4, 'hours')
      let newDateFormatted = newDateTime.format('YYYY-MM-DD')
      let storedData = localStorage.getItem('alfarm_giveWaterCount')
      let count = 0

      const getDailyMissionInfo = async () => {
        const result = await backendApis.getDailyMissionInfov2()
        UserStore.set('dailyMissionPingInfo.dailyMissionInfo', result?.data)
      }

      const getTimeDealQuestCount = (data, standardHour) => {
        let logInLogsByTime
        if (!data?.questLogFroTimeDealCheckedAt) {
          logInLogsByTime = []
        } else {
          logInLogsByTime = data?.questLogFroTimeDealCheckedAt?.filter((x) => {
            let availableTime
            const currentTime = dayjs().startOf('h')
            const currentHour = Number(currentTime.format('HH'))

            if (currentHour < standardHour) {
              availableTime = dayjs()
                .startOf('d')
                .subtract(1, 'd')
                .add(standardHour, 'h')
            } else {
              availableTime = dayjs().startOf('d').add(standardHour, 'h')
            }
            let isAvailable = false
            if (dayjs(x)?.diff(availableTime, 'ms') > 0) {
              isAvailable = true
            }
            return isAvailable
          })
        }
        const logInLogsByTimeSet = new Set(logInLogsByTime).size
        UserStore.set(
          'dailyMissionPingInfo.timeDealQuestCount',
          logInLogsByTimeSet,
        )
      }

      const getFertilizerBottomSheetInfo = async () => {
        const result = await backendApis.getFertilizerBottomSheetInfo(
          token,
          'GET',
          {
            isFrom: 'dailyMission',
          },
        )
        UserStore.set(
          'dailyMissionPingInfo.fertilizerBottomSheetInfo',
          result?.fertilizerBottomSheetInfoChecker,
        )

        // setFertilizerBottomSheetInfo(result?.fertilizerBottomSheetInfoChecker)
      }

      const getWaterBottomSheetInfo = async () => {
        const result = await backendApis.getWaterBottomSheetInfo(token, 'GET', {
          isFrom: 'dailyMission',
        })
        UserStore.set(
          'dailyMissionPingInfo.solvedQuizCount',
          result?.waterBottomSheetInfoTimeChecker?.solvedQuizCount?.length,
        )
        UserStore.set(
          'dailyMissionPingInfo.isScratchLottoPlayedToday',
          !result?.data?.minigameAvailabilityInfo?.scratchLottoPlayAvailable,
        )
        UserStore.set(
          'dailyMissionPingInfo.timeDealQuestCount',
          result?.waterBottomSheetInfoTimeChecker?.standardHour,
        )

        getTimeDealQuestCount(
          result,
          result?.waterBottomSheetInfoTimeChecker?.standardHour,
        )

        let logInLogsByTimeForLastTimeDealChecked
        if (!result?.questLogFroTimeDealCheckedAt) {
          logInLogsByTimeForLastTimeDealChecked = dayjs(
            '1970-01-01T06:01:39.853+00:00',
          )
        } else {
          logInLogsByTimeForLastTimeDealChecked = dayjs(
            result?.questLogFroTimeDealCheckedAt?.[
              result?.questLogFroTimeDealCheckedAt?.length - 1
            ],
          )
        }
        UserStore.set(
          'dailyMissionPingInfo.lastTimeDealChecked',
          logInLogsByTimeForLastTimeDealChecked,
        )
      }

      const getMiniGameInfo = async () => {
        let result = await backendApis.getMinigameInfo(token)
        let tempDrawCount = await backendApis.getDrawCount(token)

        UserStore.set(
          'dailyMissionPingInfo.isWaterBombPlayedToday',
          !result?.data?.minigameAvailabilityInfo?.waterBombPlayAvailable,
        )
        UserStore.set(
          'dailyMissionPingInfo.isLotteryDrawPlayedToday',
          !result?.data?.minigameAvailabilityInfo?.lotteryDrawPlayAvailable,
        )
        UserStore.set(
          'dailyMissionPingInfo.isYutnoriPlayedToday',
          !result?.data?.minigameAvailabilityInfo
            ?.yutnoriCompleteRewardAvailable,
        )

        if (tempDrawCount?.drawCount >= 3) {
          UserStore.set('dailyMissionPingInfo.isLuckyDrawPlayedToday', true)
          // setIsLuckyDrawPlayedToday(true)
        }
      }

      Promise.all([
        getDailyMissionInfo(),
        getFertilizerBottomSheetInfo(),
        getWaterBottomSheetInfo(),
        getMiniGameInfo(),
      ])
        .then(() => {
          if (storedData) {
            let parsedData = JSON.parse(storedData)

            if (parsedData.date === newDateFormatted) {
              count = parsedData.count
            }
          }

          let trueCount = 0

          if (UserStore?.dailyMissionPingInfo?.isWaterBombPlayedToday) {
            trueCount++
          }

          if (UserStore?.dailyMissionPingInfo?.isLotteryDrawPlayedToday) {
            trueCount++
          }

          if (UserStore?.dailyMissionPingInfo?.isLuckyDrawPlayedToday) {
            trueCount++
          }
          if (UserStore?.dailyMissionPingInfo?.isYutnoriPlayedToday) {
            trueCount++
          }

          let pingOn = false

          if (
            !UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'checkin'
            ] ||
            (!UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'giveWater'
            ] &&
              count >= 20) ||
            (!UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'browsing60'
            ] &&
              !UserStore?.dailyMissionPingInfo?.fertilizerBottomSheetInfo
                ?.recommendQuestAvailable) ||
            (!UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'timeDealBrowsing'
            ] &&
              UserStore?.dailyMissionPingInfo?.timeDealQuestCount >= 2) ||
            (!UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'arcade'
            ] &&
              trueCount >= 3) ||
            (!UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'quiz'
            ] &&
              UserStore?.farmData?.inviteList?.length >= 1) ||
            (!UserStore?.dailyMissionPingInfo?.dailyMissionInfo?.missionStatus[
              'purchase'
            ] &&
              purchaseCountWithinPeriod4Hour() >= 1)
          ) {
            pingOn = true
          }
          UserStore.set('pingManager.dailyMission', pingOn)

          // 모든 함수가 완료되었을 때 여기에서 로딩 상태를 해제하거나 설정할 수 있습니다.
          // 예를 들어, 컴포넌트의 상태에 로딩 상태를 설정하려면:
        })
        .catch((error) => {
          // 함수 실행 중에 발생할 수 있는 오류를 처리합니다.
          console.error('오류:', error)
        })
    }
  }, [isLoading, UserStore?.pingManager?.dailyMission])

  // 매일도전 핑 찍어주기용

  // 팜린이 보물상자 핑 찍어주기용

  useEffect(() => {
    if (!isLoading && isTreasureBoxMissionTargetUser) {
      const fetchData = async () => {
        setIsLoaded(false)
        const result = await backendApis.checkTreasureBoxStatus(token)
        if (result.status === 200 && result.data) {
          let pingOn = false
          if (
            (result?.data?.giveWater?.count >= 10 &&
              !result?.data?.giveWater?.opened) ||
            (result?.data?.giveFertilizer?.count >= 5 &&
              !result?.data?.giveFertilizer?.opened)
          ) {
            pingOn = true
          }
          UserStore.set('pingManager.treasureBox', pingOn)
        }
      }
      fetchData()
    }
  }, [
    isLoading,
    isTreasureBoxMissionTargetUser,
    farmData?.water,
    farmData?.fertilizer,
  ])

  useInterval(() => {
    if (ongoingWaterBattleBanner && !battleData?.canGetReward) {
      autoOpponentGiveWater()
      setTimeout(() => {
        waterOneOneBattleData()
      }, 500)
    }
  }, 1000 * 15)

  const timeDealEntryData = async (data) => {
    if (
      data &&
      TimeStore.currentDayJS - dayjs(data[data?.length - 1]) < 1000 * 60 * 60
    ) {
      setTimeDealEntryIcon(true)
    }
  }

  const shouldShowBluebird =
    (UserStore.rewardAdWatchCount < 10 &&
      AB(UserStore?.farmData?.userId, 'bluebird0115', [97]) === 'b' &&
      (!localStorage.getItem('bluebirdFlyAway') ||
        new Date() - new Date(localStorage.getItem('bluebirdFlyAway')) >
          1000 * 60 * 60 * 48) &&
      (!localStorage.getItem('bluebirdModalOpen') ||
        new Date() - new Date(localStorage.getItem('bluebirdModalOpen')) <
          1000 * 60 * 60 * 6 ||
        new Date() - new Date(localStorage.getItem('bluebirdModalOpen')) >
          1000 * 60 * 60 * 48)) ||
    [
      '6565aa51ed116a90f986d306',
      '620cce8196d049583db642e3',
      '6246b1c63433802aafeb591f',
      '64e7cad226232f188ba057d4',
      '63bfb30b14a835810aacffb3',
    ].includes(UserStore?.farmData?.userId)

  const dealExceptUser = [
    '63a1904735e6a5266ed4b6ce',
    '62593469cfa30e59362e4df8',
    '656af925ad362554efe8ae9f',
    '6617a235e4ed206045de96ac',
    '6487ea055765cfa094e55941',
    '639e5cf22313b30b63be1fdf',
    '65338bda33887d46b1934d9c',
    '6487ea055765cfa094e55941',
    '641049e72d99ccdcc1b22803',
    '647e73c4bd3515b37e7880f8',
    '6559c283a5db7a20d4a5fd7c',
    '654cbb636c0d65f9a7ce233d',
    '64257558892b1efa30c82f3f',
    '641bf585240b9d982fd5dca4',
    '63b8c3ebc032d697345abf1a',
    '657452f0213c214dfaa173d0',

    '652640740050759b8935184f',
    '6499039b0221e2347c44ee30',
    '63fe8c2588dc020d3350c239',
    '64963e651848d86e4f9fbf6e',

    '6498093c7682ea9a23be8633',
    '665ae7bb2d3b2b90fdee9326',
    '64910954349b811732137851',
    '65c7293314f94cfaafe3e787',
    '62823babb4f34685747bf176',
    '65616ebb122a39a9ce4bf45f',
    '61c5b544d452f638c2eada9f',
    '6690b28b4bbdc1f8b362be38',
    '654f4d203e494bf8acf3a2d7',
    '63eb1093a585570649f58bca',
    '6434acd4d7604125a2b67d28',

    '6506a12fe814bdb82bf72615',
    '61fe58fe0ee00e6d2257ac7d',

    '63cc93ededc257214e0296b7',
    '6472097bf1d56f3fb4f1c4ff',
    '6555a28b3bc8062e3bd09380',

    '65b091f1acad01177890166e',

    '6433d48ea04a583e9f18f88f',
    '63f622f46faa112bbf51c190',
    '63eb1093a585570649f58bca',

    '660208667dc1e08d37916ac7',
    '64017ce32b20936965626705',
    '646dbd50c75d54e65a800626',
    '650d8eee3dea82c27de7addc',
    '652e2c7c76b840dfcd95bdb5',
    '646793f789ab45a5bfae94a7',
    '65666b63611020c2bbf01c05',
    '626bf4653a93ae0cbab54c3f',
    '665ae7bb2d3b2b90fdee9326',
    '6439fe7a0247f3e9ff8adc54',
    '649fe4d14a56bbdf7cd67658',
    '642d25dd66cc8ec2012e68fc',
    '6688a2cda5479da58827b97f',
    '627d10bfd430af8ffc940c7a',
    '647868ca1ce3029597f54009',
    '654f4d203e494bf8acf3a2d7',
    '6434acd4d7604125a2b67d28',

    '651921f0b2f5f413d8a721a8',
    '64d47f4f48035e9d6a2afd6b',
    '64a2d59c136f58f6ff24a8eb',
    '64fbdbbfa9622e52961ddcbd',
    '657fd6cb9137c1f7ccca9df9',
    '654b9e6ffeff2096f688ee05',

    '658a4c290ec1386a77898859',
    '6563e5308a0e5fb78de88e2b',
    '6502816fc1789c53391c2fa6',
    '63e872ef02e571066d365762',
    '645cf122df05a1d65ab47156',
    '6546256dfc0dfb9659e23e1e',
  ]

  const getWelcomeQuestCheckInData = async () => {
    const result = await backendApis.checkInData(token)
    setCanCheckInForWelcomeNewbie(result?.data?.success)
  }

  const loadUserCoupon = () => {
    window?.ReactNativeWebView?.postMessage('loadUserCoupon')
    fetchUserCoupons()
  }

  const singleDealFetchData = async () => {
    const result = await backendApis.checkUserEventParticipated(token, 'GET', {
      itemId: singleDealInfo?.itemId || '',
    })
    if (result?.status === 200 && result?.data) {
      setSingleDealParticipated(true)
    } else {
      if (
        singleDealInfo?.modalOpenForced &&
        (singleDealInfo?.toggleOn ||
          (singleDealInfo?.betaToggleOn &&
            [
              '62271be8b3ef552fb2dd424d',
              '6171614346fa9f36878921c1',
              '60fa478ad28d5e1c65152c6f',
              '61a5a1313a1787613ebc7f2f',
              '60f7444cd28d5e1c65152c21',
            ].includes(farmData?.userId)))
      ) {
        singleDealModalOpenFetchData()
      }
      setSingleDealParticipated(false)
    }
  }

  async function setUserAnnouncementNumber() {
    await backendApis.setUserAnnouncementNumber(token, 'PUT', {
      number: announcementList?.[0]?.number,
    })
    setHasUnreadAnnouncement(false)
    setLastAnnouncementNumber(farmData?.lastAnnouncementNumber)
  }
  async function setLastRewardAnnouncementNumber(number) {
    await backendApis.setLastRewardAnnouncementNumber(token, 'PUT', {
      number,
    })
    setHasUnreadAnnouncement(false)
  }

  const singleDealModalOpenFetchData = async () => {
    const result = await backendApis.checkUserSingleDealModalOpened(
      token,
      'GET',
      {
        itemId: singleDealInfo?.itemId || '',
      },
    )
    if (result.status === 200 && !singleDealParticipated) {
      await backendApis.setAlfarmSingleDealModalOpenedLog(token, 'POST', {
        itemId: singleDealInfo?.itemId,
      })
      ModalStore.setIsModalOpen('alfarmDeal')
    }
  }

  const getAlfarmData = async ({ token, farmData }) => {
    if (UserStore.isWatchingAd === true) {
      /**
       * 유저가 보상형 광고를 전부 봤을 때 서드파티에서 훅을 호출하는데,
       * 이 훅이 backend에 도달하는 시간과 네이티브에서 웹뷰로 도달하는 시간 간의 차이가 있어서
       * 올팜에서 보상을 받지 못한다는 인입이 들어왔습니다.
       * 따라서 일정 시간 동안 타이머를 걸고, 그 시간이 지나면 서드파티에서 backend에 훅을 호출하였다고
       * 휴리스틱하게 가정합니다.
       */
      await sleep(1650)
    }
    if (token && (!farmData?.userId || UserStore.isWatchingAd === true)) {
      let data = await backendApis.startAlfarm({
        itemType: itemType,
        shareType: shareType,
        alwayzInvitorId: alwayzInvitorId,
        myItemName: myItemName || '',
        phoneNumber: phoneNumber,
        indexNumber: indexNumber,
      })

      UserStore?.setPurchaseHistorys(data?.purchaseHistorys)
      UserStore.setIsWatchingAd(false)

      if (!data?.sameInvitation) {
        if (data?.inviteFrom === 'AlfarmSurpriseMission') {
          if (data?.surpriseMissionInfo) {
            setTimeout(() => {
              if (ModalStore.isModalOpen !== 'basic') {
                ModalStore.pushQueue('surpriseMission')
              } else {
                ModalStore.setIsModalOpen('surpriseMission')
              }
            }, 1000)
          } else if (data?.status === 299) {
            setInviteResultStatus('noInvitorData')
          }
        }
        if (data?.initModalList) {
          InitModalStore?.setIsInitModal(data?.initModalList)
        }

        if (data?.inviteFrom === 'AlfarmGuessMe') {
          ModalStore.setIsModalOpen('guessMe')
        }
        if (data?.inviteFrom === 'AlfarmDoubleExpEvent') {
          if (data?.status === 200) {
            setInviteResultStatus('doubleExpSuccess')
          } else if (data?.status === 250) {
            setInviteResultStatus('friendGardenNotNewUser')
          }
        } else if (data?.inviteFrom === 'AlfarmTeamAlfarmInvite') {
          if (data?.status === 2300) {
            setInviteResultStatus('teamAlfarmSuccess')
            setAlertModalOnClick(() => {
              navigate(`../teamAlfarm?token=${token}&itemType=${itemType}`)
            })
          } else if (data?.status === 2201) {
            setInviteResultStatus('teamAlfarmAlreadyTeam')
          } else if (data?.status === 2202) {
            setInviteResultStatus('teamAlfarmTeamFull')
          }
        } else if (
          data?.inviteFrom === 'AlfarmChangePlantForChurn' &&
          !data?.changedPlantBeforeChurnAt
        ) {
          ModalStore.setIsModalOpen('changePlantBeforeChurn')
        } else if (data?.inviteFrom === 'AlfarmDailyLotteryInvite') {
          if (data?.status === 2001) {
            setInviteResultStatus('dailyLotterySelfInvite')
          }
          if (data?.status === 2002) {
            setInviteResultStatus('dailyLotteryEarlyInvite')
          }
          if (data?.status === 2003) {
            setInviteResultStatus('dailyLotteryInvitorAlreadyTeamed')
          } else if (data?.status === 2000) {
            ModalStore.setIsModalOpen('dailyLottery')
            setCommonRemovedDependencyInviteInfo({
              shareType: 'AlfarmDailyLotteryInvite',
            })
          }
        } else if (data?.inviteFrom === 'todaysQuotes') {
          setQuotesIndex(data?.indexNumber)
          ModalStore.setIsModalOpen('invitedQuotes')
        } else if (data?.inviteFrom === 'AlfarmQRBonus') {
          ModalStore.setIsModalOpen('qrBonusReward')
        } else {
          if (data?.status === 200) {
            setInviteResultStatus('normal')
          } else if (data?.status === 202) {
            setInviteResultStatus('inviteFull')
          } else if (data?.status === 203) {
            setInviteResultStatus('invitorFull')
          } else if (data?.status === 204) {
            setInviteResultStatus('selfInvite')
          } else if (data?.status === 205) {
            setInviteResultStatus('alreadyInvited')
          } else if (data?.status === 206) {
            setInviteResultStatus('alreadyInvite')
          } else if (data?.status === 207) {
            setInviteResultStatus('noUserData')
          } else if (data?.status === 208) {
            setInviteResultStatus('noInvitorData')
          } else if (data?.status === 201) {
            setInviteResultStatus('success')
          } else if (data?.status === 230) {
            setInviteResultStatus('successLuckyPiggy')
          } else if (data?.status === 231) {
            setInviteResultStatus('inviteFullLuckyPiggy')
          } else if (data?.status === 232) {
            setInviteResultStatus('selfInviteLuckyPiggy')
          } else if (data?.status === 233) {
            setInviteResultStatus('alreadyInviteLuckyPiggy')
          } else if (data?.status === 234) {
            setInviteResultStatus('noDataLuckyPiggy')
          } else if (data?.status === 235) {
            setInviteResultStatus('noDataLuckyPiggy')
          } else if (data?.status === 209) {
            setInviteResultStatus('leftUser')
          } else if (data?.status === 299) {
            setInviteResultStatus('leftUser2')
          } else if (data?.status === 260) {
            setInviteResultStatus('mustStartWith010')
          } else if (data?.status === 261) {
            setInviteResultStatus('notRealUser')
          } else if (data?.status === 271) {
            setInviteResultStatus('gganbuInviteFull')
          } else if (data?.status === 272) {
            setInviteResultStatus('gganbuInvitorFull')
          } else if (data?.status === 210) {
            let tempFarmData = await backendApis.getSpecificOngoingAlfarmInfo({
              fieldsProjected: JSON.stringify({
                gganbuList: 1,
              }),
            })
            setFarmData((prev) => {
              Object.assign(prev, { gganbuList: tempFarmData?.data })
              return prev
            })
            setGganbuData(tempFarmData?.data)
            setInviteResultStatus('gganbuSuccess')
          } else if (data?.status === 211) {
            setInviteResultStatus('basicModal')
          } else if (data?.status === 212) {
            setInviteResultStatus('drinkWaterModal')
          } else if (data?.status === 240) {
            setInviteResultStatus('alreadyResurrected')
          } else if (data?.status === 242) {
            setInviteResultStatus('successResurrect')
          } else if (data?.status === 241) {
            setInviteResultStatus('gganbuOtherId')
          } else if (data?.status === 250) {
            if (data?.msg === 'alreadyParticipated') {
              setInviteResultStatus('gganbuAlreadyInvited')
            } else if (data?.msg === 'slotFull') {
              setInviteResultStatus('friendGardenFull')
            } else if (data?.msg === 'notNewUser') {
              setInviteResultStatus('friendGardenNotNewUser')
            } else {
              setInviteResultStatus('errorAlert')
            }
          } else if (data?.status === 2200) {
            setInviteResultStatus('churnedGganbuBountySuccess')
          } else if (data?.status === 2201) {
            setInviteResultStatus('churnedGganbuBountyFail')
          } else if (data?.status === 2202) {
            setInviteResultStatus('churnedGganbuBountyFail2')
          } else if (data?.status === 2203) {
            setInviteResultStatus('churnedGganbuBountyFail3')
          } else {
            setInviteResultStatus('etc')
          }
        }
      }

      if (data?.userId) {
        getGganbuList()

        ABStore.initialize({ userId: data?.userId, data })
        setFarmData(data)
        setItemType(data?.itemType)

        UserStore.set(
          'purchaseRewardNotification',
          data?.purchaseRewardNotificationLog,
        )
        harvestInviteInit(data)

        luckydrawPurchaseReward()
        setGiveWaterCount(data?.giveWaterCount)
        UserStore.setGiveWaterCount(data?.giveWaterCount)
        setInviteFriendLength(data?.inviteList?.length)
        setMaxDailyInviteCount(data?.dailyInviteInfo?.maxInviteCount)
        getLastClearedInviteQuestTime(data)
        setTodayCheckInDone(data?.initTimeCheckerResult?.todayCheckInDone)
        setCanGetDailyAttendanceWaterNotification(
          !data?.initTimeCheckerResult?.todayCheckInDone,
        )
        setCanGetSevendaysEventNotification(
          !data?.initTimeCheckerResult?.sevendaysEventDone,
        )

        setIsFirstConversion(data?.isFirstConvUser)

        waterOneOneBattleData()
        autoOpponentGiveWaterEnter()
        getDailyGiveWaterLevel()

        // ---

        fetchAlfarmCommonData()
        fetchAlfarmSingleDealData()
        setCanClickGetWater(true)
        setCanClickGiveWater(true)
        setCanClickSellerFarm(true)
        dailyLotteryInit(data)
        setUserId(data?.userId)
        setIsNewUser(data?.isNewUser)
        newbiePurchaseWaterRewardTime(data)
        secretScratchTime(data?.secretScratchHistory?.reverse())
        produceFertMachineTime(data)
        lastMinuteInviteInit(data)
        timeDealEntryData(data?.questLogFroTimeDealCheckedAt)
        kidsRevenueDealTime(data?.kidsRevenueDealAppearedAt)
        winterRevenueDealTime(data?.winterRevenueDealAppearedAt)
        snackRevenueDealTime(data?.snackRevenueDealAppearedAt)
        daisoRevenueDealTime(data?.daisoRevenueDealAppearedAt)
        healthRevenueDealTime(data?.healthRevenueDealAppearedAt)

        summerRevenueDealTime(data?.summerRevenueDealAppearedAt)
        goldAccesorryRevenueDealTime(data?.goldAccesorryRevenueDealAppearedAt)

        levelUpChanceInviteInit(data)
        fetchAnnouncementData(data)
        fetchAltoonMissionData(data)
        fetchDramaMissionData(data)

        fetchUserCoupons()
        fetchSellerCoupons()
        // 작물변경 이벤트 아이콘
        setChangePlantBeforeChurnIconOn(
          dayjs(data?.changePlantBeforeChurnOpenedAt)
            .add(24, 'h')
            .isAfter(dayjs()),
        )

        // 미션 사냥꾼 첫 팝업 및 아이콘
        triggerMissionHunter(data)
        checkinPurchaseInfo()
        // 릴레이 보상 아이콘
        setRelayRewardInfo(data?.relayRewardInfo)
        // 개인화 매대 아이콘
        setPersonalizedShopData(data?.personalizedShopInfo)
        // 구매 룰렛 아이콘
        setPurchaseRouletteData(data?.purchaseRouletteInfo)

        // 골라사기 보상 아이콘
        setSelectOnePurchaseData(data?.selectOnePurchaseInfo)

        // 올브랜드딜 보상 아이콘
        setAllBrandDealPurchaseData(data?.allBrandDealInfo)
        // 보물상자 보상 아이콘

        // fertilizerRouletteTimerButton(data);
        fertilizerTowerGameTimerButton(data)

        // 피버타임 버튼
        feverTimeButton(data)

        // 구매 룰렛 버튼
        purchaseRouletteButton(data)
        //개인화 보상 매대 버튼
        personalizedShopButton(data)
        //골라사기 매대 버튼
        selectOnePurchaseButon(data)
        //올브랜드 매대 버튼
        allBrandDealPurchaseButton(data)
        // 미니게임 뽑기 매대 버튼
        lotteryDrawSpecialPurchaseButton(data)

        // 작물 양 줄이고 빨리 받기 팝업
        triggerShowChangePlantAmountModal(data)
        if (
          new Date(data?.relayRewardInfo?.endAt || '2022-01-01') >
          TimeStore.currentNewDate
        ) {
          setRelayRewardIconOn(true)
        } else {
          setRelayRewardIconOn(false)
        }
        setLastAnnouncementNumber(data?.lastAnnouncementNumber || 0)
        if (
          !timeChecker(data?.lastWaterBrowsingAdQuestAt) &&
          TimeStore.currentDayJS?.diff(
            dayjs(data?.lastWaterBrowsingAdQuestAt).add(3, 'h'),
            's',
          ) < 0
        ) {
          setWaterBrowsingAdQuestOn(true)
        }
        //내일 획득 팝업
        if (
          // data?.initTimeCheckerResult?.tomorrowWaterReceivePopUpAvailable &&
          data?.dailyGiveWater > 1 &&
          data?.lastGetDailyGiveWaterAt &&
          data?.initTimeCheckerResult?.yesterdayGiveWaterReceivePopUpAvailable
        ) {
          setCanGetDailyGiveWater(true)
          const result = await backendApis.getDailyGiveWater(token)
          setYesterdayGiveWater(
            result?.data?.dailyGiveWater - result?.data?.removedWater,
          )
          setRemovedWater(result?.data?.removedWater)
        }

        if (
          data?.initTimeCheckerResult?.tomorrowWaterReceivePopUpAvailable &&
          data?.dailyGiveWater > 1 &&
          !data?.lastGetDailyGiveWaterAt
        ) {
          setCanGetDailyGiveWater(true)
          const result = await backendApis.getDailyGiveWater(token)
          setYesterdayGiveWater(
            result?.data?.dailyGiveWater - result?.data?.removedWater,
          )
          setRemovedWater(result?.data?.removedWater)
          ModalStore.setIsModalOpen('yesterdayGiveWaterModal')
        }

        if (data?.timeCapsuleRewardAutoReceivable) {
          backendApis.updateSpecificField({
            fieldName: 'timeCapsuleRewardAvailable',
            value: false,
          })
          backendApis.giveReward(token, 'PUT', {
            loggingRewardName: 'timeCapsuleBonus',
            rewardType: 'water',
            valueAmount: 100,
          })
          setFarmData((prev) => {
            if (UserStore.waterExceeded) {
              prev.water += Math.round(100 * 0.5)
            } else {
              prev.water += 100
            }
            prev.timeCapsuleRewardAvailable = false
            return prev
          })
          setTimeCapsuleReceived(true)
          ModalStore.setIsModalOpen('timeCapsuleBonus')
        }

        // 올웨이즈 체험 농장 체크
        if (data?.bonusRewardBoxFromAlwayzFarm) {
          setClearedAlwayzFarm(true)
        }

        if (
          data?.unCheckedOfferwallHistory &&
          data.unCheckedOfferwallHistory.length > 0
        ) {
          ModalStore.setIsModalOpen('offerwallMissionComplete')
        }
        // 튜토리얼 로그 없으면 아이템 페이지로 이동 시키기
        if (!data?.itemType) {
          Log.send({
            action: 'selectFarm',
            user_id: data?.userId,
          })
          window.location.href = `/selectFarm?token=${token}`
        } else {
          Log.send({
            action: 'launch',
            user_id: data?.userId,
          })
        }
        isLoadingRef.current = setTimeout(() => {
          setIsLoading(false)
        }, 500)
      } else {
        setInviteResultStatus('errorAlert')
      }
      if (data?.surpriseMissionCandidate) {
        let surpriseMissionOpenedAt = window.localStorage.getItem(
          'surpriseMissionOpenedAt',
        )
        surpriseMissionOpenedAt = JSON.parse(surpriseMissionOpenedAt)

        if (
          dayjs(surpriseMissionOpenedAt).format('YYYY-MM-DD') !==
          TimeStore.currentDayJS?.format('YYYY-MM-DD')
        ) {
          surpriseMissionOpenedAt = TimeStore.currentDayJS?.toDate()
          window.localStorage.setItem(
            'surpriseMissionOpenedAt',
            JSON.stringify(TimeStore.currentDayJS?.toDate()),
          )

          setTimeout(() => {
            if (ModalStore.isModalOpen !== 'basic') {
              ModalStore.pushQueue('surpriseMission')
            } else {
              ModalStore.setIsModalOpen('surpriseMission')
            }
          }, 1000)
        }

        if (
          dayjs(surpriseMissionOpenedAt)
            .add(30, 'm')
            .isAfter(TimeStore.currentDayJS)
        ) {
          setSurpriseMissionEntryIconOn(true)
        }
      }

      // 작물 효능 모달
      const level = getLevelOnly(data?.farmLevel, data?.exp)
      const efficacyAB = AB(data?.userId, 'farmEfficacy241016', [90])
      const testItems = ['mool', 'tomato', 'sweetpotato', 'coffee_mega', 'egg']
      const efficacyModalAvailable =
        data?.initTimeCheckerResult?.efficacyModalAvailable &&
        level >= 4 &&
        efficacyAB === 'b' &&
        testItems.includes(data?.itemType)

      if (efficacyModalAvailable) {
        ModalStore.setIsModalOpen('efficacyModal')
      }

      // RN 으로 푸시 동의 여부 체크
      window?.ReactNativeWebView?.postMessage('getUserDataFromAlwayzApp')

      const setUserDataFromAlwayzApp = ({ data }) => {
        try {
          const result = JSON.parse(data)
          if (result?.userData) {
            const userData = result?.userData
            AlwayzUserStore.setAlwayzUserData(userData)

            if (userData?.ifa) UserStore.set('offerwallInfo.ifa', userData?.ifa)
            if (userData?.ifv) UserStore.set('offerwallInfo.ifv', userData?.ifv)
            if (userData?.platform)
              UserStore.set(
                'offerwallInfo.platform',
                userData?.platform === 'ios' ? 'I' : 'A',
              )
            if (userData?.uniqueUserId)
              UserStore.set(
                'offerwallInfo.uniqueUserId',
                userData?.uniqueUserId,
              )

            if (result?.userData?.codePushVersion) {
              UserStore?.setCodePushVersionFromAlwayzApp(
                result?.userData?.codePushVersion,
              )
              setUserCodePushVersion(result?.userData?.codePushVersion)
            }
          }
        } catch (error) {
          // console.log(error);
        }
      }

      if (isIOS || isIOS13) {
        window.addEventListener('message', setUserDataFromAlwayzApp)
        return () => {
          window.removeEventListener('message', setUserDataFromAlwayzApp)
        }
      } else {
        document.addEventListener('message', setUserDataFromAlwayzApp)
        return () => {
          document.removeEventListener('message', setUserDataFromAlwayzApp)
        }
      }
    }
  }
  const [altoonWaterMissionData, setAltoonWaterMissionData] = useState()
  const [altoonFertMissionData, setAltoonFertMissionData] = useState()
  const [altoonFertMissionList, setAltoonFertMissionList] = useState()
  const fetchAltoonMissionData = async (data) => {
    const waterMission = await backendApis.altoonWaterMissionData(token)
    const fertMission = await backendApis.altoonFertMissionData(token)

    if (waterMission?.status === 200 && fertMission?.status === 200) {
      setAltoonWaterMissionData(waterMission?.data)
      setAltoonFertMissionData(fertMission?.data?.result)
      setAltoonFertMissionList(fertMission?.data?.type)
    }
  }

  const [dramaWaterMissionData, setDramaWaterMissionData] = useState()
  const [dramaWaterMissionList, setDramaWaterMissionList] = useState()
  const [dramaFertMissionData, setDramaFertMissionData] = useState()
  const [dramaFertMissionList, setDramaFertMissionList] = useState()
  const fetchDramaMissionData = async (data) => {
    const dramaWaterMission = await backendApis.dramaWaterMissionData(
      token,
      'GET',
    )
    const dramaFertMission = await backendApis.dramaFertMissionData(
      token,
      'GET',
    )
    if (dramaWaterMission?.status === 200) {
      setDramaWaterMissionData(dramaWaterMission?.data?.log)
      setDramaWaterMissionList(dramaWaterMission?.data?.info)
    }

    if (dramaFertMission?.status === 200) {
      setDramaFertMissionData(dramaFertMission?.data?.log)
      setDramaFertMissionList(dramaFertMission?.data?.info)
    }
  }

  const fertilizerTowerGameTimerButton = (data) => {
    if (
      data?.fertilizerTowerGameData?.fertilizerTowerGameOnAt &&
      dayjs(
        data?.fertilizerTowerGameData?.fertilizerTowerGameOnAt ?? '2022-01-01',
      ).diff(
        dayjs(
          data?.fertilizerTowerGameData?.fertilizerTowerGamePurchasedAt ??
            '2022-01-01',
        ),
        'ms',
      ) > 0 &&
      dayjs(
        data?.fertilizerTowerGameData?.fertilizerTowerGameOnAt ?? '2022-01-01',
      )
        .add(3, 'h')
        .diff(dayjs(), 'ms') > 0
    ) {
      setFertilizerTowerGameOn(true)
    } else {
      setFertilizerTowerGameOn(false)
    }
  }

  const feverTimeButton = (data) => {
    if (
      data?.feverModeGameData?.feverModeGameOnAt &&
      dayjs(data?.feverModeGameData?.feverModeGameOnAt ?? '2022-01-01')
        .add(1, 'h')
        .diff(dayjs(), 'ms') > 0
    ) {
      setFeverModeGameOn(true)
    } else {
      setFeverModeGameOn(false)
    }
  }

  const purchaseRouletteButton = (data) => {
    if (
      data?.purchaseRouletteInfo?.createdAt &&
      dayjs(data?.purchaseRouletteInfo?.createdAt ?? '2022-01-01')
        .add(3, 'h')
        .diff(dayjs(), 'ms') > 0 &&
      !data?.purchaseRouletteInfo?.runAt &&
      codePushVersionAvailable(userCodePushVersion, '2.3.26')
    ) {
      setPurchaseRouletteButtonOn(true)
    } else if (
      data?.purchaseRouletteInfo?.purchased &&
      data?.purchaseRouletteInfo?.run === false
    ) {
      setPurchaseRouletteButtonOn(true)
    } else {
      setPurchaseRouletteButtonOn(false)
    }
  }

  const personalizedShopButton = (data) => {
    if (
      data?.personalizedShopInfo?.createdAt &&
      dayjs(data?.personalizedShopInfo?.createdAt ?? '2022-01-01').diff(
        dayjs(data?.personalizedShopInfo?.purchasedAt ?? '2022-01-01'),
        'ms',
      ) > 0 &&
      dayjs(data?.personalizedShopInfo?.createdAt ?? '2022-01-01')
        .add(3, 'h')
        .diff(dayjs(), 'ms') > 0 &&
      !data?.personalizedShopInfo?.purchasedAt
    ) {
      setPersonalizedShopButtonOn(true)
    } else {
      setPersonalizedShopButtonOn(false)
    }
  }

  const selectOnePurchaseButon = (data) => {
    if (
      data?.selectOnePurchaseInfo?.createdAt &&
      dayjs(data?.selectOnePurchaseInfo?.createdAt ?? '2022-01-01')
        .add(3, 'h')
        .diff(dayjs(), 'ms') > 0 &&
      !data?.selectOnePurchaseInfo?.purchased
    ) {
      setSelectOnePurchaseButtonOn(true)
    } else {
      setSelectOnePurchaseButtonOn(false)
    }
  }

  const allBrandDealPurchaseButton = (data) => {
    if (
      data?.allBrandDealInfo?.createdAt &&
      dayjs(data?.allBrandDealInfo?.createdAt ?? '2022-01-01')
        .add(3, 'h')
        .diff(dayjs(), 'ms') > 0 &&
      !data?.allBrandDealInfo?.purchased
    ) {
      setAllBrandDealPurchaseButtonOn(true)
    } else {
      setAllBrandDealPurchaseButtonOn(false)
    }
  }

  const lotteryDrawSpecialPurchaseButton = (data) => {
    if (
      data?.lotteryDrawSpecialPurchase &&
      dayjs(data?.lotteryDrawSpecialPurchase ?? '2022-01-01')
        .add(1, 'h')
        .diff(dayjs(), 'ms') > 0
    ) {
      setLotteryDrawSpecialPurchase(true)
    } else {
      setLotteryDrawSpecialPurchase(false)
    }
  }

  const fertilizerRouletteTimerButton = (data) => {
    if (
      data?.fertilizerRouletteData?.fertilizerRouletteOnAt &&
      dayjs(
        data?.fertilizerRouletteData?.fertilizerRouletteOnAt ?? '2022-01-01',
      ).diff(
        dayjs(
          data?.fertilizerRouletteData?.fertilizerRoulettePurchasedAt ??
            '2022-01-01',
        ),
        'ms',
      ) > 0 &&
      dayjs(
        data?.fertilizerRouletteData?.fertilizerRouletteOnAt ?? '2022-01-01',
      )
        .add(6, 'h')
        .diff(dayjs(), 'ms') > 0
    ) {
      setFertilizerRouletteOn(true)
    } else {
      setFertilizerRouletteOn(false)
    }
  }

  // 이거 뭐하는건지 잘 모르겠음; 일단 남겨둠
  const triggerShowChangePlantAmountModal = async (data) => {
    if (
      ['apple', 'tomato', 'mandarin', 'persimmon'].includes(data?.itemType) &&
      data?.farmLevel?.level6 > 50000 &&
      data?.farmLevel?.level6 - data?.exp > 100 &&
      data?.exp > data?.farmLevel?.level3 &&
      !data?.changePlantAmount &&
      new Date(data?.createdAt) < new Date('2023/03/15 00:00:00')
    ) {
      if (
        !data?.changePlantModalOpened ||
        dayjs().isBefore(dayjs(data?.changePlantModalOpened).add(3, 'd'))
      ) {
        setShowChangePlantAmountRightButton(true)
      }
      if (!data.changePlantModalOpened) {
        setShowChangePlantAmountRightButton(true)
        setShowChangePlantAmountModal(true)

        await backendApis.updatelastUpdatedAt(token, 'PUT', {
          updateType: 'changePlantModalOpened',
        })
      }
    }
  }

  const checkinPurchaseInfo = async () => {
    if (
      new Date(
        new Date(farmData?.questLogForAttendanceWaterFinalDate).getTime() +
          1000 * 60 * 60 * 3,
      ) >= new Date() &&
      farmData?.purchasedCheckInReward?.rewardUntil &&
      new Date(farmData?.purchasedCheckInReward?.rewardUntil) <= new Date()
    ) {
      setCheckInPurchaseIconOn(true)
    } else if (
      new Date(
        new Date(farmData?.questLogForAttendanceWaterFinalDate).getTime() +
          1000 * 60 * 60 * 3,
      ) >= new Date() &&
      !farmData?.purchasedCheckInReward?.rewardUntil
    ) {
      setCheckInPurchaseIconOn(true)
    } else {
      setCheckInPurchaseIconOn(false)
    }
  }
  const triggerMissionHunter = async (data) => {
    setMissionHunterPurchaseInfo(data?.missionHunterPurchaseInfo)
    if (
      data?.missionHunterPurchaseInfo &&
      data?.missionHunterPurchaseInfo?.isFinished === false &&
      !data?.missionHunterPurchaseInfo?.isFirstPopUpClosed
    ) {
      ModalStore.setIsModalOpen('missionHunter')
      await backendApis.updateSpecificField({
        fieldName: 'missionHunterPurchaseInfo.isFirstPopUpClosed',
        value: true,
      })
    }

    if (
      data?.missionHunterPurchaseInfo &&
      data?.missionHunterPurchaseInfo?.isFinished === false &&
      dayjs().isBefore(
        dayjs(data?.missionHunterPurchaseInfo?.startedAt).add(3, 'h'),
      )
    ) {
      setShowMissionHunterIcon(true)
    } else {
      setShowMissionHunterIcon(false)
    }
  }

  const fetchSellerCoupons = async () => {
    let result = await backendApis.getUserSellerCoupons()
    if (result?.data?.length > 0) {
      setShowSellerCouponPurchaseIconOn(true)
    }
    let couponList = []
    for (let i = 0; i < result?.data?.length; i++) {
      let coupon = result?.data?.[i]
      let sellerInfo = await backendApis.getSellerBasicInfo({
        sellerId: coupon?.condition?.sellerId,
      })

      let temp = { ...coupon, sellerName: sellerInfo?.data?.sellerDisplayName }
      couponList.push(temp)
    }

    setSellerCoupons(couponList)
    setSelectedSellerCoupon(couponList?.[0])
  }

  const fetchUserCoupons = async () => {
    let result = await backendApis.getUserAlfarmCoupons()
    let couponInfo = {}
    couponInfo.couponList = result?.data?.sort(
      (a, b) => b.expiresAt - a.expiresAt,
    )
    couponInfo.timeDiff = dayjs(couponInfo?.couponList?.[0]?.expiresAt).diff(
      dayjs(couponInfo?.couponList?.[0]?.receivedAt),
      's',
    )
    if (couponInfo.timeDiff > 0) {
      setShowCouponUsageNudgeIconOn(true)
    }
    setCouponInfo(couponInfo)
  }

  const fetchAnnouncementData = async (data) => {
    let res = await backendApis.getAnnouncementList(token, 'GET', {
      page: 1,
      itemType: data?.itemType,
    })
    setAnnouncementList(res?.data)
  }

  const fetchAlfarmCommonData = async () => {
    let dailyWaterRewardToggle =
      await backendApis.getAlfarmCommonDataDailyWaterRewardToggle(token)
    setDailyWaterPurchaseRewardToggle(
      dailyWaterRewardToggle?.data?.dailyWaterPurchaseRewardToggle,
    )
  }

  const fetchAlfarmSingleDealData = async () => {
    let result = await backendApis.getAlfarmSingleItemDealInfo(token)
    if (result?.status === 200 && result?.data) {
      setSingleDealInfo(result?.data)
    }
  }

  const dailyLotteryInit = async (data) => {
    let lotteryData = await backendApis.getTodayDailyLotteryData(token)
    setPersonalDailyLotteryInfo(data?.dailyLotteryInfo)
    let now = TimeStore.now
    let ninePM = dayjs(
      `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 21:00:00`,
      'YYYY-MM-DD HH:mm:ss',
    )
    let diff = ninePM.diff(dayjs(now), 'm')
    let rewardedAt = data?.dailyLotteryInfo?.rewardedAt || '2021-01-01'
    let isRewardedToday = !timeChecker(
      rewardedAt,
      data?.initTimeCheckerResult,
      'dailyLottery',
    )

    if (diff < 0 || isRewardedToday) {
      setIsDailyLotteryOn(false)
    } else if (diff < 120 && lotteryData?.data) {
      Object.assign(lotteryData?.data, { diff: diff })
      Object.assign(lotteryData?.data, { now: now })
      setDailyLotteryData(lotteryData?.data)
      setIsDailyLotteryOn(true)
    }
  }

  const levelUpChanceInviteInit = async (data) => {
    if (data?.levelUpChanceInviteInfo) {
      levelUpChanceInviteOn(data)
      return
    }

    if (
      data?.isNewUser &&
      data?.giveWaterCount >= 20 &&
      data?.exp < data?.farmLevel?.level5 &&
      TimeStore.currentNewDate - new Date(data?.createdAt) <=
        1000 * 60 * 60 * 24 * 3
    ) {
      await backendApis.setLevelUpChanceInviteInfo(token)
      let temp = { ...data }
      temp.levelUpChanceInviteInfo = {
        createdAt: new Date(),
        inviteeList: [],
      }
      levelUpChanceInviteOn(temp)
      setFarmData((prev) => {
        return {
          ...prev,
          levelUpChanceInviteInfo: {
            createdAt: new Date(),
            inviteeList: [],
          },
        }
      })
      setTimeout(() => {
        ModalStore.setIsModalOpen('levelUpChance')
      }, 1200)
    }
  }

  const levelUpChanceInviteOn = async (data) => {
    if (!data?.levelUpChanceInviteInfo) return
    const now = TimeStore.currentDayJS
    let endDate = dayjs(data?.levelUpChanceInviteInfo?.createdAt)
    endDate = endDate.add(3, 'd')
    const diff = endDate.diff(now, 'minute')
    if (diff > 0) {
      setIsLevelUpChanceInviteOn(true)
    }
  }

  const harvestInviteInit = async (data, pushQueue = false) => {
    let harvestInviteAvailable = JSON.parse(
      window.localStorage.getItem('harvestInviteAvailable'),
    )

    if (
      !harvestInviteAvailable ||
      harvestInviteAvailable?.itemType !== data?.itemType
    ) {
      let res = await backendApis.getItemInfo(token, 'GET', {
        itemType: data?.itemType,
        itemPrice: data?.itemPrice,
      })
      if (!res?.data?.success) {
        // ToastStore?.toastOn({
        //   type: "error",
        //   message: "[오류] 올팜을 재접속 해주세요",
        //   duration: 3000,
        // });
      }

      if (
        pushQueue &&
        res?.data?.totalOptions?.length === 1 &&
        res?.data?.totalOptions?.[0]?.length > 1
      ) {
        ModalStore.pushQueue('harvestInvite')
      }

      window.localStorage.setItem(
        'harvestInviteAvailable',
        JSON.stringify({
          itemType: data?.itemType,
          available:
            res?.data?.totalOptions?.length === 1 &&
            res?.data?.totalOptions?.[0]?.length > 1,
        }),
      )

      setIsHarvestInviteAvailable(
        res?.data?.totalOptions?.length === 1 &&
          res?.data?.totalOptions?.[0]?.length > 1,
      )
    } else {
      setIsHarvestInviteAvailable(harvestInviteAvailable?.available)
      if (pushQueue && harvestInviteAvailable?.available) {
        ModalStore.pushQueue('harvestInvite')
      }
    }
  }

  const lastMinuteInviteInit = async (data) => {
    // if (farmData?.exp < farmData?.farmLevel?.level6) {
    //   return;
    // }

    if (data?.lastMinuteInviteInfo) {
      lastMinuteInviteOn(data)
      return
    }

    const detectResult = await backendApis.detectLastMinuteInviteParticipant(
      token,
    )
    if (detectResult?.data?.detected === true) {
      return
    }

    if (data?.exp > data?.farmLevel?.level5 && data?.isNewUser) {
      await backendApis.setLastMinuteInviteInfo(token)
      let temp = { ...data }

      temp.lastMinuteInviteInfo = {
        createdAt: new Date(),
        inviteeList: [],
      }
      lastMinuteInviteOn(temp)
      setFarmData(temp)
      setTimeout(() => {
        ModalStore.setIsModalOpen('lastMinuteInvite')
      }, 5000)
    }
  }

  const lastMinuteInviteOn = async (data) => {
    if (!data?.lastMinuteInviteInfo) return
    const now = TimeStore.currentDayJS
    let endDate = dayjs(data?.lastMinuteInviteInfo?.createdAt)
    endDate = endDate.add(3, 'd')
    const diff = endDate.diff(now, 'minute')

    if (diff > 0) {
      setIsLastMinuteInviteOn(true)
    }
  }

  function showRewardToast(rewardAmount, rewardtype = 'water') {
    setRewardTypeForRewardToast(rewardtype)
    setRewardAmountForRewardToast(rewardAmount)
    setGetResourceModal(true)
  }

  const newbiePurchaseWaterRewardTime = async (data) => {
    const now = TimeStore.currentDayJS
    let endDate = dayjs(data?.createdAt)
    endDate = endDate.add(3, 'd')
    const diff = endDate.diff(now, 'minute')
    setNewbiePurchaseWaterRemainTime(diff)
  }

  const secretScratchTime = async (tempHistory) => {
    if (!tempHistory) {
      return
    }
    setSecretScratchHistory(tempHistory)
    let endDate = dayjs(new Date(tempHistory?.[0]?.createdAt))
    endDate = endDate.add(2, 'h')

    setLastScratch(endDate)
    const now = TimeStore.currentDayJS

    const diff = endDate.isSameOrAfter(now)
    if (diff) {
      setIsSecretIconOn(true)
    }
  }

  const kidsRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setKidsRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS

    const diff = endDate.isSameOrAfter(now)
    if (diff) {
      setKidsRevenueDealIconOn(true)
    }
  }

  const winterRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setWinterRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS

    const diff = endDate.isSameOrAfter(now)
    if (diff) {
      setWinterRevenueDealIconOn(true)
    }
  }

  const snackRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setSnackRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS
    const diff = endDate.isSameOrAfter(now)

    if (diff) {
      setSnackRevenueDealIconOn(true)
    }
  }

  const daisoRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setDaisoRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS
    const diff = endDate.isSameOrAfter(now)

    if (diff) {
      setDaisoRevenueDealIconOn(true)
    }
  }

  const summerRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setSummerRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS
    const diff = endDate.isSameOrAfter(now)

    if (diff) {
      setSummerRevenueDealIconOn(true)
    }
  }

  const goldAccesorryRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setGoldAccesorryRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS
    const diff = endDate.isSameOrAfter(now)

    if (diff) {
      setGoldAccesorryRevenueDealIconOn(true)
    }
  }

  const healthRevenueDealTime = async (tempTime) => {
    if (!tempTime) {
      return
    }
    let endDate = dayjs(new Date(tempTime))
    endDate = endDate.add(3, 'h')

    setHealthRevenueDealEndTime(endDate)
    const now = TimeStore.currentDayJS
    const diff = endDate.isSameOrAfter(now)

    if (diff) {
      setHealthRevenueDealIconOn(true)
    }
  }

  const produceFertMachineTime = async (data) => {
    if (!data?.produceFertMachineHistory) {
      return
    }
    const tempHistory = data?.produceFertMachineHistory?.reverse()
    setProduceFertMachineHistory(tempHistory)

    if (!tempHistory?.[0]?.isEnded) {
      setProducingFertMachine(true)
    }
  }

  const codePushVersion = async () => {
    let version = window.localStorage.getItem('userCodePushVersionNew')
    if (
      typeof version === 'string' &&
      codePushVersionAvailable(version, AuthStore.minCodePushVersion)
    ) {
      setUserCodePushVersion(version)
      return
    }
    const result = await backendApis.getUserCodePushVersion(token)
    setUserCodePushVersion(result?.codePushVersion)
    window.localStorage.setItem(
      'userCodePushVersionNew',
      result?.codePushVersion,
    )
  }

  const getLevelOnly = (levels, exp) => {
    const level1 = levels?.level1
    const level2 = levels?.level2
    const level3 = levels?.level3
    const level4 = levels?.level4
    const level5 = levels?.level5
    const level6 = levels?.level6

    const levelData = [level1, level2, level3, level4, level5, level6]

    let level = 1
    for (let i = 0; i < levelData.length; i++) {
      const each = levelData[i]
      if (exp > each) level++
    }

    return level
  }

  const getLevel = async (exp, token) => {
    const level1 = farmData?.farmLevel?.level1
    const level2 = farmData?.farmLevel?.level2
    const level3 = farmData?.farmLevel?.level3
    const level4 = farmData?.farmLevel?.level4
    const level5 = farmData?.farmLevel?.level5
    const level6 = farmData?.farmLevel?.level6

    const levelData = [level1, level2, level3, level4, level5, level6]

    let level = 1
    let remainExp = 0
    let expToLevelUp = 0
    let prev = 0
    for (let i = 0; i < levelData.length; i++) {
      const each = levelData[i]
      if (exp > each) level++
      else {
        //레벨카운팅 종료 하고 remainExp, expToLevelUp계산
        expToLevelUp = each - exp
        remainExp = exp - prev
        break
      }

      prev = each
    }

    setLevel((prev) => {
      if (prev === 5 && level === 6) {
        lastMinuteInviteInit(farmData)
        setTimeout(() => {
          harvestInviteInit(farmData, true)
        }, 2000)
      }
      return level
    })
    setRemainExp(remainExp)
    setExpToLevelUp(expToLevelUp)

    return { level, remainExp, expToLevelUp }
  }

  const getWaterButtonNotification = () => {
    if (Object.keys(farmData).length === 0) return

    if (farmData?.questLogForGetWaterDailyAttendance) {
      if (
        timeChecker(
          farmData?.questLogForGetWaterDailyAttendance,
          farmData?.initTimeCheckerResult,
          'getWaterButtonNotification',
        )
      ) {
        setCanGetWaterButtonNotification(true)
      }
    }
    if (farmData?.questLogForGetWaterDailyAttendance) {
      if (
        !timeChecker(
          farmData?.questLogForGetWaterDailyAttendance,
          farmData?.initTimeCheckerResult,
          'getWaterButtonNotification',
        )
      ) {
        setCanGetWaterButtonNotification(false)
      }
    }
  }

  const getFertButtonNotification = () => {
    if (Object.keys(farmData).length === 0) return

    if (farmData?.questLogForAttendanceFert) {
      const logInLogsByTime = farmData?.questLogForAttendanceFert.filter(
        (x) =>
          !timeChecker(
            x,
            farmData?.initTimeCheckerResult,
            'getFertButtonNotification',
          ),
      )
      const logInLogsByTimeSet = new Set(logInLogsByTime).size
      if (
        TimeStore.currentNewDate -
          new Date(
            farmData?.questLogForAttendanceFert[
              farmData?.questLogForAttendanceFert.length - 1
            ],
          ) >
          1800000 &&
        logInLogsByTimeSet <= 2
      ) {
        setCanGetFertButtonNotification(true)
      }

      if (farmData?.questLogForAttendanceFert) {
        if (
          timeChecker(
            farmData?.questLogForAttendanceFert[
              farmData?.questLogForAttendanceFert.length - 1
            ],
            farmData?.initTimeCheckerResult,
            'getFertButtonNotification',
          )
        ) {
          setCanGetFertButtonNotification(true)
        }
      }
      if (
        (TimeStore.currentNewDate -
          new Date(
            farmData?.questLogForAttendanceFert[
              farmData?.questLogForAttendanceFert.length - 1
            ],
          ) <
          1800000 &&
          !timeChecker(
            farmData?.questLogForAttendanceFert[
              farmData?.questLogForAttendanceFert.length - 1
            ],
            farmData?.initTimeCheckerResult,
            'getFertButtonNotification',
          )) ||
        (logInLogsByTimeSet >= 3 &&
          !timeChecker(
            farmData?.questLogForAttendanceFert[
              farmData?.questLogForAttendanceFert.length - 1
            ],
            farmData?.initTimeCheckerResult,
            'getFertButtonNotification',
          ))
      ) {
        setCanGetFertButtonNotification(false)
      }
    }
  }

  const reSetDailyGiveWater = async (data) => {
    const result = await backendApis.reSetDailyGiveWater(token)

    if (!result?.data?.success) {
      ToastStore.toastOn({
        type: 'error',
        message: '잠시후 다시 시도해주세요.',
        duration: 3000,
      })
      return
    }

    if (result?.data?.dailyWater > 0 && result?.data?.success) {
      setDailyGiveWaterPopUp({
        dailyWater: result?.data?.dailyWater,
        removedWater: result?.data?.removedWater,
      })
      setFarmData((prev) => {
        if (UserStore.waterExceeded) {
          prev.water += Math.round(result?.data?.dailyWater * 0.5)
        } else {
          prev.water += result?.data?.dailyWater
        }
        return {
          ...prev,
          dailyGiveWater: 0,
          water: prev?.water,
        }
      })

      setDailyGiveWaterLevelOne(result?.data?.level1)
      setDailyGiveWaterLevelTwo(result?.data?.level2)
      setDailyGiveWaterLevelThree(result?.data?.level3)
      setDailyGiveWaterLevelFour(result?.data?.level4)
      setCanGetDailyGiveWater(false)
    }
  }

  const getDailyGiveWaterLevel = async () => {
    const result = await backendApis.getDailyGiveWaterLevel(token)
    setDailyGiveWaterLevelOne(result?.dailyGiveWaterLevel?.level1)
    setDailyGiveWaterLevelTwo(result?.dailyGiveWaterLevel?.level2)
    setDailyGiveWaterLevelThree(result?.dailyGiveWaterLevel?.level3)
    setDailyGiveWaterLevelFour(result?.dailyGiveWaterLevel?.level4)
  }

  const waterOneOneBattleData = async () => {
    const result = await backendApis.getOngoingWaterBattleData(token)
    if (result?.data?.isEnded === false) {
      setBattleData(result?.data)
      setOngoingWaterBattleBanner(true)
      setBattleWaterAmount(result?.data?.waterCount)
      setOpponentBattleWaterCount(result?.data?.opponentWaterCount)
    } else if (result?.data?.isEnded === true) {
      setOngoingWaterBattleBanner(false)
    }
  }

  const autoOpponentGiveWater = async () => {
    const result = await backendApis.autoOpponentGiveWater(token)
    if (result?.data?.autoWaterCount >= 1 || result?.data?.isEnded) {
      setTimeout(() => {
        setBattleClassName('shake-bottom')
      }, 500)
      setBattleClassName('none')
    }
  }

  const autoOpponentGiveWaterEnter = async () => {
    await backendApis.autoOpponentGiveWaterEnter(token)
  }

  const getLastClearedInviteQuestTime = (data) => {
    if (
      !timeChecker(
        data?.clearedInviteQuest,
        data?.initTimeCheckerResult,
        'getLastClearedInviteQuestTime',
      )
    ) {
      setDidDailyInviteQuestCleared(true)
    } else {
      setDidDailyInviteQuestCleared(false)
    }
  }

  const handleAnim = (action, duration, callback) => {
    UserStore?.set('giveWaterAnimationOn', true)
    setAnimState(action)
    getLevel(farmData?.exp, token)
    setTimeout(() => {
      UserStore?.set('giveWaterAnimationOn', false)
      callback()
    }, duration)
  }

  // gif render delay가 조금씩 생겨서 여러번 누르면 점점 더 밀리는 현상 해결과
  // effect render 중에 다른 버튼 아무것도 누르지 못하도록 하기 위해서 모달로 적용함
  const showWaterModal = () => {
    setWaterModalVisible(true)
    setTimeout(() => {
      setWaterModalVisible(false)
    }, 1200)
  }

  // 진입점 아이콘 개인화
  function getPersonalizationLastDate(dataArray, dateField) {
    if (!Array.isArray(dataArray) || dataArray.length === 0) {
      return null
    }

    return dataArray.reduce((latest, current) => {
      const latestDate = new Date(latest[dateField])
      const currentDate = new Date(current[dateField])

      return latestDate > currentDate ? latest : current
    })
  }
  const useIconVisibility = (iconLastUseDate, farmData) => {
    const now = new Date()
    const twoWeeks = 14 * 24 * 60 * 60 * 1000
    const oneWeek = 7 * 24 * 60 * 60 * 1000

    const gameCreatedAt = new Date(farmData?.createdAt)

    let lastVisibleDate
    let nextVisibleDate

    // 신규 유저인 경우
    if (isNewUser) {
      lastVisibleDate = new Date(gameCreatedAt.getTime() + twoWeeks)
      nextVisibleDate = new Date(lastVisibleDate.getTime() + twoWeeks)
    } else if (iconLastUseDate) {
      // 사용 기록이 있는 경우
      const lastUseDate = new Date(iconLastUseDate)
      lastVisibleDate = new Date(lastUseDate.getTime() + twoWeeks)
      nextVisibleDate = new Date(lastVisibleDate.getTime() + oneWeek)
    } else {
      // 사용 기록이 없는 경우
      lastVisibleDate = new Date(gameCreatedAt.getTime() + twoWeeks)
      nextVisibleDate = new Date(lastVisibleDate.getTime() + twoWeeks)
    }

    // 현재 날짜가 마지막 가시 날짜 이전이라면 가시
    if (now <= lastVisibleDate) {
      return true
    }

    // 현재 날짜가 마지막 가시 날짜와 다음 가시 날짜 사이라면 비가시
    if (now > lastVisibleDate && now <= nextVisibleDate) {
      return false
    }

    // 그 외의 경우에는 1주 동안 가시 후 다시 2주 동안 비가시를 반복
    const weeksSinceLastVisible = Math.floor((now - nextVisibleDate) / oneWeek)
    return weeksSinceLastVisible % 3 < 1
  }
  // 셀러 이슈로 특정 작물을 포기해야하는 케이스
  useEffect(() => {
    const itemEndModal = async (data) => {
      if (!data?.userId) return
      const itemType = data?.itemType
      const endItem = ['pear', 'lime']
      const endDateList = {
        pear: '2024-02-10',
        apple: '2024-03-31',
        coffee_starbucks: '2024-08-01',
      }
      let endDate = endDateList?.[itemType]

      if (endItem?.includes(itemType)) {
        ModalStore?.setIsModalOpen('itemEndModal')
      }
    }

    itemEndModal(farmData)
  }, [farmData])

  useEffect(() => {
    fetchAlranchData()
  }, [])

  // 테스트 코드!
  useEffect(() => {
    if (window.Cypress && Cypress.env('NODE_ENV') === 'test') {
      window.setTestState = (newState) => {
        setFinishedModalVisible(newState)
      }
    }
  }, [])

  async function getCowInfo() {
    const result = await client.get('v2/resources/')
    if (result.data) {
      setAlranchCowData(result.data)
    }
  }
  async function getMissionsInfo() {
    const result = await client.get('v2/missions/log/')
    if (result.data) {
      setAlranchMissionsData(result.data)
    }
  }

  async function fetchAlranchData() {
    getCowInfo()
    getMissionsInfo()
  }

  // 옛날 작물 아닌 케이스에 대해서 왕창 수확
  const lastHarvestPurchaseInit = async () => {
    if (!codePushVersionAvailable(userCodePushVersion, '2.2.3')) {
      return
    }
    if (farmData?.harvestInviteList?.length > 0) {
      return
    }
    if (
      (farmData?.itemType === 'apple' ||
        farmData?.itemType === 'tomato' ||
        farmData?.itemType === 'mandarin' ||
        farmData?.itemType === 'persimmon') &&
      new Date(farmData?.createdAt) - new Date('2023-01-06 13:00:00') < 0
    ) {
      return
    }
    if (!!farmData?.lastHarvestPurchaseInfo?.createdAt) {
      if (
        new Date(
          new Date(farmData?.lastHarvestPurchaseInfo?.createdAt).getTime() +
            1000 * 60 * 60 * 24 * 3,
        ) >= new Date()
      ) {
        setLastHarvestPurchaseButtonOn(true)
        return
      } else setLastHarvestPurchaseButtonOn(false)
    }
    if (!farmData?.exp) return
    if (!farmData?.farmLevel?.level6) return

    if (farmData?.exp < farmData?.farmLevel?.level6 * 0.95) {
      return false
    }
    if (farmData?.lastHarvestPurchaseInfo) {
      return false
    }

    // 해당 작물에 대해서 왕창 수확을 띄우는 것임
    if (
      [
        'onion',
        'carrot',
        'potato',
        'lemon',
        'banana',
        'tomato',
        'pear',
        'greenOnion',
        'orange',
        'sweetpotato',
        'egg',
        'grape',
        'grapefruit',
        'avocado',
        'apple',
        'rice',
        'lime',
        'galic',
        'sprout',
        'asparagus',
        'pepper',
        'pineapple',
        'mool',
        'dishdetergent',
        'toothset',
        'toothpaste',
      ]?.includes(farmData?.itemType)
    ) {
      const result = await backendApis.setLastHarvestPurchaseInit(token)
      if (result?.data?.success) {
        let tempData = farmData
        tempData.lastHarvestPurchaseInfo = {
          createdAt: new Date(),
        }
        setFarmData(tempData)
        setLastHarvestPurchaseButtonOn(true)
        setTimeout(() => {
          ModalStore.setIsModalOpen('lastHarvestPurchase')
          harvestInviteInit(farmData, true)
        }, 1200)
      }
    }
  }

  const quizPurchaseButtonInit = () => {
    if (!farmData?.solvedQuizHistory) return

    if (farmData?.solvedQuizHistory) {
      if (
        new Date(
          new Date(
            farmData?.solvedQuizHistory[
              farmData?.solvedQuizHistory?.length - 1
            ]?.solvedAt,
          ).getTime() +
            1000 * 60 * 60 * AlfarmAdStore.quizAdTimeIntervalBetweenExposure,
        ) >= new Date()
      ) {
        setQuizPurchaseButtonOn(true)
      }
    }
  }

  const yutnoriPurchaseButtonInit = () => {
    if (!farmData?.lastYutnoriPlayedAt) return

    if (farmData?.lastYutnoriPlayedAt) {
      if (
        new Date(
          new Date(farmData?.lastYutnoriPlayedAt).getTime() +
            1000 * 60 * 60 * AlfarmAdStore.yutnoriTimeIntervalBetweenExposure,
        ) >= new Date()
      ) {
        setYutnoriPurchaseEntryButtonOn(true)
      }
    }
  }

  const adItemBrowsingButtonInit = () => {
    if (!farmData?.adItemBrowsingFertilizer) return

    if (farmData?.adItemBrowsingFertilizer) {
      if (
        new Date(
          new Date(
            farmData?.adItemBrowsingFertilizer[
              farmData?.adItemBrowsingFertilizer?.length - 1
            ]?.clearedAt,
          ).getTime() +
            1000 * 60 * 60 * 3,
        ) >= new Date()
      ) {
        setAdItemBrowsingButtonOn(true)
      }
    }
  }

  // 바텀싯 띄울 때 필요
  function onDismiss() {
    setTimeout(() => BottomSheetStore.setIsbottomSheetOpen('basic'), 50)
  }

  // 특판비료 시작
  const luckydrawPurchaseReward = async () => {
    const result = await backendApis.luckydrawPurchaseReward(token)
    if (result?.data?.success === true) {
      setLuckydrawPurchaseRewardOn(true)
      setLuckydrawPurchaseRewardEndAt(new Date(result?.data?.endedAt))
      setTimeLoadingEndLuckydraw(false)
    }
    if (result?.data?.success === false) {
      setLuckydrawPurchaseRewardOn(false)
      setLuckydrawPurchaseRewardEndAt(new Date(result?.data?.endedAt))
      setTimeLoadingEndLuckydraw(false)
    }
  }

  // 특판 비료 끝

  // 멍뭉이 시작
  const puppyEndAt = async () => {
    const result = await backendApis.puppyEndAt(token)

    if (result?.data?.success === true) {
      setShowPuppy(true)
      const result = await backendApis.lastGetPuppyGift(token)

      UserStore?.set('puppyCanClick', result?.data?.canClick)
      UserStore?.set('puppyGiftAt', new Date(result?.data?.giftAt))

      setPuppyEndTime(new Date(result?.data?.endedAt))
    }
    if (result?.data?.success === false) {
      setShowPuppy(false)
      setPuppyEndTime(new Date(result?.data?.endedAt))
    }
  }

  // 멍뭉이 끝

  // 복귀 유저 시작
  // const lastPlayedAt = async () => {
  //   const result = await backendApis.lastPlayedAt(token);
  //   if (result?.data?.success === true) {
  //     ModalStore.setIsModalOpen("comebackUser");
  //   }
  // };

  const updatelWater = async () => {
    const result = await backendApis.initialWater(token)
    setBasicWater(result?.data?.basicWater)
    setIncreasePerSecond(result?.data?.increasePerSecond)
  }

  const getBasicWater = async () => {
    if (canClickGetWater) {
      setCanClickGetWater(false)
      const result = await backendApis.getBasicWater(token)

      let tmpFarmData = farmData

      tmpFarmData.water += result?.amount
      showRewardToast(result?.amount, 'water')

      tmpFarmData.lastGetWaterAt = new Date()
      tmpFarmData?.getWaterCount
        ? (tmpFarmData.getWaterCount += 1)
        : (tmpFarmData.getWaterCount = 1)
      setGetWaterCount(tmpFarmData?.getWaterCount)
      setBasicWater(0.01)
      setFarmData(tmpFarmData)
      setGetResourceModal(true)

      setAddWater(result?.amount)

      setCanClickGetWater(true)
    }
  }

  const getWaterTimeReset = async () => {
    if (canClickGetWater) {
      setCanClickGetWater(false)
      let tmpFarmData = farmData

      tmpFarmData.lastGetWaterAt = new Date()
      tmpFarmData?.getWaterCount
        ? (tmpFarmData.getWaterCount += 1)
        : (tmpFarmData.getWaterCount = 1)
      setGetWaterCount(tmpFarmData?.getWaterCount)
      setBasicWater(0.01)
      setFarmData(tmpFarmData)
      setCanClickGetWater(true)
    }
  }

  const getWater = async () => {
    if (basicWater < 1) {
      if (showMinimumBasicWaterModal) return
      setShowMinimumBasicWaterModal(true)
      setTimeout(() => {
        setShowMinimumBasicWaterModal(false)
      }, 2000)
      return
    }

    if (
      ((codePushVersionAvailable(userCodePushVersion, '7.0.27') &&
        ABStore.admobTester) ||
        !ABStore.admobTester) &&
      basicWaterDoubleAB.get() !== 'a' &&
      giveWaterCount >= 100
    ) {
      const result = await backendApis.getBasicWaterAmount(token)
      setPendingWaterAmount(result?.amount)
      ModalStore.setIsModalOpen('doubleWaterModal')
      return
    }

    getBasicWater()
  }

  const giveWaterBasket = async () => {
    newGiveWaterBasket(1)
  }

  const newGiveWaterBasket = async (giveWaterCount = 5) => {
    if (!canClickGiveWater) return

    if (BottomSheetStore.isBottomSheetOpen !== 'basic') return
    if (giveWaterBlock) return

    if (farmData?.water < giveWaterCount * 10) {
      if (showMinimumWaterModal) return
      setShowMinimumWaterModal(true)
      setTimeout(() => {
        setShowMinimumWaterModal(false)
      }, 2500)
      return
    }
    if (farmData?.water < giveWaterCount * 10) return
    setCanClickGiveWater(false)
    const result = await backendApis.newGiveWaterBasket(token, 'POST', {
      giveWaterCount,
      indexCount,
      orderCount: UserStore?.orderCount,
    })

    if (result?.status === 200) {
      // 매일 물 준 횟수 카운트 for daily mission v2
      let newDateTime = moment().add(-4, 'hours')
      let newDateFormatted = newDateTime.format('YYYY-MM-DD')
      let count = 0
      let storedData = localStorage.getItem('alfarm_giveWaterCount')

      if (storedData) {
        let parsedData = JSON.parse(storedData)

        if (parsedData.date === newDateFormatted) {
          count = parsedData.count
        }
      }
      localStorage.setItem(
        'alfarm_giveWaterCount',
        JSON.stringify({
          date: newDateFormatted,
          count: count + 1,
        }),
      )
      // 매일 물 준 횟수 카운트 for daily mission v2 end

      setIndexCount(indexCount + 1)
      setGiveWaterCountAtThisVisit(giveWaterCountAtThisVisit + 1)
      if (result?.data?.success === false) {
        setShowMinimumNutrimentModal(true)
        setTimeout(() => {
          setShowMinimumNutrimentModal(false)
          setCanClickGiveWater(true)
        }, 2000)

        return
      }

      let tmpFarmData = farmData
      giveWaterPopUpEventHandler(result)

      if (ongoingWaterBattleBanner) {
        waterOneOneBattleData()
        newBattlingGiveWaterBasket(giveWaterCount)
      }

      setGiveWaterCount(tmpFarmData?.giveWaterCount + result?.data?.giveCount)
      UserStore.setGiveWaterCount(
        tmpFarmData?.giveWaterCount + result?.data?.giveCount,
      )

      tmpFarmData.giveWaterCount += result?.data?.giveCount

      tmpFarmData.water -= result?.data?.point
      tmpFarmData.exp += result?.data?.exp
      tmpFarmData.nutriment -= result?.data?.fert
      tmpFarmData.dailyGiveWater += result?.data?.dailyGiveWater
      tmpFarmData.dailyGiveWaterLevel.level1 =
        result?.data?.dailyGiveWaterLevel?.level1
      tmpFarmData.dailyGiveWaterLevel.level2 =
        result?.data?.dailyGiveWaterLevel?.level2
      tmpFarmData.dailyGiveWaterLevel.level3 =
        result?.data?.dailyGiveWaterLevel?.level3
      tmpFarmData.dailyGiveWaterLevel.level4 =
        result?.data?.dailyGiveWaterLevel?.level4
      tmpFarmData.engagementNutrimentInfo =
        result?.data?.engagementNutrimentInfo

      setFarmData(tmpFarmData)
      UserStore.set(
        'farmData.nutriment',
        UserStore?.farmData?.nutriment - result?.data?.fert,
      )

      if (result?.data?.plusWater) {
        setTimeout(() => {
          let tmpFarmData = farmData
          tmpFarmData.dailyGiveWater = result?.data?.addedDailyGiveWater
          setFarmData(tmpFarmData)
        }, 1200)
        if (UserStore?.giveWaterCount < 2000) {
          setTimeout(() => {
            ModalStore.setIsEngagementWaterModal(true)
          }, 1200)
          setTimeout(() => {
            ModalStore.setIsEngagementWaterModal(false)
          }, 4500)
        }
      }

      showWaterModal()

      handleAnim('giveWaterBasket', 1200, () => {
        setCanClickGiveWater(true)
        setAnimState('')
      })
    }
  }

  const getGganbuList = async () => {
    const result = await backendApis.getGganbuList(token)
    if (result?.data) {
      setGganbuData(result?.data)
      window.localStorage.setItem('gganbuData', JSON.stringify(result?.data))
    }
  }

  // const getStoreByAdId = async () => {
  //   const result = await backendApis.getStoreByAdId(token, 'GET', {
  //     sellerStoreId:
  //       farmData?.waterFromSellerFarm[farmData?.waterFromSellerFarm?.length - 1]
  //         ?.sellerStoreId,
  //   })
  //   if (result?.status === 200) {
  //     setSellerFarmList(result?.data)
  //   }
  // }

  const getStoreByAdIdList = async () => {
    const mainStoreId =
      farmData?.waterFromSellerFarm?.[farmData?.waterFromSellerFarm?.length - 1]
        ?.sellerStoreId
    const subStoreId1 =
      farmData?.waterFromSellerFarm?.[farmData?.waterFromSellerFarm?.length - 1]
        ?.subSellerStoreId1
    const subStoreId2 =
      farmData?.waterFromSellerFarm?.[farmData?.waterFromSellerFarm?.length - 1]
        ?.subSellerStoreId2
    const sellerStoreIdList = [mainStoreId, subStoreId1, subStoreId2]?.filter(
      (e) => e !== null || e !== undefined,
    )

    const result = await backendApis.getStoreByAdIdList(token, 'GET', {
      sellerStoreIdList,
    })
    if (result?.status === 200) {
      setSellerFarmList(result?.data)
    }
  }

  // const getSellerFarmList = async () => {
  //   // localStorage에 있던 'alfarmStore' 꺼냄
  //   const objString = window.localStorage.getItem('alfarmStore')
  //   const obj = JSON.parse(objString)

  //   // expire된 경우(4시간 지난 경우) localStorage에서 삭제
  //   if (objString) {
  //     if (new Date() > obj.expire) {
  //       window.localStorage.removeItem('alfarmStore')
  //     }
  //   }
  //   // localStorage에 있는 storeId에 해당하는 광고 정보 가져옴
  //   if (obj?.value?.[0]?._id) {
  //     const result = await backendApis.getStoreByLocalStorage(token, 'GET', {
  //       storeId: JSON.parse(window.localStorage.getItem('alfarmStore'))
  //         ?.value[0]?._id,
  //     })
  //     if (result?.data?.success) {
  //       setSellerFarmList(
  //         JSON.parse(window.localStorage.getItem('alfarmStore'))?.value,
  //       )
  //     } else {
  //       // 데이터 가져오기 실패한 경우 (광고 이미 ended된 경우) - 새 광고
  //       const result = await backendApis.getSellerFarmList(token)
  //       if (result?.status === 200) {
  //         setSellerFarmList(result?.data)
  //         const obj = {
  //           value: result?.data,
  //           expire: new Date().getTime() + 1000 * 60 * 60 * 4,
  //         }
  //         const objString = JSON.stringify(obj)
  //         //localStorage에 새로 가져온 광고 정보 저장
  //         window.localStorage.setItem('alfarmStore', objString)
  //       }
  //     }
  //   } else {
  //     // localStorage에 없는 경우 새로운 광고 가져옴
  //     const result = await backendApis.getSellerFarmList(token)
  //     if (result?.status === 200) {
  //       setSellerFarmList(result?.data)
  //       const obj = {
  //         value: result?.data,
  //         expire: new Date().getTime() + 1000 * 60 * 60 * 4,
  //       }
  //       const objString = JSON.stringify(obj)
  //       window.localStorage.setItem('alfarmStore', objString)
  //     }
  //   }
  // }

  //테스트용 - 닫아둬야함
  // useEffect(() => {
  //   window.localStorage.removeItem('alfarmStore')
  // }, [])

  const getSellerFarmListForTripleExposure = async () => {
    // localStorage에 있던 'alfarmStore' 꺼냄
    const objString = window.localStorage.getItem('alfarmStore')
    const obj = JSON.parse(objString)

    // expire된 경우(4시간 지난 경우) localStorage에서 삭제
    if (objString) {
      if (new Date() > obj.expire) {
        window.localStorage.removeItem('alfarmStore')
      }
    }

    // localStorage에 있는 storeId에 해당하는 광고 정보 살아있는지 확인
    if (obj?.value?.[0]?._id) {
      const result = await backendApis.getStoreByLocalStorage(token, 'GET', {
        storeId: JSON.parse(window.localStorage.getItem('alfarmStore'))
          ?.value?.[0]?._id,
      })
      if (result?.data?.success) {
        setSellerFarmList(
          JSON.parse(window.localStorage.getItem('alfarmStore'))?.value,
        )
      } else {
        // 데이터 가져오기 실패한 경우 (광고 이미 ended된 경우) - 새 광고
        const result = await backendApis.getSellerFarmListForTripleExposure(
          token,
        )
        if (result?.status === 200) {
          setSellerFarmList(result?.data)
          const obj = {
            value: result?.data,
            expire: new Date().getTime() + 1000 * 60 * 60 * 4,
          }
          const objString = JSON.stringify(obj)
          //localStorage에 새로 가져온 광고 정보 저장
          window.localStorage.setItem('alfarmStore', objString)
        }
      }
    } else {
      // localStorage에 없는 경우 새로운 광고 가져옴
      const result = await backendApis.getSellerFarmListForTripleExposure(token)
      if (result?.status === 200) {
        setSellerFarmList(result?.data)
        const obj = {
          value: result?.data,
          expire: new Date().getTime() + 1000 * 60 * 60 * 4,
        }
        const objString = JSON.stringify(obj)
        window.localStorage.setItem('alfarmStore', objString)
      }
    }
  }

  async function processRelayReward(result) {
    if (result?.data?.relayRewardOn) {
      let relayRewardData = await backendApis.getSpecificOngoingAlfarmInfo({
        fieldsProjected: JSON.stringify({ relayRewardInfo: 1 }),
      })
      setRelayRewardInfo(relayRewardData?.data?.relayRewardInfo)
      setTimeout(() => {
        ModalStore.setIsModalOpen('relayReward')
        setRelayRewardIconOn(true)
      }, 1200)
    }
  }

  async function processTimeCapsulBonus(result) {
    if (result?.data?.timeCapsuleBonus && giveWaterCount >= 10) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('timeCapsuleBonus')
        setFarmData((prev) => {
          setTimeCapsuleReceived(false)
          prev.timeCapsuleRewardAvailable = true
          return prev
        })
      }, 1200)
    }
  }

  function leftScrollButtonHandler(result) {
    if (result?.data?.scrollButtonResult) {
      UserStore?.setFarmData({
        ...UserStore?.farmData,
        buttonManager: {
          ...UserStore?.farmData?.buttonManager,
          [`${result?.data?.scrollButtonResult}`]: new Date(),
        },
      })
      setTimeout(() => {
        if (result?.data?.scrollButtonResult !== 'friendGarden') {
          const Button = document.getElementById(
            result?.data?.scrollButtonResult,
          )
          Button?.click()
        }
        // vipCard는 까만화면만 나옴 왜??
      }, 1200)
    }
  }

  function engagementNutrimentPopUp() {
    if (
      !UserStore?.farmData?.engagementNutrimentInfo?.didReceive &&
      UserStore?.farmData?.resurrectBonus
    ) {
      // 하루 3번 띄우기
      setTimeout(() => {
        ModalStore.setIsModalOpen('engagementNutriment')
      }, 1200)
    }
  }

  function comebackUserModalPopUp() {
    if (
      TimeStore?.currentMoment?.isSameOrBefore(
        moment(
          UserStore?.farmData?.resurrectInfo?.createdAt ?? '2023-01-01',
        ).add(10, 'd'),
      ) &&
      UserStore?.comebackUserCanCheckIn &&
      Math.random() < 0.3
    ) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('comebackUser')
      }, 1200)
    }
  }

  function churnedGganbuBounty(result) {
    if (
      result?.data?.churnedGganbuBounty &&
      result?.data?.targetChurnedGganbu
    ) {
      // 해당 깐부만 적용되어야함
      // 시간제한이 있어야함
      // 한번 적용되면 중복이 안되어야함
      setTimeout(() => {
        ModalStore.setIsModalOpen('churnedGganbuBounty')
      }, 1200)
      UserStore?.set('farmData.gganbuBountyInfo', {
        bountyPopAt: TimeStore?.currentNewDate,
        gganbu: result?.data?.targetChurnedGganbu,
      })
    }
  }

  function changePlantBeforeChurnAppearTrigger() {
    // 부활유저임?
    // 부활유저 이후로 한번도 모달 안켰음?
    let storedData = localStorage.getItem('alfarm_giveWaterCount')
    let count = 0
    let newDateFormatted = TimeStore?.currentMoment?.format('YYYY-MM-DD')
    if (storedData) {
      let parsedData = JSON.parse(storedData)

      if (parsedData.date === newDateFormatted) {
        count = parsedData.count
      }
    }

    let forcePopAt = window.localStorage.getItem('changePlantBeforeChurnPopAt')
    const newRessurect = TimeStore?.currentMoment.isAfter(
      moment(forcePopAt ?? '2023-01-01').add(14, 'd'),
    )

    if (
      newRessurect &&
      count === 8 &&
      UserStore?.farmData?.resurrectBonus &&
      TimeStore?.currentMoment?.isSameOrBefore(
        moment(
          new Date(
            new Date(
              farmData?.resurrectInfo?.createdAt ?? '2023-01-01',
            ).getTime() +
              1000 * 60 * 60 * 24 * 2,
          ),
        ),
      ) &&
      TimeStore?.currentMoment.isSameOrAfter(
        moment(farmData?.changedPlantBeforeChurnAt ?? '2023-01-01').add(4, 'd'),
      )
    ) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('changePlantBeforeChurn')
      }, 1200)
      window.localStorage.setItem(
        'changePlantBeforeChurnPopAt',
        TimeStore?.currentNewDate,
      )
    }
  }

  async function giveWaterPopUpEventHandler(result) {
    changePlantBeforeChurnAppearTrigger()
    churnedGganbuBounty(result)
    // engagementNutrimentPopUp()
    leftScrollButtonHandler(result)
    processSecretScratch(result)

    processSummerRevenueDeal(result)
    processDaisoRevenueDeal(result)
    processTimeCapsulBonus(result)
    waterToFertilizer(result)
    // waterOneOneBattle(result)
    produceFertMachine(result)
    fertilizerRoulette(result)
    PurchaseRoulette(result)
    PersonalizedShop(result)
    SelectOnePurchase(result)
    AllBrandDealPurchase(result)
    LackWaterDailyInvite(result)
    LackNutrimentPopUp(result)
    YesterdayGiveWaterInit(result)
    cherryPickerCoupon(result)
    fertilizerTowerGame(result)
    // feverModeGame(result)
    newUserEventWater()
    newUserValueIndicate(result) // 신규유저 가치 인지
  }

  async function newUserEventWater() {
    if (farmData?.isNewUser && farmData?.giveWaterCount === 25) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('newUserEventWater')
      }, 1200)
    }
  }

  async function newUserValueIndicate(result) {
    if (
      result?.data?.newUserValueIndicate &&
      (!UserStore?.farmData?.newUserValueIndicateInfo ||
        UserStore?.farmData?.newUserValueIndicateInfo?.step <= 4)
    ) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('newUserValueIndicate')
      }, 1200)
    }
  }

  async function cherryPickerCoupon(result) {
    if (result?.data?.cherryPickerCoupon) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('cherryPickerRoulette')
      }, 1200)
    }
  }

  async function processSecretScratch(result) {
    if (!codePushVersionAvailable(userCodePushVersion, '1.6.13')) {
      return
    }
    if (result?.data?.didSecretScratchWon) {
      let secretScratchHistoryResult =
        await backendApis.getAlFarmSecretScratchHistory(token)
      if (secretScratchHistoryResult?.status === 200) {
        let tempHistory =
          secretScratchHistoryResult?.data?.secretScratchHistory?.reverse()

        setSecretScratchHistory(tempHistory)
        setTimeout(() => {
          secretScratchTime(tempHistory)
          setOpenSecretScratchModal(true)
        }, 1200)
      }
    }
  }

  async function processSummerRevenueDeal(result) {
    if (!codePushVersionAvailable(userCodePushVersion, '4.3.49')) {
      return
    }
    if (result?.data?.summerRevenueDeal) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('summerRevenueDeal')
        summerRevenueDealTime(new Date())
        // setWinterRevenueDealIconOn(true);
        // UserStore.setFarmData({
        //   ...UserStore?.farmData,
        //   winterRevenueDealAppearedAt: new Date(),
        // });
      }, 1200)
    }
  }

  async function processDaisoRevenueDeal(result) {
    if (!codePushVersionAvailable(userCodePushVersion, '4.3.49')) {
      return
    }
    if (result?.data?.daisoRevenueDeal) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('daisoRevenueDeal')
        daisoRevenueDealTime(new Date())
        // setWinterRevenueDealIconOn(true);
        // UserStore.setFarmData({
        //   ...UserStore?.farmData,
        //   winterRevenueDealAppearedAt: new Date(),
        // });
      }, 1200)
    }
  }

  async function waterToFertilizer(result) {
    if (result?.data?.canChangeWaterToFertilizer) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('waterToFertilizer')
      }, 1200)
    }
  }

  async function waterOneOneBattle(result) {
    if (result?.data?.waterOneOneBattle) {
      waterOneOneBattleData()
      setTimeout(() => {
        ModalStore.setIsModalOpen('waterOneOneBattle')
      }, 1200)
    }
  }

  async function fertilizerTowerGame(result) {
    if (result?.data?.fertilizerTowerGameOn) {
      setFarmData((prev) => {
        let temp = { ...prev?.fertilizerTowerGameData }
        temp.fertilizerTowerGameOnAt =
          result?.data?.fertilizerTowerGameData?.fertilizerTowerGameOnAt
        temp.rewardCount = result?.data?.fertilizerTowerGameData?.rewardCount
        temp.tutorialOn = result?.data?.fertilizerTowerGameData?.tutorialOn
        prev.fertilizerTowerGameData = { ...temp }
        return prev
      })

      setTimeout(() => {
        ModalStore.setIsModalOpen('fertilizerTowerGame')
        setFertilizerTowerGameOn(true)
      }, 1200)
    }
  }

  async function feverModeGame(result) {
    if (result?.data?.feverModeGameOn) {
      setFarmData((prev) => {
        let temp = { ...prev?.feverModeGameData }
        temp.feverModeGameOnAt =
          result?.data?.feverModeGameData?.feverModeGameOnAt
        prev.feverModeGameData = { ...temp }
        return prev
      })

      setTimeout(() => {
        ModalStore.setIsModalOpen('feverModeModal')
        setFeverModeGameOn(true)
      }, 1200)
    }
  }
  // setFeverModalGameOn

  async function PurchaseRoulette(result) {
    if (result?.data?.purchaseRouletteOn) {
      let purchaseRouletteData = await backendApis.getSpecificOngoingAlfarmInfo(
        {
          fieldsProjected: JSON.stringify({ purchaseRouletteInfo: 1 }),
        },
      )
      setPurchaseRouletteData(purchaseRouletteData?.data?.purchaseRouletteInfo)
      setTimeout(() => {
        ModalStore.setIsModalOpen('purchaseRoulette')
        setPurchaseRouletteButtonOn(true)
      }, 1200)
    }
  }

  async function PersonalizedShop(result) {
    if (result?.data?.personalizedShop) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('personalizedShop')
      }, 1200)
    }
  }

  async function SelectOnePurchase(result) {
    if (result?.data?.selectOnePurchase) {
      let selectOnePurchaseData =
        await backendApis.getSpecificOngoingAlfarmInfo({
          fieldsProjected: JSON.stringify({ selectOnePurchaseInfo: 1 }),
        })
      setSelectOnePurchaseData(
        selectOnePurchaseData?.data?.selectOnePurchaseInfo,
      )
      setTimeout(() => {
        ModalStore.setIsModalOpen('selectOnePurchaseModal')
        setSelectOnePurchaseButtonOn(true)
      }, 1200)
    }
  }

  async function AllBrandDealPurchase(result) {
    if (result?.data?.allBrandDealPurchase) {
      let allBrandDealPurchaseData =
        await backendApis.getSpecificOngoingAlfarmInfo({
          fieldsProjected: JSON.stringify({ allBrandDealInfo: 1 }),
        })
      setAllBrandDealPurchaseData(
        allBrandDealPurchaseData?.data?.allBrandDealInfo,
      )
      setTimeout(() => {
        ModalStore.setIsModalOpen('allBrandDealPurchaseModal')
        setAllBrandDealPurchaseButtonOn(true)
      }, 1200)
    }
  }

  async function LackNutrimentPopUp(result) {
    if (giveWaterCount <= 300) return
    if (result?.data?.nutriment - 3 <= 1) {
      if (lastLackNutrimentPopUpAt) {
        if (
          TimeStore.currentDayJS.isSameOrAfter(
            dayjs(lastLackNutrimentPopUpAt).add(6, 'h'),
          )
        ) {
          window.localStorage.setItem(
            'lastLackNutrimentPopUpAt',
            JSON.stringify(new Date()),
          )
          setLastLackNutrimentPopUpAt(new Date())
          let flag = Math.random()
          if (flag < 0.5) {
            setLackWaterEngageType('vipCard_nutriment')
          } else {
            setLackWaterEngageType('friendGarden_nutriment')
          }

          setTimeout(() => {
            ModalStore.setIsModalOpen('lackWaterEngageModal')
          }, 1200)
        }
      } else {
        window.localStorage.setItem(
          'lastLackNutrimentPopUpAt',
          JSON.stringify(new Date()),
        )
        setLastLackNutrimentPopUpAt(new Date())
        let flag = Math.random()
        if (flag < 0.5) {
          setLackWaterEngageType('vipCard_nutriment')
        } else {
          setLackWaterEngageType('friendGarden_nutriment')
        }

        setTimeout(() => {
          ModalStore.setIsModalOpen('lackWaterEngageModal')
        }, 1200)
      }
    }
  }

  async function LackWaterDailyInvite(result) {
    if (result?.data?.lackWaterDailyInvite) {
      setEngageModalCount(engageModalCount + 1)
      if (engageModalCount < 1) {
        setLackWaterEngageType('dailyInvite')
        setTimeout(() => {
          ModalStore.setIsModalOpen('lackWaterEngageModal')
        }, 1200)
      }
    }
  }

  async function YesterdayGiveWaterInit(result) {
    if (result?.data?.yesterdayGiveWaterModal) {
      setTimeout(() => {
        ModalStore.setIsModalOpen('yesterdayGiveWaterModal')
      }, 1200)
    }
  }

  async function fertilizerRoulette(result) {
    if (result?.data?.fertilizerRouletteOn) {
      setFarmData((prev) => {
        let temp = { ...prev?.fertilizerRouletteData }
        temp.fertilizerRouletteOnAt =
          result?.data?.fertilizerRouletteData?.fertilizerRouletteOnAt
        temp.rewardCount = result?.data?.fertilizerRouletteData?.rewardCount
        temp.rewardNum = result?.data?.fertilizerRouletteData?.rewardNum
        prev.fertilizerRouletteData = { ...temp }
        return prev
      })

      setTimeout(() => {
        ModalStore.setIsModalOpen('fertilizerRoulette')
        setFertilizerRouletteOn(true)
      }, 1200)
    }
  }

  async function produceFertMachine(result) {
    if (result?.data?.produceFertMachine) {
      let produceFertMachineHistoryResult =
        await backendApis.getAlFarmProduceFertMachineHistory(token)
      if (produceFertMachineHistoryResult?.status === 200) {
        let tempHistory =
          produceFertMachineHistoryResult?.data?.produceFertMachineHistory?.reverse()
        setProduceFertMachineHistory(tempHistory)
        setTimeout(() => {
          ModalStore.setIsModalOpen('produceFertMachine')
        }, 1200)
        setProducingFertMachine(true)
      }
    }
    if (produceFertMachineHistory) {
      if (new Date(produceFertMachineHistory?.[0]?.endsAt) >= new Date()) {
        const giveWaterForMachine =
          await backendApis.waterForProduceFertMachine(token, 'POST', {
            giveCount: result?.data?.giveCount,
          })

        let tmpProduceFertMachine = { ...produceFertMachineHistory }
        tmpProduceFertMachine[0].waterCount +=
          giveWaterForMachine?.data?.giveCount

        setProduceFertMachineHistory(tmpProduceFertMachine)
      }
      // }
    }
  }

  async function battlingGiveWaterBasket() {
    const result = await backendApis.battlingGiveWaterBasket(token)
    if (result?.data?.success) {
      setBattleWaterAmount(battleWaterAmount + 1)
    }
    // setOpponentBattleWaterCount(opponentBattleWaterCount + result?.data);
  }

  async function newBattlingGiveWaterBasket(giveWaterCount) {
    const result = await backendApis.newBattlingGiveWaterBasket(token, 'POST', {
      giveWaterCount,
    })
    if (result?.data?.success) {
      setBattleWaterAmount(battleWaterAmount + giveWaterCount)
    }

    // setOpponentBattleWaterCount(opponentBattleWaterCount + result?.data);
  }

  // 맞팜 친구 정보를 업데이트
  useEffect(() => {
    if (!farmData?.userId) return
    if (new Date() - new Date(farmData?.gganbuReloadAt) <= 1000 * 60 * 60 * 24)
      return
    if (farmData?.gganbuList?.length === 0) return
    if (!ABStore?.gganbuListReloadTest) return

    const reloadGganbuData = async () => {
      await backendApis.reloadGganbuData()
    }
    reloadGganbuData()
  }, [farmData?.userId])

  useEffect(() => {
    const handleRewardedAd = async () => {
      if (
        UserStore.isWatchingAd === true &&
        farmData?.userId !== '626aa6a6c4d18f4110ecd6cb'
      ) {
        // 로그 조회해서 어떤 미션한건지 확인해야하는데?
        await sleep(700)
        const data = await backendApis.getInitModal()
        if (data?.initModalList) {
          InitModalStore.setIsInitModal(data?.initModalList)
        }
        updatelWater()
      }
    }
    handleRewardedAd()
  }, [UserStore.isWatchingAd])

  return (
    <div className='w-full h-full'>
      <div
        className={`
        ${isLoading ? 'opacity-0' : 'opacity-1'} 
        transition-all w-full h-full`}
      >
        <Layout isLoading={isLoading}>
          <div className='flex flex-row justify-between items-center pt-[10vw]'>
            <button
              className='w-[7vw] ml-[2vw] z-10'
              action='goBack'
              onClick={() => {
                window.location.href = '#closeGame'
              }}
            >
              <img src='/icon/Back.png' alt='' />
            </button>
            <button
              className='w-[10vw]'
              onClick={() => {
                BottomSheetStore.setIsbottomSheetOpen('feed')
              }}
            >
              <img src='/feed/feed.png' alt='' />
            </button>
            {hasUnreadAnnouncement && giveWaterCount > 100 && (
              <div
                style={{
                  borderRadius: 99,
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  marginTop: 8,
                  left: '50%',
                  position: 'absolute',
                  transform: 'translate(-50%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2vw 3vw 2vw 3vw',
                  transition: '',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('announce')
                  setUserAnnouncementNumber()
                }}
              >
                <div style={{ fontFamily: 'maplestory', fontSize: '4vw' }}>
                  새로운 공지가 있어요!
                </div>
                <div
                  style={{
                    right: '-9vw',
                    position: 'absolute',
                    width: '8vw',
                    height: '8vw',
                    backgroundImage: 'url(../announcement/entryIcon.png)',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                  className={'wobble-ver-left'}
                />
              </div>
            )}

            {farmData?.giveWaterCount >= 100 &&
              !hasUnreadAnnouncement &&
              TimeStore?.currentMoment?.isSameOrBefore(moment('2024-03-31')) &&
              ABStore.doubleExpEventTester && (
                <div
                  className='rounded-full bg-black bg-opacity-50 mt-[2vw] left-1/2 absolute transform -translate-x-1/2 flex justify-center items-center pt-[2vw] pl-[3vw] pr-[3vw] pb-[2vw] w-[70vw]'
                  onClick={() => {
                    ModalStore.setIsModalOpen('doubleExpEvent')
                  }}
                >
                  <img src='/doubleExpEventModal/entryIcon.png' alt='' />
                </div>
              )}
          </div>

          {newYear2025EventEntryOn && giveWaterCount >= 100 && (
            <button
              onClick={() => {
                navigate('/dealEventSecond')
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: '19vw',
                top: '23vw',
                width: '19vw',
              }}
            >
              <img
                src={`/dealEvent/newYear2025/entryIcon.png`}
                alt='newYear2025'
              />
            </button>
          )}

          {/* {파랑새가 복권긁기 물어오기} */}
          {shouldShowBluebird && (
            <button
              className='absolute left-[41vw] top-[35vh] w-[18vw] z-[1]'
              onClick={() => {
                ModalStore.setIsModalOpen('scratchLottoBluebird')
              }}
            >
              <LottieGif
                animationData={bluebird}
                style={{ width: '100%' }}
                loop
              />
            </button>
          )}

          {/*이 코드가 좌상단에 있던 우주최저가 코드임*/}
          {trendQuizOn && giveWaterCount >= 500 && (
            <button
              onClick={() => {
                ModalStore.setIsModalOpen('trendQuiz')
              }}
              className={`flex flex-col justify-center items-center absolute left-[20vw] ${
                newYear2025EventEntryOn ? 'top-[44vw]' : 'top-[24vw]'
              } w-[18vw]`}
            >
              <img
                src={
                  DealAutomationStore?.firstTrendQuizData?.entryIcon ||
                  `/icon/leftScrollImage/trendQuizv3.png`
                }
                alt=''
              />
              {timeChecker(farmData?.lastTrendQuizSolvedAt) && (
                <div className='w-[12vw] right-[-2vw] top-[-2.5vw] absolute wobble-ver-left'>
                  <img src={`/icon/newPing.png`} alt='' />
                </div>
              )}
            </button>
          )}

          {/* tenMinDeal 10분핫딜 좌상단 */}
          {giveWaterCount >= 50 &&
            ABv2(farmData?.userId, 'tenMin1110', [95]) === 'a' &&
            DealAutomationStore.isTenMinDealOpen() &&
            codePushVersionAvailable(userCodePushVersion, '4.3.14') && (
              <button
                onClick={() => {
                  ModalStore.setIsModalOpen('tenMinDealModal')
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  left: '19vw',
                  top: newYear2025EventEntryOn ? '60vw' : '40vw',
                  width: '20vw',
                }}
              >
                <img src={`/icon/tenMinDealGIF_text2.gif`} alt='tenMin' />
              </button>
            )}

          {farmData?.timeCapsuleRewardAvailable && (
            <div
              className='w-[20vw] h-[20vw] right-[10%] bottom-[37%] absolute transform translate-x-[-50%] translate-y-[-50%] z-[2] bg-cover bg-no-repeat bg-center '
              style={{
                backgroundImage: 'url(../timeCapsuleBonus/entryIcon.png)',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('timeCapsuleBonus')
              }}
            />
          )}

          {/* 좌상단 아이콘 영역 */}

          {/* 내일 얻는 물 */}
          <div className='absolute top-[24vw] right-[42vw] flex flex-col z-[102] color-[#bbb]'>
            {giveWaterCount >= 3 && !canGetDailyGiveWater && (
              <TomorrowGetWaterButton
                farmData={farmData}
                setFarmData={setFarmData}
                action='dailyGiveWater'
                token={token}
                onClick={() => {
                  if (giveWaterCount > 8 && !canGetDailyGiveWater) {
                    // ModalStore.setIsTomorrowEngagingWaterModal(true);
                    ModalStore.setIsModalOpen('tomorrowEngagingWaterModal')
                  }
                }}
                level1={dailyGiveWaterLevelOne}
                level2={dailyGiveWaterLevelTwo}
                level3={dailyGiveWaterLevelThree}
                level4={dailyGiveWaterLevelFour}
              />
            )}
            {!canGetDailyGiveWater &&
              farmData?.resurrectBonus &&
              farmData?.dailyGiveWater < dailyGiveWaterLevelFour && (
                <div className='absolute top-[-2vw] right-[-16vw] bg-[#FFF9DA] border-[1.5px] border-[#FFE761] rounded-[4px] text-center flex justify-center items-center pt-[1vw] pb-[2vw] w-[20vw] font-bold z-[-10] text-[#AB5A5A] text-[3vw]'>
                  2배 이벤트!
                </div>
              )}
          </div>
          {/* 오늘 얻는 물 */}
          <div className='absolute top-[24vw] right-[20vw] flex flex-col z-[102] color-[#bbb]'>
            {giveWaterCount >= 3 && canGetDailyGiveWater && (
              <DailyGiveWaterGetButton
                farmData={farmData}
                yesterdayGiveWater={yesterdayGiveWater}
                removedWater={removedWater}
                setFarmData={setFarmData}
                token={token}
                onClick={() => {
                  if (giveWaterCount > 8 && canGetDailyGiveWater) {
                    setTimeout(() => {
                      ModalStore.setIsModalOpen('yesterdayGiveWaterModal')
                    }, 300)
                  }
                }}
              />
            )}
          </div>
          {UserStore?.farmData?.resurrectBonus && (
            <div className='absolute top-[42vw] right-[20vw] flex flex-col z-[102] color-[#bbb]'>
              <div
                onClick={() => {
                  ModalStore?.setIsModalOpen('resurrectD1Nudge')
                }}
              >
                <img
                  className='w-[17vw] h-[17vw]'
                  alt='entypoint'
                  src='../resurrectBonus/d1Nudge.png'
                />
              </div>
              {UserStore?.farmData?.resurrectBonusD1NudgeInfo
                ?.receivedRewardAt !==
                TimeStore?.currentDayJS.format('YYYY-MM-DD') && (
                <img
                  className='w-[9vw] h-[9vw] absolute -right-[2vw] -top-[2vw]'
                  alt='ping'
                  src={`../icon/!.png`}
                />
              )}
            </div>
          )}
          <div className='absolute top-[16vw] left-[2vw] flex flex-col z-[102] color-[#bbb] masked-overflow overflow-y-scroll overflow-x-hidden'>
            <LeftScrollSection
              isNewUser={isNewUser}
              giveWaterCount={giveWaterCount}
              newUserEvent={newUserEvent}
              farmData={farmData}
              canGetSevendaysEventNotification={
                canGetSevendaysEventNotification
              }
              questLogForGetWaterDailyAttendance={
                questLogForGetWaterDailyAttendance
              }
              canGetDailyAttendanceWaterNotification={
                canGetDailyAttendanceWaterNotification
              }
              purchaseHistoryWithinPeriod={purchaseHistoryWithinPeriod}
              codePushVersionAvailable={codePushVersionAvailable}
              userCodePushVersion={userCodePushVersion}
              lastFriendGardenUse={lastFriendGardenUse}
              lastRouletteUse={lastRouletteUse}
              level={level}
              isHarvestInviteAvailable={isHarvestInviteAvailable}
              useIconVisibility={useIconVisibility}
              token={token}
              itemType={itemType}
              navigate={navigate}
            />
          </div>
          {/* 오른쪽 타이머 버튼 */}
          <div className='h-[40vh] absolute top-[20vw] right-[2vw] flex flex-col z-[102] color-[#bbb] masked-overflow overflow-y-scroll overflow-x-hidden'>
            {UserStore?.farmData?.resurrectBonus &&
              TimeStore?.currentMoment?.isSameOrBefore(
                moment(
                  new Date(
                    new Date(
                      farmData?.resurrectInfo?.createdAt ?? '2023-01-01',
                    ).getTime() +
                      1000 * 60 * 60 * 24 * 2,
                  ),
                ),
              ) &&
              TimeStore?.currentMoment.isSameOrAfter(
                moment(farmData?.changedPlantBeforeChurnAt ?? '2023-01-01').add(
                  4,
                  'd',
                ),
              ) && (
                <PurchaseScreenTimerButton
                  imageType='changePlantBeforeChurn'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('changePlantBeforeChurn')
                  }}
                  onTimeEnd={() => {}}
                  timeStamp={
                    new Date(
                      new Date(
                        farmData?.resurrectInfo?.createdAt ?? '2023-01-01',
                      ).getTime() +
                        1000 * 60 * 60 * 24 * 2,
                    )
                  }
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 100 &&
              TimeStore?.currentMoment?.isSameOrBefore(
                moment(
                  UserStore?.farmData?.gganbuBountyInfo?.bountyPopAt ??
                    '2023-01-01',
                ).add(1, 'd'),
              ) &&
              UserStore?.farmData?.gganbuBountyInfo?.gganbu && (
                <PurchaseScreenTimerButton
                  imageType='gganbuBounty'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('churnedGganbuBounty', {
                      gganbu: UserStore?.farmData?.gganbuBountyInfo?.gganbu,
                    })
                  }}
                  onTimeEnd={() => {
                    UserStore?.set('farmData.gganbuBountyInfo', {
                      ...UserStore?.farmData?.gganbuBountyInfo,
                      bountyPopAt: moment(
                        UserStore?.farmData?.gganbuBountyInfo?.bountyPopAt,
                      )
                        .subtract(2, 'd')
                        .toDate(),
                    })
                    backendApis.updatelastUpdatedAt(token, 'PUT', {
                      updateType: 'gganbuBountyInfo.bountyPopAt',
                    })
                    backendApis.updateSpecificField({
                      fieldName: 'gganbuBountyInfo.gganbu',
                      value: null,
                    })
                  }}
                  timeStamp={moment(
                    UserStore?.farmData?.gganbuBountyInfo?.bountyPopAt,
                  )
                    .add(1, 'd')
                    .toDate()}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 50 &&
              dailyGetWaterUpgradeButton &&
              timerWaterUpTracking === 'b' && (
                <PurchaseScreenTimerButton
                  imageType='dailyGetWaterUpgrade'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('timerWaterPurchaseUpgrade')
                  }}
                  onTimeEnd={() => {
                    setDailyGetWaterUpgradeButton(false)
                  }}
                  timeStamp={
                    new Date(
                      DealAutomationStore?.firstWaterFountainAdData?.timeRangeEnd,
                    )
                  }
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {UserStore?.farmData?.goldFilledAppearedAt &&
              ((codePushVersionAvailable(userCodePushVersion, '4.2.5') &&
                !codePushVersionAvailable(userCodePushVersion, '4.3.0')) ||
                codePushVersionAvailable(userCodePushVersion, '4.3.1')) &&
              giveWaterCount >= 50 &&
              dayjs().isBefore(
                dayjs(
                  UserStore?.farmData?.goldFilledAppearedAt ?? '2023-01-01',
                ).add(3, 'h'),
              ) && (
                <PurchaseScreenTimerButton
                  imageType='goldFilled'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('goldFilled')
                  }}
                  onTimeEnd={() => {
                    UserStore.setFarmData({
                      ...UserStore?.farmData,
                      goldFilledAppearedAt: dayjs().subtract(4, 'h').toDate(),
                    })
                  }}
                  timeStamp={dayjs(
                    UserStore?.farmData?.goldFilledAppearedAt ?? '2023-01-01',
                  )
                    .add(3, 'h')
                    .toDate()}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {UserStore?.farmData?.jewelryRevenueDealAppearedAt &&
              ((codePushVersionAvailable(userCodePushVersion, '4.2.5') &&
                !codePushVersionAvailable(userCodePushVersion, '4.3.0')) ||
                codePushVersionAvailable(userCodePushVersion, '4.3.1')) &&
              giveWaterCount >= 50 &&
              dayjs().isBefore(
                dayjs(
                  UserStore?.farmData?.jewelryRevenueDealAppearedAt ??
                    '2023-01-01',
                ).add(3, 'h'),
              ) && (
                <PurchaseScreenTimerButton
                  imageType='jewelryRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('jewelryRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    UserStore.setFarmData({
                      ...UserStore?.farmData,
                      jewelryRevenueDealAppearedAt: dayjs()
                        .subtract(4, 'h')
                        .toDate(),
                    })
                  }}
                  timeStamp={dayjs(
                    UserStore?.farmData?.jewelryRevenueDealAppearedAt ??
                      '2023-01-01',
                  )
                    .add(3, 'h')
                    .toDate()}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {kidsRevenueDealIconOn &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              giveWaterCount >= 50 && (
                <PurchaseScreenTimerButton
                  imageType='kidsRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('kidsRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setKidsRevenueDealIconOn(false)
                    // UserStore.setFarmData({
                    //   ...UserStore?.farmData,
                    //   kidsRevenueDealAppearedAt: dayjs()
                    //     .subtract(4, "h")
                    //     .toDate(),
                    // });
                  }}
                  timeStamp={new Date(kidsRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {winterRevenueDealIconOn &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              giveWaterCount >= 50 && (
                <PurchaseScreenTimerButton
                  imageType='winterRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('winterRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setWinterRevenueDealIconOn(false)
                    // UserStore.setFarmData({
                    //   ...UserStore?.farmData,
                    //   winterRevenueDealAppearedAt: dayjs()
                    //     .subtract(4, "h")
                    //     .toDate(),
                    // });
                  }}
                  timeStamp={new Date(winterRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {snackRevenueDealIconOn &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              giveWaterCount >= 50 && (
                <PurchaseScreenTimerButton
                  imageType='snackRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('snackRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setSnackRevenueDealIconOn(false)
                  }}
                  timeStamp={new Date(snackRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 50 &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              daisoRevenueDealIconOn && (
                <PurchaseScreenTimerButton
                  imageType='daisoRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('daisoRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setDaisoRevenueDealIconOn(false)
                    // UserStore.setFarmData({
                  }}
                  timeStamp={new Date(daisoRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 50 &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              healthRevenueDealIconOn && (
                <PurchaseScreenTimerButton
                  imageType='healthRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('healthRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setHealthRevenueDealIconOn(false)
                  }}
                  timeStamp={new Date(healthRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 50 &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              goldAccesorryRevenueDealIconOn && (
                <PurchaseScreenTimerButton
                  imageType='jewelryRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('goldAccesorryRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setGoldAccesorryRevenueDealIconOn(false)
                  }}
                  timeStamp={new Date(goldAccesorryRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 50 &&
              codePushVersionAvailable(userCodePushVersion, '4.3.49') &&
              summerRevenueDealIconOn && (
                <PurchaseScreenTimerButton
                  imageType='summerRevenueDeal'
                  onClick={() => {
                    ModalStore?.setIsModalOpen('summerRevenueDeal')
                  }}
                  onTimeEnd={() => {
                    setSummerRevenueDealIconOn(false)
                  }}
                  timeStamp={new Date(summerRevenueDealEndTime)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount > 10 &&
              codePushVersionAvailable(userCodePushVersion, '2.4.19') &&
              waterBrowsingAdQuestOn && (
                <PurchaseScreenTimerButton
                  imageType='waterBrowsingAdQuest'
                  onClick={async () => {
                    let waterBrowsingAdItem = JSON.parse(
                      window.localStorage.getItem('waterBrowsingAdItem'),
                    )
                    const adItemBrowsing = {
                      text: '올팜',

                      isTimerOn: false,
                      isBannerOnly: true,
                      timerText: `초간 구경하면 물 20g을 받아요`,
                      isTimerEndText: '축하합니다! 물 20g을 받았어요',
                      itemId: waterBrowsingAdItem?.itemId,
                      enteringComponent: 'Alfarm_WaterBrowsingAdQuest',
                      source:
                        'https://assets.ilevit.com/08215223-2a7c-4934-9e54-5e68c0e5412c.png',
                      isAdItem: waterBrowsingAdItem?.isAdItem,
                      itemInfo: waterBrowsingAdItem,
                      payload: {
                        rewardAmount: 10,
                        rewardType: 'water',
                        loggingRewardName: 'waterBrowsingAdQuest',
                        lastUpdateName: 'lastWaterBrowsingAdQuestAt',
                      },
                    }

                    window.location.href = `#adItemBrowsing.${JSON.stringify(
                      adItemBrowsing,
                    )}`
                  }}
                  onTimeEnd={() => {}}
                  timeStamp={dayjs(farmData?.lastWaterBrowsingAdQuestAt)
                    .add(3, 'h')
                    .toDate()}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {surpriseMissionEntryIconOn && (
              <PurchaseScreenTimerButton
                imageType='surpriseMission'
                onClick={() => {
                  ModalStore.setIsModalOpen('surpriseMission')
                }}
                onTimeEnd={() => {
                  setSurpriseMissionEntryIconOn(false)
                }}
                timeStamp={dayjs(
                  JSON.parse(
                    window.localStorage.getItem('surpriseMissionOpenedAt'),
                  ),
                ).toDate()}
                timerMinutes={30}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {/* 팜린이 보물상자 */}
            {isTreasureBoxMissionTargetUser && (
              <PurchaseScreenTimerButton
                imageType='newbieTreasureBox'
                onClick={() => {
                  ModalStore.setIsModalOpen('treasureBox')
                }}
                onTimeEnd={() => {
                  setIsTreasureBoxMissionTargetUser(false)
                }}
                timeStamp={
                  farmData?.treasureBoxMissionStartedAt
                    ? new Date(farmData?.treasureBoxMissionStartedAt)
                    : new Date()
                }
                timerMinutes={60 * 24}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
                pingType={UserStore?.pingManager.treasureBox ? '!' : null}
              />
            )}
            {lastHarvestPurchaseButtonOn && (
              <PurchaseScreenTimerButton
                imageType='lastHarvestPurchase'
                onClick={() => {
                  ModalStore.setIsModalOpen('lastHarvestPurchase')
                }}
                onTimeEnd={() => {
                  setLastHarvestPurchaseButtonOn(false)
                }}
                timeStamp={
                  farmData?.lastHarvestPurchaseInfo
                    ? new Date(farmData?.lastHarvestPurchaseInfo?.createdAt)
                    : new Date()
                }
                timerMinutes={60 * 24 * 3}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {yutnoriPurchaseEntryButtonOn && (
              <PurchaseScreenTimerButton
                imageType='yutnori'
                onClick={() => {
                  ModalStore.setIsModalOpen('yutnori')
                }}
                onTimeEnd={() => {
                  setYutnoriPurchaseEntryButtonOn(false)
                }}
                timeStamp={
                  farmData?.lastYutnoriPlayedAt
                    ? new Date(farmData?.lastYutnoriPlayedAt)
                    : new Date()
                }
                timerMinutes={
                  60 * AlfarmAdStore.yutnoriTimeIntervalBetweenExposure
                }
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {quizPurchaseButtonOn && (
              <PurchaseScreenTimerButton
                imageType='quiz'
                onClick={() => {
                  ModalStore.setIsModalOpen('quizPurchase')
                }}
                onTimeEnd={() => {
                  setQuizPurchaseButtonOn(false)
                }}
                timeStamp={
                  farmData?.solvedQuizHistory
                    ? new Date(
                        farmData?.solvedQuizHistory[
                          farmData?.solvedQuizHistory?.length - 1
                        ]?.solvedAt,
                      )
                    : new Date()
                }
                timerMinutes={
                  60 * AlfarmAdStore.quizAdTimeIntervalBetweenExposure
                }
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {adItemBrowsingButtonOn && (
              <PurchaseScreenTimerButton
                imageType='adItemBrowsing'
                onClick={() => {
                  ModalStore.setIsModalOpen('adItemBrowsing')
                }}
                onTimeEnd={() => {
                  setAdItemBrowsingButtonOn(false)
                }}
                timeStamp={
                  farmData?.adItemBrowsingFertilizer
                    ? new Date(
                        farmData?.adItemBrowsingFertilizer[
                          farmData?.adItemBrowsingFertilizer?.length - 1
                        ]?.clearedAt,
                      )
                    : new Date()
                }
                timerMinutes={60 * 3}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {isSecretIconOn &&
              !secretScratchHistory?.[0]?.didPurchase &&
              codePushVersionAvailable(userCodePushVersion, '1.6.13') &&
              giveWaterCount >= 25 && (
                <PurchaseScreenTimerButton
                  imageType='secretScratch'
                  onClick={() => {
                    window.location.href = '#secretScratchReward'
                  }}
                  onTimeEnd={() => {
                    setIsSecretIconOn(false)
                  }}
                  timeStamp={new Date(lastScratch)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                />
              )}
            {showMissionHunterIcon &&
              missionHunterPurchaseInfo &&
              codePushVersionAvailable(userCodePushVersion, '1.6.71') &&
              giveWaterCount >= 25 && (
                <PurchaseScreenTimerButton
                  imageType='missionHunter'
                  onClick={() => {
                    ModalStore.setIsModalOpen('missionHunter')
                  }}
                  onTimeEnd={() => {
                    setShowMissionHunterIcon(false)
                  }}
                  timeStamp={new Date(missionHunterPurchaseInfo?.startedAt)}
                  timerMinutes={60 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                />
              )}
            {giveWaterCount >= 250 &&
              timeDealEntryIcon &&
              codePushVersionAvailable(userCodePushVersion, '2.3.26') && (
                <PurchaseScreenTimerButton
                  imageType='timeDealQuest'
                  onClick={() => {
                    const purchaseRewardWater = {
                      screen: 'AlFarmQuestLinkTimeDeal',
                      enteringComponent: 'Alfarm_timeDeal',
                      source:
                        'https://assets.ilevit.com/d8f6e8e0-3754-4093-9285-5001d6282a66.png',
                    }

                    window.location.href = `#timeDealPurchaseNew.${JSON.stringify(
                      purchaseRewardWater,
                    )}`
                  }}
                  onTimeEnd={() => {
                    setTimeDealEntryIcon(false)
                  }}
                  timeStamp={
                    new Date(
                      farmData?.questLogFroTimeDealCheckedAt[
                        farmData?.questLogFroTimeDealCheckedAt?.length - 1
                      ],
                    )
                  }
                  timerMinutes={60}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                />
              )}
            {feverModeGameOn && (
              <PurchaseScreenTimerButton
                imageType='feverMode'
                onClick={() => {
                  ModalStore.setIsModalOpen('feverModePurchaseModal')
                }}
                onTimeEnd={() => {
                  setFeverModeGameOn(false)
                }}
                timeStamp={
                  new Date(farmData?.feverModeGameData?.feverModeGameOnAt)
                }
                timerMinutes={60 * 1}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {fertilizerTowerGameOn && (
              <PurchaseScreenTimerButton
                imageType='fertilizerTowerGame'
                onClick={() => {
                  //  매대로 보내기

                  const sendToPurchaseScreen = () => {
                    const purchaseData = {
                      enteringComponent: `Alfarm_fertilizerTowerGame_${farmData?.fertilizerTowerGameData?.rewardCount}`,
                      text: '비료 쌓기',
                      source:
                        'https://assets.ilevit.com/7816e13b-2a7a-499a-a266-517dfba4dd07.png',
                      rewardAmount:
                        farmData?.fertilizerTowerGameData?.rewardCount,
                      rewardType: 'fertilizerSet',
                      rewardText: `상품을 구매하면 고급비료 ${farmData?.fertilizerTowerGameData?.rewardCount}개를 받아요`,
                    }

                    window.location.href = `#commonPurchaseReward.${JSON.stringify(
                      purchaseData,
                    )}`
                    ModalStore.setIsModalOpen('basic')
                  }

                  sendToPurchaseScreen()
                }}
                onTimeEnd={() => {
                  setFertilizerTowerGameOn(false)
                }}
                timeStamp={
                  new Date(
                    farmData?.fertilizerTowerGameData?.fertilizerTowerGameOnAt,
                  )
                }
                timerMinutes={60 * 3}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {personalizedShopButtonOn &&
              codePushVersionAvailable(userCodePushVersion, '2.3.36') && (
                <PurchaseScreenTimerButton
                  imageType='personalizedShop'
                  onClick={() => {
                    ModalStore.setIsModalOpen('personalizedShopRewardModal')
                  }}
                  onTimeEnd={() => {
                    setPersonalizedShopButtonOn(false)
                  }}
                  timeStamp={
                    farmData?.personalizedShopInfo?.createdAt
                      ? new Date(farmData?.personalizedShopInfo?.createdAt)
                      : new Date()
                  }
                  timerMinutes={60 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                />
              )}
            {/* 구매하기전 룰렛 */}
            {codePushVersionAvailable(userCodePushVersion, '2.3.26') &&
              purchaseRouletteButtonOn &&
              !farmData?.purchaseRouletteInfo?.purchase && (
                <PurchaseScreenTimerButton
                  imageType='purchaseRoulette'
                  onClick={() => {
                    ModalStore.setIsModalOpen('purchaseRoulette')
                  }}
                  onTimeEnd={() => {
                    setPurchaseRouletteButtonOn(false)
                  }}
                  timeStamp={new Date(purchaseRouletteData?.createdAt)}
                  timerMinutes={60 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {/* 구매 후 룰렛 */}
            {purchaseRouletteButtonOn &&
              farmData?.purchaseRouletteInfo?.purchase && (
                <PurchaseScreenTimerButton
                  imageType='purchaseRoulette'
                  onClick={() => {
                    ModalStore.setIsModalOpen('purchaseRoulette')
                  }}
                  onTimeEnd={() => {
                    setPurchaseRouletteButtonOn(false)
                  }}
                  timeStamp={
                    new Date(farmData?.purchaseRouletteInfo?.purchased)
                  }
                  timerMinutes={60 * 12}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {selectOnePurchaseButtonOn && (
              <PurchaseScreenTimerButton
                imageType='selectOnePurchase'
                onClick={() => {
                  ModalStore.setIsModalOpen('selectOnePurchaseModal')
                }}
                onTimeEnd={() => {
                  setSelectOnePurchaseButtonOn(false)
                }}
                timeStamp={new Date(selectOnePurchaseData?.createdAt)}
                timerMinutes={60 * 3}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {allBrandDealPurchaseButtonOn &&
              codePushVersionAvailable(userCodePushVersion, '2.3.58') && (
                <PurchaseScreenTimerButton
                  imageType='allBrandDealPurchase'
                  onClick={() => {
                    ModalStore.setIsModalOpen('allBrandDealPurchaseModal')
                  }}
                  onTimeEnd={() => {
                    setAllBrandDealPurchaseButtonOn(false)
                  }}
                  timeStamp={new Date(allBrandDealPurchaseData?.createdAt)}
                  timerMinutes={60 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                />
              )}
            {checkInPurchaseIconOn &&
              farmData?.questLogForAttendanceWaterFinalDate && (
                <PurchaseScreenTimerButton
                  imageType='checkinPurchase'
                  onClick={() => {
                    ModalStore.setIsModalOpen('checkinPurchase')
                  }}
                  onTimeEnd={() => {
                    setCheckInPurchaseIconOn(false)
                  }}
                  timeStamp={
                    new Date(farmData?.questLogForAttendanceWaterFinalDate)
                  }
                  timerMinutes={60 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {!farmData?.didChangePlant &&
              farmData?.changePlantBeforeChurnOpenedAt &&
              changePlantBeforeChurnIconOn && (
                <PurchaseScreenTimerButton
                  imageType='changePlantBeforeChurn'
                  onClick={() => {
                    ModalStore.setIsModalOpen('changePlantBeforeChurn')
                  }}
                  onTimeEnd={() => {
                    setChangePlantBeforeChurnIconOn(false)
                  }}
                  timeStamp={new Date(farmData?.changePlantBeforeChurnOpenedAt)}
                  timerMinutes={60 * 24}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {luckydrawPurchaseRewardOn && !timeLoadingEndLuckydraw && (
              <PurchaseScreenTimerButton
                imageType='luckydrawIcon'
                onClick={() => {
                  ModalStore.setIsModalOpen('luckydrawPurchaseRewardModal')
                }}
                onTimeEnd={() => {
                  setLuckydrawPurchaseRewardOn(false)
                }}
                timeStamp={luckydrawPurchaseRewardEndAt}
                timerMinutes={0}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
                noDays={true}
              />
            )}
            {giveWaterCount >= 25 &&
              isDailyLotteryOn &&
              dailyLotteryData &&
              codePushVersionAvailable(userCodePushVersion, '1.6.24') && (
                <PurchaseScreenTimerButton
                  imageType='dailyLottery'
                  onClick={() => {
                    ModalStore.setIsModalOpen('dailyLottery')
                    setCommonRemovedDependencyInviteInfo({
                      shareType: 'AlfarmDailyLotteryInvite',
                    })
                  }}
                  onTimeEnd={() => {
                    setIsDailyLotteryOn(false)
                  }}
                  timeStamp={dailyLotteryData?.now}
                  timerMinutes={dailyLotteryData?.diff}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 25 &&
              isLastMinuteInviteOn &&
              farmData?.lastMinuteInviteInfo?.createdAt &&
              codePushVersionAvailable(userCodePushVersion, '1.6.17') && (
                <PurchaseScreenTimerButton
                  imageType='lastMinuteInvite'
                  onClick={() => {
                    ModalStore.setIsModalOpen('lastMinuteInvite')
                  }}
                  onTimeEnd={() => {
                    setIsLastMinuteInviteOn(false)
                  }}
                  timeStamp={
                    new Date(farmData?.lastMinuteInviteInfo?.createdAt)
                  }
                  timerMinutes={60 * 24 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 20 &&
              isLevelUpChanceInviteOn &&
              farmData?.exp < farmData?.farmLevel?.level5 &&
              farmData?.isNewUser &&
              farmData?.levelUpChanceInviteInfo?.createdAt &&
              codePushVersionAvailable(userCodePushVersion, '1.6.17') && (
                <PurchaseScreenTimerButton
                  imageType='levelUpChance'
                  onClick={() => {
                    ModalStore.setIsModalOpen('levelUpChance')
                  }}
                  onTimeEnd={() => {
                    setIsLevelUpChanceInviteOn(false)
                  }}
                  timeStamp={
                    new Date(farmData?.levelUpChanceInviteInfo?.createdAt)
                  }
                  timerMinutes={60 * 24 * 3}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 15 &&
              showSellerCouponPurchaseIconOn &&
              selectedSellerCoupon &&
              codePushVersionAvailable(userCodePushVersion, '1.6.66') && (
                <PurchaseScreenTimerButton
                  imageType='sellerCouponPurchase'
                  onClick={() => {
                    ModalStore.setIsModalOpen('sellerCounponPurchase')
                  }}
                  onTimeEnd={() => {
                    setShowSellerCouponPurchaseIconOn(false)
                  }}
                  timeStamp={new Date(selectedSellerCoupon?.expiresAt)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  noDays={true}
                />
              )}
            {giveWaterCount >= 10 && relayRewardIconOn && (
              <PurchaseScreenTimerButton
                imageType='relayReward'
                onClick={() => {
                  ModalStore.setIsModalOpen('relayReward')
                }}
                onTimeEnd={() => {
                  setRelayRewardIconOn(false)
                }}
                timeStamp={new Date(relayRewardInfo?.endAt)}
                timerMinutes={0}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
              />
            )}
            {giveWaterCount >= 100 &&
              showCouponUsageNudgeIconOn &&
              codePushVersionAvailable(userCodePushVersion, '1.6.58') &&
              couponInfo?.timeDiff > 0 && (
                <PurchaseScreenTimerButton
                  imageType='couponUsageNudge'
                  onClick={() => {
                    ModalStore.setIsModalOpen('couponUsageNudge')
                  }}
                  onTimeEnd={() => {
                    ModalStore.setIsModalOpen('basic')
                    setShowCouponUsageNudgeIconOn(false)
                  }}
                  timeStamp={new Date(couponInfo?.couponList?.[0]?.expiresAt)}
                  timerMinutes={0}
                  timerStyle={{ fontFamily: 'Maplestory' }}
                  interval={1000}
                  // noDays={true}
                />
              )}
            {lotteryDrawSpecialPurchase && (
              <PurchaseScreenTimerButton
                imageType='lotteryDrawSpecialPurchase'
                onClick={() => {
                  ModalStore.setIsModalOpen('lotteryDrawSpecialPurchaseModal')
                }}
                onTimeEnd={() => {
                  ModalStore.setIsModalOpen('basic')
                  setLotteryDrawSpecialPurchase(false)
                }}
                timeStamp={
                  farmData?.lotteryDrawSpecialPurchase
                    ? new Date(
                        new Date(
                          farmData?.lotteryDrawSpecialPurchase,
                        ).getTime() +
                          1000 * 60 * 60,
                      )
                    : new Date(new Date().getTime() + 1000 * 60 * 60)
                }
                timerMinutes={0}
                timerStyle={{ fontFamily: 'Maplestory' }}
                interval={1000}
              />
            )}
          </div>

          {/* 우물 */}

          <div>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                height: '35vw',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  position: 'relative',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: '26vw',
                    zIndex: 103,
                  }}
                >
                  <ResourceButton
                    action='fertilizerResource'
                    label={
                      farmData?.nutriment === undefined
                        ? 0
                        : farmData?.nutriment
                    }
                    onClick={() => {
                      BottomSheetStore.setIsbottomSheetOpen('fertilizer')
                    }}
                    giveWaterCount={giveWaterCount}
                    showCondition={level >= 2 || !farmData?.isNewUser}
                    farmData={farmData}
                  />
                </div>

                {giveWaterCount > 50 &&
                  timerWaterUpTracking === 'b' &&
                  isEntryVisible && (
                    <button
                      onClick={() => {
                        ModalStore.setIsModalOpen('timerWaterPurchaseUpgrade')
                      }}
                    >
                      <img
                        className='w-[20vw] h-[7vw] ml-[1vw] relative z-[100]'
                        src='/icon/timerWaterUpgradeButtonB.png'
                        alt=''
                      />
                    </button>
                  )}
                <div className='relative'>
                  <ActionButton
                    token={token}
                    action={dailyGetWaterActionName}
                    basicWater={basicWater}
                    setFarmData={setFarmData}
                    onClick={getWater}
                    doubleClickDisabled={!canClickGetWater}
                    farmData={farmData}
                    componentStyle={{
                      top: 0,
                    }}
                    initialNumber={basicWater}
                    maxNumber={dailyGetWaterActionName === 'getWater' ? 30 : 90}
                    increasePerSecond={increasePerSecond}
                    setBasicWater={(num) => setBasicWater(num)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '8vw',
                  flex: 1,
                }}
              >
                <Farm
                  id={PlantBasicInfo.IdFromPlantName(itemType)}
                  itemType={itemType}
                  level={level}
                  token={token}
                  farmData={farmData}
                  setFarmData={setFarmData}
                  setAddWater={setAddWater}
                  setAddFertilizer={setAddFertilizer}
                  setAddFertilizerSet={setAddFertilizerSet}
                  ongoingWaterBattleBanner={ongoingWaterBattleBanner}
                  battleData={battleData}
                  battleWaterAmount={battleWaterAmount}
                  opponentBattleWaterCount={opponentBattleWaterCount}
                  battleClassName={battleClassName}
                  giveWaterCountAtThisVisit={giveWaterCountAtThisVisit}
                  isTreasureBoxMissionTargetUser={
                    isTreasureBoxMissionTargetUser
                  }
                  setIsTreasureBoxMissionTargetUser={
                    setIsTreasureBoxMissionTargetUser
                  }
                  lastHarvestPurchaseButtonOn={lastHarvestPurchaseButtonOn}
                  cpmLeavesAdProps={cpmLeavesAdProps}
                />

                <div className='w-full flex flex-col z-110 text-center mt-[-10vw]'>
                  <GaugeBar
                    id={PlantBasicInfo.IdFromPlantName(itemType)}
                    level={level}
                    remainExp={remainExp}
                    expToLevelUp={expToLevelUp}
                    isGiveWater={animState === 'giveWaterBasket'}
                    token={token}
                    giveWaterCount={giveWaterCount}
                    animState={animState}
                    userId={userId}
                  />
                  <GaugeBarPercentage
                    level={level}
                    remainExp={remainExp}
                    expToLevelUp={expToLevelUp}
                    giveWaterCount={giveWaterCount}
                    farmData={farmData}
                  />
                </div>
              </div>

              <div
                style={{
                  width: '26vw',
                  flex: 1,
                  zIndex: 103,
                }}
              >
                <PuppyButton
                  farmData={farmData}
                  setFarmData={setFarmData}
                  token={token}
                  setAddWater={setAddWater}
                  setAddFertilizer={setAddFertilizer}
                  setAddFertilizerSet={setAddFertilizerSet}
                  setPuppyGiftModal={setPuppyGiftModal}
                  puppyGiftModal={puppyGiftModal}
                  setPuppyRewardType={setPuppyRewardType}
                  loadUserCoupon={loadUserCoupon}
                  showPuppy={showPuppy}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                paddingLeft: '2vw',
                justifyContent: 'space-between',
                height: '20vw',
              }}
            >
              <div>
                <GetResourceButton
                  action='getFertButton'
                  onClick={async () => {
                    BottomSheetStore.setIsbottomSheetOpen('fertilizer')
                  }}
                  giveWaterCount={giveWaterCount}
                  isCleared={canGetFertButtonNotification}
                  showCondition={giveWaterCount > 3}
                />

                <GetResourceButton
                  action='getWaterButton'
                  onClick={() => {
                    BottomSheetStore.setIsbottomSheetOpen('water')
                    setWinnerDealViewAvailable(false)
                    setQuizViewAvailable(false)
                  }}
                  isCleared={canGetWaterButtonNotification}
                  showCondition={giveWaterCount > 3}
                  tutorialZindex={
                    giveWaterCount === 4 &&
                    BottomSheetStore.isBottomSheetOpen !== 'water' &&
                    !questLogForGetWaterDailyAttendance &&
                    !animState
                      ? 151
                      : null
                  }
                  onWinnerDeal={winnerDealViewAvailable}
                  onQuiz={quizViewAvailable}
                />

                <GetResourceButton
                  action='minigame'
                  onClick={() => {
                    BottomSheetStore.setIsbottomSheetOpen('miniGame')
                    setYutnoriPlayAvailable(false)
                  }}
                  showCondition={giveWaterCount > 50}
                  onYutnori={yutnoriPlayAvailable}
                />
                {ABv3(farmData?.userId, 'badaham1107', [5]) === 'b' &&
                  codePushVersionAvailable(userCodePushVersion, '4.3.14') && (
                    <GetResourceButton
                      action='badaham'
                      onClick={() => {
                        AlfarmEventLogger({
                          throttleRef,
                          locationType: 'bottomEventIcons',
                          locationName: 'badahamV2',
                          eventType: 'click',
                          collection: 'UserAlfarmClickLog',
                        })
                        navigate(`/badaham-v2`)
                      }}
                      showCondition={giveWaterCount > 100}
                    />
                  )}

                {/* 올목장 미션 바텀시트 */}
                {/* <GetResourceButton
                  action='alranchMission'
                  onClick={() => {
                    BottomSheetStore.setIsbottomSheetOpen('alranch')
                  }}
                  isCleared={!farmData?.clearedCheckinNewYear}
                  showCondition={
                    userCodePushVersion !== '6.2.0' &&
                    codePushVersionAvailable(userCodePushVersion, '6.1.10') &&
                    giveWaterCount >= 100
                  }
                /> */}
              </div>
              {/* 물주기 */}
              <div className='pr-[2vw]'>
                {!waterModalVisible &&
                  giveWaterTest === 'a' &&
                  farmData?.water >= 50 &&
                  giveWaterCount > 30 && (
                    <BasicButton
                      type='waterFive'
                      action='giveWaterFive'
                      disabled={farmData?.water < 50 || !canClickGiveWater}
                      onClick={() => {
                        if (!giveWaterBlock) {
                          newGiveWaterBasket(5)
                          setGiveWaterButtonClickedCount(
                            giveWaterButtonClickedCount + 1,
                          )
                        }
                      }}
                      subButton={true}
                    />
                  )}
                {!waterModalVisible &&
                  farmData?.water <= 599 &&
                  giveWaterTest === 'b' &&
                  farmData?.water >= 50 &&
                  giveWaterCount > 30 && (
                    <BasicButton
                      type='waterFive'
                      action='giveWaterFive'
                      disabled={farmData?.water < 50 || !canClickGiveWater}
                      onClick={() => {
                        if (!giveWaterBlock) {
                          newGiveWaterBasket(5)
                          setGiveWaterButtonClickedCount(
                            giveWaterButtonClickedCount + 1,
                          )
                        }
                      }}
                      subButton={true}
                    />
                  )}
                {!waterModalVisible &&
                  giveWaterTest === 'b' &&
                  farmData?.water > 599 &&
                  giveWaterCount > 500 && (
                    <BasicButton
                      type='waterTwenty'
                      action='newGiveWater'
                      disabled={farmData?.water < 50 || !canClickGiveWater}
                      onClick={() => {
                        newGiveWaterBasket(20)
                      }}
                      subButton={true}
                    />
                  )}

                {!waterModalVisible && (
                  <BasicButton
                    type='water'
                    action='giveWater'
                    onClick={() => {
                      giveWaterBasket()
                      setGiveWaterButtonClickedCount(
                        giveWaterButtonClickedCount + 1,
                      )
                    }}
                    label={
                      farmData?.nutriment === undefined
                        ? `0g`
                        : `${commaNumber(Math.floor(farmData?.water))}g`
                    }
                    farmData={farmData}
                  />
                )}

                {waterModalVisible && (
                  <BasicButton
                    type='water'
                    action='givingWater'
                    label={
                      farmData?.nutriment === undefined
                        ? `0g`
                        : `${commaNumber(Math.floor(farmData?.water))}g`
                    }
                  />
                )}
              </div>
            </div>
            {/* 깐부모드 */}
            <GganbuWithEventIcons
              token={token}
              farmData={farmData}
              userId={farmData?.userId}
              setFarmData={setFarmData}
              itemType={itemType}
              giveWaterCount={giveWaterCount}
              clearedAlwayzFarm={clearedAlwayzFarm}
              userCodePushVersion={userCodePushVersion}
              singleDealInfo={singleDealInfo}
              producingFertMachine={producingFertMachine}
              produceFertMachineHistory={produceFertMachineHistory}
              singleDealParticipated={singleDealParticipated}
              gganbuData={gganbuData}
              setGganbuData={setGganbuData}
              water={farmData?.water}
              setOpenJuwelryModal={setOpenJuwelryModal}
              setOpenLifeModal={setOpenLifeModal}
              setOpenSeasonOffModal={setOpenSeasonOffModal}
              sellerFarmList={sellerFarmList}
              canClickGiveWater={canClickGiveWater}
              cpmLeavesAdProps={cpmLeavesAdProps}
              cpmFortuneCookieAdProps={cpmFortuneCookieAdProps}
            />
          </div>
          {/* 튜토리얼 코드 */}
          {(giveWaterCount === 0 || giveWaterCount === undefined) && (
            <>
              <div className='absolute w-full h-full bg-black opacity-80' />
              <div className='absolute flex-1 right-[26vw] bottom-[32vw] text-center text-[18px] bottom-fert-title'>
                물을 주면 작물이 자라요!
                <div>클릭해서 물을 줘볼까요?</div>
              </div>

              <div className='slide-bottom absolute right-[6%] bottom-[58vw] text-center text-[18px]'>
                <img
                  className='w-[12vw]'
                  src='/icon/guideIndicator.png'
                  alt=''
                />
              </div>
            </>
          )}
          {giveWaterCount === 1 && !animState && (
            <>
              <div className='absolute w-full h-full bg-black opacity-80' />

              <div className='absolute flex-1 left-[15vw] bottom-[30vw] right-0 text-center text-[18px] bottom-fert-title'>
                물을 더 줘서
                <div>레벨을 올려보세요!</div>
              </div>
              <div className='slide-bottom absolute  right-[6%] bottom-[58vw] text-center text-[18px]'>
                <img
                  className='w-[12vw]'
                  src='/icon/guideIndicator.png'
                  alt=''
                />
              </div>
            </>
          )}
          {giveWaterCount === 2 &&
            !animState &&
            ModalStore?.isModalOpen !== 'review' &&
            !showlevelUpRewardModal && (
              <>
                <div className='absolute w-full h-full bg-black opacity-80' />
                <div className='absolute flex-1 left-[12vw] bottom-[30vw] right-0 text-center text-[18px] bottom-fert-title'>
                  계속해서 물을 주고
                  <div>작물을 키워보세요</div>
                </div>
                <div className='slide-bottom absolute  right-[6%] bottom-[58vw] text-center text-[18px]'>
                  <img
                    className='w-[12vw]'
                    src='/icon/guideIndicator.png'
                    alt=''
                  />
                </div>
              </>
            )}
          {/* 여기서 데일리워터 띄운다! */}
          {giveWaterCount === 3 &&
            !animState &&
            ModalStore?.isModalOpen !== 'review' && (
              <>
                <div className='bg-black absolute w-full h-full opacity-80 z-[200]' />
                <img
                  className='absolute w-[60%] z-[200] top-[40%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]'
                  src='/icon/tomorrowEngagingWaterModalBackground.png'
                  alt=''
                />
                <div
                  style={{
                    textShadow:
                      '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                  }}
                  className='z-[200] text-[8vw] font-bold text-center w-full top-[40%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] absolute'
                >
                  {farmData?.dailyGiveWater}g
                </div>
                <div className='flex-1 absolute top-[60%] left-1/2 transform translate-x-[-50%] text-center w-full right-0 text-[6vw] z-[200] leading-[150%]'>
                  오늘 물을 많이 줄수록 <br />
                  내일 더 많이 얻을 수 있어요
                </div>
                <button
                  className='flex-1 absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[200] rounded-[30px] w-[70%] h-[14vw] bg-gradient-to-r from-[#FFE99A] to-[#F09236] text-[5vw] font-maplestory'
                  onClick={() => {
                    giveWaterBasket()
                  }}
                  onPointerDown={() => {
                    setIsButtonPressedGiveWater(true)
                  }}
                  onPointerCancel={() => {
                    setIsButtonPressedGiveWater(false)
                  }}
                  onPointerUp={() => {
                    setIsButtonPressedGiveWater(false)
                  }}
                >
                  <div className='z-[200] color-[#402C24] text-[5vw] font-maplestory'>
                    물주기
                  </div>
                  <img
                    className='z-[200] w-[15vw] absolute right-[-10vw] top-[12vw] slide-tl'
                    src='/icon/finger.png'
                    alt=''
                  />
                </button>
                <div className='flex-1 absolute top-[85%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[200] text-center w-full right-0 text-[5vw] '>
                  {dailyGiveWaterLevelOne}g 채우면 {dailyGiveWaterLevelOne + 30}
                  g 획득가능
                </div>
              </>
            )}
          {/* 물주기 퀘스트 모달 */}

          {giveWaterCount === 5 && !getWaterCount && !animState && (
            <>
              <div className='bg-black absolute w-full h-full opacity-80 z-[105]' />
              <img
                className='absolute w-[35vw] z-[105] top-[35%] left-[32.5%]'
                src='/icon/getWaterFullSample.png'
                alt=''
              />
              <div className='flex-1 absolute left-0 top-[60%] bottom-0 right-0 text-[18px] text-center z-[105] bottom-fert-title'>
                우물을 클릭하여 쌓인 물을 획득할 수 있어요
                <div>물은 자동으로 채워져요 </div>
                <div>가득차면 더 이상 물이 쌓이지 않아요</div>
              </div>
              <button
                className='flex-1 absolute left-[15%] top-[70%] bottom-0 right-0 text-[18px] text-center z-[105] rounded-[30px] w-[70%] h-[50px] bg-gradient-to-r from-[#FFE99A] to-[#F09236] font-maplestory'
                onClick={() => {
                  getWater()
                }}
                onPointerDown={() => {
                  setIsButtonPressedGiveWater(true)
                }}
                onPointerCancel={() => {
                  setIsButtonPressedGiveWater(false)
                }}
                onPointerUp={() => {
                  setIsButtonPressedGiveWater(false)
                }}
              >
                <div className='color-[#402C24] font-maplestory text-[18px]'>
                  물 받기
                </div>
                <img
                  className='w-[15vw] absolute right-[-10vw] top-[10vw] z-[222] slide-tl'
                  src='/icon/finger.png'
                  alt=''
                />
              </button>
            </>
          )}
          {giveWaterCount === 4 &&
            !animState &&
            questLogForGetWaterDailyAttendance && (
              <>
                <div className='bg-black absolute w-full h-full opacity-80 z-[105]' />
                <div className='z-[105] absolute flex-1 left-[12vw] bottom-[30vw] right-0 text-center text-[18px] bottom-fert-title'>
                  계속해서 물을 주고
                  <div>작물을 키워보세요</div>
                </div>
                <div className='slide-bottom absolute right-[6%] bottom-[60vw] text-center text-[18px] z-[105]'>
                  <img
                    className='w-[12vw]'
                    src='/icon/guideIndicator.png'
                    alt=''
                  />
                </div>
                <div className='z-[105] absolute flex-1 right-[2vw] bottom-[22.5vw]'>
                  {!waterModalVisible ? (
                    <BasicButton
                      type='water'
                      action={'giveWater'}
                      onClick={giveWaterBasket}
                      farmData={farmData}
                      label={
                        farmData?.nutriment === undefined
                          ? `0g`
                          : `${commaNumber(Math.floor(farmData?.water))}g`
                      }
                    />
                  ) : (
                    <BasicButton
                      type='water'
                      action='givingWater'
                      farmData={farmData}
                    />
                  )}
                </div>
              </>
            )}
          {giveWaterCount === 5 && getWaterCount >= 1 && !animState && (
            <>
              <div className='bg-black absolute w-full h-full opacity-80 z-[105]' />
              <div className='absolute flex-1 left-[12vw] bottom-[30vw] right-0 text-center text-[18px] bottom-fert-title z-[106]'>
                계속해서 물을 주고
                <div>작물을 키워보세요</div>
              </div>
              <div className='slide-bottom absolute right-[6%] bottom-[60vw] text-center text-[18px] z-[105]'>
                <img
                  className='w-[12vw]'
                  src='/icon/guideIndicator.png'
                  alt=''
                />
              </div>
              <div className='z-[125] absolute flex-1 right-[2vw] bottom-[22.5vw]'>
                {!waterModalVisible ? (
                  <BasicButton
                    type='water'
                    action={'giveWater'}
                    onClick={giveWaterBasket}
                    farmData={farmData}
                    label={
                      farmData?.nutriment === undefined
                        ? `0g`
                        : `${commaNumber(Math.floor(farmData?.water))}g`
                    }
                  />
                ) : (
                  <BasicButton
                    type='water'
                    action='givingWater'
                    farmData={farmData}
                  />
                )}
              </div>
            </>
          )}

          {giveWaterCount === 4 &&
            BottomSheetStore.isBottomSheetOpen !== 'water' &&
            !questLogForGetWaterDailyAttendance &&
            !animState && (
              <>
                <div className='bg-black absolute w-full h-full opacity-80 z-[103]' />
                <div className='absolute flex-1 left-[17vw] bottom-[62vw] right-0 text-center text-[18px] bottom-fert-title z-[103]'>
                  물이 부족한가요?
                  <div>물받기를 통해 물을 얻을 수 있어요</div>
                  <div className='flex-1 absolute left-[5.5vw] top-[11vw] z-[105] text-center slide-bottom'>
                    <img
                      className='w-[12vw]'
                      src='/icon/guideIndicator.png'
                      alt=''
                    />
                  </div>
                </div>
              </>
            )}

          {giveWaterCount >= 6 &&
            giveWaterCount <= 13 &&
            farmData?.water >= 10 && (
              <button
                className='absolute right-[16vw] bottom-[18vw] z-[100]'
                onClick={giveWaterBasket}
              >
                <div
                  style={{
                    fontSize: '3.5vw',
                    fontFamily: 'Maplestory',
                    color: 'black',
                    position: 'absolute',
                    background: 'white',
                    border: '1vw solid #FF5454',
                    borderRadius: '4vw',
                    width: '50vw',
                    padding: '2vw',
                    right: '-14vw',
                    top: '8vw',
                    zIndex: 100,
                  }}
                >
                  물을 {14 - giveWaterCount}번 주면 작물이 자라요!
                </div>

                <div className='flip-horizontal '>
                  <img
                    className='w-[13vw] slide-tl-basket '
                    src='/icon/newfinger.png'
                    alt=''
                  />
                </div>
              </button>
            )}
        </Layout>
      </div>

      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          addWater={addWater}
          addFertilizer={addFertilizer}
          addFertilizerSet={addFertilizerSet}
          type={resourceType}
        />
      )}
      {showPushSettingFinishedPopup && (
        <PushSettingFinishedPopup
          type={resourceType}
          setShowPushNotificationSettingModal={setShowPushSettingFinishedPopup}
        />
      )}
      {ModalStore?.isModalOpen === 'tomorrowEngagingWaterModal' && (
        <TomorrowEngagingWaterModal
          farmData={farmData?.dailyGiveWater}
          level1={dailyGiveWaterLevelOne}
          level2={dailyGiveWaterLevelTwo}
          level3={dailyGiveWaterLevelThree}
          level4={dailyGiveWaterLevelFour}
          onClick={giveWaterBasket}
        />
      )}
      {ModalStore?.isModalOpen === 'timerWaterPurchaseUpgrade' && (
        <TimerWaterUpgradeModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          // isLoading={isLoading}
        />
      )}
      {ModalStore?.isEngagementWaterModal && (
        <SuccessTomorrowGetWaterModal
          dailyGiveWater={farmData?.dailyGiveWater}
          level1={dailyGiveWaterLevelOne}
          level2={dailyGiveWaterLevelTwo}
          level3={dailyGiveWaterLevelThree}
          level4={dailyGiveWaterLevelFour}
          onClick={giveWaterBasket}
        />
      )}
      {ModalStore?.isModalOpen === 'attendanceWater' && (
        <AttendanceWaterModalNew
          setAddWater={setAddWater}
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setCanGetDailyAttendanceWaterNotification={
            setCanGetDailyAttendanceWaterNotification
          }
          todayCheckInDone={todayCheckInDone}
          setTodayCheckInDone={setTodayCheckInDone}
          setShowPopup={setShowPopup}
          setResourceType={setResourceType}
          // setCheckInPurchaseIconOn={setCheckInPurchaseIconOn}
          checkinPurchaseInfo={checkinPurchaseInfo}
        />
      )}
      {ModalStore?.isModalOpen === 'review' && (
        <ReviewModal farmData={farmData} token={token} />
      )}

      {ModalStore?.isModalOpen === 'drinkWater' && (
        <DrinkWaterModal
          farmData={farmData}
          token={token}
          setFarmData={setFarmData}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'photoReview' && (
        <PhotoReviewModal
          photoReviewItemsInfo={photoReviewItemsInfo}
          farmData={farmData}
          token={token}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
        />
      )}
      {ModalStore?.isModalOpen === 'noonDailyWaterGet' && (
        <NoonDailyWaterGetModal farmData={dailyGiveWaterPopUp} />
      )}
      {ModalStore?.isModalOpen === 'yesterdayGiveWaterModal' && (
        <YesterdayGiveWaterModal
          farmData={farmData}
          setCanGetDailyGiveWater={setCanGetDailyGiveWater}
          reSetDailyGiveWater={reSetDailyGiveWater}
          token={token}
          yesterdayGiveWater={yesterdayGiveWater}
          removedWater={removedWater}
        />
      )}
      {ModalStore?.isModalOpen === 'roulette' && (
        <RouletteModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setShowPuppy={setShowPuppy}
        />
      )}
      {ModalStore?.isModalOpen === 'fertilizerTowerGame' && (
        <FertilizerTowerGameModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'guessMe' && (
        <GuessMeModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'fertilizerRoulette' && (
        <FertilizerRouletteModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setFertilizerRouletteOn={setFertilizerRouletteOn}
        />
      )}
      {ModalStore?.isModalOpen === 'cherryPickerRoulette' && (
        <CherryPickerRouletteModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          loadUserCoupon={loadUserCoupon}
        />
      )}
      {inviteSuccessModal && (
        <InviteSuccessModal
          setInviteSuccessModal={setInviteSuccessModal}
          rewardType={successModalRewardType}
        />
      )}
      {showlevelUpRewardModal && (
        <LevelUpRewardModal
          setShowLevelUpRewardModal={setShowLevelUpRewardModal}
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          level={level}
          id={PlantBasicInfo.IdFromPlantName(farmData?.itemType)}
          setAddWater={setAddWater}
          setAddFertilizer={setAddFertilizer}
          setAddFertilizerSet={setAddFertilizerSet}
          setIsLastMinuteInviteOn={setIsLastMinuteInviteOn}
          loadUserCoupon={loadUserCoupon}
        />
      )}
      {showMinimumWaterModal && (
        <LackWaterEngaingModal
          setShowMinimumWaterModal={setShowMinimumWaterModal}
          token={token}
        />
      )}
      {showMinimumNutrimentModal && (
        <LackNutrimentEngagingModal
          setShowMinimumNutrimentModal={setShowMinimumNutrimentModal}
          token={token}
        />
      )}
      {showMinimumBasicWaterModal && (
        <MinimumBaiscWater
          setShowMinimumBasicWaterModal={setShowMinimumBasicWaterModal}
          token={token}
        />
      )}
      {alertModal && (
        <AlertModal
          enteringRoute={enteringRoute}
          setAlertModal={setAlertModal}
          getGganbuList={getGganbuList}
          setInviteResultStatus={setInviteResultStatus}
          onClick={alertModalOnClick}
          setAlertModalOnClick={setAlertModalOnClick}
        />
      )}
      {ModalStore?.isModalOpen === 'dailyGetWaterUpgrade' && (
        <DailyGetWaterUpgradeModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}
      {ModalStore?.isModalOpen === 'luckydraw' && (
        <Luckydraw
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setUpdateMinigameInfo={setUpdateMinigameInfo}
          luckydrawPurchaseReward={luckydrawPurchaseReward}
          loadUserCoupon={loadUserCoupon}
        />
      )}
      {ModalStore?.isModalOpen === 'fertilizerHistory' && (
        <FertilizerHistoryModal token={token} />
      )}
      {ModalStore?.isModalOpen === 'waterHistory' && (
        <WaterHistoryModal token={token} />
      )}
      {ModalStore?.isModalOpen === 'minigameHistory' && (
        <MinigameHistoryModal token={token} />
      )}
      {ModalStore?.isModalOpen === 'luckydrawHistory' && (
        <LuckydrawHistoryModal token={token} />
      )}
      {ModalStore?.isModalOpen === 'inviteHistory' && (
        <InviteHistoryModal token={token} />
      )}
      {ModalStore?.isModalOpen === 'lastHarvestPurchase' && (
        <LastHarvestPurchaseModal
          itemType={itemType}
          farmData={farmData}
          setEnteringRoute={setEnteringRoute}
          setAlertModal={setAlertModal}
        />
      )}
      {tutorialFinishedModal && (
        <TutorialFinisedModal
          token={token}
          setTutorialFinishedModal={setTutorialFinishedModal}
          farmData={farmData}
          setFarmData={setFarmData}
          setAddWater={setAddWater}
          setAddFertilizerSet={setAddFertilizerSet}
          setIsTreasureBoxMissionTargetUser={setIsTreasureBoxMissionTargetUser}
        />
      )}
      {puppyGiftModal && (
        <PuppyGiftModal
          token={token}
          puppyRewardType={puppyRewardType}
          setPuppyGiftModal={setPuppyGiftModal}
          addWater={addWater}
          addFertilizer={addFertilizer}
          addFertilizerSet={addFertilizerSet}
        />
      )}
      {ModalStore?.isModalOpen === 'puppy' && (
        <PuppyModal
          farmData={farmData}
          token={token}
          puppyEndTime={puppyEndTime}
          setPuppyEndTime={setPuppyEndTime}
          setShowPuppy={setShowPuppy}
          userCodePushVersion={userCodePushVersion}
        />
      )}
      {harvestModalVisible && (
        <HarvestModal
          farmData={farmData}
          itemType={itemType}
          token={token}
          setHarvestModalVisible={setHarvestModalVisible}
          harvestModalVisible={harvestModalVisible}
          fetcher={fetcher}
          setFinishedModalVisible={setFinishedModalVisible}
          finishedModalVisible={finishedModalVisible}
          setInviteResultStatus={setInviteResultStatus}
          setEnteringRoute={setEnteringRoute}
          setAlertModal={setAlertModal}
          userCodePushVersion={userCodePushVersion}
        />
      )}
      {finishedModalVisible && (
        <HarvestModal
          farmData={farmData}
          itemType={itemType}
          token={token}
          setHarvestModalVisible={setHarvestModalVisible}
          harvestModalVisible={harvestModalVisible}
          fetcher={fetcher}
          setFinishedModalVisible={setFinishedModalVisible}
          finishedModalVisible={finishedModalVisible}
          setEnteringRoute={setEnteringRoute}
          setAlertModal={setAlertModal}
          userCodePushVersion={userCodePushVersion}
        />
      )}
      {ModalStore?.isModalOpen === 'sevenDays' && (
        <SevenDaysModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          loadUserCoupon={loadUserCoupon}
          getWelcomeQuestCheckInData={getWelcomeQuestCheckInData}
          setCanGetSevendaysEventNotification={
            setCanGetSevendaysEventNotification
          }
        />
      )}
      {ModalStore?.isModalOpen === 'tapCountGame' && (
        <TapCountGameModal
          closeModal={() => {
            ModalStore.setIsModalOpen(null)
          }}
          token={token}
        />
      )}

      {ModalStore?.isModalOpen === 'harvestGrape' && (
        //
        <HarvestGrapeModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setEnteringRoute={setEnteringRoute}
          setAlertModal={setAlertModal}
          userCodePushVersion={userCodePushVersion}
          itemType={itemType}
          setHarvestModalVisible={setHarvestModalVisible}
          setFinishedModalVisible={setFinishedModalVisible}
          setInviteResultStatus={setInviteResultStatus}
        />
      )}
      {ModalStore?.isModalOpen === 'allBrandDealPurchaseModal' && (
        <AllBrandDealModal token={token} farmData={farmData} />
      )}
      {!showPopup &&
        ModalStore?.isModalOpen === 'resurrectBonusAttendanceEvent' && (
          <ResurrectBonusAttendanceEventModal token={token} />
        )}
      {!showPopup && ModalStore?.isModalOpen === 'floatingButtonNudge' && (
        <FloatingButtonNudgeModal token={token} />
      )}
      {ModalStore?.isModalOpen === 'comebackUser' && (
        <ComebackUserModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          loadUserCoupon={loadUserCoupon}
        />
      )}
      {ModalStore?.isModalOpen === 'newUserInstantHarvest' && (
        <NewUserInstantHarvestModal />
      )}
      {ModalStore?.isModalOpen === 'doubleExpEvent' && <DoubleExpEventModal />}

      {openChangeMandarinModal && (
        <ChangePlantForMandarin
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setOpenChangeMandarinModal={setOpenChangeMandarinModal}
          itemType={itemType}
        />
      )}
      {openChangePersimmonModal && (
        <ChangePlantForPersimmon
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setOpenChangePersimmonModal={setOpenChangePersimmonModal}
          itemType={itemType}
        />
      )}
      {/* {goBackToAlwayzAppModal && <GoBackToAlwayzAppModal />} */}
      {ModalStore?.isModalOpen === 'changePlant' && (
        <ChangePlantModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          itemType={itemType}
        />
      )}
      {openSecretScratchModal && (
        <SecretScratchModal
          setOpenSecretScratchModal={(bool) => setOpenSecretScratchModal(bool)}
          farmData={farmData}
          setFarmData={setFarmData}
          secretScratchHistory={secretScratchHistory}
        />
      )}
      {ModalStore?.isModalOpen === 'LotteryTicketScratchModal' && (
        <LotteryTicketScratchModal
          farmData={farmData}
          setFarmData={setFarmData}
          showRewardToast={showRewardToast}
          token={token}
          setUpdateMinigameInfo={setUpdateMinigameInfo}
        />
      )}
      {ModalStore?.isModalOpen === 'LotteryTicketScratchBluebirdModal' && (
        <LotteryTicketScratchBluebirdModal
          farmData={farmData}
          setFarmData={setFarmData}
          showRewardToast={showRewardToast}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'lastMinuteInvite' && (
        <LastMinuteInviteModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
        />
      )}
      {ModalStore?.isModalOpen === 'waterMaximum' && <WaterMaximumModal />}
      {ModalStore?.isModalOpen === 'harvestInvite' && (
        <HarvestInviteModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
        />
      )}
      {ModalStore?.isModalOpen === 'makeGganbu' && <MakeGganbuModal />}
      {ModalStore?.isModalOpen === 'levelUpChance' && (
        <LevelUpChanceModalNew
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
        />
      )}
      {ModalStore?.isModalOpen === 'weeklyBingoPurchase' && (
        <WeeklyBingoPurchaseModal farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'commonRemovedDependency' && (
        <CommonRemovedDependencyModal
          farmData={farmData}
          inviteInfo={commonRemovedDependencyInviteInfo}
        />
      )}
      {ModalStore?.isModalOpen === 'dailyLottery' &&
        farmData &&
        commonRemovedDependencyInviteInfo &&
        dailyLotteryData && (
          <DailyLotteryModal
            farmData={farmData}
            setFarmData={setFarmData}
            inviteInfo={commonRemovedDependencyInviteInfo}
            dailyLotteryData={dailyLotteryData}
            personalDailyLotteryInfo={personalDailyLotteryInfo}
            token={token}
            setIsDailyLotteryOn={setIsDailyLotteryOn}
          />
        )}
      {ModalStore?.isModalOpen === 'selectOnePurchaseModal' && (
        <SelectOnePurchaseModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'waterToFertilizer' && (
        <WaterToFertilizerModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'resurrectD1Nudge' && (
        <ResurrectD1NudgeModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'waterOneOneBattle' && (
        <WaterOneOneBattleModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          onClick={giveWaterBasket}
          battleData={battleData}
          setOngoingWaterBattleBanner={setOngoingWaterBattleBanner}
          setAmount={setAmount}
          battleWaterAmount={battleWaterAmount}
          opponentBattleWaterCount={opponentBattleWaterCount}
        />
      )}
      {ModalStore?.isModalOpen === 'qrBonus' && (
        <QRBonusModal
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
        />
      )}
      {ModalStore?.isModalOpen === 'couponUsageNudge' && (
        <CouponUsageNudgeModal
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
          couponInfo={couponInfo}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'sellerCounponPurchase' && (
        <SellerCounponPurchaseModal
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
          couponInfo={couponInfo}
          token={token}
          selectedSellerCoupon={selectedSellerCoupon}
          setSelectedSellerCoupon={setSelectedSellerCoupon}
          sellerCoupons={sellerCoupons}
        />
      )}
      {ModalStore?.isModalOpen === 'missionHunter' && (
        <MissionHunterModal
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
          couponInfo={couponInfo}
          token={token}
          selectedSellerCoupon={selectedSellerCoupon}
          setSelectedSellerCoupon={setSelectedSellerCoupon}
          sellerCoupons={sellerCoupons}
        />
      )}
      {ModalStore?.isModalOpen === 'relayReward' && (
        <RelayRewardModal
          relayRewardInfo={relayRewardInfo}
          farmData={farmData}
          setFarmData={setFarmData}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'qrBonusReward' && (
        <QRBonusRewardModal farmData={farmData} setFarmData={setFarmData} />
      )}
      {ModalStore?.isModalOpen === 'engagementNutriment' && (
        <EngagementNutrimentModal
          farmData={farmData}
          setFarmData={setFarmData}
          giveWaterBasket={giveWaterBasket}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'waterBombGame' && (
        <WaterBombGameModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setUpdateMinigameInfo={setUpdateMinigameInfo}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'yutnori' && (
        <YutnoriGameModalV2
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setUpdateMinigameInfo={setUpdateMinigameInfo}
          showRewardToast={showRewardToast}
          setYutnoriPurchaseEntryButtonOn={setYutnoriPurchaseEntryButtonOn}
        />
      )}
      {ModalStore?.isModalOpen === 'lotteryDrawModal' && (
        <LotteryDrawModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          setUpdateMinigameInfo={setUpdateMinigameInfo}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'alfarmDeal' &&
        singleDealInfo &&
        giveWaterCount > 15 && (
          <SingleDealModal
            farmData={farmData}
            token={token}
            singleDealInfo={singleDealInfo}
            isLoaded={isLoaded}
          />
        )}
      {ModalStore?.isModalOpen === 'battleReward' && (
        <NewRewardModal amount={amount} />
      )}
      {ModalStore?.isModalOpen === 'produceFertMachine' &&
        produceFertMachineHistory && (
          <ProduceFertMachineModal
            farmData={farmData}
            setFarmData={setFarmData}
            produceFertMachineHistory={produceFertMachineHistory}
            onClick={giveWaterBasket}
            token={token}
            setProducingFertMachine={setProducingFertMachine}
            setShowPopup={setShowPopup}
            setResourceType={setResourceType}
            setAddFertilizer={setAddFertilizer}
          />
        )}
      {showPushSettingModal && (
        <PushSettingModal
          token={token}
          setShowPushSettingModal={setShowPushSettingModal}
          farmData={farmData}
          setFarmData={setFarmData}
          setShowPopup={setShowPushSettingFinishedPopup}
          setResourceType={setResourceType}
        />
      )}
      {ModalStore.isModalOpen === 'referralCode' && (
        <ReferralCodeModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setResourceType={setResourceType}
        />
      )}
      {ModalStore.isModalOpen === 'purchaseRoulette' && (
        <PurchaseRouletteModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setPurchaseRouletteButtonOn={setPurchaseRouletteButtonOn}
        />
      )}
      {ModalStore.isModalOpen === 'eggBreakersV2' && (
        <EggBreakersV2
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}
      {ModalStore.isModalOpen === 'personalizedShop' && (
        <PersonalizedShopModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setPersonalizedShopButtonOn={setPersonalizedShopButtonOn}
        />
      )}
      {ModalStore.isModalOpen === 'personalizedShopRewardModal' && (
        <PersonalizedShopRewardModal token={token} farmData={farmData} />
      )}
      {ModalStore.isModalOpen === 'goldFilled' && (
        <GoldFilledModal token={token} farmData={farmData} />
      )}
      {ModalStore.isModalOpen === 'jewelryRevenueDeal' && (
        <JewelryRevenueDealModal token={token} farmData={farmData} />
      )}
      {ModalStore.isModalOpen === 'kidsRevenueDeal' && (
        <KidsRevenueDealModal2 token={token} farmData={farmData} />
      )}
      {ModalStore.isModalOpen === 'winterRevenueDeal' && (
        <WinterRevenueDealModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'snackRevenueDeal' && (
        <SnackRevenueDealModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'daisoRevenueDeal' && (
        <DaisoRevenueDealModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'healthRevenueDeal' && (
        <HealthRevenueDealModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'goldAccesorryRevenueDeal' && (
        <JewelryRevenueDealModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'summerRevenueDeal' && (
        <SummerRevenueDealModal token={token} farmData={farmData} />
      )}

      {ModalStore?.isModalOpen === 'familyDealModal' && (
        <EventModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'announce' && (
        <AnnouncementModal
          lastRewardAnnouncementDoc={lastRewardAnnouncementDoc}
          setLastRewardAnnouncementDoc={setLastRewardAnnouncementDoc}
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          announcementList={announcementList}
          setAnnouncementList={setAnnouncementList}
          setLastRewardAnnouncementNumber={setLastRewardAnnouncementNumber}
          setUserAnnouncementNumber={setUserAnnouncementNumber}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'luckydrawPurchaseRewardModal' && (
        <LuckydrawPurchaseReward
          setLuckydrawPurchaseRewardEndAt={setLuckydrawPurchaseRewardEndAt}
          luckydrawPurchaseRewardEndAt={luckydrawPurchaseRewardEndAt}
          luckydrawPurchaseRewardModal={luckydrawPurchaseRewardModal}
          setLuckydrawPurchaseRewardModal={setLuckydrawPurchaseRewardModal}
          farmData={farmData}
          token={token}
          setLuckydrawPurchaseRewardOn={setLuckydrawPurchaseRewardOn}
        />
      )}
      {ModalStore?.isModalOpen === 'lotteryDrawSpecialPurchaseModal' && (
        <LotteryDrawSpecialPurchaseModal />
      )}
      {ModalStore?.isModalOpen === 'treasureBox' && (
        <NewbieTreasureBoxModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          level={level}
          giveWaterBasket={giveWaterBasket}
          setOpenIndicator={setOpenIndicator}
          setCheckShowPuppy={setCheckShowPuppy}
          giveWaterCount={giveWaterCount}
        />
      )}
      {ModalStore?.isModalOpen === 'plantName' && (
        <NameMyItemModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setInviteResultStatus={setInviteResultStatus}
        />
      )}

      {ModalStore?.isModalOpen === 'feverModeModal' && (
        <FeverModeModal farmData={farmData} token={token} />
      )}

      {ModalStore?.isModalOpen === 'feverModeStart' && (
        <FeverGameStart
          token={token}
          setRewardTypeForRewardToast={setRewardTypeForRewardToast}
          setRewardAmountForRewardToast={setRewardAmountForRewardToast}
          setGetResourceModal={setGetResourceModal}
        />
      )}

      {ModalStore?.isModalOpen === 'feverModePurchaseModal' && (
        <FeverModePurchaseModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}

      {ModalStore?.isModalOpen === 'mysteryFlower' && (
        <MysteryFlowerGameModal farmData={farmData} setFarmData={setFarmData} />
      )}

      {ModalStore?.isModalOpen === 'vipCard' && (
        <VipCardModal farmData={farmData} showRewardToast={showRewardToast} />
      )}

      {ModalStore?.isModalOpen === 'petDetail' && (
        <PetDetailModal farmData={farmData} showRewardToast={showRewardToast} />
      )}

      {ModalStore?.isModalOpen === 'eventPet' && (
        <EventPetModal farmData={farmData} showRewardToast={showRewardToast} />
      )}

      {ModalStore?.isModalOpen === 'trialPet' && (
        <PetTrialModal farmData={farmData} showRewardToast={showRewardToast} />
      )}

      {ModalStore?.isModalOpen === 'changePlantBeforeChurn' && (
        <ChangePlantBeforeChurnModal
          farmData={farmData}
          showRewardToast={showRewardToast}
          setFarmData={setFarmData}
          setItemType={setItemType}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'timeCapsuleBonus' && (
        <TimeCapsuleBonus
          timeCapsuleReceived={timeCapsuleReceived}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'watermelonModal' && (
        <WatermelonModal token={token} />
      )}
      {ModalStore?.isModalOpen ===
        'leftScrollButtonSeosonalEventInviteModal' && (
        <LeftScrollButtonSeosonalEventInviteModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}
      {ModalStore?.isModalOpen === 'dailyInvite' && (
        <DailyInviteModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}

      {ModalStore?.isModalOpen === 'dailyInviteSpecial' && (
        <DailyInviteSpecial
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}

      {ModalStore?.isModalOpen === 'adItemBrowsing' && (
        <AdItemBrowsingModal token={token} farmData={farmData} />
      )}
      {ModalStore.isModalOpen === 'checkinPurchase' && (
        <CheckInPurchaseBefore token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'fashionDeal' && (
        <FashionModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'winnerDeal' && (
        <WinnerDealModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'winnerDealV2' && (
        <WinnerDealModalV2
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'winnerDealV3' && (
        <WinnerDealModalV3
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'winnerDealForAd' && (
        <WinnerDealModalForAd
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'winnerDealForAdV2' && (
        <WinnerDealModalForAdV2
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'alfarmLife' && (
        <LifeModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {ModalStore?.isModalOpen === 'newItemDeal' && (
        <NewItemDealModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
        />
      )}
      {openSeasonOffModal && (
        <SeasonOffModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          isLoading={isLoading}
          setOpenSeasonOffModal={setOpenSeasonOffModal}
        />
      )}
      {ModalStore.isModalOpen === 'brandDeal' && (
        <BrandDealModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          userCodePushVersion={userCodePushVersion}
          codePushVersionAvailable={codePushVersionAvailable}
        />
      )}
      {ModalStore?.isModalOpen === 'phoneBookPermission' && <PhoneBookModal />}
      {ModalStore?.isModalOpen === 'openQuotes' && (
        <QuotesModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'invitedQuotes' && (
        <QuotesModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          quotesIndex={quotesIndex}
          isFrom='invite'
        />
      )}
      {ModalStore?.isModalOpen === 'christmasTree' && (
        <ChristmasTreeModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          quotesIndex={quotesIndex}
          isFrom='invite'
        />
      )}
      {openJuwelryModal && (
        <JuwelryModal
          setOpenJuwelryModal={setOpenJuwelryModal}
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'contactsList' && (
        <ContactsList
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'firstConv' && (
        <CategoryFirstConv
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
        />
      )}
      {ModalStore?.isModalOpen === 'alfarmQuiz' && (
        <QuizModalV2
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setQuizPurchaseButtonOn={setQuizPurchaseButtonOn}
          itemType={itemType}
        />
      )}
      {ModalStore?.isModalOpen === 'surpriseMission' && (
        <SurpriseMissionModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          giveWaterBasket={giveWaterBasket}
        />
      )}
      {ModalStore?.isModalOpen === 'lotteryTeamDealModal' && (
        <LotteryTeamDealModal
          token={token}
          itemType={itemType}
          farmData={farmData}
        />
      )}
      {ModalStore?.isModalOpen === 'tenMinDealModal' && (
        <TenMinDealModal
          token={token}
          itemType={itemType}
          farmData={farmData}
        />
      )}
      {ModalStore?.isModalOpen === 'groceryDealModal' && (
        <GroceryDealModal
          token={token}
          itemType={itemType}
          farmData={farmData}
        />
      )}

      {ModalStore?.isModalOpen === 'quizPurchase' && (
        <QuizPurchaseModalV2
          token={token}
          farmData={farmData}
          itemType={itemType}
        />
      )}
      {ModalStore?.isModalOpen === 'lackWaterEngageModal' && (
        <ActivationEngagingModal
          farmData={farmData}
          lackWaterEngageType={lackWaterEngageType}
          token={token}
          itemType={itemType}
        />
      )}
      {ModalStore?.isModalOpen === 'trendQuiz' && (
        <TrendQuizModal
          farmData={farmData}
          setTrendQuizOn={setTrendQuizOn}
          token={token}
          showRewardToast={showRewardToast}
          setFarmData={setFarmData}
        />
      )}

      {ModalStore?.isModalOpen === 'brainTeaser' && (
        <BrainTeaserModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          showRewardToast={showRewardToast}
        />
      )}

      {ModalStore?.isModalOpen === 'dailyMissionv2' && (
        <DailyMissionModalV2
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
          setGetResourceModal={setGetResourceModal}
          showRewardToast={showRewardToast}
          giveWaterBasket={giveWaterBasket}
          userCodePushVersion={userCodePushVersion}
        />
      )}

      {ModalStore?.isModalOpen === 'pesticideModal' && (
        <PesticideModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}
      {showChangePlantAmountModal && (
        <ChangePlantGetFasterModal
          token={token}
          setShowModal={setShowChangePlantAmountModal}
          setShowButton={setShowChangePlantAmountRightButton}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}

      {ModalStore?.isModalOpen === 'AlfarmBrandBannerDealModal' && (
        <AlfarmBrandBannerDealModal
          token={token}
          itemType={itemType}
          farmData={farmData}
        />
      )}

      {ModalStore.isModalOpen === 'carnation' && (
        <CarnationModal token={token} farmData={farmData} />
      )}

      {ModalStore.isModalOpen === 'newUserEventWater' && (
        <NewUserEventWaterModal token={token} setFarmData={setFarmData} />
      )}

      {ModalStore.isModalOpen === 'newUserValueIndicate' && (
        <NewUserValueIndicateModal token={token} setFarmData={setFarmData} />
      )}

      {ModalStore.isModalOpen === 'gganbuRecommendation' && (
        <GganbuRecommendationModal
          token={token}
          setShowModal={setShowChangePlantAmountModal}
          setShowButton={setShowChangePlantAmountRightButton}
          farmData={farmData}
          setFarmData={setFarmData}
          getGganbuList={getGganbuList}
        />
      )}
      {ModalStore.isModalOpen === 'requestGganbu' && (
        <RequestGganbuModal
          token={token}
          setShowModal={setShowChangePlantAmountModal}
          setShowButton={setShowChangePlantAmountRightButton}
          farmData={farmData}
          setFarmData={setFarmData}
          getGganbuList={getGganbuList}
        />
      )}
      {ModalStore.isModalOpen === 'churnedGganbuBounty' && (
        <ChurnedGganbuBountyModal />
      )}

      {ModalStore?.isModalOpen === 'bfForceModal' && <BlackFridayForceModal />}

      {ModalStore?.isModalOpen === 'badge' && (
        <BadgeModal
          gganbuName={'12'}
          gganbuId={farmData?.userId}
          token={token}
          gganbuFarmData={farmData}
        />
      )}

      {ModalStore?.isModalOpen === 'itemEndBeforeModal' && (
        <ItemEndBeforeModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}

      {ModalStore?.isModalOpen === 'itemEndModal' && (
        <ItemEndedModal
          token={token}
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}

      {ModalStore?.isModalOpen === 'nextLevelReward' && (
        <NextLevelRewardModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          level={level}
          id={PlantBasicInfo.IdFromPlantName(farmData?.itemType)}
          setAddWater={setAddWater}
          setAddFertilizer={setAddFertilizer}
          setAddFertilizerSet={setAddFertilizerSet}
        />
      )}

      {ModalStore?.isModalOpen === 'SellerAdPromotingModal' && (
        <SellerAdPromotingModal
          farmData={farmData}
          setFarmData={setFarmData}
          token={token}
          level={level}
          id={PlantBasicInfo.IdFromPlantName(farmData?.itemType)}
          setAddWater={setAddWater}
          setAddFertilizer={setAddFertilizer}
          setAddFertilizerSet={setAddFertilizerSet}
          showRewardToast={showRewardToast}
        />
      )}
      {ModalStore?.isModalOpen === 'sansinEventModal' && (
        <SansinEventModal token={token} showRewardToast={showRewardToast} />
      )}

      {SecondModalStore?.isModalOpen === 'alphabetEventHistoryModal' && (
        <AlphabetEventHistoryModal
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}
      {ModalStore?.isModalOpen === 'alphabetEvent' && (
        <AlphabetEventModal farmData={farmData} setFarmData={setFarmData} />
      )}
      {(ModalStore?.isModalOpen === 'rewardAdModal' ||
        ModalStore?.isModalOpen === 'rewardAdModalWater') && (
        <RewardAdModal farmData={farmData} setFarmData={setFarmData} />
      )}
      {ModalStore?.isModalOpen === 'rewardAdModalForWinnerDeal' && (
        <RewardAdModalForWinnerDeal
          farmData={farmData}
          setFarmData={setFarmData}
        />
      )}

      {/* CPM 레디 모달 */}
      {ModalStore?.isModalOpen === 'quizAd_ready' && <QuizAdReadyModal />}
      {ModalStore?.isModalOpen === 'yutnoriAd_ready' && <YutnoriAdReadyModal />}

      {ModalStore?.isModalOpen === 'fortuneCookieAd_ready' && (
        <FortuneCookieAdReadyModal />
      )}

      {ModalStore?.isModalOpen === 'updateAppRequest' && (
        <UpdateAppRequestModal />
      )}

      {ModalStore?.isModalOpen === 'newYearSpecialDealEntryModal' && (
        <NewYearSpecialDealEntryModal />
      )}

      {/* CPM 상품 모달 */}
      <CPMModalWrapper
        userCodePushVersion={userCodePushVersion}
        userId={farmData?.userId}
      />

      {/* CPM 비디오 모달 */}
      {[
        VIDEO_AD_MODAL_TYPE.todayDealAd_video,
        VIDEO_AD_MODAL_TYPE.recommendedProductAd_video,
      ].includes(ModalStore?.isModalOpen) && (
        <CPMVideoAdModal modalType={ModalStore?.isModalOpen} />
      )}
      {VIDEO_AD_MODAL_TYPE.quizAd_video === ModalStore?.isModalOpen && (
        <QuizVideoAdModal />
      )}
      {ModalStore?.isModalOpen === VIDEO_AD_MODAL_TYPE.leavesAd_video && (
        <LeavesVideoAdModal />
      )}
      {ModalStore?.isModalOpen === VIDEO_AD_MODAL_TYPE.yutnoriAd_video && (
        <YutnoriVideoAdModal />
      )}
      {ModalStore?.isModalOpen ===
        VIDEO_AD_MODAL_TYPE.fortuneCookieAd_video && (
        <FortuneCookieVideoAdModal />
      )}

      {ModalStore?.isModalOpen === 'efficacyModal' && (
        <FarmEfficacyModal
          level={level}
          itemType={itemType}
          remainExp={remainExp}
          expToLevelUp={expToLevelUp}
          giveWaterBasket={giveWaterBasket}
          setGiveWaterButtonClickedCount={setGiveWaterButtonClickedCount}
        />
      )}
      {ModalStore?.isModalOpen === 'offerwallMissionComplete' && (
        <OfferwallMissionCompleteModal farmData={farmData} />
      )}

      {ModalStore?.isModalOpen === 'doubleWaterModal' && (
        <DoubleWaterModal
          amount={pendingWaterAmount}
          onConfirm={() => {
            getWaterTimeReset()
            ModalStore.setIsModalOpen('basic')
            showRewardAd('rewarded', 'ALFARM_DOUBLE_BASIC_WATER_MISSION_REWARD')
            AlfarmEventLogger({
              throttleRef,
              locationType: 'modal',
              locationName: 'DoubleWaterModal',
              eventType: 'click_ad_receive',
              data: {
                waterAmount: amount,
              },
              collection: 'UserAlfarmClickLog',
            })
          }}
          onCancel={async () => {
            await getBasicWater()
            ModalStore.setIsModalOpen('basic')
            AlfarmEventLogger({
              throttleRef,
              locationType: 'modal',
              locationName: 'DoubleWaterModal',
              eventType: 'click_normal_receive',
              data: {
                waterAmount: amount,
              },
              collection: 'UserAlfarmClickLog',
            })
          }}
          onModalClose={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        />
      )}

      {ModalStore?.isModalOpen === 'fertilizerSetGetAdWatch' && (
        <FertilizerSetGetAdWatchModal />
      )}
      {ModalStore?.isModalOpen === 'fertilizerSetAdWatch' && (
        <FertilizerSetAdWatchModal />
      )}

      {ModalStore?.isModalOpen === 'scratchLotto' && <ScratchLottoModal />}
      {ModalStore?.isModalOpen === 'scratchLottoBluebird' && (
        <ScratchLottoBluebirdModal
          setShowByebyeBluebird={setShowByebyeBluebird}
        />
      )}
      <InitModal
        setFarmData={setFarmData}
        farmData={farmData}
        setAddWater={setAddWater}
        setShowPopup={setShowPopup}
        setResourceType={setResourceType}
      />
      <Modal
        isOpen={waterModalVisible}
        onRequestClose={() => {}}
        className='modal-basic relative w-full h-[110%]'
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0)',
          },
        }}
      >
        <WaterEffect />
      </Modal>

      {getResourceModal && (
        <GetResourceModal
          amount={
            rewardAmountForRewardToast > 0
              ? rewardAmountForRewardToast
              : addWater
          }
          rewardType={rewardTypeForRewardToast}
          setGetResourceModal={setGetResourceModal}
          getResourceModal={getResourceModal}
          setRewardAmountForRewardToast={setRewardAmountForRewardToast}
          setRewardTypeForRewardToast={setRewardTypeForRewardToast}
        />
      )}

      {showByebyeBluebird && (
        <div className='absolute flex-1 left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-[60vw] h-[45vw] rounded-3xl bg-black bg-opacity-80 z-[2000000] flex flex-col justify-center items-center'>
          <img src='/scratchLotto/bluebird.png' className='w-[25vw]' />
          <div className='text-[18px] text-white'>파랑새가 날아갔어요</div>
          <div className='text-[16px] text-[#C2C2C2]'>다음에 다시 만나요!</div>
        </div>
      )}

      {onlyOneGifticonPerDayModal && (
        <OnlyOneGifticonPerDayModal setModal={setonlyOneGifticonPerDayModal} />
      )}

      {/* 바텀싯 */}
      {/* 비료받기 탭 */}
      <BottomSheet
        className='bottom-sheet-new'
        open={BottomSheetStore.isBottomSheetOpen === 'fertilizer'}
        blocking={ModalStore?.isModalOpen === 'basic'}
        scrollLocking={false}
        onDismiss={onDismiss}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <FertilizerBottomsheet
          farmData={farmData}
          setFarmData={setFarmData}
          fetcher={fetcher}
          token={token}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setResourceType={setResourceType}
          setAddFertilizer={setAddFertilizer}
          setAddFertilizerSet={setAddFertilizerSet}
          giveWaterCount={giveWaterCount}
          userCodePushVersion={userCodePushVersion}
          openIndicator={openIndicator}
          setOpenIndicator={setOpenIndicator}
          gganbuData={gganbuData}
          setGganbuData={setGganbuData}
          singleDealInfo={singleDealInfo}
          setCanClickGiveWater={setCanClickGiveWater}
          setCanClickSellerFarm={setCanClickSellerFarm}
          setLackWaterEngageType={setLackWaterEngageType}
          altoonData={altoonFertMissionData}
          setAltoonData={setAltoonFertMissionData}
          altoonFertMissionList={altoonFertMissionList}
          dramaData={dramaFertMissionData}
          setDramaData={setDramaFertMissionData}
          dramaFertMissionList={dramaFertMissionList}
        />
      </BottomSheet>

      {/* 펫 바텀싯 */}
      <BottomSheet
        className='bottom-sheet-new'
        open={BottomSheetStore.isBottomSheetOpen === 'pet'}
        // expandOnContentDrag
        blocking={ModalStore?.isModalOpen === 'basic'}
        scrollLocking={false}
        onDismiss={onDismiss}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <PetBottomSheet
          farmData={farmData}
          setFarmData={setFarmData}
          fetcher={fetcher}
          token={token}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setResourceType={setResourceType}
          setAddFertilizer={setAddFertilizer}
          setAddFertilizerSet={setAddFertilizerSet}
          giveWaterCount={giveWaterCount}
          userCodePushVersion={userCodePushVersion}
          openIndicator={openIndicator}
          setOpenIndicator={setOpenIndicator}
          gganbuData={gganbuData}
          setGganbuData={setGganbuData}
          singleDealInfo={singleDealInfo}
          setCanClickGiveWater={setCanClickGiveWater}
          setCanClickSellerFarm={setCanClickSellerFarm}
          setLackWaterEngageType={setLackWaterEngageType}
        />
      </BottomSheet>

      {/* 미니게임 바텀싯 */}
      <BottomSheet
        className='bottom-sheet'
        open={BottomSheetStore.isBottomSheetOpen === 'miniGame'}
        blocking={ModalStore?.isModalOpen === 'basic'}
        scrollLocking={false}
        onDismiss={onDismiss}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <Minigame
          token={token}
          itemType={itemType}
          userCodePushVersion={userCodePushVersion}
          userId={userId}
          farmData={farmData}
          setFarmData={setFarmData}
          updateMinigameInfo={updateMinigameInfo}
          setUpdateMinigameInfo={setUpdateMinigameInfo}
          setShowPopup={setShowPopup}
          showRewardToast={showRewardToast}
        />
      </BottomSheet>

      {/* 올목장 바텀싯 */}
      <BottomSheet
        className='bottom-sheet'
        open={BottomSheetStore.isBottomSheetOpen === 'alranch'}
        blocking={ModalStore?.isModalOpen === 'basic'}
        scrollLocking={false}
        onDismiss={onDismiss}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <AlranchMissions
          farmData={farmData}
          setFarmData={setFarmData}
          cowData={alranchCowData}
          missionsData={alranchMissionsData}
          setMissionsData={setAlranchMissionsData}
          refetchFunc={fetchAlranchData}
        />
      </BottomSheet>

      {/* 물받기 바텀싯 */}
      <BottomSheet
        className='bottom-sheet-new'
        open={BottomSheetStore.isBottomSheetOpen === 'water'}
        blocking={ModalStore?.isModalOpen === 'basic'}
        scrollLocking={false}
        onDismiss={onDismiss}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <WaterBottomsheet
          farmData={farmData}
          setFarmData={setFarmData}
          fetcher={fetcher}
          token={token}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setResourceType={setResourceType}
          setAddWater={setAddWater}
          giveWaterCount={giveWaterCount}
          userId={userId}
          dailyWaterPurchaseRewardToggle={dailyWaterPurchaseRewardToggle}
          newbiePurchaseWaterRemainTime={newbiePurchaseWaterRemainTime}
          couponInfo={couponInfo}
          isSecretIconOn={isSecretIconOn}
          secretScratchHistory={secretScratchHistory}
          gganbuData={gganbuData}
          setGganbuData={setGganbuData}
          showSellerCouponPurchaseIconOn={showSellerCouponPurchaseIconOn}
          selectedSellerCoupon={selectedSellerCoupon}
          codePushVersionAvailable={codePushVersionAvailable}
          triggerMissionHunter={triggerMissionHunter}
          showRewardToast={showRewardToast}
          setCanClickGiveWater={setCanClickGiveWater}
          photoReviewItemsInfo={photoReviewItemsInfo}
          setPhotoReviewItemsInfo={setPhotoReviewItemsInfo}
          setInviteResultStatus={setInviteResultStatus}
          setCanClickSellerFarm={setCanClickSellerFarm}
          userCodePushVersion={userCodePushVersion}
          altoonData={altoonWaterMissionData}
          setAltoonData={setAltoonWaterMissionData}
          dramaData={dramaWaterMissionData}
          setDramaData={setDramaWaterMissionData}
          dramaWaterMissionList={dramaWaterMissionList}
        />
      </BottomSheet>

      {/* 피드 바텀싯 */}
      <BottomSheet
        className='bottom-sheet'
        open={BottomSheetStore?.isBottomSheetOpen === 'feed'}
        blocking={ModalStore?.isModalOpen === 'basic'}
        scrollLocking={false}
        onDismiss={onDismiss}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <FeedModal
          token={token}
          itemType={itemType}
          userCodePushVersion={userCodePushVersion}
          userId={userId}
          setUserAnnouncementNumber={setUserAnnouncementNumber}
          setShowPushSettingModal={setShowPushSettingModal}
          farmData={farmData}
        />
      </BottomSheet>
      <LoadingIndicator isLoading={isLoading} />
    </div>
  )
})

export default FarmPage
