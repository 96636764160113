import { memo } from 'react'
import LottieGif from '../LottieGif'
import glitter from '../../../json/gifJson/lotteryDraw/glitter.json'

const SpecialLotteryCapsulesOpen = memo(
  ({ capsule, rewardType }) => {
    return (
      <div
        key={capsule.id}
        style={{
          width: '106px',
          height: '160px',
          position: 'absolute',
          top: '16vw',
          left: capsule.left,
          transform: 'translate(-50%, 0)',
          backgroundImage: `url(../minigame/lotteryDraw/specialCapsule${capsule.id}_${rewardType}.png)`,
          zIndex: 900,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: capsule.isVisible ? 1 : 0,
        }}
      >
        <LottieGif
          animationData={glitter}
          style={{
            zIndex: 9000,
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          loop
          speed={1.5}
        />
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.capsule.id === nextProps.capsule.id &&
      prevProps.capsule.isVisible === nextProps.capsule.isVisible &&
      prevProps.capsule.left === nextProps.capsule.left &&
      prevProps.rewardType === nextProps.rewardType
    )
  },
)

export default SpecialLotteryCapsulesOpen
