import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import WhiteTextTimer from '../../../comps/WhiteTextTimer'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const PersonalizedShopRewardModal = observer(({ token, farmData }) => {
  const [bannerImg, setBannerImg] = useState(false)
  const [rewardType, setRewardType] = useState(false)
  const [rewardText, setRewardText] = useState(false)
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  const throttleRef = useRef({})

  useEffect(() => {
    setRewardType(farmData?.personalizedShopInfo?.type)
    if (farmData?.personalizedShopInfo?.twice) {
      setRewardType(farmData?.personalizedShopInfo?.type + 3)
    }
  }, [])

  useEffect(() => {
    if (
      farmData?.personalizedShopInfo?.type === 1 &&
      farmData?.personalizedShopInfo?.twice
    ) {
      setBannerImg(
        'https://assets.ilevit.com/4584f142-1854-4772-a23f-255a548541de.png',
      )
      setRewardText('물 2000g을')
    }
    if (
      farmData?.personalizedShopInfo?.type === 3 &&
      !farmData?.personalizedShopInfo?.twice
    ) {
      setBannerImg(
        'https://assets.ilevit.com/815aa307-8dbb-406b-98c4-f0058a24d608.png',
      )
      setRewardText('물 500g, 고급비료 2개를')
    }
    if (
      farmData?.personalizedShopInfo?.type === 2 &&
      !farmData?.personalizedShopInfo?.twice
    ) {
      setBannerImg(
        'https://assets.ilevit.com/436ffd65-9de7-4a1e-9531-b68b48f913d4.png',
      )
      setRewardText('고급비료 2개, 일반비료 5개를')
    }
    if (
      farmData?.personalizedShopInfo?.type === 2 &&
      farmData?.personalizedShopInfo?.twice
    ) {
      setBannerImg(
        'https://assets.ilevit.com/308da146-17df-47c5-9e68-d6d7a64c40ef.png',
      )
      setRewardText('고급비료 4개, 일반비료 10개를')
    }
    if (
      farmData?.personalizedShopInfo?.type === 3 &&
      farmData?.personalizedShopInfo?.twice
    ) {
      setBannerImg(
        'https://assets.ilevit.com/54ae7469-0ec8-4c0e-8a14-dbc3ca937779.png',
      )
      setRewardText('물 1,000g, 고급비료 4개를')
    }
    if (
      farmData?.personalizedShopInfo?.type === 1 &&
      !farmData?.personalizedShopInfo?.twice
    ) {
      setBannerImg(
        'https://assets.ilevit.com/66e5c396-a740-4856-9f2a-adfa951bcfb3.png',
      )
      setRewardText('물 1,000g을')
    }
  }, [])

  const RewardDesciption = () => {
    if (farmData?.personalizedShopInfo?.type === 1) {
      return (
        <div
          style={{
            color: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          물 {farmData?.personalizedShopInfo?.rewardWater}g
          <div
            style={{
              color: '#6E5831',
            }}
          >
            을 받아요
          </div>
        </div>
      )
    }
    if (farmData?.personalizedShopInfo?.type === 2) {
      return (
        <div
          style={{
            color: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          고급비료 {farmData?.personalizedShopInfo?.rewardFertilizerSet}개,{' '}
          일반비료 {farmData?.personalizedShopInfo?.rewardFertilizer}개
          <div
            style={{
              color: '#6E5831',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            를 받아요
          </div>
        </div>
      )
    }
    if (farmData?.personalizedShopInfo?.type === 3) {
      return (
        <div
          style={{
            color: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          물 {farmData?.personalizedShopInfo?.rewardWater}g, 고급비료{' '}
          {farmData?.personalizedShopInfo?.rewardFertilizerSet}개
          <div
            style={{
              color: '#6E5831',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            를 받아요
          </div>
        </div>
      )
    }
  }

  const RewardIcon = () => {
    return rewardType === false ? null : (
      <img
        style={{
          zIndex: 101,
          position: 'absolute',
          width: '80%',
          marginTop: '18%',
        }}
        src={`/personalizedShop/${rewardType}.png`}
        alt=''
      />
    )
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '180vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              backgroundImage: 'url(../personalizedShop/rewardModal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <div
              style={{
                color: '#6E5831',
                top: '30%',
                position: 'absolute',
                fontSize: '5vw',
                textAlign: 'center',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              상품을 구매하면 <br />
              <RewardDesciption />
              <div
                style={{
                  marginTop: '3vw',
                  color: '#8D7044',
                  fontSize: '4.5vw',
                }}
              >
                <WhiteTextTimer
                  //   onTimeEnd={() => {}}
                  timeStamp={
                    new Date(farmData?.personalizedShopInfo?.createdAt)
                  }
                  timerMinutes={60 * 3}
                  color='#8D7044'
                />
                {'  '}이후에 사라져요
              </div>
            </div>
            {!farmData?.personalizedShopInfo?.browsing && (
              <img
                style={{
                  zIndex: 101,
                  position: 'absolute',
                  width: '50%',
                  marginTop: '68%',
                  marginLeft: '35%',
                }}
                src='/personalizedShop/textBubble.png'
                alt=''
              />
            )}
            <RewardIcon />
            <div
              style={{
                color: 'white',
                fontSize: '6vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                background: isButtonPressed
                  ? 'linear-gradient(180deg, #02D658 0%, #01BA4B 100%)'
                  : 'linear-gradient(180deg, #02D658 0%, #01BA4B 100%)',
                boxShadow: isButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
                padding: '5vw',
                borderRadius: '50vw',
                width: '75vw',
                marginTop: '90%',
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: 'cta',
                  data: {},
                  collection: 'UserAlfarmClickLog',
                })

                const purchaseData = {
                  text: '올팜 선물상자',
                  enteringComponent: 'Alfarm_personalizedShop',
                  source: bannerImg,
                  timeStamp: new Date(new Date().getTime() + 1000 * 30),
                  isTimerOn: farmData?.personalizedShopInfo?.browsing
                    ? false
                    : true,
                  timerText: '미션 완료까지 ',
                  isTimerEndText: '축하합니다! 물 10g을 받았어요',
                  questAmount: 10,
                  rewardText: `상품을 구매하면 ${rewardText} 받아요`,
                }

                window.location.href = `#commonPurchaseReward.${JSON.stringify(
                  purchaseData,
                )}`
                ModalStore.setIsModalOpen('basic')
              }}
            >
              {farmData?.personalizedShopInfo?.browsing
                ? '상품 구매하기'
                : '구경하고 구매하기'}
            </div>
            <button
              style={{
                width: '10%',
                top: '18vw',
                right: '7%',
                position: 'absolute',
                zIndex: 1113,
                background: 'black',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      </div>
    </>
  )
})
export default PersonalizedShopRewardModal
