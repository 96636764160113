import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import AB3 from 'utils/ab_v3'
import UserStore from 'store/UserStore'
import ABStore from 'store/ABStore'
import {
  CPM_ENTERING_COMPONENTS,
  CPM_GOODS_MODAL_TYPES,
  CPM_READY_MODAL_TYPES,
} from 'utils/alfarmCPMConstant/cpmAdTypes'
import useGetCPMAdStatusByType from 'queries/cpm/useGetCPMAdStatusByType'
import { checkIsTodayWithISODate } from 'utils/utils'
import BottomSheetStore from 'store/BottomSheetStore'

const RewardButton = ({
  didReceive = true,
  isReceivable = false,
  iconSrc = '',
  openGame = () => {},
  getReward = () => {},
}) => {
  if (didReceive) {
    return (
      <div
        style={{
          marginTop: 8,
          width: '16vw',
          height: '16vw',
          backgroundImage: 'url(../minigame/receivedReward.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        onClick={() => {}}
      />
    )
  }
  return isReceivable ? (
    <div
      className='scaleUp'
      style={{
        marginTop: 8,
        width: '16vw',
        height: '16vw',
        backgroundImage: 'url(../minigame/receivable.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => {
        getReward()
      }}
    />
  ) : (
    <div
      style={{
        marginTop: 8,
        width: '16vw',
        height: '16vw',
        backgroundImage: iconSrc,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => {
        openGame()
      }}
    />
  )
}

const MinigameUpperReward = observer(
  ({
    minigameInfo,
    drawCount,
    isWaterBombPlayedToday,
    userCodePushVersion,
    token,
    setUpdateMinigameInfo,
    isLotteryDrawPlayedToday,
    showRewardToast,
    isLoading,
    farmData,
    setFarmData,
    isYutnoriPlayedToday,
    isScratchLottoPlayedToday,
  }) => {
    const { data: yutnoriAdStatus, isPending: isYutnoriAdStatusPending } =
      useGetCPMAdStatusByType({
        type: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
        enabled: ABStore.isCPMAdViewer,
      })

    const isTodayCleared =
      ABStore.isCPMAdViewer &&
      checkIsTodayWithISODate(yutnoriAdStatus?.rewardDeterminedAt)

    const [waterbombRewardButtonAvailable, setWaterbombRewardButtonAvailable] =
      useState(true)
    const [
      scratchLottoRewardButtonAvailable,
      setScratchLottoRewardButtonAvailable,
    ] = useState(true)
    const [
      lotteryDrawRewardButtonAvailable,
      setlotteryDrawRewardButtonAvailable,
    ] = useState(true)
    const [luckyDrawRewardButtonAvailable, setLuckyDrawRewardButtonAvailable] =
      useState(true)
    const [sidepickRewardButtonAvailable, setSidepickRewardButtonAvailable] =
      useState(true)
    const [yutnoriRewardButtonAvailable, setYutnoriRewardButtonAvailable] =
      useState(true)
    const [
      flappyBirdRewardButtonAvailable,
      setFlappyBirdRewardButtonAvailable,
    ] = useState(true)

    const codePushVersionAvailable = useCheckCodePushVersion()

    function setNewDataToFarmData() {
      let temp = { ...farmData }
      if (UserStore.waterExceeded) {
        temp.water += 5
      } else {
        temp.water += 10
      }
      setFarmData(temp)
    }

    async function giveMinigameCompleteRewardWaterBomb() {
      await backendApis.giveMinigameCompleteRewardWaterBomb(token)
      setUpdateMinigameInfo(true)
      setNewDataToFarmData()
    }
    async function giveMinigameCompleteRewardLuckyDraw() {
      await backendApis.giveMinigameCompleteRewardLuckyDraw(token)
      setUpdateMinigameInfo(true)
      setNewDataToFarmData()
    }
    async function giveMinigameCompleteRewardLotteryDraw() {
      await backendApis.giveMinigameCompleteRewardLotteryDraw(token)
      setUpdateMinigameInfo(true)
      setNewDataToFarmData()
    }

    async function giveMinigameCompleteRewardScratchLotto() {
      await backendApis.giveMinigameCompleteRewardScratchLotto(token)
      setUpdateMinigameInfo(true)
      setNewDataToFarmData()
    }

    const getReward = async () => {
      setYutnoriRewardButtonAvailable(false)

      await backendApis.giveReward(token, 'PUT', {
        loggingRewardName: 'minigameCompleteReward',
        rewardType: 'water',
        valueAmount: 10,
      })
      await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'minigameCompleteRewardYutnori',
      })
      showRewardToast(10, 'water')

      setNewDataToFarmData()
      setUpdateMinigameInfo(true)
    }

    return (
      <div
        style={{
          color: 'black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: '#EEE1FE',
        }}
      >
        {ABStore.scratchLottoTester &&
        UserStore.farmData?.giveWaterCount >= 100 ? (
          <RewardButton
            didReceive={
              !minigameInfo?.minigameAvailabilityInfo
                ?.scratchLottoCompleteRewardAvailable ||
              !scratchLottoRewardButtonAvailable
            }
            isReceivable={
              isScratchLottoPlayedToday &&
              !isLoading &&
              scratchLottoRewardButtonAvailable
            }
            openGame={() => ModalStore.setIsModalOpen('scratchLotto')}
            getReward={() => {
              giveMinigameCompleteRewardScratchLotto()
              showRewardToast(10, 'water')
              setScratchLottoRewardButtonAvailable(false)
            }}
            iconSrc={'url(../minigame/scratchLotto/scratchLotto.png)'}
          />
        ) : null}
        {codePushVersionAvailable(userCodePushVersion, '4.5.20') && (
          <>
            {ABStore.isCPMAdViewer ? (
              <RewardButton
                didReceive={
                  !minigameInfo?.minigameAvailabilityInfo
                    ?.yutnoriCompleteRewardAvailable ||
                  !yutnoriRewardButtonAvailable
                }
                isReceivable={
                  isTodayCleared &&
                  !isYutnoriAdStatusPending &&
                  yutnoriRewardButtonAvailable
                }
                openGame={() => {
                  BottomSheetStore.setIsbottomSheetOpen('basic')

                  if (
                    !yutnoriAdStatus?.rewardDeterminedAt ||
                    !isTodayCleared ||
                    yutnoriAdStatus?.rewardDeterminedAt <=
                      yutnoriAdStatus?.clearedAt
                  ) {
                    ModalStore.setIsModalOpen(CPM_READY_MODAL_TYPES.YUTNORI_AD)
                    return
                  }

                  ModalStore.setIsModalOpen(CPM_GOODS_MODAL_TYPES.YUTNORI_AD)
                }}
                getReward={getReward}
                iconSrc={'url(../minigame/yutnori/yutnoriIcon.png)'}
              />
            ) : (
              <RewardButton
                didReceive={
                  !minigameInfo?.minigameAvailabilityInfo
                    ?.yutnoriCompleteRewardAvailable ||
                  !yutnoriRewardButtonAvailable
                }
                isReceivable={
                  isYutnoriPlayedToday &&
                  !isLoading &&
                  yutnoriRewardButtonAvailable
                }
                openGame={() => {
                  ModalStore.setIsModalOpen('yutnori')
                }}
                getReward={getReward}
                iconSrc={'url(../minigame/yutnori/yutnoriIcon.png)'}
              />
            )}
          </>
        )}

        <RewardButton
          didReceive={
            !minigameInfo?.minigameAvailabilityInfo
              ?.waterBombCompleteRewardAvailable ||
            !waterbombRewardButtonAvailable
          }
          isReceivable={
            isWaterBombPlayedToday &&
            !isLoading &&
            waterbombRewardButtonAvailable
          }
          openGame={() => ModalStore.setIsModalOpen('waterBombGame')}
          getReward={() => {
            giveMinigameCompleteRewardWaterBomb()
            showRewardToast(10, 'water')
            setWaterbombRewardButtonAvailable(false)
          }}
          iconSrc={'url(../minigame/waterBombIcon.png)'}
        />
        <RewardButton
          didReceive={
            !minigameInfo?.minigameAvailabilityInfo
              ?.luckyDrawCompleteRewardAvailable ||
            !lotteryDrawRewardButtonAvailable
          }
          isReceivable={
            drawCount >= 3 && !isLoading && lotteryDrawRewardButtonAvailable
          }
          openGame={() => ModalStore.setIsModalOpen('luckydraw')}
          getReward={() => {
            giveMinigameCompleteRewardLuckyDraw()
            showRewardToast(10, 'water')
            setlotteryDrawRewardButtonAvailable(false)
          }}
          iconSrc={'url(../minigame/luckyDrawIcon.png)'}
        />
        <RewardButton
          didReceive={
            !minigameInfo?.minigameAvailabilityInfo
              ?.lotteryDrawCompleteRewardAvailble ||
            !luckyDrawRewardButtonAvailable
          }
          isReceivable={
            isLotteryDrawPlayedToday &&
            !isLoading &&
            luckyDrawRewardButtonAvailable
          }
          openGame={() => ModalStore.setIsModalOpen('lotteryDrawModal')}
          getReward={() => {
            giveMinigameCompleteRewardLotteryDraw()
            showRewardToast(10, 'water')
            setLuckyDrawRewardButtonAvailable(false)
          }}
          iconSrc={'url(../minigame/lotteryDrawIcon.png)'}
        />
        {/* <RewardButton
          didReceive={
            !minigameInfo?.minigameAvailabilityInfo
              ?.sidepickCompleteRewardAvailble || !sidepickRewardButtonAvailable
          }
          isReceivable={
            minigameInfo?.minigameAvailabilityInfo?.sidepickPlayedToday &&
            !isLoading &&
            sidepickRewardButtonAvailable
          }
          openGame={() =>
            (window.location.href = `/sidePickGame?token=${token}&itemType=${itemType}&randomNumber=${randomString}`)
          }
          getReward={async () => {
            setSidepickRewardButtonAvailable(false)
            let result = await backendApis.getMinigameInfo(token)
            if (
              !result?.data?.minigameAvailabilityInfo
                ?.sidepickCompleteRewardAvailble
            ) {
              return
            }

            await backendApis.giveReward(token, 'PUT', {
              loggingRewardName: 'minigameCompleteReward',
              rewardType: 'water',
              valueAmount: 10,
            })
            await backendApis.updatelastUpdatedAt(token, 'PUT', {
              updateType: 'minigameCompleteRewardSidepick',
            })
            showRewardToast(10, 'water')
            setNewDataToFarmData()
            setUpdateMinigameInfo(true)
          }}
          iconSrc={'url(../minigame/sidepickIcon.png)'}
        /> */}
        {/* <RewardButton
          didReceive={
            !minigameInfo?.minigameAvailabilityInfo
              ?.flappyBirdCompleteRewardAvailble ||
            !flappyBirdRewardButtonAvailable
          }
          isReceivable={
            minigameInfo?.minigameAvailabilityInfo?.flappyBirdPlayedToday &&
            !isLoading &&
            flappyBirdRewardButtonAvailable
          }
          openGame={() =>
            (window.location.href = `/flappyBird?token=${token}&itemType=${itemType}&randomNumber=${Math.random()
              .toString(36)
              .slice(2, 8)}&water=${farmData?.water}`)
          }
          getReward={async () => {
            setFlappyBirdRewardButtonAvailable(false);
            let result = await backendApis.getMinigameInfo(token);
            if (
              !result?.data?.minigameAvailabilityInfo
                ?.flappyBirdCompleteRewardAvailble
            ) {
              return;
            }

            await backendApis.giveReward(token, "PUT", {
              loggingRewardName: "minigameCompleteReward",
              rewardType: "water",
              valueAmount: 10,
            });
            await backendApis.updatelastUpdatedAt(token, "PUT", {
              updateType: "minigameCompleteRewardFlappyBird",
            });
            showRewardToast(10, "water");
            setNewDataToFarmData();
            setUpdateMinigameInfo(true);
          }}
          iconSrc={"url(../minigame/flappyBirdIcon.png)"}
        /> */}
      </div>
    )
  },
)

export default MinigameUpperReward
