const UpdateAppRequestModal = () => {
  const updateApp = () => {
    window.location.href = '#alfarmNewyearGift'
  }

  return (
    <>
      <div className='flex-1 fixed left-0 top-0 z-[5004] w-full h-screen bg-black/80'>
        <div className='w-[100vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
          <img
            className='w-[90vw]'
            src='https://assets.ilevit.com/154c98de-f30f-4508-9c8a-90b83ec35209.png'
            alt=''
          />
          <button
            className='absolute bottom-[10vw] w-[50vw] h-[20vw] bg-yellow-500 text-white text-[4.8vw] font-bold rounded-full'
            onClick={updateApp}
          >
            앱 업데이트
          </button>
        </div>
      </div>
    </>
  )
}

export default UpdateAppRequestModal
