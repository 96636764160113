import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import dayjs from 'dayjs'
import UserStore from 'store/UserStore'
import moment from 'moment'
import ABStore from 'store/ABStore'
import BottomSheetStore from 'store/BottomSheetStore'

const InviteButton = ({ isFilled = false, shareKakao, userImage }) => {
  return isFilled ? (
    <img
      style={{
        width: '18vw',
        height: '18vw',
        borderRadius: 55,
        marginRight: 8,
        marginTop: '0.5vw',
      }}
      src={userImage}
      alt=''
      onError={(e) => {
        e.target.src = UserStore?.default_profile_image
      }}
    />
  ) : (
    <div
      style={{
        // border: "1px solid black",
        width: '18vw',
        height: '18vw',
        backgroundImage: 'url(../lastMinuteInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}

const VipCardModal = observer(({ token, farmData, showRewardToast }) => {
  const [isFilled, setIsFilled] = useState(false)
  const [userImage, setUserImage] = useState()
  const [infoModal, setInfoModal] = useState()
  const [isVip, setIsVip] = useState(false)
  const [isVipMonth, setIsVipMonth] = useState('')
  const [isVipDay, setIsVipDay] = useState('')
  const [isVipUserName, setIsVipUserName] = useState('농부')
  const [canClickButton, setCanClickButton] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rewardAmount, setRewardAmount] = useState(3)

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmVipCard',
      title: '[올팜] VIP가 되어주세요!',
      description: '당신은 나에게 가장 중요한 사람이거든요',
      referralCode: farmData?.referralCode,
      bannerImage:
        'https://assets.ilevit.com/16536579-ebdc-4644-b61b-6ac2f8daac49.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  useEffect(() => {
    if (ABStore.waterMaximumTester) {
      setRewardAmount(9)
    }
  }, [])

  const vipCardFertSet = async () => {
    if (!canClickButton) return
    setCanClickButton(false)
    const result = await backendApis.vipCardFertSet(token)
    if (result?.data?.success) {
      showRewardToast(rewardAmount, 'fertilizerSet')
      // ModalStore.setIsModalOpen("basic");
    }
  }

  const checkVipCardData = async () => {
    try {
      const { data } = await backendApis.checkVipCardData()

      setIsVip(data?.isVip)
      if (data?.isVip) {
        const newDate = data?.canGetFertSet
          ? dayjs(data?.vipCardEndAt).add(7, 'd')
          : { month: data?.vipCardEndMonth, day: data?.vipCardEndDay }
        setIsVipMonth(moment(data?.vipCardEndAt)?.format('MM'))
        setIsVipDay(moment(data?.vipCardEndAt)?.format('DD'))
        setIsVipUserName(data?.userName)
        setCanClickButton(data?.canGetFertSet)
      }
      setIsLoading(false)
    } catch (error) {
      console.error('오류가 발생했어요.\n다시 시도해주세요', error)
    }
  }

  useEffect(() => {
    checkVipCardData()
  }, [])

  const content = () => {
    if (isVip === '0') {
      return <></>
    }
    if (isVip) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {canClickButton ? (
            <>
              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                  textAlign: 'center',
                  lineHeight: '200%',
                  marginTop: '10vw',
                }}
              >
                <strong style={{ color: 'black' }}>{isVipUserName} </strong>님은{' '}
                <br />
                <strong style={{ color: 'black' }}>
                  {isVipMonth}월 {isVipDay}일{' '}
                </strong>
                까지 VIP 에요 <br />
                <b className='text-[#000]'>
                  매일 고급비료 {rewardAmount}개를
                </b>{' '}
                받을 수 있어요
              </div>
              <div
                style={{
                  width: '20vw',
                  height: '20vw',
                  backgroundImage: 'url(../vip/giftBoxClose.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '5vw',
                  marginBottom: '4vw',
                }}
                // className="scaleUp"
                onClick={() => {
                  vipCardFertSet()
                }}
              >
                오늘의 고급비료 받기
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  marginTop: '10vw',
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '4vw',
                  textAlign: 'center',
                  // lineHeight: "8vw",
                  backgroundColor: '#FFFAEC',
                  borderRadius: 99,
                  paddingLeft: '2vw',
                  paddingRight: '2vw',
                  paddingTop: '1vw',
                  paddingBottom: '1vw',
                  border: '1px solid #D57E25',
                }}
              >
                오늘은 보상을 받았어요
              </div>
              <div
                style={{
                  // border: "1px solid black",
                  width: '18vw',
                  height: '18vw',
                  backgroundImage: 'url(../vip/giftBoxOpen.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                onClick={() => shareKakao()}
              />
              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                  textAlign: 'center',
                  lineHeight: '8vw',
                }}
              >
                새친구를 초대하면 <br />
                <strong style={{ color: 'black' }}>7일 동안 </strong>
                매일 <br />
                고급비료 3개를 받을 수 있어요
              </div>
              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '5vw',
                  marginBottom: '4vw',
                }}
                onClick={() => {
                  shareKakao()
                }}
              >
                친구 초대하기
              </div>
            </>
          )}
        </div>
      )
    }
    if (!isVip) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '5vw',
              textAlign: 'center',
              lineHeight: '160%',
              marginTop: '8vw',
            }}
          >
            VIP 카드가 있으면 <br />
            <b className='text-[#000]'>매일 고급비료 {rewardAmount}개를</b>{' '}
            받아요
          </div>
          <InviteButton
            isFilled={isFilled}
            shareKakao={() => shareKakao()}
            userImage={userImage}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
            }}
          >
            <div
              style={{
                color: 'black',
                marginLeft: '1vw',
                lineHeight: '150%',
              }}
            >
              올팜을 안 해본 친구를 1명 초대하면 <br />
              친구도 나도 VIP 카드 7일을 받아요
            </div>
          </div>

          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'Maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '5.5vw',
              marginBottom: '8vw',
            }}
            className='scaleUp'
            onClick={() => {
              shareKakao()
            }}
          >
            친구 초대하기
          </div>
        </div>
      )
    }
  }
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        {!isLoading && (
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: 'url(../vip/modal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '47vw',
              paddingBottom: '16%',
            }}
          >
            <button
              style={{
                width: '10vw',
                right: 0,
                top: '10vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {content()}
            <button
              style={{
                width: '100%',
                color: 'black',
                fontFamily: 'Maplestory',
                textAlign: 'center',
                fontSize: '4vw',
                marginBottom: '1vw',
                marginTop: '-3vw',
                textDecoration: 'underline',
              }}
              onClick={() => {
                setInfoModal(true)
              }}
            >
              안내사항
            </button>
          </div>
        )}
      </div>
      {infoModal && (
        <>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: '50%',
              top: '50%',
              zIndex: 2000,
              width: '80%',
              background: 'rgba(0,0,0,0.9)',
              borderRadius: 20,
              fontFamily: 'maplestory',
              fontSize: '4vw',
              padding: '6vw',
              paddingTop: '8vw',
              lineHeight: '170%',
              paddingBottom: '8vw',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <button
              style={{
                width: '8%',
                top: '5vw',
                right: '5vw',
                zIndex: 2001,
                position: 'absolute',
              }}
              onClick={() => {
                setInfoModal(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseIcon.png'
                alt=''
              />
            </button>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              안내사항
            </div>
            <div style={{ height: '3vw' }} />- 올팜을 아직 안 해본 친구 1명을
            초대하면 친구도 나도 VIP 카드를 받을 수 있어요 <br />- VIP 카드가
            있으면 매일 고급비료 {rewardAmount}개를 받을 수 있어요 <br />-
            고급비료는 매일 버튼을 클릭하여 받아야해요
            <br />- 비료를 받지 않으면 해당 일은 소멸되며 다시 받을 수 없어요
            <br />- 친구를 초대해도 적용이 되지 않는 경우 작물을 선택하고 친구가
            보낸 초대 링크를 다시 클릭해주세요
            <br /> - 또는 좌측 하단의 피드 {'>'} 중앙 상단의 초대코드 입력하기{' '}
            {'>'} 친구의 링크에 있는 초대코드 입력을 통해서 초대 보상을 적용할
            수 있어요
            <br />- 정상적이지 않은 방법으로 친구를 초대하는 경우 작물 발송이
            제한될 수 있어요
          </div>
        </>
      )}
    </>
  )
})
export default VipCardModal
