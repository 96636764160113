const REWARD_INFO_MAP = {
  water: { src: '/icon/water.png', title: '물', description: '최대 1000g' },
  fertilizer: {
    src: '/icon/fertilizer.png',
    title: '일반비료',
    description: '최대 100개',
  },
  fertilizerSet: {
    src: '/icon/fertilizerSet.png',
    title: '고급비료',
    description: '최대 100개',
  },
  wetWipes: {
    src: '/icon/wetWipes.png',
    title: '물티슈',
    description: '100매 10세트',
  },
  starbucks: {
    src: '/icon/starbucks.png',
    title: '스타벅스',
    description: '10만원',
  },
  shinsaegae: {
    src: '/icon/shinsaegae.png',
    title: '신세계 상품권',
    description: '100만원',
  },
  cash: { src: '/icon/cash.png', title: '현금', description: '100만원' },
}

const RewardInfoBox = ({ rewardType }) => {
  const rewardInfo = REWARD_INFO_MAP[rewardType]
  return (
    <div className='flex flex-col items-center justify-center bg-[#FFF2E9] w-[80px] h-[88px] rounded-xl'>
      <div className='font-bold text-[12px] text-[#000]'>
        {rewardInfo.title}
      </div>
      <img src={rewardInfo.src} className='w-[33px] h-[33px]' />
      <div className='text-[#7E5840] text-[12px] font-light'>
        {rewardInfo.description}
      </div>
    </div>
  )
}

export default RewardInfoBox
