import React, { useState, useEffect, useMemo } from 'react'
import './App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import FarmPage from './pages/farm'
import ReactModal from 'react-modal'
import SelectFarm from './pages/selectFarm'
import GganbuFarm from './pages/gganbuFarm'
import AlwayzFarm from './pages/alwayzFarm'
import backendApis from './utils/backendApis'
import LoadingIndicator from './comps/loadingIndicator'
import LuckyPiggy from './pages/luckyPiggy'
import SendMoneyPage from './pages/sendMoneyPage'
import FlappyBird from './pages/FlappyBird'
import Affiliation from './pages/Affiliation'
import CoffeeGift from './pages/CoffeeGift'
import ChocoGift from './pages/ChocoGift'
import SidePickGame from './pages/SidePickGame'
import Manbogi from './pages/Manbogi'
import appBridge from './utils/appBride'
import DealAutomationStore from './store/DealAutomationStore'
import TimeStore from './store/TimeStore'
import SellerFarm from './pages/SellerFarm'
import FriendGarden from './pages/FriendGarden'
import GrapeEvent from './pages/GrapeEvent'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ToastStore from './store/ToastStore'
import { Toaster } from 'react-hot-toast'
import ResourceToast from 'comps/molecules/ResourceToast'
import ResourceToastStore from 'store/ResourceToastStore'
import DealEvent from 'pages/DealEvent'
import FamilyDealEvent from 'pages/FamilyDeal2024'
import TeamAlfarm from 'pages/TeamAlfarm'
import DealEventSecond from 'pages/DealEventSecond'
import ShortFormShopScreen from 'pages/ShortFormShopScreen'
import GganbuFarmList from 'pages/gganbuFarmList'
import OfferwallPage from 'pages/OfferwallPage'
import BadahamScreen from 'pages/AdMission/Badaham/BadahamScreen'
import BadahamScreenV2 from 'pages/AdMission/BadahamV2/BadahamScreenV2'
import { AdProvider } from '@wetripod/alwayz-frontend-shared'

dayjs.extend(isSameOrAfter)

ReactModal.setAppElement('#root')

const queryClient = new QueryClient()

function App() {
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const [gganbuKey, setGganbuKey] = useState('')

  const [duplicateLoginCheckDone, setDuplicateLoginCheckDone] = useState(false)

  useEffect(() => {
    // RN 웹뷰 환경인지 체크
    const isInReactNativeWebView = !!window.ReactNativeWebView

    if (token) {
      if (!isInReactNativeWebView && window.location.hostname !== 'localhost') {
        const path = window.location.pathname
        backendApis.recordEventLog({
          page: 'errorPage',
          locationType: path,
          locationName: path,
          eventType: 'error',
          eventName: 'invalid_useragent',
          data: {
            userAgent: navigator?.userAgent,
            token,
          },
          collection: 'UserAlfarmPageviewLog',
        })
        alert(
          '정상적인 접속이 아닙니다. 올팜 이용은 올웨이즈 앱을 통해서 이용해주세요.',
        )
        window.location.href = 'https://alwayzshop.ilevit.com/app'
      } else {
        backendApis.setToken(token, setDuplicateLoginCheckDone)
      }
    }
  }, [token])

  useEffect(() => {
    // if (!token || !itemType) return null;
    const init = async () => {
      appBridge(token, itemType)
      await DealAutomationStore.fetchCommonData()
      await TimeStore.getNow()
    }
    init()
  }, [token, itemType])

  const Router = useMemo(
    () =>
      createBrowserRouter([
        // !!!!! /games.. 라우트는 사용금지 api용으로 사용중! !!!!!
        { path: '/', element: <FarmPage /> },
        { path: '/farmPage', element: <FarmPage /> },
        { path: '/selectFarm', element: <SelectFarm /> },
        {
          path: '/gganbuFarm/:gganbuId',
          element: <GganbuFarm key={gganbuKey} setGganbuKey={setGganbuKey} />,
        },
        {
          path: '/gganbuFarmList',
          element: (
            <GganbuFarmList key={gganbuKey} setGganbuKey={setGganbuKey} />
          ),
        },
        { path: '/alwayzFarm', element: <AlwayzFarm /> },
        { path: '/luckyPiggy', element: <LuckyPiggy /> },
        { path: '/sendMoneyPage', element: <SendMoneyPage /> },
        { path: '/flappyBird', element: <FlappyBird /> },
        { path: '/affiliation', element: <Affiliation /> },
        { path: '/coffeeGift', element: <CoffeeGift /> },
        { path: '/chocoGift', element: <ChocoGift /> },
        { path: '/sidePickGame', element: <SidePickGame /> },
        { path: '/manbogi', element: <Manbogi /> },
        { path: '/sellerFarm', element: <SellerFarm /> },
        { path: '/friendGarden', element: <FriendGarden /> },
        { path: '/grapeEvent', element: <GrapeEvent /> },
        { path: '/dealEvent', element: <DealEvent /> },
        { path: '/teamAlfarm', element: <TeamAlfarm /> },
        { path: '/familyDealEvent', element: <FamilyDealEvent /> },
        { path: '/dealEventSecond', element: <DealEventSecond /> },
        { path: '/shortFormShop', element: <ShortFormShopScreen /> },
        { path: '/offerwall', element: <OfferwallPage /> },
        { path: '/badaham', element: <BadahamScreen /> },
        { path: '/badaham-v2', element: <BadahamScreenV2 /> },

        // !!!!! /games.. 라우트는 사용금지 api용으로 사용중! !!!!!
      ]),
    [gganbuKey],
  )

  if (!duplicateLoginCheckDone) {
    return <LoadingIndicator isLoading={!duplicateLoginCheckDone} />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className='w-full h-full'>
        <AdProvider>
          <RouterProvider router={Router} />
        </AdProvider>
        <Toaster
          position='top-center'
          toastOptions={{
            style: {
              marginTop: '20vw',
              fontFamily: 'maplestory',
              color: '#965F42',
            },
          }}
        />
        <ResourceToast
          type={ResourceToastStore?.resourceToast?.type}
          count={ResourceToastStore?.resourceToast?.count}
        />
      </div>
    </QueryClientProvider>
  )
}

export default App
