import React, { useState } from 'react'
import { farmingList } from '../../config'
import ReviewModal from './reviewModal'
import Log from '../../utils/log'
import ModalStore from '../../store/ModalStore'

function GaugeBar({
  id = 0,
  level = 1,
  remainExp,
  expToLevelUp,
  isGiveWater = false,
  isGiveFert = false,
  isRain = false,
  token,
  giveWaterCount,
  animState,
  gganbuMode,
  userId,
}) {
  const [clickedReview, setClickedReview] = useState(false)

  return (
    <div>
      {giveWaterCount === 1 && !animState && (
        <div
          style={{
            flex: 1,
            left: '0%',
            right: '0',
            fontSize: 18,
            textAlign: 'center',
            zIndex: 100,
            position: 'absolute',
          }}
          className='bottom-fert-title'
        >
          물을 줘서 경험치가 올랐어요
        </div>
      )}
      {level <= 6 && (
        <div
          style={{
            position: 'relative',
          }}
        >
          <div className='gauge-bg'>
            <div
              className='gauge-bar'
              style={{
                width: `${(remainExp / (expToLevelUp + remainExp)) * 100}%`,
              }}
            />
            {(isGiveWater || isRain || isGiveFert) && (
              <div
                className='gauge-exp-up'
                style={{
                  width: `${(remainExp / (expToLevelUp + remainExp)) * 98.8}%`,
                }}
              />
            )}
          </div>

          {!gganbuMode && farmingList[id] && (
            <>
              <button
                style={{
                  width: '12vw',
                  position: 'absolute',
                  right: '-7vw',
                  bottom: '-4vw',
                  zIndex: 0.1,
                }}
                className={!clickedReview && 'pulsate-fwd'}
                onClick={() => {
                  ModalStore.setIsModalOpen('review')
                  setClickedReview(true)
                  Log.send({
                    action: 'clickAlfarmReviewButton',
                    user_id: userId,
                  })
                }}
              >
                <img
                  alt=''
                  src={`/gauge/${farmingList[id]}/${level + 1}.png`}
                />
              </button>
            </>
          )}
        </div>
      )}

      <div>
        {ModalStore?.isModalOpen === 'review' && <ReviewModal token={token} />}
      </div>
    </div>
  )
}

export default GaugeBar
