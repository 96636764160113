import clsx from 'clsx'
import { useState } from 'react'

export const ModalButton = ({
  disabled = false,
  className,
  position = 'absolute',
  children,
  onClick,
}) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  return (
    <button
      type='button'
      disabled={disabled}
      className={clsx(
        'w-[60vw] h-[14vw] disabled:bg-[#B2B2B2] bg-[#F7C82A] rounded-[99px] text-black text-[4.5vw] text-center flex justify-center items-center gap-2',
        isButtonPressed && 'shadow-[inset_3px_3px_4px_rgba(79,19,0,0.15)]',
        position,
        className,
      )}
      onPointerDown={() => {
        setIsButtonPressed(true)
      }}
      onPointerCancel={() => {
        setIsButtonPressed(false)
      }}
      onPointerUp={() => {
        setIsButtonPressed(false)
      }}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
