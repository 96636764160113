import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import AuthStore from 'store/AuthStore'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { VIDEO_AD_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import { useCallback, useEffect, useState } from 'react'
import UserStore from 'store/UserStore'

const RewardAdModal = observer(
  ({ token, farmData, setFarmData, loadUserCoupon }) => {
    const navigate = useNavigate()

    const location = window.location.pathname
    const isBadaham = location === '/badaham'
    const isBadahamV2 = location === '/badaham-v2'

    const getAdType = useCallback(() => {
      if (isBadaham) {
        return VIDEO_AD_TYPE.ALFARM_BADAHAM_MISSION_REWARD
      }

      if (isBadahamV2) {
        return VIDEO_AD_TYPE.ALFARM_BADAHAM_V2_MISSION_REWARD
      }

      return VIDEO_AD_TYPE.ALFARM_WATER_DAILY_MISSION_REWARD
    }, [isBadaham, isBadahamV2])

    useEffect(() => {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'prepareRewardedAd',
          data: {
            adType: 'rewarded',
            placement: getAdType().placement,
          },
        }),
      )
    }, [getAdType])

    const handleClick = () => {
      if (isBadaham || isBadahamV2) {
        navigate(`/?token=${AuthStore.token}`, {
          state: { from: 'badahamAdWatch' },
        })
      }
    }

    return (
      <VideoAdModal>
        <VideoAdModal.Title title='광고보고 물 받기' questReward='물20~50g' />
        <VideoAdModal.ActionButton
          onClick={handleClick}
          adType={getAdType()}
          className='bottom-[9vw]'
        >
          광고보고 물 받기
        </VideoAdModal.ActionButton>
      </VideoAdModal>
    )
  },
)

export default RewardAdModal
