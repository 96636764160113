import clsx from 'clsx'

export const REWARD_STATUS = {
  LOCKED: 'locked',
  CLAIMABLE: 'claimable',
  CLAIMED: 'claimed',
}

export const REWARD_TYPE = {
  FIRST: 'first',
  SECOND: 'second',
  THIRD: 'third',
}

export const REWARD_NAME = {
  [REWARD_TYPE.FIRST]: 'SanSinFirstReward',
  [REWARD_TYPE.SECOND]: 'SanSinSecondReward',
  [REWARD_TYPE.THIRD]: 'SanSinThirdReward',
}

const getRandomReward = (rewardType) => {
  const random = Math.random()

  if (rewardType === REWARD_TYPE.FIRST) {
    if (random < 0.7) {
      return Math.floor(random * 6 + 20)
    }

    return Math.floor(random * 6 + 25)
  }

  if (rewardType === REWARD_TYPE.SECOND) {
    if (random < 0.8333) {
      return Math.floor(random * 30 + 40)
    }

    return Math.floor((random - 0.8333) * 30 + 70)
  }

  if (rewardType === REWARD_TYPE.THIRD) {
    if (random < 0.6667) {
      return Math.floor(random * 2 + 1)
    }

    return Math.floor(random * 2 + 2)
  }
}

const MissionRewardButton = ({
  type,
  status = REWARD_STATUS.LOCKED,
  position,
  onClick,
  isLoading,
}) => {
  const getPosition = (index) => {
    const BASE_POSITION = 8.6
    const STEP = 27.2

    return `${BASE_POSITION + index * STEP}vw`
  }

  const handleClick = async () => {
    const reward = getRandomReward(type)

    onClick({
      type: type === REWARD_TYPE.THIRD ? 'fertilizer' : 'water',
      amount: reward,
      rewardName: REWARD_NAME[type],
      rewardType: type,
    })
  }

  const buttonText = {
    [REWARD_STATUS.LOCKED]: {
      [REWARD_TYPE.FIRST]: '1개 완료 시',
      [REWARD_TYPE.SECOND]: '3개 완료 시',
      [REWARD_TYPE.THIRD]: '5개 완료 시',
    },
    [REWARD_STATUS.CLAIMABLE]: '받기',
    [REWARD_STATUS.CLAIMED]: '완료',
  }

  const displayButtonText =
    status === REWARD_STATUS.LOCKED
      ? buttonText[status][type]
      : buttonText[status]

  return (
    <button
      type='button'
      onClick={handleClick}
      className={clsx(
        'absolute w-[17vw] h-[7vw] rounded-[5px] font-bold text-[3vw]',
        status === REWARD_STATUS.LOCKED && 'text-black',
        status === REWARD_STATUS.CLAIMABLE && 'bg-[#85C836] text-white',
        status === REWARD_STATUS.CLAIMED && 'bg-[#94938F] text-white',
        isLoading && 'opacity-50',
      )}
      style={{
        left: getPosition(position),
        bottom: '2.8vw',
      }}
      disabled={
        status === REWARD_STATUS.CLAIMED ||
        status === REWARD_STATUS.LOCKED ||
        isLoading
      }
    >
      {displayButtonText}
    </button>
  )
}

export default MissionRewardButton
