import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import useInterval from '../../../utils/customHooks/useInterval'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import ToastStore from 'store/ToastStore'
import UserStore from 'store/UserStore'
import LoadingIndicator from 'comps/loadingIndicator'
import { sleep } from 'utils/utils'
import LottieGif from '../LottieGif'
import glitter from '../../../json/gifJson/lotteryDraw/glitter.json'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ABStore from 'store/ABStore'
import SpecialLotteryCapsules from './SpecialLotteryCapsules'
import { showRewardAd } from 'utils/rewardAdHelper'

const styles = {
  modalWrapper: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.9)',
    touchAction: 'none',
    fontFamily: 'maplestory',
  },
  closeButton: {
    width: '8vw',
    right: 32,
    top: '5vw',
    position: 'absolute',
    zIndex: 999,
  },
  actionButton: (isDisabled) => ({
    background: isDisabled
      ? 'gray'
      : 'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
    width: '60vw',
    height: '14vw',
    marginTop: '2vw',
    borderRadius: 99,
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 30,
    zIndex: 9999,
    fontFamily: 'maplestory',
    fontSize: '3.8vw',
  }),
}

const ChooseLotteryDrawModal = ({
  onClose,
  onSelectNormal,
  onSelectSpecial,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const throttleRef = useRef({})

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_SPECIAL_LOTTERY_DRAW_REWARD',
        },
      }),
    )
  }, [])

  useEffect(() => {
    AlfarmEventLogger({
      needSampling: false,
      throttleRef,
      locationType: 'modal',
      locationName: 'ChooseLotteryDrawModal',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
    })
  }, [])
  return (
    <div style={styles.modalWrapper}>
      <div
        className='pt-16'
        style={{
          width: '100%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -55%)',
          display: 'flex',
          position: 'absolute',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 16,
          paddingBottom: '16%',
          zIndex: 99,
        }}
      >
        <div className='flex justify-end w-[80vw] mb-8'>
          <button style={{ width: '8vw', zIndex: 1000 }} onClick={onClose}>
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
        </div>

        <div className='flex flex-col w-[80vw]'>
          <div className='w-full mb-8'>
            <img src='/minigame/lotteryDraw/chooseLuckyDraw.png' alt='' />
            <LottieGif
              animationData={glitter}
              style={{
                position: 'absolute',
                top: '50vw',
                right: '8vw',
                width: '40vw',
              }}
              speed={1.5}
              loop
            />
          </div>
          <button
            onClick={() => {
              setIsLoading(true)
              onSelectSpecial()
            }}
            className='w-full py-[3vw] text-center rounded-[2vw] text-[5vw] font-bold text-white h-[14vw] mb-2'
            style={{
              backgroundImage: 'url(/doubleWater/greenButtonBackground.png)',
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              color: '#366600',
            }}
          >
            특별 캡슐 뽑기
          </button>
          <button
            onClick={onSelectNormal}
            className='w-full py-[3vw] text-center rounded-[2vw] text-[5vw] font-bold text-white h-[14vw]'
            style={{
              backgroundImage: 'url(/doubleWater/yellowButtonBackground.png)',
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              color: '#9A6609',
            }}
          >
            일반 캡슐 뽑기
          </button>
          <div className='absolute w-full bottom-0 z-[140] text-[#9C9DA4] text-center ml-[-10vw] text-[4.5vw]'>
            올웨이즈와는 무관한 광고예요.
          </div>
        </div>
      </div>
      <LoadingIndicator isLoading={isLoading} />
    </div>
  )
}

const LotteryDrawModal = observer(
  ({
    token,
    setShowModal,
    farmData,
    setFarmData,
    setUpdateMinigameInfo,
    showRewardToast,
  }) => {
    const specialLotteryDrawTester =
      ABStore.specialLotteryDrawTester &&
      UserStore.farmData?.giveWaterCount >= 300

    const [scale, setScale] = useState(1)
    const [threshHold, setThreshHold] = useState(5)
    const [didPressButton, setDidPressButton] = useState(false)
    const [isInflateOn, setIsInflateOn] = useState(false)
    const [scaleStyle, setScaleStyle] = useState(
      `translate(-50%, 0%) scale(${scale}, ${scale})`,
    )
    const [showReward, setShowReward] = useState(false)
    const [backgroundSrc, setBackgroundSrc] = useState(
      'url(../minigame/lotteryDraw/background.gif)',
    )
    const [rewardSrc, setRewardSrc] = useState(
      'url(../minigame/lotteryDraw/winner.png)',
    )

    const [showChooseLotteryDrawModal, setShowChooseLotteryDrawModal] =
      useState(specialLotteryDrawTester)
    const [isSpecialLotteryDraw, setIsSpecialLotteryDraw] = useState(false)

    const images = [
      'url(../minigame/lotteryDraw/capsule1.png)',
      'url(../minigame/lotteryDraw/capsule2.png)',
      'url(../minigame/lotteryDraw/capsule3.png)',
      'url(../minigame/lotteryDraw/capsule4.png)',
      'url(../minigame/lotteryDraw/capsule5.png)',
    ]
    const specialCapsule = 'url(../minigame/lotteryDraw/specialCapsule.png)'
    const randomIndex = Math.floor(Math.random() * images.length)
    const [randomCapsule, setRandomCapsule] = useState(0)
    const throttleRef = useRef({})
    const [reward, setReward] = useState({
      water: 0,
      fertilizer: 0,
      fertilizerSet: 0,
    })

    useEffect(() => {
      setRandomCapsule(randomIndex)
    }, [])

    const [rewardForToast, setRewardForToast] = useState()

    const codePushVersionAvailable = useCheckCodePushVersion()
    const userCodePushVersion = UserStore.codePushVersionFromAlwayzApp

    function onPressButton() {
      setShowChooseLotteryDrawModal(false)
      if (farmData?.water < 10) {
        ToastStore.toastOn({
          type: 'error',
          message: '물이 부족해요! 10g이상 물이 있어야 해요',
          duration: 3000,
        })
        return
      }

      giveLotteryDrawReward()
      setIsInflateOn(true)
      setDidPressButton(true)
    }

    async function giveLotteryDrawReward() {
      let result = await backendApis.giveLotteryDrawReward(token, 'PUT', {
        isSpecial: isSpecialLotteryDraw,
      })

      const waterAmount = result?.data?.water
      const fertilizerAmount = result?.data?.fertilizer
      const fertilizerSetAmount = result?.data?.fertilizerSet

      let temp = { ...farmData }
      temp.water -= 10
      temp.water += waterAmount
      temp.fertilizer += fertilizerAmount
      temp.fertilizerSet += fertilizerSetAmount

      setReward({
        water: waterAmount,
        fertilizer: fertilizerAmount,
        fertilizerSet: fertilizerSetAmount,
      })

      if (isSpecialLotteryDraw) {
        if (waterAmount === 70) {
          setRewardSrc('url(../minigame/lotteryDraw/water70.png)')
          setRewardForToast({
            rewardAmount: waterAmount,
            rewardType: 'water',
          })
        } else if (waterAmount === 30 && fertilizerAmount === 1) {
          setRewardSrc('url(../minigame/lotteryDraw/water30fertilizer1.png)')
          setRewardForToast({
            rewardAmount: waterAmount,
            rewardType: 'waterAndFertilizer',
          })
        } else if (waterAmount === 30 && fertilizerSetAmount === 1) {
          setRewardSrc('url(../minigame/lotteryDraw/water30fertilizerSet1.png)')
          setRewardForToast({
            rewardAmount: waterAmount,
            rewardType: 'waterAndFertilizerSet',
          })
        } else if (fertilizerAmount === 2 && waterAmount === 10) {
          setRewardSrc('url(../minigame/lotteryDraw/fertilizer2.png)')
          setRewardForToast({
            rewardAmount: waterAmount,
            rewardType: 'doubleFertilizerAndWater',
          })
        } else if (fertilizerSetAmount === 2 && waterAmount === 10) {
          setRewardSrc('url(../minigame/lotteryDraw/fertilizerSet2.png)')
          setRewardForToast({
            rewardAmount: waterAmount,
            rewardType: 'doubleFertilizerSetAndWater',
          })
        }
      } else {
        if (fertilizerSetAmount > 0) {
          setRewardSrc('url(../minigame/lotteryDraw/fertilizerSet.png)')
          setRewardForToast({
            rewardAmount: fertilizerSetAmount,
            rewardType: 'fertilizerSet',
          })
        } else if (fertilizerAmount > 0) {
          setRewardSrc('url(../minigame/lotteryDraw/fertilizer.png)')
          setRewardForToast({
            rewardAmount: fertilizerAmount,
            rewardType: 'fertilizer',
          })
        } else if (waterAmount > 0) {
          setRewardSrc(`url(../minigame/lotteryDraw/water${waterAmount}.png)`)
          setRewardForToast({
            rewardAmount: waterAmount,
            rewardType: 'water',
          })
        }
      }

      setFarmData(temp)

      setUpdateMinigameInfo(true)
    }

    useEffect(() => {
      if (showReward) {
        setBackgroundSrc('')
      }
    }, [showReward])

    useInterval(
      () => {
        if (scale >= threshHold) {
          setIsInflateOn(false)
        }
        let scaleAdd = 0.06
        setScale(scale + scaleAdd)
        setScaleStyle(
          `translate(-50%, -${scale * 10 - 10}%) scale(${scale + scaleAdd}, ${
            scale + scaleAdd
          })`,
        )
      },
      isInflateOn ? 10 : null,
    )

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '120vw',
              top: '65%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: backgroundSrc,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            {didPressButton && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  width: '20vw',
                  top: '65%',
                  left: '40%',
                  transform: 'translate(-50%, -50%)',
                }}
                className='slide-out-top'
              >
                <img src='/icon/luckydrawCost.png' alt='' />
              </div>
            )}
            <div
              style={{
                width: '100%',
                height: '40vw',
                top: '-40vw',
                backgroundImage: 'url(../minigame/lotteryDraw/title.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'absolute',
              }}
            />
            {!isSpecialLotteryDraw && (
              <button
                style={{
                  width: '8vw',
                  right: 16,
                  top: '-45vw',
                  position: 'absolute',
                  zIndex: 999,
                  // borderRadius: "1px solid black",
                }}
                onClick={() => {
                  if (showReward) {
                    showRewardToast(
                      rewardForToast?.rewardAmount,
                      rewardForToast?.rewardType,
                    )
                  }
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{
                    zIndex: 101,
                  }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
            )}

            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                // border: "1px solid black",
                color: 'black',
                fontFamily: 'maplestory',
              }}
            >
              {!showReward && (
                <div
                  style={{
                    width: '40vw',
                    height: '16vw',
                    top: '84vw',
                    left: '50%',
                    transform: 'translate(-50%, 0%)',
                    position: 'absolute',
                    // backgroundImage: "url(../minigame/lotteryDraw/button.png)",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    // marginBottom: "15%",
                    // border: "1px solid black",
                  }}
                  onClick={() => {
                    if (!didPressButton) {
                      onPressButton()
                    }
                  }}
                />
              )}
              {didPressButton &&
                !showReward &&
                (isSpecialLotteryDraw ? (
                  <>
                    <SpecialLotteryCapsules
                      showRewardToast={showRewardToast}
                      rewardForToast={rewardForToast}
                      reward={reward}
                    />
                  </>
                ) : (
                  <>
                    {/* 캡슐 커지고 나서 클릭하는 부분 */}
                    {scale >= threshHold && (
                      <>
                        <div
                          style={{
                            width: '12vw',
                            height: '12vw',
                            backgroundImage:
                              'url(../minigame/waterBomb/finger.png)',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            zIndex: 98,
                            marginTop: '35vw',
                            marginLeft: '50vw',
                          }}
                          onClick={() => {
                            if (scale >= threshHold) {
                              setShowReward(true)
                            }
                          }}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            width: '12vw',
                            height: '12vw',

                            zIndex: 98,
                            marginTop: '30vw',
                            marginLeft: '14vw',
                            color: 'black',
                            fontFamily: 'maplestory',
                            bottom: '23vw',
                            left: '45vw',
                          }}
                          onClick={() => {
                            if (scale >= threshHold) {
                              setShowReward(true)
                            }
                          }}
                        >
                          클릭!
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        width: '12vw',
                        height: '10vw',
                        top: '72vw',
                        left: '50%',
                        transform: scaleStyle,
                        position: 'absolute',
                        backgroundImage: isSpecialLotteryDraw
                          ? specialCapsule
                          : images[randomCapsule],

                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        paddingTop: '57vw',
                        paddingBottom: '16%',
                        // marginBottom: "15%",
                      }}
                      onClick={() => {
                        if (scale >= threshHold) {
                          setShowReward(true)
                        }
                      }}
                    >
                      {isSpecialLotteryDraw && (
                        <LottieGif
                          animationData={glitter}
                          style={{
                            zIndex: 9000,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                          }}
                          loop
                          speed={1.5}
                        />
                      )}
                    </div>
                  </>
                ))}
              {showReward && (
                <div
                  style={{
                    top: '35vw',
                    width: '50vw',
                    height: '50vw',
                    position: 'absolute',
                    backgroundImage: rewardSrc,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    // border: "10px solid white",
                    // marginBottom: "15%",
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      top: '-40vw',
                      width: '80vw',
                      height: '50vw',
                      position: 'absolute',
                      backgroundImage:
                        'url(../minigame/lotteryDraw/congrat.png)',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                  <div
                    style={{
                      // width: "100%",
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                      fontFamily: 'maplestory',
                      color: 'black',
                      position: 'absolute',
                      textAlign: 'center',
                      bottom: '-25vw',
                      padding: '18px 44px 18px 44px',
                      borderRadius: 99,
                      fontSize: '1.5rem',
                    }}
                    onClick={() => {
                      showRewardToast(
                        rewardForToast?.rewardAmount,
                        rewardForToast?.rewardType,
                      )
                      ModalStore.setIsModalOpen('basic')
                    }}
                  >
                    보상 받기
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showChooseLotteryDrawModal &&
          UserStore.isRewardAdReady &&
          codePushVersionAvailable(userCodePushVersion, '7.0.27') && (
            <ChooseLotteryDrawModal
              onClose={() => ModalStore.setIsModalOpen('basic')}
              onSelectNormal={() => {
                setIsSpecialLotteryDraw(false)
                onPressButton()
                AlfarmEventLogger({
                  needSampling: false,
                  throttleRef,
                  locationType: 'modal',
                  locationName: 'ChooseLotteryDrawModal',
                  eventType: 'click',
                  eventName: 'cta',
                  data: { type: 'normal' },
                  collection: 'UserAlfarmClickLog',
                })
              }}
              onSelectSpecial={async () => {
                showRewardAd('rewarded', 'ALFARM_SPECIAL_LOTTERY_DRAW_REWARD')

                AlfarmEventLogger({
                  needSampling: false,
                  throttleRef,
                  locationType: 'modal',
                  locationName: 'ChooseLotteryDrawModal',
                  eventType: 'click',
                  eventName: 'cta',
                  data: { type: 'special' },
                  collection: 'UserAlfarmClickLog',
                })
                await sleep(1000 * 10)
                setIsSpecialLotteryDraw(true)
                setShowChooseLotteryDrawModal(false)
              }}
            />
          )}
      </>
    )
  },
)

export default LotteryDrawModal
