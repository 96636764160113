import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SORT_OPTIONS } from '../_constants/sortOptions'
import useOfferwallPage from './OfferwallPage/useOfferwallPage'
import backendApis from 'utils/backendApis'
import UserStore from 'store/UserStore'
import { observer } from 'mobx-react-lite'

export const FertilizerBottomsheetOfferwallItem = observer(({ farmData }) => {
  const navigate = useNavigate()
  const ref = useRef(null)
  const [offerwallItem, setOfferwallItem] = useState(null)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [dailyBadahamData, setDailyBadahamData] = useState(undefined)

  const refetchDailyBadahamData = async () => {
    const res = await backendApis.getDailyEventQuestData({
      event: 'badaham',
    })
    setDailyBadahamData(res?.data)
  }

  const canParticipate = useMemo(() => {
    if (dailyBadahamData === undefined) return false
    return (dailyBadahamData?.questInfo?.offerwall?.length || 0) < 5
  }, [dailyBadahamData])

  useEffect(() => {
    refetchDailyBadahamData()
  }, [])

  const { sortedAndFilteredList } = useOfferwallPage({
    defaultFilter: ['cpinsta', 'cpk', 'cpq', 'cpqlite'],
    defaultSortOption: SORT_OPTIONS.FAMOUS.value,
  })

  const title = useMemo(() => {
    if (!offerwallItem) return ''
    return offerwallItem?.type === 'cpinsta'
      ? '인스타 팔로우하기'
      : offerwallItem?.type === 'cpk'
      ? '카톡채널 추가하기'
      : '퀴즈 맞추기'
  }, [offerwallItem])

  const iconUrl = useMemo(() => {
    if (!offerwallItem) return ''
    return offerwallItem?.type === 'cpinsta'
      ? '/icon/cpinsta.svg'
      : offerwallItem?.type === 'cpk'
      ? '/icon/cpk.svg'
      : '/icon/cpq.svg'
  }, [offerwallItem])

  const buttonText = useMemo(() => {
    if (!offerwallItem) return ''
    return offerwallItem?.type === 'cpinsta'
      ? '참여하기'
      : offerwallItem?.type === 'cpk'
      ? '참여하기'
      : '퀴즈풀기'
  }, [offerwallItem])

  useEffect(() => {
    if (sortedAndFilteredList.length > 0) {
      setOfferwallItem(sortedAndFilteredList[0])
    }
  }, [sortedAndFilteredList])

  const handleClick = () => {
    const clickLog = {
      eventType: 'ad_click',
      userId: farmData?.userId,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      networkName: UserStore.offerwallInfo.networkName,
      placement: 'ALFARM_FERTILIZER_OFFERWALL',
      adId: offerwallItem?.id,
      adType: offerwallItem?.type,
      reward: offerwallItem?.reward,
      rewardPoint: offerwallItem?.rewardPoint,
      adName: offerwallItem?.name,
      creativeTitle: offerwallItem?.creative?.title,
      creativeDescription: offerwallItem?.creative?.description,
      reward_condition: offerwallItem?.reward_condition,
      check_participation_url: offerwallItem?.check_participation_url || '',
    }
    backendApis.recordOfferwallEventLog(clickLog)
  }

  useEffect(() => {
    const impressionLog = {
      eventType: 'ad_impression',
      userId: farmData?.userId,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      networkName: UserStore.offerwallInfo.networkName,
      placement: 'ALFARM_FERTILIZER_OFFERWALL',
      adId: offerwallItem?.id,
      adType: offerwallItem?.type,
      reward: offerwallItem?.reward,
      rewardPoint: offerwallItem?.rewardPoint,
      adName: offerwallItem?.name,
      creativeTitle: offerwallItem?.creative?.title,
      creativeDescription: offerwallItem?.creative?.description,
      reward_condition: offerwallItem?.reward_condition,
      check_participation_url: offerwallItem?.check_participation_url || '',
    }
    if (offerwallItem && ref.current) {
      backendApis.recordOfferwallEventLog(impressionLog)
    }
  }, [offerwallItem])

  if (!offerwallItem || !canParticipate) return null
  return (
    <div
      ref={ref}
      className='relative flex px-[4vw] pt-[3vw] pb-[4vw] bg-white border-b-[0.1vw] border-[#D3D7DD]'
    >
      <div className='flex items-center'>
        <img src={iconUrl} alt='' className='mr-[2vw] w-[10.6vw] h-[10.6vw]' />
        <div>
          <div className='flex flex-row gap-[1.6vw] justify-start items-center '>
            <div className='text-[4.4vw] text-[#525964]'>{title}</div>
          </div>
          <div className='flex flex-row items-center'>
            <img
              src={'/icon/fertilizer.png'}
              alt=''
              className='mr-[1vw] w-[5vw] h-full'
            />
            <div className='text-[3.6vw] text-[#6D7680] '>
              {'일반비료 ' +
                (['cpinsta', 'cpk'].includes(offerwallItem?.type)
                  ? '2개'
                  : '1개') +
                ', 하루 5번 가능'}
            </div>
          </div>
        </div>
      </div>
      <button
        className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
          ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
        onPointerDown={() => {
          setButtonClicked(true)
        }}
        onPointerCancel={() => {
          setButtonClicked(false)
        }}
        onPointerUp={() => {
          setButtonClicked(false)
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'closeAdPopcornNativeForFertilizerBottomsheet',
            }),
          )
          navigate(`/offerwall?adId=${offerwallItem.id}&from=fertilizer`)
          handleClick()
        }}
      >
        {buttonText}
      </button>
    </div>
  )
})
