import dayjs from 'dayjs'
import moment from 'moment'
import TimeStore from 'store/TimeStore'
import UserStore from 'store/UserStore'
import backendApis from './backendApis'
import DealAutomationStore from '../store/DealAutomationStore'
import ABStore from '../store/ABStore'
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

const isPurchaseUser = (farmData) => {
  const isPurchaseUser =
    UserStore?.purchaseHistorys?.map(
      (item) => TimeStore?.currentDayJS.diff(dayjs(item?.createdAt), 'd') <= 21,
    )?.length > 0 ||
    (TimeStore?.currentDayJS.diff(dayjs(farmData?.createdAt), 'd') <= 21 &&
      farmData?.isNewUser)

  return isPurchaseUser
}

const purchaseHistoryWithinPeriod = (farmData, period, count, str) => {
  const currentDate = new Date()
  let history = []

  if (UserStore?.purchaseHistorys) {
    history = [...UserStore?.purchaseHistorys]
  }
  if (farmData?.purchaseHistory) {
    history = [...history, ...farmData?.purchaseHistory]
  }

  const matchingItemsCount =
    history?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt ?? item?.purchasedAt)
      const daysDiff = (currentDate - purchaseDate) / (1000 * 60 * 60 * 24)

      return daysDiff <= period
    })?.length || 0

  return matchingItemsCount < count
}

const getUserOrderCountIn30Days = (farmData) => {
  const currentDate = new Date()
  const CHECK_PERIOD = 30 // UserStore에 들어있는 purchaseHistory의 max 기간은 61일임

  const purchaseHistory = UserStore?.purchaseHistorys
    ? [...UserStore?.purchaseHistorys]
    : farmData?.purchaseHistory
    ? [...farmData?.purchaseHistory]
    : []

  const matchingItemsCount =
    purchaseHistory?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt ?? item?.purchasedAt)
      const daysDiff = (currentDate - purchaseDate) / (1000 * 60 * 60 * 24)

      return daysDiff <= CHECK_PERIOD
    })?.length || 0

  return matchingItemsCount
}

const purchaseCountWithinPeriod = () => {
  const currentDate = new Date()
  const currentUTCDate = new Date(currentDate.getTime() + 9 * 60 * 60 * 1000) // 현재 날짜와 시간에 9시간을 더한 UTC 시간

  const todayUTCDate = new Date(currentUTCDate.toISOString().slice(0, 10)) // 오늘 날짜의 UTC 시간
  let history = UserStore?.purchaseHistorys

  const matchingItemsCount =
    history?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt)
      const purchaseUTCDate = new Date(
        purchaseDate.getTime() + 9 * 60 * 60 * 1000,
      ) // 구매 일자와 시간에 9시간을 더한 UTC 시간
      const purchaseDateString = purchaseUTCDate.toISOString().slice(0, 10) // 구매 일자를 YYYY-MM-DD 형식으로 가져옴
      return purchaseDateString === todayUTCDate.toISOString().slice(0, 10)
    })?.length || 0

  return matchingItemsCount
}

const purchaseHistoryThisWeek = () => {
  const currentDate = dayjs(new Date()).toDate()

  currentDate.setHours(0, 0, 0, 0) // 현재 날짜를 자정으로 설정

  const currentDay = currentDate.getDay() // 현재 요일을 가져옴 (0: 일요일, 1: 월요일, ..., 6: 토요일)

  const daysUntilSunday = 0 - currentDay // 현재 요일부터 다음 일요일까지의 날짜 수를 계산 (0은 일요일)

  const sundayDate = new Date(
    currentDate.getTime() + daysUntilSunday * 24 * 60 * 60 * 1000,
  ) // 이번 주 일요일의 날짜를 계산

  // const sundayUTCDate = new Date(sundayDate.getTime() + 9 * 60 * 60 * 1000) // 이번 주 일요일의 UTC 시간
  // console.log('sundayUTCDate', sundayUTCDate)
  const saturdayUTCDate = new Date(
    sundayDate.getTime() + 7 * 24 * 60 * 60 * 1000,
  ) // 이번 주 토요일의 UTC 시간

  const purchaseHistoryThisWeek =
    UserStore?.purchaseHistorys?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt)
      const purchaseUTCDate = new Date(purchaseDate.getTime()) // 구매 일자와 시간에 9시간을 더한 UTC 시간

      return purchaseUTCDate >= sundayDate && purchaseUTCDate <= saturdayUTCDate // 구매 일자가 이번 주 일요일부터 토요일 사이인 경우만 포함
    }) || []

  return purchaseHistoryThisWeek
}

const purchaseCountWithinPeriod4Hour = () => {
  const currentDate = new Date(new Date().getTime() - 4 * 60 * 60 * 1000)
  const currentUTCDate = new Date(currentDate.getTime() + 9 * 60 * 60 * 1000) // 현재 날짜와 시간에 9시간을 더한 UTC 시간
  const todayUTCDate = new Date(currentUTCDate.toISOString().slice(0, 10)) // 오늘 날짜의 UTC 시간
  const matchingItemsCount =
    UserStore?.purchaseHistorys?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt)
      const purchaseUTCDate = new Date(
        purchaseDate.getTime() + 5 * 60 * 60 * 1000,
      ) // 구매 일자와 시간에 9시간을 더한 UTC 시간

      const purchaseDateString = purchaseUTCDate.toISOString().slice(0, 10) // 구매 일자를 YYYY-MM-DD 형식으로 가져옴
      return purchaseDateString === todayUTCDate.toISOString().slice(0, 10)
    })?.length || 0

  return matchingItemsCount
}

const modalController = (farmData) => {
  let modalType = 'basic'
  const number = Math.floor(Math.random() * 10)
  if (number > 3) return modalType
  if (
    localStorage.getItem('alfarmModalControllers') ===
    moment().format('YYYY-MM-DD')
  )
    return modalType

  if (farmData?.giveWaterCount < 100) return modalType
  if (
    farmData?.clearedInviteQuest === undefined ||
    moment(new Date(farmData?.clearedInviteQuest)).format('YYYY-MM-DD') !==
      moment(new Date()).format('YYYY-MM-DD')
  ) {
    modalType = 'dailyInvite'
    localStorage.setItem(
      'alfarmModalControllers',
      moment().format('YYYY-MM-DD'),
    )
  }
  return modalType
}

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const checkIsTodayWithISODate = (comparisonTime) => {
  if (!comparisonTime) return false

  const GAME_RESET_UTC_HOUR = 19 // KST 04:00 = UTC 19:00
  const ADD_ONE_DAY = 24 * 60 * 60 * 1000

  // 1-1. 현재 시간의 ISO 형식 계산
  const now = new Date()
  let currentISODate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
    ),
  )

  // 1-2. 19시 이후면 다음날로 처리
  if (now.getUTCHours() >= GAME_RESET_UTC_HOUR) {
    currentISODate = new Date(currentISODate.getTime() + ADD_ONE_DAY)
  }

  // 2-1. 받아온 시간의 ISO 형식 계산
  const comparisonDate = new Date(comparisonTime)
  let comparisonISODate = new Date(
    Date.UTC(
      comparisonDate.getUTCFullYear(),
      comparisonDate.getUTCMonth(),
      comparisonDate.getUTCDate(),
      comparisonDate.getUTCHours(),
    ),
  )

  // 2-2. 19시 이후면 다음날로 처리
  if (comparisonDate.getUTCHours() >= GAME_RESET_UTC_HOUR) {
    comparisonISODate = new Date(comparisonISODate.getTime() + ADD_ONE_DAY)
  }

  // 3. 년/월/일만 비교
  return (
    currentISODate.getUTCFullYear() === comparisonISODate.getUTCFullYear() &&
    currentISODate.getUTCMonth() === comparisonISODate.getUTCMonth() &&
    currentISODate.getUTCDate() === comparisonISODate.getUTCDate()
  )
}

const shuffleArrayByBatch = (itemList, batchSize = 10) => {
  if (!itemList?.length || itemList.length <= 1) return itemList

  const result = Array.from(itemList)
  const itemListLength = result.length

  for (
    let startIndex = 0;
    startIndex < itemListLength;
    startIndex += batchSize
  ) {
    const endIndex = Math.min(startIndex + batchSize, itemListLength)

    for (let index = endIndex - 1; index > startIndex; index--) {
      const randomIndex =
        startIndex + Math.floor(Math.random() * (index - startIndex + 1))
      ;[result[index], result[randomIndex]] = [
        result[randomIndex],
        result[index],
      ]
    }
  }

  return result
}

const getCPMRecommendedItemsWithDefaults = async ({
  withCancelledAfterActiveCPM,
}) => {
  const RETURN_ITEM_COUNT = 500
  const viewedItemIds = checkIsTodayWithISODate(
    JSON.parse(localStorage.getItem('CPMExposedItems'))?.recentUpdatedAt,
  )
    ? JSON.parse(localStorage.getItem('CPMExposedItems'))?.exposedItemIds
    : []

  try {
    const result = await backendApis.getCPMRecommendedItems({
      viewedItemIds,
      returnAdCount: RETURN_ITEM_COUNT,
      withCancelledAfterActiveCPM,
    })

    if (result && result.status === 200) {
      const itemList = result.data.items
      const shuffledItemList = shuffleArrayByBatch(itemList)
      return shuffledItemList
    }
    return []
  } catch (error) {
    console.error(error)
    return []
  }
}

const throttleMap = new Map()

const createDefaultCPMAdDocument = async ({ type }) => {
  if (!type) return

  // 현재 시간 & 마지막 실행 시간 확인
  const now = Date.now()
  const lastCall = throttleMap.get(type)

  // 2초(2000ms) 이내 같은 타입으로 호출된 경우 이전 결과 반환
  if (lastCall && now - lastCall < 2000) {
    return
  }

  try {
    // 현재 시간 저장
    throttleMap.set(type, now)

    const result = await backendApis.createDefaultCPMAdDocumentByType({
      type,
    })
    if (result?.status === 200) {
      return result?.data
    }
    return null
  } catch (error) {
    console.error('Failed to create default ad status:', error)
    return null
  }
}

const returnTodayDealAdImageInfo = () => {
  if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.firstWinnerDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.firstWinnerDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.firstWinnerDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.firstWinnerDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.firstWinnerDealData.purchaseScreenText,
    }
  } else if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.secondWinnerDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.secondWinnerDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.secondWinnerDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.secondWinnerDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.secondWinnerDealData.purchaseScreenText,
    }
  }
}

export {
  shuffleArray,
  isPurchaseUser,
  purchaseHistoryWithinPeriod,
  purchaseCountWithinPeriod,
  purchaseHistoryThisWeek,
  purchaseCountWithinPeriod4Hour,
  modalController,
  sleep,
  checkIsTodayWithISODate,
  getCPMRecommendedItemsWithDefaults,
  returnTodayDealAdImageInfo,
  getUserOrderCountIn30Days,
  createDefaultCPMAdDocument,
}
