import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { purchaseHistoryThisWeek } from 'utils/utils'
import backendApis from 'utils/backendApis'
import BottomSheetStore from 'store/BottomSheetStore'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import { isBrowsingQuestAvailable } from 'utils/alfarmCPMConstant/utils'

const BottomSheetPurchaseQuestFertilizer = observer(
  ({
    token,
    farmData,
    setFarmData,
    setResourceType,
    setShowPopup,
    setAddFertilizerSet,
  }) => {
    if (farmData?.giveWaterCount <= 100) return null
    const [todayPurchasedCount, setTodayPurchasedCount] = useState(0)
    const [clearedFirstQuest, setClearedFirstQuest] = useState(false)
    const [clearedSecondQuest, setClearedSecondQuest] = useState(false)
    const [clearedThirdQuest, setClearedThirdQuest] = useState(false)
    const [bannerImage, setBannerImage] = useState(0)

    const DailyPurchaseRewardFert = async () => {
      BottomSheetStore.setIsbottomSheetOpen('basic')
      window.location.href = `#purchaseRewardFert.${JSON.stringify({
        screen: 'AlfarmPurchaseRewardScreen',
        rewardType: 'fert',
        isBrowsingQuestAvailable: isBrowsingQuestAvailable({
          browsingAt: farmData?.fertilizerRewardPurchaseBrowsingAt,
        }),
        scrollQuestReward: '물 10g',
        browsingQuestEnteringComponent:
          'Alfarm_fertilizerRewardPurchaseBrowsing',
      })}`
    }

    useEffect(() => {
      setTodayPurchasedCount(purchaseHistoryThisWeek()?.length || 0)
    }, [])

    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0) // 현재 날짜를 자정으로 설정
    const currentDay = currentDate.getDay() // 현재 요일을 가져옴 (0: 일요일, 1: 월요일, ..., 6: 토요일)
    const daysUntilSunday = 0 - currentDay // 현재 요일부터 다음 일요일까지의 날짜 수를 계산 (0은 일요일)
    const sundayDate = new Date(
      currentDate.getTime() + daysUntilSunday * 24 * 60 * 60 * 1000,
    ) // 이번 주 일요일의 날짜를 계산
    const saturdayDate = new Date(
      sundayDate.getTime() + 7 * 24 * 60 * 60 * 1000,
    ) // 이번 주 토요일의 날짜를 계산

    const thisWeekStart = sundayDate
    const thisWeekEnd = saturdayDate

    useEffect(() => {
      if (
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['1']) >=
          thisWeekStart &&
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['1']) <=
          thisWeekEnd
      ) {
        setClearedFirstQuest(true)
      }
      if (
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['2']) >=
          thisWeekStart &&
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['2']) <=
          thisWeekEnd
      ) {
        setClearedSecondQuest(true)
      }
      if (
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['3']) >=
          thisWeekStart &&
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['3']) <=
          thisWeekEnd
      ) {
        setClearedThirdQuest(true)
      }
    }, [farmData, thisWeekStart, thisWeekEnd])

    useEffect(() => {
      if (
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['3']) >=
          thisWeekStart &&
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['3']) <=
          thisWeekEnd
      ) {
        setBannerImage(3)
      } else if (
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['2']) >=
          thisWeekStart &&
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['2']) <=
          thisWeekEnd
      ) {
        setBannerImage(2)
      } else if (
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['1']) >=
          thisWeekStart &&
        new Date(farmData?.bottomSheetPurchaseQuestFertilizer?.['1']) <=
          thisWeekEnd
      ) {
        setBannerImage(1)
      }
    }, [todayPurchasedCount, farmData, thisWeekStart])
    const clearedQuest = async (level) => {
      const result =
        await backendApis.clearedBottomSheetPurchaseQuestFertilizer(
          token,
          'PUT',
          {
            level: level,
          },
        )

      if (result?.status === 200) {
        let tmpFarmData = farmData

        if (!tmpFarmData.bottomSheetPurchaseQuestFertilizer) {
          tmpFarmData.bottomSheetPurchaseQuestFertilizer = {}
        }

        tmpFarmData.bottomSheetPurchaseQuestFertilizer[level] = new Date()
        setFarmData(tmpFarmData)
        BottomSheetStore.setIsbottomSheetOpen('basic')
        setResourceType('fertilizerSet')
        setAddFertilizerSet(result?.data?.fertilizerSet)
        setShowPopup(true)
      }
    }

    const ActionButton1 = () => {
      if (todayPurchasedCount >= 2 && !clearedFirstQuest) {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '8vw',
            }}
            onClick={() => {
              clearedQuest(1)
            }}
          >
            받기
          </div>
        )
      } else if (todayPurchasedCount >= 1 && clearedFirstQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '10vw',
            }}
          >
            완료
          </div>
        )
      } else {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '5.25vw',
            }}
            onClick={() => {
              DailyPurchaseRewardFert()
            }}
          >
            {todayPurchasedCount}/2 구매
          </div>
        )
      }
    }
    const ActionButton2 = () => {
      if (todayPurchasedCount >= 4 && !clearedSecondQuest) {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '38.5vw',
            }}
            onClick={() => {
              clearedQuest(2)
            }}
          >
            받기
          </div>
        )
      } else if (todayPurchasedCount >= 3 && clearedSecondQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '38.5vw',
            }}
          >
            완료
          </div>
        )
      } else if (todayPurchasedCount < 4 && !clearedFirstQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '34vw',
            }}
          >
            4번 구매
          </div>
        )
      } else {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '35.5vw',
            }}
            onClick={() => {
              DailyPurchaseRewardFert()
            }}
          >
            {todayPurchasedCount}/4 구매
          </div>
        )
      }
    }
    const ActionButton3 = () => {
      if (todayPurchasedCount >= 7 && !clearedThirdQuest) {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '68.5vw',
            }}
            onClick={() => {
              clearedQuest(3)
            }}
          >
            받기
          </div>
        )
      } else if (todayPurchasedCount >= 7 && clearedThirdQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '68.5vw',
            }}
          >
            완료
          </div>
        )
      } else if (todayPurchasedCount < 7 && !clearedSecondQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '63.5vw',
            }}
          >
            7번 구매
          </div>
        )
      } else {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '64vw',
            }}
            onClick={() => {
              DailyPurchaseRewardFert()
            }}
          >
            {todayPurchasedCount}/7 구매
          </div>
        )
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          zIndex: 3,
          position: 'absolute',
          top: '-30vw',
          width: '100%',
        }}
      >
        <img
          src='/bottomsheetPurchaseQuestFertilizer/title.png'
          alt=''
          style={{
            width: '35vw',
            position: 'absolute',
            top: '-7vw',
          }}
        />

        <div
          style={{
            position: 'absolute',
            top: '-5.5vw',
            color: '#fff',
            left: '6vw',
            fontSize: '4vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              // setIsBrowsingMissionAvailable(true);
            }}
            timeStamp={new Date(saturdayDate)}
            timerMinutes={0}
            color='white'
          />{' '}
          남음
        </div>
        <div
          style={{
            position: 'absolute',
            top: '-5vw',
            color: '#fff',
            right: '7vw',
            fontSize: '4vw',
          }}
        >
          올팜 어디서든!
        </div>
        <div
          style={{
            background: '#FFF',
            width: '40vw',
            borderRadius: '5vw',
            height: '90%',
            position: 'absolute',
            bottom: '5vw',
            zIndex: -1,
          }}
        />

        <div
          style={{
            background: '#E3F3E3',
            width: '90vw',
            borderRadius: '4vw',
            border: '1.5vw solid #FFF',
            height: '26vw',
          }}
        >
          <img
            src={`/bottomsheetPurchaseQuestFertilizer/${bannerImage}.png`}
            alt=''
            style={{
              width: '80vw',
              position: 'absolute',
              top: '2vw',
              left: '10vw',
            }}
          />
          <ActionButton1 />
          <ActionButton2 />
          <ActionButton3 />
        </div>
      </div>
    )
  },
)

const styles = {
  buttonStyle: {
    background: '#F97430',
    borderRadius: '2vw',
    fontSize: '3.5vw',
    // bottom: '2.5vw',
    transform: 'translateY(calc(100% + 9.8vw))',

    position: 'absolute',
    color: 'white',
    padding: '0.5vw 2vw 0.5vw 2vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  normalButton: {
    borderRadius: '2vw',
    fontSize: '3.5vw',
    // bottom: '2vw',
    transform: 'translateY(calc(100% + 8.5vw))',

    position: 'absolute',
    color: '#000000',
    textAlign: 'center',
    padding: '1vw 2vw 1vw 2vw',
  },
}
export default BottomSheetPurchaseQuestFertilizer
