import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from 'utils/backendApis'
import ResourceToastStore from 'store/ResourceToastStore'
import ToastStore from 'store/ToastStore'
import GrapeEventPageHeader from 'comps/seasonalEvent/EventPageHeader'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import LoadingIndicator from 'comps/loadingIndicator'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ModalStore from 'store/ModalStore'
import DealEventHistoryModal from 'comps/atoms/Modals/DealEventHistoryModal'
import UserStore from 'store/UserStore'
import { ModalButton } from 'comps/atoms/buttons/ModalButton'
import clsx from 'clsx'

const DealEventSecond = observer(() => {
  const throttleRef = useRef({})
  const [isLoading, setIsLoading] = useState(true)
  const [questStatus, setQuestStatus] = useState({})
  const [questData, setQuestData] = useState({})
  const [canClick, setCanClick] = useState(true)
  const [jangbogiCoin, setJangbogiCoin] = useState(0)
  const [confirmModal, setConfirmModal] = useState(null)
  const [openNoticeModal, setOpenNoticeModal] = useState(false)

  let hidden, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  const isTester = [
    '652e877c5b42725b35fccbfe',
    '66d2e95858c039e66de7789e',
  ].includes(UserStore.farmData?.userId)

  const 설특가전_연장시점_이후_인지 =
    new Date('2025-01-22T15:00:00.000Z') <= new Date() || isTester

  const event = 'newYear2025'
  const eventTitle = '복주머니'

  const exchangeItemCard = [
    {
      type: 'water',
      needLuckBag: 10,
      rewardText: '물 50g',
      img: '../dealEvent/familyDealEvent/displaywater.png',
      color: '#B32A1A',
      headerColor: '#D8594A',
      shadow: '#B32A1A',
    },
    {
      type: 'fertilizer',
      needLuckBag: 20,
      rewardText: '일반비료 2개',
      img: '../dealEvent/familyDealEvent/displayferttilizer.png',
      color: '#435994',
      headerColor: '#576DA7',
      shadow: '#435994',
    },
    {
      type: 'fertilizerSet',
      needLuckBag: 30,
      rewardText: '고급비료 2개',
      img: '../dealEvent/familyDealEvent/displayfertilizerSet.png',
      color: '#586200',
      headerColor: '#B7C43F',
      shadow: '#869216',
    },
    {
      type: 'fertilizerSetSpecial',
      needLuckBag: 110,
      rewardText: '고급비료 8개',
      img: '../dealEvent/familyDealEvent/displayfertilizerSet.png',
      color: '#B32A1A',
      headerColor: '#FFC634',
      shadow: '#FFA113',
    },
  ]

  useEffect(() => {
    Promise.all([getUserQuestData(), getUserWaterData()])

    setIsLoading(false)
  }, [])

  useEffect(() => {
    AlfarmEventLogger({
      locationType: 'page',
      locationName: 'dealEvent',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
    })
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      await getUserQuestData()
      await getUserWaterData()
      setIsLoading(false)
    } catch (error) {
      // 오류 처리
      setIsLoading(false) // 오류 발생 시에도 로딩 상태를 해제합니다.
    }
  }

  useEffect(() => {
    const storageKey = `newYearSpecialDealNoticeModal_${UserStore.farmData?.userId}`

    const hasSeenBefore = localStorage.getItem(storageKey)

    if (isTester) {
      setOpenNoticeModal(true)
      return
    }

    if (설특가전_연장시점_이후_인지 && !hasSeenBefore) {
      setOpenNoticeModal(true)

      localStorage.setItem(storageKey, 'true')
    }
  }, [])

  useEffect(() => {
    // 페이지의 가시성 변경 이벤트 핸들러
    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        // 페이지가 다시 가시 상태로 돌아올 때 API를 다시 호출합니다.
        fetchData()
      }
    }

    // VisibilityChange 이벤트 리스너 추가
    document.addEventListener(visibilityChange, handleVisibilityChange)

    // 컴포넌트 언마운트 시에 이벤트 리스너 제거
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }

    // useEffect를 isLoading에 종속시킴으로써 가시성 변경에만 반응하도록 합니다.
  }, [isLoading])

  // useEffect(() => {
  //   if (
  //     localStorage.getItem('NewYearDealModal') !== moment().format('YYYY-MM-DD')
  //   ) {
  //     ModalStore.setIsModalOpen('NewYearDealModal')
  //     localStorage.setItem('NewYearDealModal', moment().format('YYYY-MM-DD'))
  //   }
  // }, [])

  const getUserQuestData = async () => {
    const result = await backendApis.getDealEventUserData()

    setQuestStatus(result?.data?.questStatus)
    setQuestData(result?.data?.questData)
  }

  const getUserWaterData = async () => {
    const result = await backendApis.getWaterRepoData({
      project: {
        jangbogiCoin: 1,
      },
    })
    setJangbogiCoin(result?.data?.jangbogiCoin || 0)
  }

  const missionList = [
    {
      type: 'checkin',
      title: `${eventTitle} 상점 출석하기`,
      rewardText: '1개, 하루 최대 3번 가능',
      buttonText: '출석하기',
      img: '../dealEvent/familyDealEvent/checkin.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: event,
          eventType: 'click',
          eventName: 'checkin',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        clearQuest('checkin')
      },
      isCleared: questStatus?.checkin,
      isOver: questData?.checkin?.length >= 3,
      cooltime: questData?.checkin
        ? new Date(
            new Date(
              questData?.checkin[questData?.checkin?.length - 1],
            ).getTime() +
              1000 * 60 * 60 * 3,
          )
        : new Date(new Date().getTime() + 1000 * 60 * 60 * 3),
    },
    {
      type: 'browsing',
      title: `설 특가 상품 30초 구경하기`,
      rewardText: '2개, 하루 최대 3번 가능',
      buttonText: '구경하기',
      img: '../dealEvent/familyDealEvent/browsing.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'dealEvent',
          eventType: 'click',
          eventName: 'browsing',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        sendToPurchaseScreen('browsingQuest')
      },
      isCleared: questStatus?.browsing,
      isOver: questData?.browsing?.length >= 3,
      cooltime: questData?.browsing
        ? new Date(
            new Date(
              questData?.browsing[questData?.browsing?.length - 1],
            ).getTime() +
              1000 * 60 * 60 * 3,
          )
        : new Date(new Date().getTime() + 1000 * 60 * 60 * 3),
    },
    {
      type: 'purchase',
      title: '설 특가 상품 1개 구매하기',
      rewardText: '35개, 고급비료 5개',
      buttonText: '구매하기',
      img: '../dealEvent/familyDealEvent/purchase.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'familyDealEvent',
          eventType: 'click',
          eventName: 'purchase',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        sendToPurchaseScreen('purchaseQuest')
      },
      isCleared: false,
      isOver: false,
    },
  ]

  const sendToPurchaseScreen = async (questType) => {
    // window.location.href = `#generalNavigator.${JSON.stringify({
    //   screen: 'WebviewScreen',
    //   params: {
    //     url: `https://alwayz-shop-front.prod.ilevit.com/deals/cart-deal-24`,
    //     enteringComponent: `Alfarm_${event}`,
    //     enteringLocation: 'browsingQuest',
    //     isFrom: `Alfarm_${event}`,
    //     isTimerOn: questType === 'browsingQuest' ? true : false,
    //     timerText: '미션 완료까지 ',
    //     isTimerEndText: `축하합니다! ${rewardItem} 2개를 받았어요`,
    //     timeStamp: new Date(new Date().getTime() + 1000 * 30),
    //     rewardText: `상품을 구매하면 고급비료 5, ${rewardItem} 35개를 드려요`,
    //     questAmount: '2',
    //     rewardType: `${rewardItem}`,
    //     timeMinute: 0.5,
    //   },

    // 2025 설 기획전 매대 스크린
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'WebviewScreen',
      params: {
        title: '설 특가 상품',
        enteringComponent: `Alfarm_${event}`,
        enteringLocation: questType,
        isFrom: `Alfarm_${event}`,
        dealTypes: ['mainBanner_newYear2025'],
        isBrowsingQuestAvailable: questType === 'browsingQuest' ? true : false,
        missionText: `초 구경하면 ${eventTitle} 2개을 받아요`,
        isDealRecommend: true,
        timer: 30,
        enabledScrollTimer: questType === 'browsingQuest' ? true : false,
        buyingReward: `고급비료 5개, ${eventTitle} 35개`,
        webviewPrefixType: 'shop',
        url: '/deals/main-banner-new-year-2025',
      },
    })}`
  }

  const clearQuest = async (questType) => {
    if (!canClick) return
    setCanClick(false)
    const result = await backendApis.clearDealEvent({
      questType: questType,
    })

    if (result?.data?.success) {
      ResourceToastStore?.setResourceToast({
        open: true,
        type: 'luckyBag',
        count: result?.data?.rewardAmount,
      })

      setQuestStatus({
        ...questStatus,
        [questType]: true,
      })
      if (questType === 'checkin' || questType === 'checkinSpecial') {
        setJangbogiCoin(jangbogiCoin + result?.data?.rewardAmount)
        if (questData?.checkin) {
          setQuestData({
            ...questData,
            [questType]: [
              ...questData?.checkin,
              new Date(new Date().getTime()),
            ],
          })
        } else {
          setQuestData({
            ...questData,
            [questType]: [new Date(new Date().getTime())],
          })
        }
      }

      setCanClick(true)
    }
  }

  const exchangeLuckyBag = async (type) => {
    const result = await backendApis.exchangeLuckyBag({ type })
    if (!result?.data) {
      ToastStore.toastOn({
        type: 'error',
        message: `${eventTitle}가 부족해요. 미션을 완료해주세요.`,
        duration: 2000,
      })
    }
    if (result?.data?.success) {
      setJangbogiCoin(jangbogiCoin - result?.data?.needLuckBag)
      ResourceToastStore?.setResourceToast({
        open: true,
        type: type,
        count: result?.data?.rewardAmount,
      })
    }
  }

  const MissionButton = ({ item }) => {
    if (
      item?.type === 'checkinSpecial' &&
      !item?.isOver &&
      new Date().getHours() < 21
    ) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              setQuestStatus({
                ...questStatus,
                checkinSpecial: false,
              })
            }}
            timeStamp={
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                21,
                0,
                0,
              )
            }
            timerMinutes={0}
            color='#442b22'
          />
        </div>
      )
    }
    if (item?.type === 'checkinSpecial' && new Date().getHours() >= 22) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          내일가능
        </div>
      )
    }
    if (item?.isOver) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          내일가능
        </div>
      )
    } else if (item?.isCleared) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              if (item?.type === 'checkin') {
                setQuestStatus({
                  ...questStatus,
                  checkin: false,
                })
              }
              if (item?.type === 'browsing') {
                setQuestStatus({
                  ...questStatus,
                  browsing: false,
                })
              }
            }}
            timeStamp={item?.cooltime}
            timerMinutes={0}
            color='#442b22'
          />
        </div>
      )
    } else {
      return (
        <div
          style={{
            padding: '2vw',
            background: '#FFC634',
            width: '22vw',
            borderRadius: '8px',
            color: '#FFFFFF',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
            boxShadow:
              '0px -1px 0px 0px #FFD874 inset, 0px 2px 0px 0px #FFA234',
            textShadow:
              '1.5px 0 0 #FF8A00, -1.5px 0 0 #FF8A00, 0 1.5px 0 #FF8A00, 0 -1.5px 0 #FF8A00, 1.5px 1.5px #FF8A00, -1.5px -1.5px 0 #FF8A00, 1.5px -1.5px 0 #FF8A00, -1.5px 1.5px 0 #FF8A00',
          }}
          onClick={() => {
            item?.onClick()
          }}
        >
          {item?.buttonText}
        </div>
      )
    }
  }

  if (isLoading) return null

  return (
    <div
      className={clsx(
        'w-screen h-screen fixed flex flex-col bg-[#F3E9DC] bg-cover bg-no-repeat bg-center',
        { 'overflow-y-scroll': !openNoticeModal },
      )}
    >
      <GrapeEventPageHeader />
      <div className='relative z-10'>
        {/* 헤더영역 */}
        <img
          src={`../dealEvent/${event}/${
            설특가전_연장시점_이후_인지
              ? 'dealEventHeader_v2'
              : 'dealEventHeader'
          }.png`}
          alt='dealEventHeader'
          className='relative w-[65vw] mt-[-1vw] ml-[17.5vw]'
        />
        {/* 우측 상단 레코드 버튼 */}
        {/* 서브헤더영역 */}
        <img
          src={`../dealEvent/${event}/dealEventSubHeader.png`}
          alt='dealEventSubHeader'
          className='w-[100vw] mt-[-10vw] ml-[0vw]'
        />
        {/* 보유 아이템 영역 디자인 */}
        <div
          className='bg-[#FFFFFF] w-[40vw] h-[13vw] rounded-[10vw] border-[0.5vw] border-[#821812] mx-auto mt-[2vw] z-10 absolute ml-[30vw] flex justify-center'
          style={{
            boxShadow: '0px 2px 0px 0px #946134',
          }}
        >
          {/* 아이템, 보유텀트, 0개 */}
          <div className='flex justify-center items-center flex-row'>
            <img
              src={`../dealEvent/${event}/coin.png`}
              alt='dealEventCoin'
              className='w-[10vw] mr-[2vw]'
            />
            <div>
              <div className='text-[#821812] text-[3.5vw] text-center font-bold opacity-70 mb-[-1.5vw] mt-[0.5vw]'>
                내 {eventTitle}
              </div>
              <div className='text-[#821812] text-[5vw] text-center font-bold'>
                {jangbogiCoin}개
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative'>
        {/* 핑크 배경 영역 */}
        <img
          src={`../dealEvent/${event}/dealEventRewardBody.png`}
          alt='dealEventRewardBody'
          className='absolute w-[100vw] mt-[-70vw] opacity-90 z-[-1]'
        />
        {/* 노란배경영역 */}
        <img
          src={`../dealEvent/${event}/dealEventRewardBodyDetail.png`}
          alt='dealEventRewardBodyDetail'
          className={clsx(
            'w-[100vw] h-[144vw] absolute top-[-10vw] ml-[0vw] border-[#821812]',
            설특가전_연장시점_이후_인지 ? 'h-[144vw]' : 'h-[139vw]',
          )}
        />
        {설특가전_연장시점_이후_인지 && (
          <div className='text-[1.125rem] text-[#6A3615] flex justify-center items-center w-full absolute font-light top-[17vw]'>
            여러분의 성원에 힘입어 설특가전이 연장됩니다.
          </div>
        )}
        <div
          className={clsx(
            'left-1/2 transform -translate-x-1/2 w-[90vw] grid grid-cols-2 gap-y-[2vw] gap-x-[2vw] absolute',
            설특가전_연장시점_이후_인지 ? 'top-[26vw]' : 'top-[21vw]',
          )}
        >
          {exchangeItemCard.map((item, index) => {
            return (
              <div
                key={index}
                className='w-full overflow-hidden flex justify-center items-center flex-col bg-[#FFFFFF] rounded-[3vw]  border-[2px] border-[#946134]'
              >
                {/* 아이템 상단 헤드 배경 */}
                <div
                  style={{
                    backgroundColor: item.headerColor,
                    textShadow:
                      '1px 0 0 #FFFFFF, -1px 0 0 #FFFFFF, 0 1px 0 #FFFFFF, 0 -1px 0 #FFFFFF, 1px 1px #FFFFFF, -1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF',
                  }}
                  className=' w-full text-[#6A3615] text-[4.2vw] font-bold flex items-center justify-center pt-[0.8rem] pb-[0.5rem] relative'
                >
                  <img
                    className='w-[6vw] mr-[1vw]'
                    src={`../dealEvent/${event}/coin.png`}
                    alt='dealEventCoin'
                  />
                  {item.needLuckBag}개{' '}
                </div>
                <div className='flex items-center justify-center'>
                  <img
                    className='w-[19vw] pt-[3vw] pb-[1vw] flex items-center justify-center'
                    src={`../dealEvent/familyDealEvent/display${item?.type}.png`}
                    alt='dealEvent'
                  />
                </div>
                <div className='text-[#6A3615] text-[4vw] pb-[1vw] flex items-center justify-center mt-[-1vw] '>
                  {item.rewardText}
                </div>

                <div
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    textShadow:
                      '1px 0 0 #FFFFFF, -1px 0 0 #FFFFFF, 0 1px 0 #FFFFFF, 0 -1px 0 #FFFFFF, 1px 1px #FFFFFF, -1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF',
                    background:
                      jangbogiCoin >= item.needLuckBag
                        ? item.headerColor
                        : '#CDCDCD',
                    boxShadow: `0px 2px 0px 0px ${
                      jangbogiCoin >= item.needLuckBag
                        ? item.shadow
                        : 'transparent'
                    }`,
                    borderRadius: '100vw',
                    width: '90%',
                    height: '20%',
                    marginBottom: '2.5vw',
                    padding: '1vw',
                    fontWeight: 'bold',
                    fontSize: '4vw',
                    color:
                      jangbogiCoin >= item.needLuckBag ? item.color : '#CDCDCD',
                  }}
                  onClick={() => {
                    if (jangbogiCoin < item.needLuckBag) return
                    if (jangbogiCoin >= item.needLuckBag) {
                      setConfirmModal({
                        type: item.type,
                        rewardText: item.rewardText,
                        needLuckBag: item.needLuckBag,
                      })
                    }
                  }}
                >
                  교환하기
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <>
        <div className='absolute mb-[6vw] w-[100vw] mx-auto mt-[192vw] pb-[3vw]'>
          <div className='w-[95vw] h-[78vw] mx-auto bg-[#FFF7D6]/50 rounded-[6vw] mt-[6vw] mb-[4vw] pb-[4vw] border-[0.62vw] border-[#821812] '>
            <div className='mx-auto mt-[1vw] p-[4vw] text-[5.5vw] text-[#821812] text-center font-bold'>
              {eventTitle} 미션
            </div>

            <div className='flex flex-col gap-[10px]'>
              {missionList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`py-[6px] border-[#946134] border-[2px] rounded-[10px] bg-white/50 flex-row flex w-[90vw] justify-between items-center mx-auto p-2`}
                  >
                    <div className='flex flex-row items-center'>
                      <div className='pr-[3.5vw] pl-[1vw]'>
                        <img
                          src={item?.img}
                          alt='mission'
                          className='w-[11vw] mt-[1vw]'
                        />
                      </div>
                      <div className='flex flex-col mt-[1vw]'>
                        <div className='text-[#6A3615] text-[4vw]'>
                          {item?.title}
                        </div>
                        <div className='flex flex-row items-center'>
                          <div>
                            <img
                              className='w-[5vw] mt-[1vw]'
                              src={`../dealEvent/${event}/coin.png`}
                              alt='coin'
                            />
                          </div>
                          <div className='text-[3.5vw] text-[#6A3615] ml-[1vw] mt-[1vw]'>
                            {item?.rewardText}
                          </div>
                        </div>
                        <div className='text-[3.5vw] text-[#6A3615] items-center flex'>
                          {item.desc}
                        </div>
                      </div>
                    </div>
                    <MissionButton item={item} />
                  </div>
                )
              })}
            </div>
          </div>
          <div
            style={{
              color: '#6A3615',
              fontSize: '3.8vw',
              lineHeight: '6vw',
              // marginTop:
              //   new Date() <= new Date('2024-09-10 23:59:59') ? '220vw' : '130vw',
              marginTop: '16vw',
              textAlign: 'left',
              background: '#F3E9DC',
              width: '95vw',
              paddingLeft: '4vw',
              paddingTop: '8vw',
              paddingBottom: '20vw',
            }}
          >
            - 설 연휴 전 상품마다 배송 마감 시간이 상이할 수 있으니, 상품별 상세
            페이지 및 판매자 문의를 통해 꼭 확인해 주세요.
            <br />- 이벤트 기간은 2025.01.10 오전 9시 ~{' '}
            {설특가전_연장시점_이후_인지 ? '2025.02.07' : '2025.01.24'} 오전
            9시에요.
            <br />- 이벤트 기간 중 미션을 완료하면 {eventTitle}를 획득할 수
            있어요.
            <br />- {eventTitle}는 설 특가전 상점에서 교환할 수 있어요.
            <br />- 보상을 수령하고 구매한 상품을 취소하는 경우 페널티가
            발생해요.
            <br />- 기능의 허점을 악용하여 재화를 과취득하는 경우 이용이 제한될
            수 있어요.
            <br />- 이미 교환된 {eventTitle}는 다시 복구할 수 없어요.
            <br />- 올팜 설특가전 상점 외에서 설날특가 상품을 구매한 경우에는
            올팜 보상이 지급되지 않아요.
            <br />- 미션은 새벽 4시를 기준으로 초기화 돼요.
            <br />- 기간 내 사용하지 않은 {eventTitle}는 이벤트 종료 후
            소멸돼요.
            <br />- 이벤트는 사전고지 없이 변경 또는 종료될 수 있어요.
          </div>
        </div>
      </>
      {openNoticeModal && (
        <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
          <div
            className='w-[90vw] h-[134vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute items-center flex-col px-[16px] pt-[30vw] pb-[16px]'
            style={{
              backgroundImage: 'url(../modal/modalBackground.png)',
            }}
          >
            <div
              className='absolute top-[5.6vw] flex font-bold text-[2.375rem]'
              style={{
                color: '#FFFFFF',
                textShadow:
                  '2.4px 0 0 #874300, -2.4px 0 0 #874300, 0 2.4px 0 #874300, 0 -2.4px 0 #874300, 2.4px 2.4px #874300, -2.4px -2.4px 0 #874300, 2.4px -2.4px 0 #874300, -2.4px 2.4px 0 #874300',
              }}
            >
              상점 공지
            </div>
            <div className='flex flex-col gap-[1.6vw] items-center'>
              <div className='p-[0.75rem] rounded-[1.25rem] border-2 border-[#E9D6B0] w-[74vw] bg-white flex flex-col items-center'>
                <div className='text-[5vw] text-[#402C24] text-center font-bold'>
                  설특가전 상점 오픈 연장
                </div>
                <div className='text-[3.8vw] text-[#815F51] text-center font-light'>
                  여러분의 뜨거운 성원에 힘입어
                  <br />
                  설특가전 상점 운영 기간을 연장합니다!
                </div>
                <div className='text-[4.4vw] text-[#B64416] text-center font-bold'>
                  연장 기간 : 2월 7일 오전9시까지
                </div>
              </div>
              <div className='p-[0.75rem] rounded-[1.25rem] border-2 border-[#E9D6B0] w-[74vw] bg-white flex flex-col items-center'>
                <div className='text-[5vw] text-[#402C24] text-center font-bold'>
                  복주머니 사용기간
                </div>
                <div className='text-[3.8vw] text-[#815F51] text-center font-light'>
                  2월 7일 오전 9시까지
                </div>
              </div>
              <div className='p-[0.75rem] rounded-[1.25rem] border-2 border-[#E9D6B0] w-[74vw] bg-white flex flex-col items-center'>
                <div className='text-[5vw] text-[#402C24] text-center font-bold'>
                  택배 관련 공지
                </div>
                <div className='text-[3.8vw] text-[#815F51] text-center font-light'>
                  설 연휴 기간 동안 택배가 일시 중단되오니,
                  <br />이 점 참고하시어 주문 부탁드립니다.
                </div>
              </div>
              <ModalButton
                position='relative'
                onClick={() => setOpenNoticeModal(false)}
              >
                확인
              </ModalButton>
            </div>
          </div>
        </div>
      )}

      {/* 모달영역 */}
      {confirmModal && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: 'white',
              color: '#6A3615',
              textAlign: 'center',
              padding: '4vw',
              borderRadius: '4vw',
              width: '70vw',
              fontSize: '4.5vw',
              lineHeight: '6vw',
            }}
          >
            <div
              style={{
                color: '#6A3615',
                fontSize: '6vw',
                marginTop: '2vw',
              }}
            >
              확인
            </div>
            <br />
            {eventTitle} {confirmModal?.needLuckBag}개를 사용해
            <br />
            {confirmModal?.rewardText}으로 교환하시겠어요?
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '2vw',
                flexDirection: 'row',
                marginLeft: '6vw',
                marginRight: '6vw',
                marginBottom: '2vw',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2vw',
                  border: '0.5vw solid #FFC634',
                  color: '#6A3615',
                  padding: '2vw',
                  borderRadius: '4vw',
                  width: '22vw',
                }}
                onClick={() => {
                  setConfirmModal(null)
                }}
              >
                취소
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2vw',
                  background: '#FFC634',
                  padding: '2vw',
                  borderRadius: '4vw',
                  width: '22vw',
                }}
                onClick={() => {
                  exchangeLuckyBag(confirmModal.type)
                  setConfirmModal(null)
                }}
              >
                확인
              </div>
            </div>
          </div>
        </div>
      )}
      <LoadingIndicator isLoading={isLoading} />
      {/* {ModalStore?.isModalOpen === 'NewYearDealModal' && <NewYearDealModal />} */}
      {ModalStore?.isModalOpen === 'familyRecordModal' && (
        <DealEventHistoryModal eventTitle={eventTitle} />
      )}
    </div>
  )
})

export default DealEventSecond
