import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import MinigameUpperReward from './MinigameUpperReward'
import MinigameItem from './MinigameItem'
import ReactLoading from 'react-loading'
import ModalStore from '../../store/ModalStore'
import useCheckCodePushVersion from '../../utils/customHooks/useConvertCodePushVersion'
import AB3 from 'utils/ab_v3'
import BottomSheetHeader from 'comps/molecules/BottomSheetHeader'
import CPMBottomSheetButton from './CPMBottomSheetButton'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import { getCPMButtonPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import ABStore from 'store/ABStore'
import BottomSheetStore from 'store/BottomSheetStore'
import UserStore from 'store/UserStore'

const Divider = () => (
  <div
    style={{
      width: '100%',
      height: 4,
      backgroundColor: '#EDEDED',
      fontSize: 18,
      marginTop: 4,
    }}
  />
)

const Minigame = observer(
  ({
    token,
    itemType,
    farmData,
    userCodePushVersion,
    setFarmData,
    updateMinigameInfo,
    setUpdateMinigameInfo,
    showRewardToast,
    randomString,
  }) => {
    const [minigameInfo, setMinigameInfo] = useState()
    const [isWaterBombPlayedToday, setIsWaterBombPlayedToday] = useState(true)
    const [isScratchLottoPlayedToday, setIsScratchLottoPlayedToday] =
      useState(true)
    const [isLotteryDrawPlayedToday, setIsLotteryDrawPlayedToday] =
      useState(true)
    const [isYutnoriPlayedToday, setIsYutnoriPlayedToday] = useState(true)
    const [clearedYutnoriHistory, setClearedYutnoriHistory] = useState()
    const [waterBombPlayHistory, setWaterBombPlayHistory] = useState([])
    const [drawCount, setDrawCount] = useState(3)
    const [isLoading, setIsLoading] = useState(true)
    const [scrollIndicator, setScrollIndicator] = useState(false)
    const codePushVersionAvailable = useCheckCodePushVersion()
    const scratchLottoTester =
      ABStore.scratchLottoTester && UserStore.farmData?.giveWaterCount >= 100

    const getMinigameInfo = async () => {
      setIsLoading(true)
      const [result, tempDrawCount] = await Promise.all([
        backendApis.getMinigameInfo(token),
        backendApis.getDrawCount(token),
      ])

      setMinigameInfo(result?.data)
      setIsWaterBombPlayedToday(
        !result?.data?.minigameAvailabilityInfo?.waterBombPlayAvailable,
      )
      setIsLotteryDrawPlayedToday(
        !result?.data?.minigameAvailabilityInfo?.lotteryDrawPlayAvailable,
      )

      if (!ABStore.isCPMAdViewer) {
        setIsYutnoriPlayedToday(
          result?.data?.minigameAvailabilityInfo?.yutnoriPlayedToday,
        )
        setClearedYutnoriHistory(result?.data?.clearedYutnoriHistory)
      }
      setIsScratchLottoPlayedToday(
        !result?.data?.minigameAvailabilityInfo?.scratchLottoPlayAvailable,
      )
      setWaterBombPlayHistory(result?.data?.waterBombPlayHistory || [])
      setDrawCount(tempDrawCount?.drawCount)
      setIsLoading(false)
    }

    useEffect(() => {
      getMinigameInfo()
    }, [])

    useEffect(() => {
      if (!isLoading) {
        const observer = new IntersectionObserver((e) => {
          setScrollIndicator(!e?.[0]?.isIntersecting)
        })
        const div = document.querySelector('#minigameBottomSheetEnd')
        if (div) observer.observe(div)
        return () => observer.disconnect()
      }
    }, [isLoading])

    useEffect(() => {
      if (updateMinigameInfo) {
        getMinigameInfo()
        setUpdateMinigameInfo(false)
      }
    }, [updateMinigameInfo])

    return (
      <div className={`max-h-[${scratchLottoTester ? '80' : '70'}vh]`}>
        <BottomSheetHeader
          historyModalType={'minigameHistory'}
          title='아케이드'
        />

        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 350,
            }}
          >
            <ReactLoading
              type={'spin'}
              color={'#E979BF'}
              height={'10vw'}
              width={'10vw'}
            />
          </div>
        ) : (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                backgroundColor: '#EEE1FE',
                paddingTop: '3vw',
                paddingBottom: '3vw',
              }}
            >
              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  textAlign: 'center',
                  margin: '0px 0px 8px 0px',
                  fontSize: '4vw',
                }}
              >
                아케이드를 즐기고 추가 보상 받으세요!
              </div>
              <MinigameUpperReward
                minigameInfo={minigameInfo}
                userCodePushVersion={userCodePushVersion}
                drawCount={drawCount}
                isWaterBombPlayedToday={isWaterBombPlayedToday}
                isLotteryDrawPlayedToday={isLotteryDrawPlayedToday}
                token={token}
                setUpdateMinigameInfo={setUpdateMinigameInfo}
                showRewardToast={showRewardToast}
                isLoading={isLoading}
                farmData={farmData}
                setFarmData={setFarmData}
                isYutnoriPlayedToday={isYutnoriPlayedToday}
                isScratchLottoPlayedToday={isScratchLottoPlayedToday}
              />
            </div>
            <div
              style={{
                overflowY: 'scroll',
                paddingBottom: '6vw',
                height: scratchLottoTester ? '55vh' : '90vw',
              }}
            >
              {scratchLottoTester && (
                <>
                  <MinigameItem
                    type='scratchLotto'
                    clickFunc={() => ModalStore.setIsModalOpen('scratchLotto')}
                    isPlayedToday={isScratchLottoPlayedToday}
                    farmData={farmData}
                  />
                  <Divider />
                </>
              )}
              {codePushVersionAvailable(userCodePushVersion, '4.5.20') && (
                <>
                  {!ABStore.isCPMAdViewer && (
                    <MinigameItem
                      type='yutnori'
                      clickFunc={() => ModalStore.setIsModalOpen('yutnori')}
                      isPlayedToday={isYutnoriPlayedToday}
                      farmData={farmData}
                      clearedYutnoriHistory={clearedYutnoriHistory}
                    />
                  )}
                  {ABStore.isCPMAdViewer && (
                    <CPMBottomSheetButton
                      ENTERING_COMPONENT={CPM_ENTERING_COMPONENTS.YUTNORI_AD}
                      BUTTON_PROPS={getCPMButtonPropsByType(
                        CPM_ENTERING_COMPONENTS.YUTNORI_AD,
                      )}
                      closeBottomSheet={() => {
                        BottomSheetStore.setIsbottomSheetOpen('basic')
                      }}
                    />
                  )}
                  <Divider />
                </>
              )}
              <MinigameItem
                type='waterBomb'
                clickFunc={() => ModalStore.setIsModalOpen('waterBombGame')}
                isPlayedToday={isWaterBombPlayedToday}
                waterBombPlayHistory={waterBombPlayHistory}
              />
              <Divider />
              <MinigameItem
                type='luckyDraw'
                clickFunc={() => ModalStore.setIsModalOpen('luckydraw')}
                isPlayedToday={drawCount >= 3}
              />
              <Divider />
              <MinigameItem
                type='lotteryDraw'
                clickFunc={() => ModalStore.setIsModalOpen('lotteryDrawModal')}
                isPlayedToday={isLotteryDrawPlayedToday}
              />
              <Divider />
              <MinigameItem
                type='sidepick'
                clickFunc={() => {
                  ModalStore.setIsModalOpen('basic')
                  window.location.href = `/sidePickGame?token=${token}&itemType=${itemType}&randomNumber=${randomString}`
                }}
                isPlayedToday={false}
              />
              <div id='minigameBottomSheetEnd' />
              <div style={{ height: 12 }} />
            </div>
          </div>
        )}

        {scrollIndicator && (
          <div
            className='bounce-top'
            style={{
              width: 35,
              height: 35,
              position: 'fixed',
              bottom: 36,
              left: '50%',
              backgroundImage: 'url(../waterBottomSheet/scrollIndicator.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        )}
      </div>
    )
  },
)

export default Minigame
