import WhiteTextTimer from 'comps/WhiteTextTimer'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import ModalStore from 'store/ModalStore'
import backendApis from 'utils/backendApis'
import timeChecker from 'utils/timeChecker'
import { sleep } from 'utils/utils'
import ABStore from 'store/ABStore'
import UserStore from 'store/UserStore'
import AlfarmAdStore from 'store/AlfarmAdStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import LoadingIndicator from '../../loadingIndicator'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import { showRewardAd } from 'utils/rewardAdHelper'

const EggBreakersV2 = observer(({ token, farmData, setFarmData }) => {
  const [isBroken, setIsBroken] = useState(false)
  const [unBroken, setUnBroken] = useState(true)
  const [isBreaking, setIsBreaking] = useState(false)
  const [canBreakEgg, setCanBreakEgg] = useState(false)
  const [mainEggData, setMainEggData] = useState(null)
  const [subEggDataList, setSubEggDataList] = useState(null)
  const [mainEggId, setMainEggId] = useState(null)
  const [subEggIdList, setSubEggIdList] = useState([])
  const [itemIdList, setItemIdList] = useState([])
  const [rewardAmount, setRewardAmount] = useState(0)
  const [noEgg, setNoEgg] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingRewardedAd, setIsLoadingRewardedAd] = useState(false)
  const eggRewardEnhanceTester =
    ABStore.eggRewardEnhanceTester && !UserStore.cherryPickerForAd
  const [chance, setChance] = useState(0)
  const codePushVersionAvailable = useCheckCodePushVersion()
  const codePushVersionForAdmob = codePushVersionAvailable(
    UserStore?.codePushVersionFromAlwayzApp,
    '6.1.5',
  )
  const eggBreakersRewardedAdCheck =
    UserStore.cherryPickerForAd &&
    (!codePushVersionForAdmob ||
      (codePushVersionForAdmob && !ABStore?.admobAlfarmTester))

  useEffect(() => {
    if (eggBreakersRewardedAdCheck) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'prepareRewardedAd',
          data: {
            adType: 'rewarded',
            placement: 'ALFARM_EGG_BREAKERS_MISSION_REWARD',
          },
        }),
      )
    }
  }, [eggBreakersRewardedAdCheck])

  let buttonText = '상품 구경하기'
  let subText = '상품 보면 물 '
  if (
    farmData?.breakEggHistory?.[farmData?.breakEggHistory?.length - 1]
      ?.isBrowsing
  ) {
    buttonText = '상품 구매하기'
  } else if (
    eggBreakersRewardedAdCheck &&
    !UserStore.isRewardAdReady &&
    isLoadingRewardedAd
  ) {
    buttonText = '광고 준비중..'
    subText = '광고 보면 물 '
  } else if (eggBreakersRewardedAdCheck && UserStore.isRewardAdReady) {
    buttonText = '광고 보기'
    subText = '광고 보면 물 '
  }

  const checkCanOpenEgg = async () => {
    const result = await backendApis.getMainEggData(token)
    // 광고 있을 때 - 과거에 본 egg 광고 데이터 & 가장 비싼 광고 1개 가져옴 (쓸 수 있는 광고의 유무만 확인)
    if (result) {
      setIsLoading(false)
    }
    // 광고 소진 됐을 떄 -> setNoEgg
    if (!result?.data) {
      setNoEgg(true)
    }
  }

  const checkRemainedEggCount = async () => {
    const filteredEggHistory = farmData?.breakEggHistory?.filter((x) => {
      return !timeChecker(x?.brokenAt)
    })
    const chance = filteredEggHistory?.length || 0
    setChance(chance)
  }

  const getEggData = async () => {
    // 소비자가 과거 본 광고 중 오늘 것만 필터링
    const filteredEggHistory = farmData?.breakEggHistory?.filter((x) => {
      return !timeChecker(x?.brokenAt)
    })
    if (
      // 오늘 하루 최대치 다 했고, 마지막 알 꺤 시점으로부터 n시간 넘었으면 -> setNoEgg
      filteredEggHistory?.length >= AlfarmAdStore.eggAdDailyMaxExposureCount &&
      new Date(
        farmData?.breakEggHistory[
          farmData?.breakEggHistory?.length - 1
        ]?.brokenAt,
      ).getTime() +
        1000 * 60 * 60 * AlfarmAdStore.eggAdTimeIntervalBetweenExposure <
        new Date().getTime()
    ) {
      setNoEgg(true)
      // 알 깬지 x시간 안 됐으면 -> 소비자가 봤던 광고 찾아서 setEggData
    } else if (
      farmData?.breakEggHistory &&
      new Date(
        farmData?.breakEggHistory[
          farmData?.breakEggHistory?.length - 1
        ]?.brokenAt,
      ).getTime() +
        1000 * 60 * 60 * AlfarmAdStore.eggAdTimeIntervalBetweenExposure >
        new Date().getTime()
    ) {
      const eggId =
        farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
          ?.eggId || null
      const subEggId1 =
        farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
          ?.subEggId1 || null
      const subEggId2 =
        farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
          ?.subEggId2 || null
      const result = await backendApis.getEggListByEggIds(token, 'GET', {
        eggId,
        subEggId1,
        subEggId2,
      })

      if (result?.status === 200) {
        setMainEggData(result?.data?.mainEggData)
        setSubEggDataList(result?.data?.subEggDataList)
        setIsBroken(true)
        setUnBroken(false)
        setRewardAmount(
          farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
            ?.water,
        )
      }
    } else {
      //알 깬지 x시간 넘었는데 오늘 본 거 최대치 수량 미만
      setCanBreakEgg(true)
    }
  }

  const BreakEgg = async () => {
    if (!canBreakEgg) return
    setCanBreakEgg(false)
    setUnBroken(false)
    setIsBreaking(true)
    // 새로운 광고 데이터들, 리워드 양, 성공 여부 들고옴 + gep에 데이터 저장 + 광고 종료
    const result = await backendApis.breakEggV2(token, 'PUT', {
      isRewardTester: eggRewardEnhanceTester,
    })

    if (result?.data?.success) {
      setRewardAmount(result?.data?.rewardAmount)
      setMainEggData(result?.data?.mainEggData)
      setSubEggDataList(result?.data?.subEggDataList)
      setTimeout(() => {
        setIsBreaking(false)
        setIsBroken(true)
      }, 1500)
      // farmData 즉시 업데이트 (안 나갔다가 와도 되도록)
      let temp = { ...farmData }
      Object.assign(temp, {
        breakEggHistory: [
          {
            eggId: result?.data?.mainEggData?._id,
            subEggId1: result?.data?.subEggDataList?.[0]?._id,
            subEggId2: result?.data?.subEggDataList?.[1]?._id,
            brokenAt: new Date(),
            water: result?.data?.rewardAmount,
            isBrowsing: false,
          },
        ],
      })
      setFarmData(temp)
    }
  }

  const buttonClick = () => {
    if (
      eggBreakersRewardedAdCheck &&
      !farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
        ?.isBrowsing
    ) {
      if (UserStore.isRewardAdReady) {
        ModalStore.setIsModalOpen('basic')
        showRewardAd('rewarded', 'ALFARM_EGG_BREAKERS_MISSION_REWARD')
      } else {
        if (itemIdList.length > 1) {
          const adPurchaseScreenParams = {
            screen: 'AlfarmAdPurchaseScreen',
            params: {
              title: '올팜',
              image:
                'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
              ratio: 99 / 360,
              enteringComponent: 'Alfarm_eggBreakers',
              adId: mainEggId,
              subAdId: subEggIdList,
              itemIds: itemIdList,
              questReward: rewardAmount,
              isTimerOn:
                !farmData?.breakEggHistory?.[
                  farmData.breakEggHistory.length - 1
                ]?.isBrowsing,
              questRewardType: 'water',
              questRewardText: `초간 구경하면 물 ${rewardAmount}g을 받아요!`,
              questTimer: 0.25,
            },
          }
          window.location.href = `#generalNavigator.${JSON.stringify(
            adPurchaseScreenParams,
          )}`
        } else {
          const eggBreakersScreenParams = {
            itemId: itemIdList[0],
            eggId: mainEggId,
            enteringComponent: 'Alfarm_eggBreakers',
            imageSource:
              'https://assets.ilevit.com/bab48071-27cf-4eaf-80ec-c7ccd7425754.png',
            timeStamp: new Date(new Date().getTime() + 1000 * 15),
            isTimerOn:
              !farmData?.breakEggHistory?.[farmData.breakEggHistory.length - 1]
                ?.isBrowsing,
            timerText: '미션 완료까지 ',
            isTimerEndText: `축하합니다! 물 ${rewardAmount}g을 받았어요`,
            questAmount: rewardAmount,
            rewardText: `상품을 구매하면 물 1,000g, 고급비료 5개를 받아요`,
          }
          window.location.href = `#eggBreakers.${JSON.stringify(
            eggBreakersScreenParams,
          )}`
        }
        ModalStore.setIsModalOpen('basic')
        AlfarmEventLogger({
          locationType: 'page',
          locationName: 'not_ready_rewardAd_Egg_breakers',
          eventType: 'pageview',
          data: {},
          collection: 'UserAlfarmPageviewLog',
        })
      }
    } else {
      if (itemIdList.length > 1) {
        const adPurchaseScreenParams = {
          screen: 'AlfarmAdPurchaseScreen',
          params: {
            title: '올팜',
            image:
              'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
            ratio: 99 / 360,
            enteringComponent: 'Alfarm_eggBreakers',
            adId: mainEggId,
            subAdId: subEggIdList,
            itemIds: itemIdList,
            questReward: rewardAmount,
            isTimerOn:
              !farmData?.breakEggHistory?.[farmData.breakEggHistory.length - 1]
                ?.isBrowsing,
            questRewardType: 'water',
            questRewardText: `초간 구경하면 물 ${rewardAmount}g을 받아요!`,
            questTimer: 0.25,
          },
        }
        window.location.href = `#generalNavigator.${JSON.stringify(
          adPurchaseScreenParams,
        )}`
      } else {
        const eggBreakersScreenParams = {
          itemId: itemIdList[0],
          eggId: mainEggId,
          enteringComponent: 'Alfarm_eggBreakers',
          imageSource:
            'https://assets.ilevit.com/bab48071-27cf-4eaf-80ec-c7ccd7425754.png',
          timeStamp: new Date(new Date().getTime() + 1000 * 15),
          isTimerOn:
            !farmData?.breakEggHistory?.[farmData.breakEggHistory.length - 1]
              ?.isBrowsing,
          timerText: '미션 완료까지 ',
          isTimerEndText: `축하합니다! 물 ${rewardAmount}g을 받았어요`,
          questAmount: rewardAmount,
          rewardText: `상품을 구매하면 물 1,000g, 고급비료 5개를 받아요`,
        }
        window.location.href = `#eggBreakers.${JSON.stringify(
          eggBreakersScreenParams,
        )}`
      }
      ModalStore.setIsModalOpen('basic')
    }
  }

  const HeaderTitle = () => {
    return (
      <div
        style={{
          position: 'absolute',
          fontSize: '4.7vw',
          width: '70%',
          textAlign: 'center',
          top: '20%',
          height: '30vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          lineHeight: '1.4',
          color: '#7C5C35',
          borderRadius: '20px',
          background: '#FFF',
          boxShadow: '0px -6px 0px 0px rgba(151, 176, 195, 0.34) inset',
          paddingTop: '1vw',
        }}
      >
        {noEgg && !isBroken && (
          <>
            열 수 있는 포춘쿠키가 없어요! <br /> 다음에 다시 이용해주세요
          </>
        )}
        {!isBroken && !noEgg && eggRewardEnhanceTester && (
          <>
            포춘쿠키를 열고 <br /> 물 최대 30g을 받아보세요! <br />
            <div
              style={{
                fontSize: '3.3vw',
                color: '#7C5C35',
                marginTop: '2.2vw',
              }}
            >
              {' '}
              남은 횟수 : {AlfarmAdStore.eggAdDailyMaxExposureCount -
                chance} 번 <br />{' '}
            </div>
          </>
        )}
        {!isBroken && !noEgg && !eggRewardEnhanceTester && (
          <>
            포춘쿠키를 열고 <br /> 물 최대 15g을 받아보세요! <br />
            <div
              style={{
                fontSize: '3.3vw',
                color: '#7C5C35',
                marginTop: '2.2vw',
              }}
            >
              {' '}
              남은 횟수 : {AlfarmAdStore.eggAdDailyMaxExposureCount -
                chance} 번 <br />{' '}
            </div>
          </>
        )}

        {isBroken && (
          <>
            {eggBreakersRewardedAdCheck ? (
              <>
                {farmData?.breakEggHistory[
                  farmData?.breakEggHistory?.length - 1
                ]?.isBrowsing ? (
                  <>
                    상품을 구매하고 <br /> 보상을 받아보세요!
                  </>
                ) : (
                  <>
                    광고를 보면 <br /> 물 {rewardAmount}g을 받아요
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        ModalStore.setIsModalOpen('basic')
                      }}
                      style={{
                        marginTop: '1vw',
                      }}
                      timeStamp={
                        farmData?.breakEggHistory
                          ? new Date(
                              farmData?.breakEggHistory[
                                farmData?.breakEggHistory?.length - 1
                              ]?.brokenAt,
                            )
                          : new Date()
                      }
                      timerMinutes={
                        60 * AlfarmAdStore.eggAdTimeIntervalBetweenExposure
                      }
                      color='#CD201F'
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {farmData?.breakEggHistory[
                  farmData?.breakEggHistory?.length - 1
                ]?.isBrowsing ? (
                  <>
                    상품을 구매하고 <br /> 보상을 받아보세요!
                  </>
                ) : (
                  <>
                    상품을 15초 구경하면 <br /> 물 {rewardAmount}g을 받아요
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        ModalStore.setIsModalOpen('basic')
                      }}
                      style={{
                        marginTop: '1vw',
                      }}
                      timeStamp={
                        farmData?.breakEggHistory
                          ? new Date(
                              farmData?.breakEggHistory[
                                farmData?.breakEggHistory?.length - 1
                              ]?.brokenAt,
                            )
                          : new Date()
                      }
                      timerMinutes={
                        60 * AlfarmAdStore.eggAdTimeIntervalBetweenExposure
                      }
                      color='#CD201F'
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }

  const Egg = () => {
    if (noEgg && !isBroken) {
      return (
        <div
          style={{
            width: '70%',
            marginTop: '30%',
            marginLeft: '0%',
          }}
        >
          <img src='../eggBreakers/fortuneCookieEmpty.png' alt='' />
        </div>
      )
    } else if (!noEgg && !isBroken && !isBreaking) {
      return (
        <div
          style={{
            width: '70%',
            marginTop: '32%',
            marginLeft: '0%',
          }}
          onClick={() => {
            BreakEgg()
          }}
        >
          <img src='../eggBreakers/fortuneCookie.png' alt='' />
          <div
            style={{
              width: '45%',
              marginTop: '-70%',
              position: 'absolute',
              right: '0%',
            }}
            className='hammer'
          >
            <img src='../eggBreakers/hammer.png' alt='' />
          </div>
        </div>
      )
    } else if (isBroken) {
      return (
        <div
          style={{
            width: '100%',
          }}
          onClick={() => {
            BreakEgg()
          }}
        >
          <>
            <div
              style={{
                marginTop: '-27%',
                marginLeft: '20%',
                position: 'absolute',
                width: '60%',
                height: '65vw',
                background:
                  'linear-gradient(90deg, #FFFFFF 0%, #FFF1D4 50.01%, #FFFFFF 98.42%)',
                borderRadius: '5vw',
                boxShadow: '0vw 6vw 6vw rgba(0, 0, 0, 0.25)',
              }}
              //광고 전환을 위한 임시 분기, 아래 하나 더 있음
              onClick={() => {
                if (!eggBreakersRewardedAdCheck) {
                  if (itemIdList.length > 1) {
                    window.location.href = `#generalNavigator.${JSON.stringify({
                      screen: 'AlfarmAdPurchaseScreen',
                      params: {
                        title: '올팜',
                        image:
                          'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
                        ratio: 99 / 360,
                        enteringComponent: 'Alfarm_eggBreakers',
                        adId: mainEggId,
                        subAdId: subEggIdList,
                        itemIds: itemIdList,
                        questReward: rewardAmount,
                        isTimerOn: farmData?.breakEggHistory[
                          farmData?.breakEggHistory?.length - 1
                        ]?.isBrowsing
                          ? false
                          : true,
                        questRewardType: 'water',
                        questRewardText: `초간 구경하면 물 ${rewardAmount}g을 받아요!`,
                        questTimer: 0.25,
                      },
                    })}`
                    ModalStore.setIsModalOpen('basic')
                  } else {
                    const purchaseData = {
                      itemId: itemIdList?.[0],
                      eggId: mainEggId,
                      enteringComponent: 'Alfarm_eggBreakers',
                      imageSource:
                        'https://assets.ilevit.com/bab48071-27cf-4eaf-80ec-c7ccd7425754.png',
                      timeStamp: new Date(new Date().getTime() + 1000 * 15),
                      isTimerOn: farmData?.breakEggHistory[
                        farmData?.breakEggHistory?.length - 1
                      ]?.isBrowsing
                        ? false
                        : true,
                      timerText: '미션 완료까지 ',
                      isTimerEndText: `축하합니다! 물 ${rewardAmount}g을 받았어요`,
                      questAmount: rewardAmount,
                      rewardText: `상품을 구매하면 물 1,000g, 고급비료 5개를 받아요`,
                    }

                    window.location.href = `#eggBreakers.${JSON.stringify(
                      purchaseData,
                    )}`
                    ModalStore.setIsModalOpen('basic')
                  }
                }
              }}
            >
              {/* {mainEggData?.price && (
                <div
                  style={{
                    position: 'absolute',
                    marginTop: '78%',
                    width: '50%',
                    height: '8vw',
                    background: '#A11816',
                    right: '7.5%',
                    borderBottomRightRadius: '2vw',
                    color: '#FFF422',
                    textAlign: 'center',
                    fontSize: '5.5vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  국내최저가
                </div>
              )} */}
              {mainEggData?.coupon && (
                <div
                  style={{
                    position: 'absolute',
                    marginTop: '78%',
                    width: '35%',
                    height: '8vw',
                    background: '#F26201',
                    left: '7.5%',
                    borderBottomLeftRadius: '2vw',
                    color: '#FFF7DA',
                    textAlign: 'center',
                    fontSize: '5.5vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  쿠폰
                </div>
              )}
              <img
                style={{
                  border: '0.5vw solid #ECBC70',
                  width: '85%',
                  marginLeft: '7.5%',
                  marginTop: '7.5%',
                  borderRadius: '2vw',
                }}
                src={mainEggData?.itemImage}
                alt=''
              />

              <div
                style={{
                  position: 'absolute',
                  fontSize: '4vw',
                  width: '100%',
                  textAlign: 'center',
                  height: '17vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bottom: '0vw',
                  lineHeight: '1.3',
                  color: 'black',
                }}
              >
                {eggBreakersRewardedAdCheck && '광고 본 후,'} 상품 구매하면{' '}
                <br />물 1,000g, 고급비료 5개 지급
              </div>
              <img
                style={{
                  position: 'absolute',
                  width: '120%',
                  top: '96%',
                  right: '-10%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                src='../eggBreakers/brokenFortuneCookie.png'
                alt=''
              />
            </div>
          </>
        </div>
      )
    } else if (isBreaking) {
      return (
        <div
          style={{
            width: '70%',
            marginTop: '32%',
            marginLeft: '0%',
          }}
          className='wobble-hor-bottom'
        >
          <img src='../eggBreakers/breakingFortuneCookie.png' alt='' />
        </div>
      )
    }
  }

  const Button = () => {
    if (noEgg && unBroken) {
      return <></>
    } else if (!noEgg && !isBroken && !isBreaking) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: '13.5vw',
            width: '70%',
            left: '15%',
          }}
          onClick={() => {
            BreakEgg()
          }}
        >
          <img src='../eggBreakers/button.png' alt='' />
          <div
            style={{
              position: 'absolute',
              fontSize: '6vw',
              color: '#CC2024',
              width: '100%',
              textAlign: 'center',
              bottom: '7vw',
            }}
          >
            포춘쿠키 열기
          </div>
        </div>
      )
    } else if (isBreaking) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: '13.5vw',
            width: '70%',
            left: '15%',
          }}
        >
          <img src='../eggBreakers/button.png' alt='' />
          <div
            style={{
              position: 'absolute',
              fontSize: '6vw',
              color: '#CC2024',
              width: '100%',
              textAlign: 'center',
              bottom: '7vw',
            }}
          >
            포춘쿠키 여는 중...
          </div>
        </div>
      )
    } else if (!isBreaking && isBroken) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: '13.5vw',
            width: '70%',
            left: '15%',
          }}
          onClick={buttonClick}
        >
          {!farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
            ?.isBrowsing && (
            <div
              style={{
                boxSizing: 'border-box',
                position: 'absolute',
                width: '45vw',
                height: '9vw',
                background: '#FC6749',
                border: '1px solid #FFFFFF',
                borderRadius: '8vw 8vw 8vw 0vw',
                fontSize: '4.5vw',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '-6vw',
                right: '-8vw',
              }}
            >
              {subText}
              {
                farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
                  ?.water
              }
              g 획득
            </div>
          )}
          <img src='../eggBreakers/button.png' alt='' />

          <div
            style={{
              position: 'absolute',
              fontSize: '6vw',
              color: '#CC2024',
              width: '100%',
              textAlign: 'center',
              bottom: '7vw',
            }}
          >
            {!farmData?.breakEggHistory[farmData?.breakEggHistory?.length - 1]
              ?.isBrowsing ? (
              <>{buttonText}</>
            ) : (
              '상품 구매하기'
            )}
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    checkRemainedEggCount()
    const handleRewardedAdReady = async () => {
      if (eggBreakersRewardedAdCheck && !UserStore.isRewardAdReady) {
        setIsLoadingRewardedAd(true)
        // 광고 대상자인데 로드 안된경우 0.3초마다 확인해 최대 3초까지 대기
        for (let i = 0; i < 10; i += 1) {
          if (UserStore.isRewardAdReady) break
          // 로드되면 반복 종료
          await sleep(300)
        }
        setIsLoadingRewardedAd(false)
      }
    }

    checkCanOpenEgg()
    getEggData()
    handleRewardedAdReady()
  }, [])

  useEffect(() => {
    setMainEggId(mainEggData?._id)
    setSubEggIdList(
      [subEggDataList?.[0]?._id, subEggDataList?.[1]?._id]?.filter((e) => e),
    )
    const mainEggItemIdList =
      mainEggData?.itemIds || (mainEggData?.itemId ? [mainEggData?.itemId] : [])
    const subAdItem1IdList =
      subEggDataList?.[0]?.itemIds ||
      (subEggDataList?.[0]?.itemId ? [subEggDataList?.[0]?.itemId] : [])
    const subAdItem2IdList =
      subEggDataList?.[1]?.itemIds ||
      (subEggDataList?.[1]?.itemId ? [subEggDataList?.[1]?.itemId] : [])
    const combinedItemIds = [
      ...mainEggItemIdList,
      ...subAdItem1IdList,
      ...subAdItem2IdList,
    ]
    setItemIdList(combinedItemIds)
  }, [mainEggData, subEggDataList])

  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 5004,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
      }}
    >
      {isLoadingRewardedAd && (
        <LoadingIndicator isLoading={isLoadingRewardedAd} />
      )}
      {!isLoading && (
        <div
          style={{
            width: '90vw',
            height: '180vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: 'url(../eggBreakers/fortuneCookieModal.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          {!isBreaking && (
            <button
              style={{
                width: '10%',
                top: '15vw',
                right: '5%',
                position: 'absolute',
                zIndex: 3,
                background: 'black',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          )}

          <HeaderTitle />
          <Egg />

          <Button />
        </div>
      )}
    </div>
  )
})

export default EggBreakersV2
