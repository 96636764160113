export const SORT_OPTIONS = {
  RECOMMENDED: {
    value: 'recommended',
    label: '추천순',
    sortFn: (a, b) => {
      const typeOrder = {
        cpm: 0,
        cpc: 1,
        cpylike: 2,
        cpyoutube: 3,
        cpl: 4,
        cpinsta: 5,
        cpk: 6,
        cpqlite: 7,
        cpq: 8,
        cpa: 9,
      }
      const orderA = typeOrder[a.type] ?? Number.MAX_SAFE_INTEGER
      const orderB = typeOrder[b.type] ?? Number.MAX_SAFE_INTEGER
      return orderA - orderB
    },
  },
  REWARD_HIGH: {
    value: 'rewardHigh',
    label: '혜택 많은순',
    sortFn: (a, b) => b.rewardPoint - a.rewardPoint,
  },
  REWARD_LOW: {
    value: 'rewardLow',
    label: '혜택 적은순',
    sortFn: (a, b) => a.rewardPoint - b.rewardPoint,
  },
  FAMOUS: {
    value: 'famous',
    label: '인기순',
    sortFn: (a, b) => {
      const typeOrder = {
        cpinsta: 0,
        cpk: 1,
        cpq: 2,
        cpqlite: 3,
      }
      const orderA = typeOrder[a.type] ?? Number.MAX_SAFE_INTEGER
      const orderB = typeOrder[b.type] ?? Number.MAX_SAFE_INTEGER
      return orderA - orderB
    },
  },
}
