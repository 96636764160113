import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import YutnoriResult from './YutnoriResult'
import ModalStore from 'store/ModalStore'
import YutThrowAnimation from './YutThrowAnimation'
import ABStore from 'store/ABStore'
import UserStore from 'store/UserStore'
import { sleep } from 'utils/utils'
import AlfarmAdStore from 'store/AlfarmAdStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import LoadingIndicator from '../loadingIndicator'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import { showRewardAd } from 'utils/rewardAdHelper'

// 모든 스타일 여기에 객체로 정의해놓고 쓸거임
const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalFrame: {
    width: '80vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5001,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  modalBody: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    width: '60vw',
    height: '70vw',
    justifyContent: 'center',
  },
  modalCloseButtonStyle: {
    width: '8vw',
    right: '0vw',
    top: '-42vw',
    position: 'absolute',
    zIndex: 999,
  },
  buttonImage: {
    position: 'absolute',
    display: 'flex',
    bottom: '-30vw',
    width: '60vw',
    left: '12.5%',
    alignItems: 'center',
  },
  buttonTextStyle: {
    position: 'absolute',
    fontSize: '6.5vw',
    color: '#663F16',
    width: '100%',
    textAlign: 'center',
  },
  headerMessageStyle: {
    position: 'absolute',
    fontSize: '4.8vw',
    width: '60vw',
    textAlign: 'center',
    top: '-14vw',
    height: '18vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1.3',
    color: '#7C5C35',
    borderRadius: '20px',
    background: '#FFF',
    boxShadow: '0px -6px 0px 0px rgba(151, 176, 195, 0.34) inset',
    whiteSpace: 'pre-line',
  },
  titleStyle: {
    width: '50%',
    top: '-34vw',
    left: '24%',
    zIndex: '101',
    position: 'absolute',
    display: 'flex',
  },
  infoButtonStyle: {
    width: '50%',
    color: 'white',
    fontFamily: 'Maplestory',
    textAlign: 'center',
    fontSize: '5vw',
    marginBottom: '1vw',
    marginTop: '-3vw',
    // textDecoration: 'underline',
    zIndex: 3000,
    position: 'absolute',
    bottom: '-12vw',
    // left: "-15vw",
  },
  infoModalStyle: {
    flex: 1,
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: 5100,
    width: '80%',
    // background: "rgba(0,0,0,0.9)",
    background: 'white',
    borderRadius: 20,
    fontFamily: 'maplestory',
    // fontSize: "4vw",
    padding: '6vw',
    paddingTop: '8vw',
    lineHeight: '170%',
    paddingBottom: '8vw',
    transform: 'translate(-50%,-50%)',
  },
}

const YutnoriGameModalV2 = observer(
  ({
    token,
    farmData,
    setFarmData,
    setUpdateMinigameInfo,
    setYutnoriPurchaseEntryButtonOn,
  }) => {
    // States
    const [gameComplete, setGameComplete] = useState(true)
    const [rewardComplete, setRewardComplete] = useState(true)
    const [adExhausted, setAdExhausted] = useState(false)
    const [yutPlaying, setYutPlaying] = useState(false)
    const [mainAdData, setMainAdData] = useState([])
    const [subAdData, setSubAdData] = useState([])
    const [reward, setReward] = useState(0)
    const [timerid, setTimerid] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingRewardedAd, setIsLoadingRewardedAd] = useState(false)
    const [modalBackgroundImage, setModalBackgroundImage] = useState(
      'url(../minigame/yutnori/yutnoriFieldBackground.png',
    )
    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const codePushVersionAvailable = useCheckCodePushVersion()
    const codePushVersionForAdmob = codePushVersionAvailable(
      UserStore?.codePushVersionFromAlwayzApp,
      '6.1.5',
    )
    const yutnoriRewardedAdCheck =
      UserStore.cherryPickerForAd &&
      (!codePushVersionForAdmob ||
        (codePushVersionForAdmob && !ABStore?.admobAlfarmTester))

    const ref = useRef(null)
    const outerRef = useRef(null)
    const intervalTime = AlfarmAdStore.yutnoriTimeIntervalBetweenExposure

    const getPlayStatusData = async () => {
      if (yutnoriRewardedAdCheck && UserStore.isWatchingAd === true)
        await sleep(1000)

      try {
        const result = await backendApis.getYutnoriPlayStatusV2(token, 'GET', {
          intervalTime,
        })
        const data = result?.data
        const currentDate = new Date()
        //  최근 x시간 내 플레이 기록 없으면 playedAt 반환이 안됨
        const playedAtDate = data?.playedAt ? new Date(data?.playedAt) : null

        // 두 날짜의 차이를 밀리초 단위로 계산
        const differenceInMilliseconds = data?.playedAt
          ? Math.abs(currentDate - playedAtDate)
          : 0

        // 밀리초를 시간으로 변환 (1시간 = 3600000 밀리초)
        const differenceInHours = differenceInMilliseconds / 3600000

        if (!data?.played) {
          if (!data?.mainAdData) {
            setAdExhausted(true)
          } else {
            setMainAdData(data?.mainAdData)
            setSubAdData(data?.subAdData)
          }
        } else {
          setMainAdData(data?.mainAdData)
          setSubAdData(data?.subAdData)
          setGameComplete(true)
          setReward(data?.reward)
          setRewardComplete(!!data?.rewarded)
        }
        if (
          playedAtDate === null ||
          differenceInHours >= AlfarmAdStore.yutnoriTimeIntervalBetweenExposure
        ) {
          setGameComplete(false)
        }

        if (result?.data) {
          setIsLoading(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    const mainAdItemIds = mainAdData?.itemIds || []
    const subAdItem1Ids = subAdData?.[0]?.itemIds || []
    const subAdItem2Ids = subAdData?.[1]?.itemIds || []

    const combinedItemIds = [
      ...mainAdItemIds,
      ...subAdItem1Ids,
      ...subAdItem2Ids,
    ]
    const mainAdId = mainAdData?._id
    const subAdId1 = subAdData?.[0]?._id || null
    const subAdId2 = subAdData?.[1]?._id || null
    const subAdIds = [subAdId1, subAdId2].filter((e) => e)

    const sendToPurchaseScreen = () => {
      ModalStore.setIsModalOpen('basic')
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'AlfarmAdPurchaseScreen',
        params: {
          title: '올팜',
          image:
            'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png', // s3 이미지 가져오기 - 바꿔줘야함
          ratio: 99 / 360,
          enteringComponent: 'Alfarm_YutNoriPurchase',
          adId: mainAdData?._id,
          subAdId: subAdIds,
          itemIds: combinedItemIds,
          questReward: reward,
          isTimerOn: !rewardComplete,
          questRewardType: 'water',
          questRewardText: `초간 구경하면 물 ${reward}g을 받아요!`,
          questTimer: 0.25,
        },
      })}`
    }

    // 상단 말풍선 띄워주는 거
    const HeaderMessage = () => {
      let message = ''

      const rewardToText = (reward) => {
        if (reward === 20) {
          return "'도'가"
        } else if (reward === 25) {
          return "'개'가"
        } else if (reward === 30) {
          return "'걸'이"
        } else if (reward === 40) {
          return "'윷'이"
        } else if (reward === 50) {
          return "'모'가"
        } else {
          return "'도'가"
        }
      }

      if (yutPlaying) {
        message = '두근두근~!\n어떤 결과가 나올까요?'
      } else if (!gameComplete & !adExhausted) {
        message = '윷을 던져보세요!'
      } else if (!gameComplete && adExhausted) {
        message = '오늘은 윷을 던질 수 없어요.\n내일 다시 도전해주세요!'
      } else if (gameComplete && !rewardComplete) {
        if (yutnoriRewardedAdCheck && UserStore.isRewardAdReady) {
          message = `${rewardToText(
            reward,
          )} 나왔어요!\n광고를 보면 물 ${reward}g을 받아요`
        } else {
          message = `${rewardToText(
            reward,
          )} 나왔어요!\n상품을 구경하면 물 ${reward}g을 받아요`
        }
      } else if (gameComplete && rewardComplete) {
        if (yutnoriRewardedAdCheck) {
          message = '오늘은 윷을 던질 수 없어요.\n내일 다시 도전해주세요!'
        } else {
          message = '상품을 구매하면\n물 1,000g, 고급비료 5개를 드려요'
        }
      }

      return (
        <div
          style={{
            ...styles.headerMessageStyle,
            fontSize: message?.length > 20 ? '4vw' : '4.8vw',
          }}
        >
          {message}
        </div>
      )
    }

    const InfoModal = () => {
      if (!infoModalOpen) return null

      if (infoModalOpen) {
        return (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              top: -200,
              zIndex: 5000,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div style={{ ...styles.infoModalStyle }}>
              <button
                style={{
                  width: '8%',
                  top: '5vw',
                  right: '5vw',
                  zIndex: 2001,
                  position: 'absolute',
                }}
                onClick={() => {
                  setInfoModalOpen(false)
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    zIndex: 3,
                  }}
                  src='/icon/bottomCloseButton.png'
                  alt=''
                />
              </button>
              <div
                style={{
                  fontSize: '5vw',
                  color: 'black',
                  marginBottom: '1.5vw',
                  textAlign: 'center',
                }}
              >
                도움말
              </div>
              <div
                style={{
                  fontSize: '3.5vw',
                  color: 'black',
                  marginBottom: '1.5vw',
                  textAlign: 'center',
                }}
              >
                - 버튼을 눌러서 윷을 던지면 <br /> 도, 개, 걸, 윷, 모 중 하나가
                나와요. <br />
                - 결과에 따라 보상이 달라요. <br />
                - 도: 물 20g <br />
                - 개: 물 25g <br />
                - 걸: 물 30g <br />
                - 윷: 물 40g <br />
                - 모: 물 50g <br />
                - 윷을 던진 후 상품을 15초 구경하면 보상을 받아요. <br />-
                상품을 구매하면 추가로 물 1,000g, 고급비료 5개를 받아요. <br />
              </div>
            </div>
          </div>
        )
      }
    }

    // 하단 게임 관련 버튼 정의
    const GameButton = () => {
      let buttonText = ''
      let onClickHandler = null

      if (isLoading) {
        buttonText = '로딩 중...'
      } else if (yutPlaying) {
        buttonText = '던지는 중...'
      } else if (!gameComplete && !adExhausted) {
        buttonText = '윷 던지기'
        onClickHandler = () => {
          playGame()
        }
      } else if (!gameComplete && adExhausted) {
        buttonText = '윷 던지기'
        // onClickHandler = () => {
        //   alert("오늘은 할 수 없어요.\n내일 다시 도전해주세요!");
        // };
      } else if (gameComplete && !rewardComplete) {
        if (yutnoriRewardedAdCheck) {
          if (UserStore.isRewardAdReady) {
            buttonText = `광고보고 물 ${reward}g 받기`
          } else {
            buttonText = `구경하고 물 ${reward}g 받기`
          }
          onClickHandler = () => {
            if (UserStore.isRewardAdReady) {
              ModalStore.setIsModalOpen('basic')
              showRewardAd('rewarded', 'ALFARM_YUTNORI_MISSION_REWARD')
            } else {
              sendToPurchaseScreen()
              AlfarmEventLogger({
                locationType: 'page',
                locationName: 'not_ready_rewardAd_YutNori',
                eventType: 'pageview',
                data: {},
                collection: 'UserAlfarmPageviewLog',
              })
            }
          }
        } else {
          buttonText = `구경하고 물 ${reward}g 받기`
          onClickHandler = () => {
            sendToPurchaseScreen()
            // setRewardComplete(true);
          }
        }
      } else if (gameComplete && rewardComplete) {
        buttonText = '상품 구매하기'
        onClickHandler = () => {
          sendToPurchaseScreen()
        }
      }

      return (
        <button style={{ ...styles.buttonImage }} onClick={onClickHandler}>
          <img src='../minigame/yutnori/yutnoriButtonImage.png' alt='' />
          <div
            style={{
              ...styles.buttonTextStyle,
              fontSize: buttonText?.length > 10 ? '5.5vw' : '6.5vw',
            }}
          >
            {buttonText}
          </div>
        </button>
      )
    }

    // 윷 던지기 버튼 누르면 도는 거
    const playGame = async () => {
      // ... 게임 종료 로직
      if (!gameComplete && adExhausted) return
      setYutPlaying(true)
      try {
        const result = await backendApis.playYutnoriV2(token, 'POST', {
          mainAdId,
          subAdIds,
        })
        const data = result?.data

        if (data) {
          setUpdateMinigameInfo(true)
          setYutnoriPurchaseEntryButtonOn(true)
          setFarmData({
            ...farmData,
            lastYutnoriPlayedAt: new Date(),
          })

          const timer = setTimeout(() => {
            setYutPlaying(false)
            setGameComplete(true)
            setReward(data?.reward)
            setRewardComplete(false)
            setGameComplete(true)
          }, 1900)
          setTimerid(timer)
        } else {
          alert('오류가 발생했어요.\n다시 시도해주세요.')
          setYutPlaying(false)
        }
      } catch (error) {
        console.error(error)
        setYutPlaying(false)
      }
    }

    const ModalCloseButton = () => {
      if (yutPlaying) return null
      return (
        <button
          style={{ ...styles.modalCloseButtonStyle }}
          onClick={() => {
            // showRewardToast(
            //   rewardForToast?.rewardAmount,
            //   rewardForToast?.rewardType
            // );
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      )
    }

    useEffect(() => {
      const handleRewardedAdReady = async () => {
        if (yutnoriRewardedAdCheck) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'prepareRewardedAd',
              data: {
                adType: 'rewarded',
                placement: 'ALFARM_YUTNORI_MISSION_REWARD',
              },
            }),
          )
          setIsLoadingRewardedAd(true)
          // 광고 대상자인데 로드 안된경우 0.3초마다 확인해 최대 3초까지 대기
          for (let i = 0; i < 10; i += 1) {
            if (UserStore.isRewardAdReady) break // 로드되면 반복 종료
            await sleep(300)
          }
          setIsLoadingRewardedAd(false)
        }
      }
      handleRewardedAdReady()
    }, [])

    useEffect(() => {
      setIsLoading(true)
      getPlayStatusData()
    }, [UserStore.isWatchingAd])

    useEffect(() => {
      return () => {
        if (timerid) {
          clearTimeout(timerid)
        }
      }
    }, [timerid])

    return (
      <>
        <div style={{ ...styles.background }} ref={outerRef}>
          {isLoadingRewardedAd && (
            <LoadingIndicator isLoading={isLoadingRewardedAd} />
          )}
          <div
            style={{
              ...styles.modalFrame,
              backgroundImage: modalBackgroundImage,
            }}
            ref={ref}
          >
            <div style={{ ...styles.titleStyle }}>
              <img src='/minigame/yutnori/yutnoriTitle.png' alt='' />
            </div>
            <ModalCloseButton />
            <InfoModal />
            <div style={{ ...styles.modalBody }}>
              <HeaderMessage />
              {!yutPlaying && !isLoading && <YutnoriResult state={reward} />}
              {yutPlaying && <YutThrowAnimation />}
            </div>
            <GameButton />
            <button
              style={{ ...styles.infoButtonStyle }}
              onClick={() => {
                setInfoModalOpen(true)
              }}
            >
              물 최대 50g!
              {/* <img src="/minigame/yutnori/helpIcon.png" alt="" /> */}
            </button>
          </div>
        </div>
      </>
    )
  },
)

export default YutnoriGameModalV2
