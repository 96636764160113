import { useEffect, useState, useMemo } from 'react'
import AlwayzUserStore from 'store/AlwayzUserStore'
import UserStore from 'store/UserStore'
import OfferwallModalStore from 'store/OfferwallModalStore'
import { appBridgeSender } from 'utils/appBridgeSender'
import backendApis from 'utils/backendApis'
import { SORT_OPTIONS } from '../../_constants/sortOptions'

let visibilityChange = ''
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const useOfferwallPage = ({ defaultFilter, defaultSortOption }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [payload, setPayload] = useState(undefined)
  const [bottomsheetData, setBottomsheetData] = useState({
    isOpen: false,
    ad: undefined,
  })

  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [offerwallList, setOfferwallList] = useState([])

  const [sortOption, setSortOption] = useState(defaultSortOption)

  const sortedAndFilteredList = useMemo(() => {
    const filtered =
      filter?.length > 0
        ? offerwallList.filter((ad) => filter?.includes(ad.type))
        : offerwallList

    const currentSortOption = Object.values(SORT_OPTIONS).find(
      (option) => option.value === sortOption,
    )

    return [...filtered].sort(currentSortOption.sortFn)
  }, [offerwallList, filter, sortOption])

  const fetchData = async ({ adTypeList = 'all' }) => {
    if (isLoading) return
    setIsLoading(true)

    const params = {
      userId: UserStore.farmData?.userId,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      ifa: UserStore.offerwallInfo.ifa,
      ifv: UserStore.offerwallInfo.ifv,
      platform: UserStore.offerwallInfo.platform,
      placement: UserStore.offerwallInfo.placement,
    }

    // 방어로직 - 버즈빌 오퍼월 정보 가져오기
    if (UserStore.offerwallInfo.unitId === '') {
      const response = await backendApis.getOfferwallMetaBuzzvil(params)
      const offerwallMetaData = response?.data

      if (offerwallMetaData?.code === 200) {
        const uniqueUserId = offerwallMetaData?.uniqueUserId || UserStore.userId

        const offerwallInfo = { ...UserStore.offerwallInfo }

        offerwallInfo.totalReward = Math.floor(
          Number(offerwallMetaData?.result?.total_reward || 0) * 22.4,
        )
        offerwallInfo.appId = offerwallMetaData?.appId
        offerwallInfo.unitId = offerwallMetaData?.unitId
        offerwallInfo.uniqueUserId = uniqueUserId

        UserStore.setOfferwallInfo(offerwallInfo)
        params.uniqueUserId = uniqueUserId
      } else {
        // OfferwallModalStore.setCurrentModal({
        //   open: true,
        //   isClosing: false,
        //   title: '참여할 수 있는 미션이 없어요',
        //   content:
        //     offerwallMetaData?.data?.msg ||
        //     '미성년자는 미션 참여가 제한될 수 있어요.',
        //   onSubmit: () => {},
        // })
      }
    }

    if (adTypeList !== 'all') {
      params.adTypeList = JSON.stringify(adTypeList)
    }
    if (payload) {
      params.payload = payload
    }
    const result = await backendApis.getOfferwallListBuzzvil(params)
    const ads = result?.data?.ads || []
    if (result?.status === 200 && ads?.length > 0) {
      setOfferwallList(
        offerwallList?.length > 0
          ? [...offerwallList, ...ads]
          : result?.data?.ads,
      )
      setPayload(result.data?.cursor)
    }

    setIsLoading(false)
    setIsInitialLoading(false)
  }

  const fetchRewardData = async () => {
    setBottomsheetData({ isOpen: false, ad: undefined })
  }

  const onClickAd = async (itemInfo) => {
    const adType = itemInfo?.type
    const custom = {
      adId: itemInfo?.id,
      placement: UserStore.offerwallInfo.placement,
      networkName: UserStore.offerwallInfo.networkName,
      rewardPoint: itemInfo?.rewardPoint,
      userId: AlwayzUserStore.alwayzUserData._id,
    }
    let url = ''

    if (adType === 'cpm' || adType === 'cpc') {
      // cpm, cpc는 노출형 광고이므로 click_url로 이동
      url = `${itemInfo?.creative?.click_url}&custom=${encodeURIComponent(
        JSON.stringify(custom),
      )}`
      appBridgeSender.goExternalBrowser(url)
      backendApis.clearBadahamMission({ questType: 'offerwall' })
    } else {
      // cpm, cpc 이외는 액션형 광고이므로 참여 api 호출
      const query = {
        campaignId: itemInfo.id,
        payload: itemInfo.payload,
        userId: AlwayzUserStore.alwayzUserData._id,
        uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
        unitId: UserStore.offerwallInfo.unitId,
        ifa: UserStore.offerwallInfo.ifa,
        ifv: UserStore.offerwallInfo.ifv,
        placement: UserStore.offerwallInfo.placement,
        networkName: UserStore.offerwallInfo.networkName,
        rewardPoint: itemInfo?.rewardPoint,
        adId: itemInfo?.id,
      }

      const result = await backendApis.getOfferwallAdBuzzvil(query)

      if (result?.status === 200) {
        setBottomsheetData({
          isOpen: true,
          ad: {
            ...itemInfo,
            action_description: result.data?.action_description,
            landing_url: `${
              result.data?.landing_url
            }&custom2=${encodeURIComponent(JSON.stringify(custom))}`,
          },
        })
      } else {
        // OfferwallModalStore.setCurrentModal({
        //   open: true,
        //   isClosing: false,
        //   title: '참여 안내',
        //   content:
        //     result?.data?.msg || '이미 참여하셨거나 현재 참여가 어려워요',
        //   onSubmit: () => {},
        // })
      }
    }
    setOfferwallList((prevData) =>
      prevData?.map((ad) =>
        ad.id === itemInfo?.id ? { ...ad, isParticipated: true } : ad,
      ),
    )

    // click 로깅
    const clickLog = {
      eventType: 'ad_click',
      userId: AlwayzUserStore.alwayzUserData._id,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      networkName: UserStore.offerwallInfo.networkName,
      placement: UserStore.offerwallInfo.placement,
      adId: itemInfo?.id,
      adType: itemInfo?.type,
      reward: itemInfo?.reward,
      rewardPoint: itemInfo?.rewardPoint,
      adName: itemInfo?.name,
      creativeTitle: itemInfo?.creative?.title,
      creativeDescription: itemInfo?.creative?.description,
      reward_condition: itemInfo?.reward_condition,
      check_participation_url: itemInfo?.check_participation_url || '',
    }
    backendApis.recordOfferwallEventLog(clickLog)
  }

  const participateAd = async () => {
    const thisAd = bottomsheetData?.ad
    appBridgeSender.goExternalBrowser(thisAd?.landing_url || '')
    setBottomsheetData((prevData) => ({
      ...prevData,
      ad: { ...prevData?.ad, isParticipated: true },
    }))

    // 참여 로깅
    const participateLog = {
      eventType: 'ad_participate',
      userId: AlwayzUserStore.alwayzUserData._id,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      networkName: UserStore.offerwallInfo.networkName,
      placement: UserStore.offerwallInfo.placement,
      adId: thisAd?.id,
      adType: thisAd?.type,
      reward: thisAd?.reward,
      rewardPoint: thisAd?.rewardPoint,
      adName: thisAd?.name,
      creativeTitle: thisAd?.creative?.title,
      creativeDescription: thisAd?.creative?.description,
      reward_condition: thisAd?.reward_condition,
      check_participation_url: thisAd?.check_participation_url || '',
    }
    backendApis.recordOfferwallEventLog(participateLog)
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setTimeout(() => fetchRewardData(), 500)
      }
    }
    if (offerwallList?.length === 0) {
      fetchRewardData()
      fetchData({ adTypeList: 'all' })

      if (
        UserStore.offerwallInfo.ifa === '00000000-0000-0000-0000-000000000000'
      ) {
        if (UserStore.offerwallInfo.platform === 'A') {
          OfferwallModalStore.setCurrentModal({
            open: true,
            isClosing: false,
            title: '광고 ID를 받아주세요',
            content: `ID가 있으면 더 많은 미션을 할 수 있어요.\n(설정 > Google > 모든 서비스 탭 > 광고 >새 광고 ID 받기)`,
            onSubmit: () => {},
          })
        } else {
          OfferwallModalStore.setCurrentModal({
            open: true,
            isClosing: false,
            title: '앱 추적 허용이 필요해요',
            content: `허용하면 더 많은 미션을 할 수 있어요.\n(설정 > 개인정보 보호 및 보안 >\n추적 > 올웨이즈 앱 허용)`,
            onSubmit: () => {},
          })
        }
      }
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false)
    return () => {
      document.removeEventListener(
        visibilityChange,
        handleVisibilityChange,
        false,
      )
    }
  }, [])

  return {
    isInitialLoading,
    sortedAndFilteredList,
    filter,
    setFilter,
    fetchData,
    onClickAd,
    participateAd,
    bottomsheetData,
    setBottomsheetData,
    isLoading,
    sortOption,
    setSortOption,
  }
}

export default useOfferwallPage
