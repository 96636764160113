import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import RewardInfoBox from './RewardInfoBox'
import ABStore from 'store/ABStore'
import LoadingIndicator from 'comps/loadingIndicator'
import { showRewardAd } from 'utils/rewardAdHelper'

const ScratchLottoModal = observer(() => {
  const [isLoading, setIsLoading] = useState(false)
  const prizeAB = ABStore?.lottoPrizeAB
  const prizeSet =
    prizeAB === 'a'
      ? ['water', 'fertilizer', 'fertilizerSet']
      : [
          'wetWipes',
          'shinsaegae',
          'starbucks',
          'water',
          'fertilizer',
          'fertilizerSet',
        ]

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_SCRATCH_LOTTO',
        },
      }),
    )
  }, [])

  // 애니메이션 속도 조절을 위해 분기처리
  const prizeSetForAnim =
    prizeAB === 'a'
      ? [...prizeSet, ...prizeSet, ...prizeSet]
      : [...prizeSet, ...prizeSet]

  return (
    <div className='absolute top-0 left-0 z-[900] w-screen h-screen bg-black bg-opacity-90 flex flex-col items-center justify-center'>
      <div className='relative w-[80vw] h-[100vw] bg-[#FFF] border-[8px] border-[#FFD4B9] rounded-xl pt-10 pb-4 mt-10 text-[#343434] text-[22px] flex flex-col items-center justify-between text-center'>
        <img
          src='/scratchLotto/modalTitle.png'
          className='absolute top-[-30vw]'
        />
        복권 1장을 긁을 수 있어요!
        {/* <br />꽝 */}
        <div className='relative w-full h-[135px] flex-none overflow-x-hidden mb-4'>
          <div className='text-[#7D7D7D] text-center text-[18px] font-light leading-[140%] tracking-[-0.2px] mb-4'>
            꽝 없이 모두 당첨
          </div>
          <div className='flex flex-row'>
            <div className='flex flex-row space-x-[4vw] aria-hidden animate-scroll-slow'>
              {prizeSetForAnim.map((elem, index) => (
                <RewardInfoBox key={elem + index} rewardType={elem} />
              ))}
            </div>
          </div>
        </div>
        {/* 복권 긁으러가기 버튼 */}
        <span className='relative w-[80vw] text-[#6c6c6c] text-[14px] mt-4'>
          클릭시 광고가 재생돼요
        </span>
        <img
          src='/scratchLotto/scratchLottoButton.png'
          className='w-[90%]'
          onClick={() => {
            setIsLoading(true)
            showRewardAd('rewarded', 'ALFARM_SCRATCH_LOTTO')
            setTimeout(() => {
              ModalStore.setIsModalOpen('LotteryTicketScratchModal')
            }, 10000)
          }}
        />
        <div className='absolute w-full bottom-[-12vw] z-[140] text-[#9C9DA4] text-center text-[4.5vw]'>
          올웨이즈와는 무관한 광고예요.
        </div>
      </div>
      <button
        style={{
          width: '50%',
          top: '16%',
          right: '-10%',
          position: 'absolute',
          zIndex: 3,
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          style={{
            zIndex: 101,
            position: 'absolute',
            width: '15%',
            top: '73%',
            left: '50%',
          }}
          src='/icon/modalCloseButton.png'
          alt=''
        />
      </button>
      <LoadingIndicator isLoading={isLoading} />
    </div>
  )
})

export default ScratchLottoModal
