import { memo } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import CPMGoodsModal from './CPMGoodsModal'
import {
  CPM_GOODS_MODAL_TYPES,
  CPM_ENTERING_COMPONENTS,
} from 'utils/alfarmCPMConstant/cpmAdTypes'

const CPMModalWrapper = memo(
  observer(({ userCodePushVersion, userId }) => {
    const modalType = ModalStore?.isModalOpen

    const getEnteringComponent = () => {
      switch (modalType) {
        case CPM_GOODS_MODAL_TYPES.RECOMMENDED_PRODUCT_AD:
          return CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD
        case CPM_GOODS_MODAL_TYPES.TODAY_DEAL_AD:
          return CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD
        case CPM_GOODS_MODAL_TYPES.LEAVES_AD:
          return CPM_ENTERING_COMPONENTS.LEAVES_AD
        case CPM_GOODS_MODAL_TYPES.YUTNORI_AD:
          return CPM_ENTERING_COMPONENTS.YUTNORI_AD
        case CPM_GOODS_MODAL_TYPES.FORTUNE_COOKIE_AD:
          return CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD
        case CPM_GOODS_MODAL_TYPES.QUIZ_AD:
          return CPM_ENTERING_COMPONENTS.QUIZ_AD
        case CPM_GOODS_MODAL_TYPES.WATER_BROWSING_AD:
          return CPM_ENTERING_COMPONENTS.WATER_BROWSING_AD
        default:
          return null
      }
    }

    const enteringComponent = getEnteringComponent()

    if (!enteringComponent) {
      return null
    }

    return (
      <CPMGoodsModal
        enteringComponent={enteringComponent}
        userCodePushVersion={userCodePushVersion}
        userId={userId}
      />
    )
  }),
)

export default CPMModalWrapper
