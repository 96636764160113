import React, { useState, useEffect } from 'react'
import SpecialLotteryCapsulesNotOpen from './SpecialLotteryCapsulesNotOpen'
import SpecialLotteryCapsulesOpen from './SpecialLotteryCapsulesOpen'
import LottieGif from '../LottieGif'
import centerConfetti from '../../../json/gifJson/confetti/centerConfetti.json'
import ModalStore from 'store/ModalStore'

const SpecialLotteryCapsules = ({
  reward,
  showRewardToast,
  rewardForToast,
}) => {
  const [capsules, setCapsules] = useState([
    { id: 0, isVisible: false, isOpen: false, left: '15%' },
    { id: 1, isVisible: false, isOpen: false, left: '50%' },
    { id: 2, isVisible: false, isOpen: false, left: '85%' },
  ])

  useEffect(() => {
    capsules.forEach((_, index) => {
      setTimeout(() => {
        setCapsules((prev) =>
          prev.map((c) => (c.id === index ? { ...c, isVisible: true } : c)),
        )
      }, index * 300)
    })
  }, [capsules])

  const onClickCapsule = (index) => {
    setCapsules((prev) =>
      prev.map((c) => (c.id === index ? { ...c, isOpen: true } : c)),
    )
  }

  const rewardCapsuleTypeList = React.useMemo(() => {
    const fertilizer = reward?.fertilizer || 0
    const fertilizerSet = reward?.fertilizerSet || 0
    const result = []

    // 비료 아이템 먼저 배치 (나눌 수 없음)
    for (let i = 0; i < fertilizer; i++) {
      result.push('fertilizer')
    }

    // 고급 비료 아이템 배치
    for (let i = 0; i < fertilizerSet; i++) {
      result.push('fertilizerSet')
    }

    // 남은 슬롯에 물 배치
    for (let i = result.length; i < 3; i++) {
      result.push('water')
    }

    // 결과 배열 섞기
    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[result[i], result[j]] = [result[j], result[i]]
    }

    return result
  }, [reward?.fertilizer, reward?.fertilizerSet])

  const allCapsulesOpen = capsules.every((capsule) => capsule.isOpen)

  const getRewardText = React.useMemo(() => {
    const { water = 0, fertilizer = 0, fertilizerSet = 0 } = reward || {}
    const parts = []

    // 물 보상 텍스트 생성
    if (water > 0) {
      const waterIcons = rewardCapsuleTypeList.filter(
        (type) => type === 'water',
      )
      const baseWater = Math.floor(water / waterIcons.length)
      const remainder = water % waterIcons.length

      const waterParts = waterIcons.map((_, index) => {
        const amount =
          index === waterIcons.length - 1 ? baseWater + remainder : baseWater
        return `물 ${amount}g`
      })
      parts.push(...waterParts)
    }

    // 일반 비료 보상 텍스트
    if (fertilizer > 0) {
      parts.push(`일반비료 ${fertilizer}개`)
    }

    // 고급 비료 보상 텍스트
    if (fertilizerSet > 0) {
      parts.push(`고급비료 ${fertilizerSet}개`)
    }

    return parts.join(' + ')
  }, [reward, rewardCapsuleTypeList])

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '-35vh',
          left: '-5vw',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 10,
          pointerEvents: 'none',
        }}
      />

      <div className='flex justify-around w-full'>
        {capsules.map((capsule) =>
          capsule.isOpen ? (
            <SpecialLotteryCapsulesOpen
              capsule={capsule}
              rewardType={rewardCapsuleTypeList[capsule.id]}
            />
          ) : (
            <SpecialLotteryCapsulesNotOpen
              capsule={capsule}
              onClickCapsule={onClickCapsule}
            />
          ),
        )}
      </div>

      {allCapsulesOpen && (
        <>
          <div className='absolute z-[140] top-[57vw] text-black text-[16px] font-bold text-center py-[6.6px] px-[11px] bg-white rounded-xl'>
            {getRewardText}
          </div>
          <img
            className='z-[1000] w-[294px] absolute top-[80vw] '
            src='/minigame/lotteryDraw/specialCapsuleRewardButton.png'
            onClick={() => {
              showRewardToast(
                rewardForToast?.rewardAmount,
                rewardForToast?.rewardType,
              )
              ModalStore.setIsModalOpen('basic')
            }}
          />
          <LottieGif
            animationData={centerConfetti}
            className='absolute z-[140] top-[-10vw]'
          />
        </>
      )}
    </>
  )
}

export default SpecialLotteryCapsules
