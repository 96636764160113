import UserStore from 'store/UserStore'
import AB from './ab_v3'
import toast from 'react-hot-toast'

import backendApis from 'utils/backendApis'

const ADMOB_TEST_USER_IDS = [
  '620cce8196d049583db642e3', // 우진
  '6565aa51ed116a90f986d306', // 주언
  '626aa6a6c4d18f4110ecd6cb', // 욱
  '6698baeadee5cdcd7e1084f2', // 주영
]

export function showRewardAd(adType, placement) {
  const admobStatement = UserStore?.adMobStatement.get(placement)

  const rand = Math.random() < 0.5
  const showAdmobInstead =
    (AB(UserStore?.farmData?.userId, 'radomAdDisplay1216', [80, 100]) === 'b' ||
      ADMOB_TEST_USER_IDS.includes(UserStore.farmData?.userId)) &&
    admobStatement?.ready &&
    rand

  // if (UserStore.farmData?.userId === '6565aa51ed116a90f986d306') {
  //   alert(JSON.stringify(admobStatement?.ready))
  //   alert(showAdmobInstead)
  //   return
  // }
  if (showAdmobInstead) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'showAdmobAd',
        data: {
          adType,
          placement,
        },
      }),
    )
    UserStore.clearAdmobPlacementLoaded(placement)
  } else if (UserStore.isRewardAdReady) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'showRewardedAd',
        data: {
          adType,
          placement,
        },
      }),
    )
  } else {
    toast.error(
      '광고가 준비되지 않았습니다. 이 오류가 반복될 시 고객센터로 문의해주세요.',
    )
  }

  backendApis.logRewardAdRequest(UserStore.farmData?.token, 'POST', {
    type:
      (AB(UserStore?.farmData?.userId, 'radomAdDisplay1216', [80, 100]) ===
        'b' ||
        ADMOB_TEST_USER_IDS.includes(UserStore.farmData?.userId)) &&
      rand
        ? 'admob'
        : 'applovin',
    applied_type: showAdmobInstead ? 'admob' : 'applovin',
    product: 'alfarm',
    placement,
    loaded: admobStatement?.ready ? true : false,
  })
}
