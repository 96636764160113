import React, { useState, useEffect } from 'react'
import backendApis from '../../../utils/backendApis'
import { observer } from 'mobx-react-lite'
import { sleep } from 'utils/utils'
import WhiteTextTimer from '../../../comps/WhiteTextTimer'
import indexStore from '../../../store/indexStore'
import ABStore from 'store/ABStore'
import AlfarmAdStore from 'store/AlfarmAdStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import LoadingIndicator from '../../loadingIndicator'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import { showRewardAd } from 'utils/rewardAdHelper'

const QuizPurchaseModalV2 = observer(({ token, farmData, itemType }) => {
  const { ModalStore, UserStore } = indexStore()
  const [isLoadingRewardedAd, setIsLoadingRewardedAd] = useState(false)
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [mainQuizData, setMainQuizData] = useState(null)
  const [subQuizDataList, setSubQuizDataList] = useState(null)
  const [mainQuizId, setMainQuizId] = useState(null)
  const [subQuizIdList, setSubQuizIdList] = useState(null)
  const [itemIdList, setItemIdList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [reward, setReward] = useState(10)
  const [isBrowsing, setIsBrowsing] = useState(null)
  const codePushVersionAvailable = useCheckCodePushVersion()
  const codePushVersionForAdmob = codePushVersionAvailable(
    UserStore?.codePushVersionFromAlwayzApp,
    '6.1.5',
  )
  const quizRewardedAdCheck =
    UserStore.cherryPickerForAd &&
    (!codePushVersionForAdmob ||
      (codePushVersionForAdmob && !ABStore?.admobAlfarmTester))

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_QUIZ_MISSION_REWARD',
        },
      }),
    )
  }, [])

  const updateBrowsing = async () => {
    const result = await backendApis.getQuizBrowsingInfo()
    setIsBrowsing(result?.data?.isBrowsing)
  }

  useEffect(() => {
    getQuiz()
    updateBrowsing()
  }, [])

  useEffect(() => {
    setMainQuizId(mainQuizData?._id)
    setSubQuizIdList(
      [subQuizDataList?.[0]?._id, subQuizDataList?.[1]?._id]?.filter((e) => e),
    )
    const mainQuizItemIdList =
      mainQuizData?.itemIds ||
      (mainQuizData?.itemId ? [mainQuizData?.itemId] : [])
    const subAdItem1IdList =
      subQuizDataList?.[0]?.itemIds ||
      (subQuizDataList?.[0]?.itemId ? [subQuizDataList?.[0]?.itemId] : [])
    const subAdItem2IdList =
      subQuizDataList?.[1]?.itemIds ||
      (subQuizDataList?.[1]?.itemId ? [subQuizDataList?.[1]?.itemId] : [])
    const combinedItemIds = [
      ...mainQuizItemIdList,
      ...subAdItem1IdList,
      ...subAdItem2IdList,
    ]
    setItemIdList(combinedItemIds)
  }, [mainQuizData, subQuizDataList])

  useEffect(() => {
    const handleRewardedAdReady = async () => {
      if (quizRewardedAdCheck && !UserStore.isRewardAdReady) {
        setIsLoadingRewardedAd(true)
        // 광고 대상자인데 로드 안된경우 0.3초마다 확인해 최대 3초까지 대기
        for (let i = 0; i < 10; i += 1) {
          if (UserStore.isRewardAdReady) break
          // 로드되면 반복 종료
          await sleep(300)
        }
        setIsLoadingRewardedAd(false)
      }
    }

    handleRewardedAdReady()
  }, [UserStore.isRewardAdReady])

  const getQuiz = async () => {
    if (isLoading) return
    setIsLoading(true)
    // 마지막 퀴즈 다시 불러와서 데이터 세팅
    try {
      if (
        farmData?.solvedQuizHistory &&
        new Date(
          farmData?.solvedQuizHistory[
            farmData?.solvedQuizHistory?.length - 1
          ]?.solvedAt,
        ).getTime() +
          AlfarmAdStore.quizAdTimeIntervalBetweenExposure * 60 * 60 * 1000 >
          new Date().getTime()
      ) {
        const mainQuizId =
          farmData?.solvedQuizHistory[farmData?.solvedQuizHistory?.length - 1]
            ?.quizId || null
        const subQuizId1 =
          farmData?.solvedQuizHistory[farmData?.solvedQuizHistory?.length - 1]
            ?.subQuizId1 || null
        const subQuizId2 =
          farmData?.solvedQuizHistory[farmData?.solvedQuizHistory?.length - 1]
            ?.subQuizId2 || null
        const result = await backendApis.getQuizListByQuizIds(token, 'GET', {
          quizId: mainQuizId,
          subQuizId1: subQuizId1,
          subQuizId2: subQuizId2,
        })
        if (result?.status === 200) {
          setMainQuizData(result?.data?.mainQuizData)
          setSubQuizDataList(result?.data?.subQuizDataList)
          setReward(
            farmData?.solvedQuizHistory[farmData?.solvedQuizHistory?.length - 1]
              ?.reward || 10,
          )
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const sendToPurchaseScreen = () => {
    ModalStore.setIsModalOpen('basic')
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'AlfarmAdPurchaseScreen',
      params: {
        title: '올팜',
        image:
          'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
        ratio: 99 / 360,
        enteringComponent: 'Alfarm_quizPurchase',
        adId: mainQuizId,
        subAdId: subQuizIdList,
        itemIds: itemIdList,
        questReward: reward,
        isTimerOn: !isBrowsing,
        questRewardType: 'water',
        questRewardText: `초간 구경하면 물 ${reward}g을 받아요!`,
        questTimer: 0.5,
      },
    })}`
  }

  const sendToVideoAdScreen = () => {
    ModalStore.setIsModalOpen('basic')

    showRewardAd('rewarded', 'ALFARM_QUIZ_MISSION_REWARD')
  }

  const PurchaseButton = () => {
    let buttonText = ''
    if (isBrowsing) {
      buttonText = `퀴즈 특가 상품 구매하기`
    } else if (
      quizRewardedAdCheck &&
      !UserStore.isRewardAdReady &&
      isLoadingRewardedAd
    ) {
      buttonText = `광고 준비중..`
    } else if (quizRewardedAdCheck && UserStore.isRewardAdReady) {
      buttonText = `영상 보고 물 ${reward}g 받기`
    } else if (
      !quizRewardedAdCheck ||
      (quizRewardedAdCheck &&
        !UserStore.isRewardAdReady &&
        !isLoadingRewardedAd)
    ) {
      buttonText = `구경하고 물 ${reward}g 받기`
    }

    return (
      <div
        style={{
          background: isButtonPressed ? '#F7C82A' : '#F7C82A',
          boxShadow: isButtonPressed
            ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
            : '',
          width: '70vw',
          height: '14vw',
          borderRadius: 99,
          color: 'black',
          fontFamily: 'maplestory',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '5vw',
          position: 'absolute',
          bottom: '6vw',
          opacity: isBrowsing === null ? 0.5 : 1,
          pointerEvents: isBrowsing === null ? 'none' : 'auto',
        }}
        onClick={() => {
          if (isBrowsing) {
            sendToPurchaseScreen()
          } else if (!quizRewardedAdCheck) {
            sendToPurchaseScreen()
          } else if (quizRewardedAdCheck && UserStore.isRewardAdReady) {
            sendToVideoAdScreen()
          } else if (quizRewardedAdCheck && !UserStore.isRewardAdReady) {
            sendToPurchaseScreen()
            AlfarmEventLogger({
              locationType: 'page',
              locationName: 'not_ready_rewardAd_Quiz',
              eventType: 'pageview',
              data: {},
              collection: 'UserAlfarmPageviewLog',
            })
          }
        }}
        onPointerDown={() => {
          setIsButtonPressed(true)
        }}
        onPointerCancel={() => {
          setIsButtonPressed(false)
        }}
        onPointerUp={() => {
          setIsButtonPressed(false)
        }}
      >
        buttonText
      </div>
    )
  }
  // 체리피커가 아니면 항상 sendToPurchaseScreen으로 && 체리피커여도 영상광고 봤으면 sendToPurchaseScreen으로
  if (isBrowsing === null) {
    return
  }
  if (
    !quizRewardedAdCheck ||
    (quizRewardedAdCheck && isBrowsing) ||
    (quizRewardedAdCheck && !UserStore.isRewardAdReady && !isLoadingRewardedAd)
  ) {
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '100%',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '-12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img
              src='/quiz/quizPurchaseModalNew.png'
              alt='abc'
              style={{ width: '90vw' }}
            />
            <div
              style={{
                position: 'absolute',
                fontFamily: 'maplestory',
                flex: 1,
                bottom: '24vw',
                zIndex: 2,
                width: '100%',
                textAlign: 'center',
                color: '#442b22',
                fontSize: '4.5vw',
              }}
            >
              <WhiteTextTimer
                timeStamp={
                  farmData?.solvedQuizHistory
                    ? new Date(
                        farmData?.solvedQuizHistory[
                          farmData?.solvedQuizHistory?.length - 1
                        ]?.solvedAt,
                      )
                    : new Date()
                }
                timerMinutes={
                  60 * AlfarmAdStore.quizAdTimeIntervalBetweenExposure
                }
                color='#442b22'
              />
              {'  '}이후에는 특가가 사라져요
            </div>
            {PurchaseButton()}
          </div>
        </div>
      </>
    )
    // 체리피커인데 보상을 안 받은 경우
  } else if (
    quizRewardedAdCheck &&
    !isBrowsing &&
    (UserStore.isRewardAdReady || isLoadingRewardedAd)
  ) {
    let modalText = ''
    if (
      farmData?.solvedQuizHistory[farmData?.solvedQuizHistory?.length - 1]
        ?.isCorrect
    ) {
      modalText = '정답을 맞췄어요!'
    } else {
      modalText = '정답이 아니에요'
    }

    return (
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div className='w-[90vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
          <>
            {isLoadingRewardedAd && (
              <LoadingIndicator isLoading={isLoadingRewardedAd} />
            )}
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '-12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {farmData?.solvedQuizHistory[
              farmData?.solvedQuizHistory?.length - 1
            ]?.isCorrect ? (
              <img
                src='/quiz/correctModal.png'
                alt='abc'
                style={{ width: '90vw' }}
              />
            ) : (
              <img
                src='/quiz/wrongModal.png'
                alt='abc'
                style={{ width: '90vw' }}
              />
            )}
            <div
              style={{
                transform: 'translate(-50%, -50%)',
                left: '50%',
                top: '45%',
                position: 'absolute',
                color: 'black',
                width: '90%',
                fontSize: '4.5vw',
                textAlign: 'center',
                lineHeight: '150%',
                marginTop: '8vw',
              }}
            >
              {modalText}
              <br />
              영상 광고를 보면 물 {reward}g을 받아요
              <img
                src='/quiz/rewardVideoAdWaterIconForQuiz.png'
                alt='abc'
                style={{
                  width: '45vw',
                  marginLeft: '26%',
                  marginTop: '2vw',
                }}
              />
            </div>
            {PurchaseButton()}
          </>
        </div>
      </div>
    )
  }
})

export default QuizPurchaseModalV2
