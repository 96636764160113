import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import CustomScratchCard from './CustomScratchCard'
import commaNumber from 'comma-number'
import LottieGif from './LottieGif'
import centerConfetti from '../../json/gifJson/confetti/centerConfetti.json'
import ModalStore from 'store/ModalStore'

function vwToPixels(vw) {
  return Math.round(window.innerWidth / (100 / vw))
}

function getRandomIntWithMean50(min = 40, max = 100, mean = 50, stdDev = 10) {
  // Box-Muller 변환을 사용한 정규 분포 난수 생성
  let u = 0,
    v = 0
  while (u === 0) u = Math.random() // u가 0이 되지 않도록
  while (v === 0) v = Math.random()

  let z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v) // 정규 분포 생성
  let randomValue = mean + z * stdDev // 평균과 표준편차 적용
  // 범위를 벗어난 값 조정
  randomValue = Math.max(min, Math.min(max, randomValue))

  return Math.round(randomValue)
}

const LotteryTicketScratchModal = observer(
  ({
    farmData,
    setFarmData,
    showRewardToast,
    token,
    setUpdateMinigameInfo,
  }) => {
    const WIDTH = vwToPixels(90)
    const HEIGHT = vwToPixels(60)
    const [isScratched, setIsScratched] = useState(false)
    const [showLottie, setShowLottie] = useState(true)
    const [reward, setReward] = useState({
      water: 0,
      fertilizer: {},
    })

    const updateReward = (rewardType) => {
      if (!farmData) {
        return
      }
      let tempFarmData = { ...farmData }
      if (
        rewardType.fertilizer?.type === 'fertilizer' &&
        rewardType.fertilizer.amount > 0
      ) {
        tempFarmData.fertilizer += rewardType.fertilizer.amount || 0
      }
      if (rewardType.fertilizer?.type === 'fertilizerSet') {
        tempFarmData.fertilizerSet += rewardType.fertilizer.amount || 0
      }
      if (rewardType.water) {
        tempFarmData.water += rewardType.water || 0
      }
      setFarmData(tempFarmData)
    }

    useEffect(() => {
      function getReward() {
        const hasNeverPlayedLotteryTicketScratch =
          localStorage.getItem('isPlayedLotteryTicketScratch') === null

        if (hasNeverPlayedLotteryTicketScratch) {
          localStorage.setItem('isPlayedLotteryTicketScratch', 'true')
          return {
            water: null,
            fertilizer: {
              type: 'fertilizerSet',
              amount: 1,
            },
          }
        }

        // 보상과 확률 설정
        const rewards = [
          {
            name: 'water',
            amount: getRandomIntWithMean50(),
            probability: 75.6,
          },
          { name: 'water', amount: 1000 }, // 나머지 확률로 계산
          {
            name: 'fertilizer',
            type: 'fertilizer',
            amount: 1,
            probability: 15,
          },
          { name: 'fertilizer', type: 'fertilizer', amount: 2, probability: 4 },
          { name: 'fertilizer', type: 'fertilizer', amount: 5, probability: 1 },
          {
            name: 'fertilizer',
            type: 'fertilizer',
            amount: 10,
            probability: 0.01,
          },
          {
            name: 'fertilizer',
            type: 'fertilizerSet',
            amount: 100,
            probability: 0.0002,
          },
        ]
        // 나머지 확률 계산 (water 1000)
        const totalAssignedProbability = rewards
          .filter((r) => r.probability)
          .reduce((sum, r) => sum + r.probability, 0)
        const remainingProbability = 100 - totalAssignedProbability
        rewards[1].probability = remainingProbability // water 1000의 확률 설정

        // 확률 분포 생성
        const probabilityRanges = []
        let cumulativeProbability = 0

        rewards.forEach((reward) => {
          cumulativeProbability += reward.probability
          probabilityRanges.push({ ...reward, cumulativeProbability })
        })

        // 난수 생성 (0 ~ 100)
        const random = Math.random() * 100
        // 보상 선택
        for (const reward of probabilityRanges) {
          if (random <= reward.cumulativeProbability) {
            if (reward.name === 'water') {
              return {
                water: reward.amount,
                fertilizer: null,
              }
            } else if (reward.name === 'fertilizer') {
              return {
                water: null,
                fertilizer: {
                  type: reward.type,
                  amount: reward.amount,
                },
              }
            }
          }
        }
      }

      const rewardData = getReward()
      setReward(rewardData)
      updateReward(rewardData)
      backendApis
        .giveScratchLottoReward(token, 'PUT', {
          water: rewardData.water,
          fertilizer: rewardData.fertilizer,
        })
        .then(() => {
          setUpdateMinigameInfo(true)
        })
    }, [])

    // prizeType 설정
    const prizeType = useMemo(() => {
      if (reward.water) {
        return 'water'
      } else if (reward.fertilizer?.type === 'fertilizerSet') {
        return 'fertilizerSet'
      } else {
        return 'fertilizer'
      }
    }, [reward])

    // prizeAsset 설정
    const prizeAsset = useMemo(() => {
      if (prizeType === 'water') {
        return {
          prefix: '물',
          suffix: 'g',
          iconImage: '/lotteryTicket/water.png',
        }
      } else if (prizeType === 'fertilizer') {
        return {
          prefix: '비료',
          suffix: '개',
          iconImage: '/lotteryTicket/fertilizer.png',
        }
      } else {
        return {
          prefix: '고급비료',
          suffix: '개',
          iconImage: '/lotteryTicket/fertilizerSet.png',
        }
      }
    }, [prizeType])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '100vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: 'url(../lotteryTicket/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '10%',
            }}
          >
            <CustomScratchCard
              width={WIDTH}
              height={HEIGHT}
              onComplete={async () => {
                setIsScratched(true)
              }}
            >
              <div
                style={{
                  width: WIDTH,
                  height: HEIGHT,
                  color: 'black',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <div
                  className='text-black text-center my-3 mb-4 text-2xl font-bold'
                  style={{
                    fontFamily: 'Maplestory',
                  }}
                >
                  {`${prizeAsset.prefix} ${
                    commaNumber(
                      reward.fertilizer?.amount || reward.water || 0,
                    ) || 0
                  }${prizeAsset.suffix} 당첨!`}
                </div>
                <img
                  style={{
                    objectFit: 'contain',
                    width: '30vw',
                  }}
                  src={prizeAsset.iconImage}
                  alt=''
                />
              </div>
              {isScratched && showLottie && (
                <LottieGif
                  animationData={centerConfetti}
                  className='z-[1000] top-[50vw]'
                  onComplete={() => {
                    setShowLottie(false)
                  }}
                />
              )}
            </CustomScratchCard>
          </div>
          <div className='fixed top-[70vh] w-full flex flex-col items-center'>
            <div
              className='text-white text-center text-base mt-6 mb-4'
              style={{
                fontFamily: 'Maplestory',
              }}
            >
              화면을 손으로 문질러서 복권을 긁어주세요!
            </div>
            {isScratched && (
              <button
                onClick={() => {
                  showRewardToast(
                    reward.fertilizer?.amount || reward.water || 0,
                    prizeType,
                  )
                  ModalStore.setIsModalOpen('basic')
                }}
                className='w-[80vw] py-[3vw] text-center rounded-[2vw] text-2xl font-extrabold h-[16vw] mb-2'
                style={{
                  backgroundImage:
                    'url(/doubleWater/greenButtonBackground.png)',
                  backgroundSize: '100% 100%',
                  backgroundPosition: 'center',
                  color: '#366600',
                }}
              >
                보상 받기
              </button>
            )}
          </div>
        </div>
      </>
    )
  },
)

export default LotteryTicketScratchModal
