import React, { useState, useEffect, useRef } from 'react'
import Layout from '../comps/atoms/layout'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { NavLink } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import commaNumber from 'comma-number'
import toast, { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import AuthStore from '../store/AuthStore'
import UserStore from '../store/UserStore'
import ABStore from 'store/ABStore'

const FriendGarden = () => {
  const [tutorialOn, setTutorialOn] = useState(false)
  const [friendGardenData, setFriendGardenData] = useState([
    { empty: true },
    { empty: true },
    { empty: true },
    { empty: true },
  ])
  const [rewardToastInfo, setRewardToastInfo] = useState({
    rewardImage: 'url(../friendGarden/fertilizerSet.png)',
    rewardAmount: ABStore?.waterMaximumTester ? 12 : 3,
    rewardText: '통과 보상 지급!',
    unit: '개',
    visible: false,
  })
  const [nudgeIndex, setNudgeIndex] = useState(5)

  const rewardToastInfoRef = useRef()

  // if (isLoading) {
  //   return <Layout isLoading={isLoading}></Layout>
  // }

  useEffect(() => {
    let checked = window.localStorage.getItem('friendGardenFirstVisit')
    if (!checked) {
      setTutorialOn(true)
      window.localStorage.setItem('friendGardenFirstVisit', 1)
    }
  }, [])

  useEffect(() => {
    const fetch = async () => {
      let res = await backendApis.friendGardenData()
      const tempData = res?.data
      setFriendGardenData((prev) => {
        const newData = prev?.map((item, idx) => {
          let index = tempData?.findIndex((item) => item?.slotNumber === idx)
          if (index >= 0) {
            let temp = tempData?.[index]
            temp.rewardHistory = temp?.rewardHistory?.map(
              (item) => new Date(item),
            )
            return temp
          } else {
            return item
          }
        })
        return [...newData]
      })
    }
    fetch()
  }, [])

  useEffect(() => {
    let nudgeOn = true
    for (let i = 0; i < friendGardenData?.length; i++) {
      let data = friendGardenData[i]
      if (!data?.empty) {
        nudgeOn = false
        break
      }
    }
    if (nudgeOn) {
      setNudgeIndex(0)
    } else {
      setNudgeIndex(5)
    }
  }, [friendGardenData])

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'closeAdPopcornNativeForFertilizerBottomsheet',
      }),
    )
  }, [])

  useEffect(() => {
    if (rewardToastInfo?.visible) {
      clearTimeout(rewardToastInfoRef.current)
      rewardToastInfoRef.current = setTimeout(() => {
        setRewardToastInfo({ ...rewardToastInfo, visible: false })
      }, 1500)
    }
  }, [rewardToastInfo])

  const getReward = async (data) => {
    let result = await backendApis.givefriendGardenReward(
      AuthStore.token,
      'PUT',
      {
        inviteeId: data?.inviteeId,
      },
    )
    setRewardToastInfo({ ...rewardToastInfo, visible: true })
    setFriendGardenData((prev) => {
      prev[data?.slotNumber] = { ...result?.data }
      return [...prev]
    })
  }

  const share = () => {
    const inviteData = {
      shareType: 'AlfarmFriendGarden',
      title: '[올팜] 함께 올팜 작물을 키워봐요!',
      description: '제가 잘 적응할 수 있게 도와드릴게요 😎',
      bannerImage:
        'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }
  const shareHelp = () => {
    let arr = [
      [
        '[올팜] 작물을 빨리 수확하려면?',
        '맞팜을 많이 만들어야해요! 저랑 맞팜해요! 😎',
      ],
      [
        '[올팜] 물을 더 얻으려면?',
        '다양한 미션과 아케이드를 통해 물을 얻어보세요! 🌟',
      ],
      [
        '[올팜] 비료는 무엇일까요?',
        '비료를 통해 양분을 채워서 경험치 보너스를 얻어보세요! 🌱',
      ],
      [
        '[올팜] 맞팜은 뭔가요?',
        '친구의 농장에 방문하여 물과 비료를 얻을 수 있는 기능이에요! 🤝',
      ],
      [
        '[올팜] 작물을 빨리 수확하려면?',
        '친구 초대 기능을 통해 물과 비료를 얻어보세요! 👻',
      ],
      [
        '[올팜] 작물을 빨리 수확하려면?',
        '초저가 상품을 구경하고 득템하면 보상을 크게 받아요! 🤑',
      ],
    ]
    let text = arr[Math.floor(Math.random() * arr.length)]
    const inviteData = {
      shareType: 'AlfarmFriendGardenHelp',
      title: text[0],
      description: text[1],
      bannerImage:
        'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  const LevelBanner = ({ level = 1, text, reward }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 8,
          paddingBottom: 16,
          // borderRadius: 8,
          backgroundColor: 'white',
          borderBottom: '1px solid #bbb',
          marginBottom: 16,
        }}
      >
        <div
          style={{
            borderRadius: 99,
            backgroundColor: '#F6F3EC',
          }}
        >
          <div
            style={{
              // border: "5px solid blue",
              height: '20vw',
              width: '20vw',
              backgroundImage: `url(../friendGarden/onion/guide/${level}.png)`,
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            height: '20vw',
            color: 'black',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '4vw',
            wordBreak: 'keep-all',
            wordWrap: 'break-word',
          }}
        >
          {'초대한 새친구가'}
          <br />
          {text}
        </div>
        <div
          style={{
            width: '20vw',
            height: '20vw',
            color: 'black',
            backgroundImage: `url(../friendGarden/reward.png)`,
          }}
        />
      </div>
    )
  }

  const GridSlot = ({ data, idx }) => {
    let imgSrc = '../friendGarden/inviteButton.png'
    let onClickFunc = () => {
      share()
    }
    if (!data?.empty) {
      let lastRewardLevel = data?.rewardHistory?.length
      let level = data?.level
      if (lastRewardLevel < level) {
        onClickFunc = () => {
          getReward(data)
        }
        imgSrc = `../friendGarden/onion/done/${lastRewardLevel + 1}.png`
      } else {
        onClickFunc = () => {
          shareHelp()
        }
        imgSrc = `../friendGarden/onion/progress/${lastRewardLevel + 1}.png`
      }
      if (lastRewardLevel === 4) {
        imgSrc = `../friendGarden/onion/done/${lastRewardLevel}.png`
      }
    }

    return (
      <div
        style={{
          width: 'calc(50% - 4px)',
          height: '50vw',
          backgroundColor: '#CFA67D',
          border: '4px solid #A3682E',
          boxSizing: 'border-box',
          marginBottom: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: 16,
          position: 'relative',
        }}
        onClick={onClickFunc}
      >
        <img
          style={{
            width: '40vw',
            height: '40vw',
          }}
          src={imgSrc}
          alt='slot img'
        />
        {nudgeIndex === idx && (
          <div
            style={{
              position: 'absolute',
              // border: "1px solid black",
              left: '50%',
              top: '50%',
              width: '100%',
              height: '100%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <div
              className='fadeEffect'
              style={{
                backgroundImage: `url(../friendGarden/onion/guide/1.png)`,
                width: '40vw',
                height: '40vw',
                marginTop: '-4vw',
                position: 'absolute',
              }}
            />
            <div
              // className="fadeEffectFull"
              style={{
                backgroundImage: `url(../friendGarden/nudge.png)`,
                width: '40vw',
                height: '16vw',
                marginTop: '30vw',
                position: 'absolute',
                zIndex: 99,
              }}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        overflowY: tutorialOn ? 'hidden' : 'scroll',
        background:
          'linear-gradient(180deg, rgba(52,190,209,0.3) 0%, rgba(0,149,77,0.7) 100%)',
      }}
    >
      <header
        style={{
          width: '100%',
          marginTop: '10vw',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <NavLink
          to={`/farmPage?token=${AuthStore?.token}&itemType=${
            UserStore?.itemType
          }&randomNumber=${Math.random().toString().slice(0, 8)}`}
        >
          <button
            style={{
              width: '7vw',
              marginLeft: '2vw',
              zIndex: 999,
              flex: 1,
            }}
            action='goBack'
          >
            <img src='/icon/Back.png' alt='back button' />
          </button>
        </NavLink>
        <div
          style={{
            fontWeight: 'bold',
            // marginRight: 16,
            padding: 16,
            // marginTop: 16,
          }}
          onClick={() => setTutorialOn(true)}
        >
          참여방법
        </div>
      </header>

      <div
        style={{
          display: 'flex',
          backgroundImage: 'url(../friendGarden/title.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '-16vw',
          width: '70vw',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            height: '40vw',
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          // backgroundColor: "#C7FABE88",
          padding: 16,
          margin: 8,
          borderRadius: 8,
          marginBottom: 0,
          // border: "2px solid #A3682E",
        }}
      >
        {friendGardenData?.map((item, idx) => {
          return <GridSlot key={Math.random()} data={item} idx={idx} />
        })}
      </div>

      {/* Bottom Explaination */}
      {/* <div
        style={{
          padding: 8,
          paddingTop: 16,
          borderRadius: 16,
          // border: "5px solid black",
          background: "#fff",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <div
          style={{
            color: "black",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: 16,
          }}
        >
          <div
            style={{
              color: "black",
              width: "22vw",
              paddingBottom: 4,
              borderBottom: "1px solid #bbb",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            단계별 보상
          </div>
        </div>
        <LevelBanner level={1} text={"올팜 시작하면 통과"} />
        <LevelBanner level={2} text={"물 50번 주면 통과"} />
        <LevelBanner level={3} text={"맞팜 3명 만들면 통과"} />
        <LevelBanner level={4} text={"다음날 물주기하면 통과"} />
      </div> */}
      <div
        style={{
          width: '100vw',
          // border: "5px solid black",
        }}
      >
        <img
          style={{
            width: `${(375 / 488) * 100} vw`,
            height: `${(488 / 375) * 100}vw`,
          }}
          src={`../friendGarden/explain${
            ABStore?.waterMaximumTester ? 'V2' : ''
          }.png`}
          alt='explain'
        />
      </div>

      {rewardToastInfo?.visible && (
        <div
          style={{
            width: '150px',
            height: '150px',
            borderRadius: 16,
            position: 'absolute',
            top: '90vw',
            left: '50%',
            backgroundColor: 'rgba(0,0,0,0.8)',
            transform: 'translate(-50%,-50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 500,
          }}
        >
          <div style={{ marginTop: 0, marginBottom: 8 }}>
            {rewardToastInfo?.rewardText}
          </div>

          <div
            style={{
              width: '20vw',
              height: '20vw',
              backgroundImage: rewardToastInfo?.rewardImage,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          />
          <div
            style={{ marginTop: 8 }}
          >{`${rewardToastInfo?.rewardAmount}${rewardToastInfo?.unit} 획득!`}</div>
        </div>
      )}

      {tutorialOn && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
          onClick={() => {
            setTutorialOn(false)
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -45%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../friendGarden/tutorial.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                top: '-8vw',
                right: '4vw',
                zIndex: 2001,
                position: 'absolute',
              }}
              onClick={() => {
                setTutorialOn(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default FriendGarden
