import moment from 'moment'
import { CPM_VIEWER_TYPES } from './cpmAdTypes'

export const getTesterViewerType = ({ isCPMAdViewer, viewerType }) => {
  if (viewerType !== CPM_VIEWER_TYPES.NONE) {
    return viewerType
  }

  return isCPMAdViewer ? CPM_VIEWER_TYPES.VIDEO : CPM_VIEWER_TYPES.NONE
}

export const isBrowsingQuestAvailable = ({ browsingAt }) =>
  !browsingAt ||
  (browsingAt &&
    moment(browsingAt).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD'))
