import clsx from 'clsx'
import { ModalButton } from 'comps/atoms/buttons/ModalButton'
import { observer } from 'mobx-react-lite'
import { useRef, useState, useEffect } from 'react'
import BottomSheetStore from 'store/BottomSheetStore'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import { showRewardAd } from 'utils/rewardAdHelper'

const VideoAdModalActionButton = observer(
  ({ adType, onClick, children, className, position = 'absolute' }) => {
    const throttleRef = useRef({})

    return (
      <ModalButton
        disabled={!UserStore.isRewardAdReady}
        position={position}
        className={className}
        onClick={() => {
          AlfarmEventLogger({
            throttleRef,
            locationType: ModalStore?.isModalOpen,
            locationName: 'rewardAdModalButton',
            eventType: 'click',
            collection: 'UserAlfarmClickLog',
          })

          BottomSheetStore.setIsbottomSheetOpen('basic')
          ModalStore.setIsModalOpen('basic')

          onClick?.()

          showRewardAd('rewarded', adType.placement)
        }}
      >
        {UserStore.isRewardAdReady ? children : '광고 준비 중'}
      </ModalButton>
    )
  },
)

export default VideoAdModalActionButton
