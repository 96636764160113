import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { purchaseCountWithinPeriod } from 'utils/utils'
import backendApis from 'utils/backendApis'
import moment from 'moment'
import BottomSheetStore from 'store/BottomSheetStore'
import UserStore from 'store/UserStore'
import { isBrowsingQuestAvailable } from 'utils/alfarmCPMConstant/utils'

const today = moment().format('YYYY-MM-DD')

const BottomSheetPurchaseQuest = observer(
  ({
    token,
    farmData,
    setFarmData,
    setResourceType,
    setAddWater,
    setShowPopup,
  }) => {
    if (farmData?.giveWaterCount <= 100) return null
    const [todayPurchasedCount, setTodayPurchasedCount] = useState(0)
    const [clearedFirstQuest, setClearedFirstQuest] = useState(false)
    const [clearedSecondQuest, setClearedSecondQuest] = useState(false)
    const [clearedThirdQuest, setClearedThirdQuest] = useState(false)
    const [bannerImage, setBannerImage] = useState(0)

    const DailyPurchaseRewardScreen = async () => {
      window.location.href = `#purchaseRewardWater.${JSON.stringify({
        screen: 'AlfarmPurchaseRewardScreen',
        rewardType: 'dailyPurchaseWater',
        isBrowsingQuestAvailable: isBrowsingQuestAvailable({
          browsingAt: farmData?.waterRewardPurchaseBrowsingAt,
        }),
        scrollQuestReward: '물 10g',
        browsingQuestEnteringComponent: 'Alfarm_waterRewardPurchaseBrowsing',
      })}`
      BottomSheetStore.setIsbottomSheetOpen('basic')
    }

    const questData = (data) =>
      moment(data || '2023-01-01').format('YYYY-MM-DD')

    useEffect(() => {
      setTodayPurchasedCount(purchaseCountWithinPeriod())
    }, [])

    useEffect(() => {
      if (questData(farmData?.bottomSheetPurchaseQuest?.['1']) === today) {
        setClearedFirstQuest(true)
      }
      if (questData(farmData?.bottomSheetPurchaseQuest?.['2']) === today) {
        setClearedSecondQuest(true)
      }
      if (questData(farmData?.bottomSheetPurchaseQuest?.['3']) === today) {
        setClearedThirdQuest(true)
      }
    }, [farmData, today, clearedFirstQuest])

    useEffect(() => {
      if (
        questData(farmData?.bottomSheetPurchaseQuest?.['3']) === today &&
        todayPurchasedCount >= 5
      ) {
        setBannerImage(3)
      } else if (
        questData(farmData?.bottomSheetPurchaseQuest?.['2']) === today &&
        todayPurchasedCount >= 3
      ) {
        setBannerImage(2)
      } else if (
        questData(farmData?.bottomSheetPurchaseQuest?.['1']) === today &&
        todayPurchasedCount >= 2
      ) {
        setBannerImage(1)
      }
    }, [todayPurchasedCount, clearedFirstQuest, farmData])

    const clearedQuest = async (level) => {
      const result = await backendApis.clearedBottomSheetPurchaseQuest(
        token,
        'PUT',
        {
          level: level,
        },
      )

      if (result?.status === 200) {
        let tmpFarmData = farmData
        if (UserStore.waterExceeded) {
          tmpFarmData.water += Math.round(result?.data?.water * 0.5)
          setAddWater(Math.round(result?.data?.water * 0.5))
        } else {
          tmpFarmData.water += result?.data?.water
          setAddWater(result?.data?.water)
        }
        if (!tmpFarmData.bottomSheetPurchaseQuest) {
          tmpFarmData.bottomSheetPurchaseQuest = {}
        }

        // tmpFarmData.water += result?.data?.water;
        tmpFarmData.bottomSheetPurchaseQuest[level] = new Date()
        setFarmData(tmpFarmData)
        BottomSheetStore.setIsbottomSheetOpen('basic')
        setResourceType('water')
        setShowPopup(true)
      }
    }

    const ActionButton1 = () => {
      if (todayPurchasedCount >= 2 && !clearedFirstQuest) {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '8vw',
            }}
            onClick={() => {
              clearedQuest(1)
            }}
          >
            받기
          </div>
        )
      } else if (todayPurchasedCount >= 1 && clearedFirstQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '8.5vw',
            }}
          >
            완료
          </div>
        )
      } else {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '5.25vw',
            }}
            onClick={() => {
              DailyPurchaseRewardScreen()
            }}
          >
            {todayPurchasedCount}/2 구매
          </div>
        )
      }
    }
    const ActionButton2 = () => {
      if (todayPurchasedCount >= 3 && !clearedSecondQuest) {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '38.5vw',
            }}
            onClick={() => {
              clearedQuest(2)
            }}
          >
            받기
          </div>
        )
      } else if (todayPurchasedCount >= 3 && clearedSecondQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '38.5vw',
            }}
          >
            완료
          </div>
        )
      } else if (todayPurchasedCount < 3 && !clearedFirstQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '35.5vw',
            }}
          >
            3번 구매
          </div>
        )
      } else {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '35.5vw',
            }}
            onClick={() => {
              DailyPurchaseRewardScreen()
            }}
          >
            {todayPurchasedCount}/3 구매
          </div>
        )
      }
    }
    const ActionButton3 = () => {
      if (todayPurchasedCount >= 5 && !clearedThirdQuest) {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '68.5vw',
            }}
            onClick={() => {
              clearedQuest(3)
            }}
          >
            받기
          </div>
        )
      } else if (todayPurchasedCount >= 5 && clearedThirdQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '68.5vw',
            }}
          >
            완료
          </div>
        )
      } else if (todayPurchasedCount < 5 && !clearedSecondQuest) {
        return (
          <div
            style={{
              ...styles.normalButton,
              marginLeft: '66vw',
            }}
          >
            5번 구매
          </div>
        )
      } else {
        return (
          <div
            style={{
              ...styles.buttonStyle,
              marginLeft: '66vw',
            }}
            onClick={() => {
              DailyPurchaseRewardScreen()
            }}
          >
            {todayPurchasedCount}/5 구매
          </div>
        )
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          zIndex: 3,
          position: 'absolute',
          top: '-30vw',
          width: '100%',
        }}
      >
        <img
          src='/bottomsheetPurchaseQuest/title.png'
          alt=''
          style={{
            width: '35vw',
            position: 'absolute',
            top: '-7vw',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '-5vw',
            color: '#fff',
            right: '7vw',
            fontSize: '4vw',
          }}
        >
          올팜 어디서든!
        </div>
        <div
          style={{
            background: '#FFF',
            width: '40vw',
            borderRadius: '5vw',
            height: '90%',
            position: 'absolute',
            bottom: '5vw',
            zIndex: -1,
          }}
        />

        <div
          style={{
            background: '#DCF0FF',
            width: '90vw',
            borderRadius: '4vw',
            border: '1.5vw solid #FFF',
            height: '26vw',
          }}
        >
          <img
            src={`/bottomsheetPurchaseQuest/${bannerImage}.png`}
            alt=''
            style={{
              width: '80vw',
              position: 'absolute',
              top: '2vw',
              left: '10vw',
            }}
          />
          <ActionButton1 />
          <ActionButton2 />
          <ActionButton3 />
        </div>
      </div>
    )
  },
)

const styles = {
  buttonStyle: {
    background: '#F97430',
    borderRadius: '2vw',
    fontSize: '3.5vw',
    // bottom: '2.5vw',
    transform: 'translateY(calc(100% + 9.5vw))',
    position: 'absolute',
    color: 'white',
    textAlign: 'center',
    padding: '0.5vw 2vw 0.5vw 2vw',
  },
  normalButton: {
    borderRadius: '2vw',
    fontSize: '3.5vw',
    transform: 'translateY(calc(100% + 8vw))',
    // bottom: '2.5vw',
    position: 'absolute',
    color: '#000000',
    textAlign: 'center',
    padding: '1vw 2vw 1vw 2vw',
  },
}
export default BottomSheetPurchaseQuest
