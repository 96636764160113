import { useQuery } from '@tanstack/react-query'
import backendApis from 'utils/backendApis'

function useGetCPMAdStatusByType({ type, enabled = true }) {
  const query = useQuery({
    queryKey: ['getCPMAdStatusByType', { type }],
    queryFn: () => backendApis.getCPMAdStatusByType({ type }),
    select: (data) => data?.data,
    enabled: !!type && enabled,
  })

  return query
}

export default useGetCPMAdStatusByType
